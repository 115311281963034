


















































import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
import {Dropdown, DropdownItem, DropdownMenu, Upload} from 'element-ui';
import Department from "@/intefaces/Department";
import PaginationComponentMixin from "@/mixins/PaginationComponent";
import AsyncImage from "@/components/AsyncImage.vue";
import RoutePlan from '@/intefaces/RoutePlan';

@Component({
  components: {
    AsyncImage,
  }
})
export default class ViewOutletForRoutePlan extends Mixins(PaginationComponentMixin) {
  @Prop() isActive!: boolean;
  @Prop() currentEntity!: RoutePlan;
  private routePlanOutlets: Department[] = [];

  fetchRoutePlans() {
    this.$http.get(this.$api(`/route-plans/${this.currentEntity.id}/outlets`)).then(response => {
      this.routePlanOutlets = response.data.outlets;
      this.setPagination(response.data);
      this.loading = false;
    })
  }

  created() {
    this.fetchRoutePlans();
  }

  close() {
    this.$emit('update:isActive', false);
  }
}

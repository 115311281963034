









import { Component, Mixins, Prop } from "vue-property-decorator";
import LazyDropdown from "../LazyDropdown.vue";
import DropdownField from "../../mixins/DropdownField";
import CreateCourierBranchForm from "@/views/order/transport/CreateCourierBranchForm.vue";

@Component({
  components: {
    LazyDropdown,
    CreateCourierBranchForm,
  }
})
export default class CourierBranchLazydropdown extends Mixins(DropdownField) {
  @Prop({default: 'courier-branches'}) endPoint!: string;
  @Prop() courier_id!: number | string;
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header border-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('ul',{staticClass:"list-group list-group-flush list my--3"},[_c('li',{staticClass:"list-group-item px-0"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-auto"},[_c('el-button',{attrs:{"icon":"fa fa-arrow-left","circle":""},on:{"click":function($event){return _vm.$router.back()}}})],1),_c('div',{staticClass:"col ml--2"},[_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.currentSurvey ? _vm.currentSurvey.name : ''))]),_c('small',[_vm._v("List of Questions")])])])])])]),_c('div',{staticClass:"col-6"}),_c('div',{staticClass:"col-md-7"},[_c('QuestionLazyDropdown',{attrs:{"placeholder":"Add Question","multiple":true,"end-point":("questions" + (this.queryBuilder.getFilters(true)))},model:{value:(_vm.selectedQuestions),callback:function ($$v) {_vm.selectedQuestions=$$v},expression:"selectedQuestions"}})],1),_c('div',{staticClass:"col-md-3"},[(_vm.selectedQuestions.length > 0)?_c('base-button',{attrs:{"type":"success","loading":_vm.addingQuestion},on:{"click":_vm.addQuestion}},[_vm._v("Add")]):_vm._e()],1)])]),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"table-responsive align-items-center table-flush table-striped",attrs:{"header-row-class-name":"thead-light","data":_vm.questions}},[_c('el-table-column',{attrs:{"align":"center","label":"Title","min-width":"210px","sortable":"","prop":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.title))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"Type","min-width":"170px","sortable":"","prop":"type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.type))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"Answers","min-width":"90px","prop":"id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{attrs:{"content":"Answers","placement":"top"}},[_c('a',{staticClass:"table-action",attrs:{"href":"#!","data-toggle":"tooltip","data-original-title":"Details"},on:{"click":function($event){$event.preventDefault();return _vm.onDetails(row)}}},[_c('i',{staticClass:"fa fa-inbox fa-2x text-info"})])])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"Options","min-width":"210px","sortable":"","prop":"options"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.type != 'textarea' && row.type != 'image')?_c('CustomTooltip',{attrs:{"entities":row.options,"title":"Options","button-text":"View Option"}}):_c('b',[_vm._v(" - ")])]}}])}),_c('el-table-column',{attrs:{"label":"Created At","align":"center","min-width":"170px","prop":"created_at","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(_vm._f("datetime")(row.created_at,'ll')))])]}}])}),_c('el-table-column',{attrs:{"min-width":"120px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"table-actions"},[_c('el-tooltip',{attrs:{"content":"Delete","placement":"top"}},[_c('a',{staticClass:"table-action table-action-delete",attrs:{"href":"#!","data-toggle":"tooltip","data-original-title":"Delete survey"},on:{"click":function($event){$event.preventDefault();(_vm.showDeleteModal = true), (_vm.currentEntity = row)}}},[_c('i',{staticClass:"fas fa-trash text-danger"})])])],1)}}])})],1),(_vm.questions.length > 0)?_c('base-pagination',{staticClass:"mt-3",attrs:{"perPage":_vm.pagination.entityPerPage,"total":_vm.pagination.totalEntity,"align":"center","disabled":_vm.loading},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}}):_vm._e(),_c('modal',{attrs:{"show":_vm.showDeleteModal},on:{"update:show":function($event){_vm.showDeleteModal=$event}}},[[_c('h5',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel1"}},[_vm._v("Are you sure you want to delete this ?")])],_c('template',{slot:"footer"},[_c('base-button',{attrs:{"type":"secondary"},on:{"click":function($event){_vm.showDeleteModal = false}}},[_vm._v("Close")]),_c('base-button',{attrs:{"type":"danger"},on:{"click":function($event){$event.preventDefault();return _vm.deleteEntity($event)}}},[_vm._v("Delete")])],1)],2),_c('SurveyQuestionDetailsModal',{attrs:{"is-active-details":_vm.isActiveDetails,"current-entity":_vm.isActiveDetails ? _vm.currentEntity : null},on:{"update:isActiveDetails":function($event){_vm.isActiveDetails=$event},"update:is-active-details":function($event){_vm.isActiveDetails=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
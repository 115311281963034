






















































































import {Component, Prop, Vue, Watch, Mixins} from 'vue-property-decorator';
import Question from '@/intefaces/Question';
import Answer from '@/intefaces/Answer';
import PaginationComponentMixin from "@/mixins/PaginationComponent";
// @ts-ignore
import PieChart from '@/components/argon-core/Charts/PieChart';
// @ts-ignore
import * as chartConfigs from '@/components/argon-core/Charts/config';
import AsyncImage from "@/components/AsyncImage.vue";


@Component({
  components: {
    AsyncImage,
    PieChart
  }
})
export default class SurveyQuestionDetailsModal extends Mixins(PaginationComponentMixin) {
  @Prop({default: null})
  currentEntity!: any;
  @Prop()
  isActiveDetails!: boolean;
  public exporting: boolean = false;
  public loading: boolean = false;
  private answers: Array<Answer> = [];
  private responseRates: any = [];
  private labels: any = [];
  private allData: any = [];
  private getAverage: any = 0;
  private colors: any = ['#2d9bce', '#497aad', '#39abcd']
  private value1: any = 3.7;

  checkingNumOrStr() {
    if (typeof this.currentEntity.options[0] === 'number') {
      return 'col-12'
    } else return 'col-6'
  }

  private pieChart: any = {
    chartData: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: ['#5e72e4', '#fb6340', '#2dce89', '#2bffc6'],
          label: 'Dataset 1'
        }
      ]
    },
    extraOptions: chartConfigs.blueChartOptions,
  };

  initPieChart(label: any, data: any) {
    let chartData = {
      datasets: [
        {
          label: 'Dataset',
          data: data,
          backgroundColor: ['#5e72e4', '#fb6340', '#2dce89', '#2bffc6'],
        }
      ],
      labels: label
    };
    this.pieChart.chartData = chartData;
  }


  @Watch('currentEntity')
  onChangeCurrentEntity(val: Question) {
    if (!val) return;
    this.fetchAnswers();
    this.fetchResponseRates()
  }

  @Watch('pagination.currentPage')
  async fetchAnswers() {
    this.loading = true;
    this.answers = [];
    const {data} = await this.$http.get(this.$api(`/surveys/${this.currentEntity.pivot.survey_id}/questions/${this.currentEntity.id}/answers`));
    this.answers = data.answers;
    this.setPagination(data)
    this.loading = false;
  }

  async fetchResponseRates() {
    const {data} = await this.$http.get(this.$api(`/surveys/${this.currentEntity.pivot.survey_id}/questions/${this.currentEntity.id}/response-rates`));
    this.responseRates = data.responseRates
    let response = data.responseRates
    let totalRating = 0;
    this.labels = Object.keys(response)
    this.allData = Object.values(response)
    for (let i = 0; i < this.labels.length; i++) {
      totalRating += this.labels[i] * this.allData[i]
    }
    this.getAverage = totalRating / 100
    if (typeof this.currentEntity.options[0] === 'string') {
      this.initPieChart(this.labels, this.allData)
    }
  }

  exportAnswers() {
    //TODO-miraj: implement answer export
    throw 'Need to implement';
  }

  close() {
    this.$emit('update:isActiveDetails', false);
  }
}

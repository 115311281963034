









import {Component, Mixins, Prop} from "vue-property-decorator";
import LazyDropdown from "../LazyDropdown.vue";
import DropdownField from "../../mixins/DropdownField";
import VariantCategoryForm from "@/components/products/VariantCategoryForm.vue";
import VariantCategory from "@/intefaces/VariantCategory";

@Component({
  components: {
    LazyDropdown,
    VariantCategoryForm
  }
})
export default class VariantCategoryLazydropdown extends Mixins(DropdownField) {
  @Prop() addButton!: boolean;
}





























































































































































































import {Component, Mixins, Watch} from 'vue-property-decorator';
import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tag, Tooltip} from 'element-ui';
import GIFModal from '@/components/tour/GIFModal.vue';
import FilterDrawer from "@/components/FilterDrawer.vue";
import InvoiceDetails from "@/views/order/invoice/InvoiceDetails.vue";
import PaginationComponentMixin from "@/mixins/PaginationComponent";
import {Invoice} from "@/intefaces/Invoice";
import InvoiceAmountDetailsForm from "@/views/order/invoice/InvoiceAmountDetailsForm.vue";
import InvoicePaymentDetailsForm from "@/views/order/invoice/InvoicePaymentDetailsForm.vue";
import OrderRevision from "@/intefaces/OrderRevision";
import RevisionList from "@/views/order/RevisionList.vue";
import TaxonomyMixin from "@/mixins/TaxonomyComponent";
import {Permission as PermissionEnum} from '@/enums/Permission'
import CreateTransportationInfoForm from "@/views/order/transport/CreateTransportationInfoForm.vue";
import DispatchInvoiceDetails from "@/views/order/readyToDispatch/DispatchInvoiceDetails.vue";


@Component({
  components: {
    FilterDrawer,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
    GIFModal,
    InvoiceDetails,
    InvoiceAmountDetailsForm,
    InvoicePaymentDetailsForm,
    RevisionList,
    CreateTransportationInfoForm,
    DispatchInvoiceDetails,
  }
})
export default class InvoiceList extends Mixins(PaginationComponentMixin, TaxonomyMixin) {
  private invoices: Invoice[] = [];
  private currentInvoice: any = [];
  private allRevision: OrderRevision[] = [];
  private invoiceDetails: Invoice[] = [];
  private showGIF: boolean = false;
  private showTransportationInfo: boolean = false;
  private currentInvoiceId: string = '';
  private demoTourEntity: string = 'invoice';
  private isShowAmountDetails: boolean = false;
  private isShowPaymentDetails: boolean = false;
  private isActiveInvoiceDetails: boolean = false;
  private renderKey: number = 0;
  private isTransportationUpdatable: boolean = false;
  private form = {
    status: '',
    remarks: ''
  }
  private GIFs: Array<any> = [
    {
      label: 'Create a invoice',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a invoice',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a invoice',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of invoice';

  get permissionEnum() {
    return PermissionEnum;
  }

  entityUpdated(entity: Invoice): void {
    this.invoices.splice(
      this.invoices.findIndex(type => type.id == entity.id),
      1
    );
    this.isActiveInvoiceDetails = false;
  }

  @Watch('invoiceDetails')
  async updateRenderKey() {
    await this.fetchInvoices();
    this.renderKey += 1;
  }

  showTransportationModal(row: any) {
    if(row.transportation_info){
      this.isTransportationUpdatable = true;
    }
    this.currentInvoiceId = row.id;
    this.showTransportationInfo = true;
  }

  changeInTRInfo() {
    this.showTransportationInfo = false;
    this.updateRenderKey();
  }

  @Watch('pagination.currentPage')
  async fetchInvoices() {
    this.loading = true;
    try {
      let {data} = await this.$http.get(
        this.$api(`/invoices?latest_status=readyToDispatch&with=payments&page=${this.pagination.currentPage}`)
      );
      this.setPagination(data);
      this.invoices = data.invoices;
    } catch (e) {
      this.$notify({title: 'Something Went Wrong!', message: 'Please Try again.', type: 'error'});
    } finally {
      this.loading = false;
    }
  }

  async created() {
    await this.fetchInvoices();
  }
}










































































































































































import TerritoryLazydropdown from '@/components/lazyDropdown/TerritoryLazydropdown.vue';
import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator';
import TargetModalData from '@/mixins/TargetModalData';
import RegularTargetComponent from '@/views/user/target/RegularTargetComponent.vue';
import RouteWiseTargetListComponent from '@/views/user/target/RouteWiseTargetListComponent.vue';
import RouteWiseTargetLists from '@/intefaces/RouteWiseTargetLists';
import TaxonomyMixin from '@/mixins/TaxonomyComponent';
import moment from 'moment';

@Component({
  components: {
    TerritoryLazydropdown,
    RegularTargetComponent,
    RouteWiseTargetListComponent
  }
})
export default class RouteWiseTargetComponent extends Mixins(TargetModalData) {
  @Prop() routeWiseTargetLists!: RouteWiseTargetLists[];
  @Prop() routeColumnTitle!: string;
  private updatedTarget: boolean = false;
  private targetIndex: any = '';

  async addedRouteWiseTargetList() {
    let territoryName: string = '';
    await this.$http.get(this.$api(`/territories/${this.form.territory_id}`)).then(response => {
      territoryName = response.data.territory.name;
    });
    if (this.updatedTarget) {
      this.routeWiseTargetLists.splice(this.targetIndex, 1);
      this.updatedTarget = false;
    }
    this.routeWiseTargetLists.push({
      territory_id: this.form.territory_id,
      type: this.form.type,
      scheme: this.form.scheme,
      amount: this.form.amount,
      start_from: this.form.start_from,
      unit: this.form.unit,
      expiresDate: this.form.expiresDate,
      status: this.form.status,
      products: this.products ? this.products : [],
      assignees: this.form.assignTo,
      territoryName: territoryName
    });
    this.formReset();
  }

  editTarget(event: any) {
    this.form.territory_id = event.row.territory_id;
    this.form.type = event.row.type;
    this.form.expiresDate = event.row.expiresDate;
    this.form.unit = event.row.unit;
    this.form.amount = event.row.amount;
    this.form.scheme = event.row.scheme;
    this.form.start_from = event.row.start_from;
    this.form.status = event.row.status;
    this.form.assignTo = event.row.assignees;
    if (event.row.products.length > 0) {
      this.products = event.row.products;
    }
    this.targetIndex = event.index;
    this.updatedTarget = true;
  }

  deleteTarget(index: number) {
    this.routeWiseTargetLists.splice(index, 1);
  }
}






















































































































































































import { Component, Watch } from 'vue-property-decorator';
import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tag, Tooltip } from 'element-ui';
import { mixins } from 'vue-class-component';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import DepartmentProduct from '@/intefaces/DepartmentProduct';
import Department from '@/intefaces/Department';
import ProductDetailsForm from '@/views/stock/departmentStock/ProductDetailsForm.vue';
import DepartmentDetailsForm from '@/views/stock/departmentStock/DepartmentDetailsForm.vue';
import DepartmentStockFilterDrawer from '@/views/stock/departmentStock/DepartmentSrockFilterDrawer.vue';
import GIFModal from '@/components/tour/GIFModal.vue';
import TaxonomyMixin from '@/mixins/TaxonomyComponent';
import FileInput from '@/components/argon-core/Inputs/FileInput.vue';
import { AxiosError, AxiosResponse } from 'axios';

@Component({
  components: {
    GIFModal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
    ProductDetailsForm,
    DepartmentDetailsForm,
    DepartmentStockFilterDrawer,
    FileInput
  }
})
export default class DepartmentStockList extends mixins(PaginationComponentMixin, TaxonomyMixin) {
  //variable section +++
  private departments: Department[] = [];
  private stocks: any = [];
  private isFilterModelActive: boolean = false;
  private isActiveProductDetails = false;
  private currentDepartmentProduct: DepartmentProduct[] = [];
  private isActiveDepartment = false;
  private isBuyer = false;
  private showGIF: boolean = false;
  private demoTourEntity: string = 'departmentStock';
  public AddModal: boolean = false;
  public UpdateModal: boolean = false;
  public downloading: boolean = false;
  public btnDisabled: boolean = false;
  private csvFile: Blob | string = '';
  private errors: Array<string> = [];
  private is_owner: boolean = false;
  private GIFs: Array<any> = [
    {
      label: 'Create a department stock',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a department stock',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a department stock',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of department stock';
  // Variable section ---

  //method +++
  buyerDepartment(row: any) {
    this.isActiveDepartment = true;
    this.isBuyer = true;
    this.currentDepartmentProduct = row;
  }

  //method ---

  //Watcher +++
  @Watch('departmentFilter')
  filterDepartmentWiseProduct(newVal: any) {
    if (newVal == 'all') {
      this.fetchDepartmentProduct();
    } else {
      this.loading = true;
      this.$http.get(this.$api(`/stocks?department_id=${newVal}`)).then(response => {
        this.stocks = response.data.products;
        this.setPagination(response.data);
        this.loading = false;
      });
    }
  }
  filesChange(files: FileList) {
    this.csvFile = files[0];
  }
  _import() {
    this.btnDisabled = true;
    let form = new FormData();
    form.append('bulkStock', this.csvFile);
    this.$http
      .post(this.$api('/stock/bulk-import/add'), form)
      .then((response: AxiosResponse) => {
        this.$notify({
          title: 'Success',
          message: response.data.message,
          duration: 3000,
          iconClass: 'ni ni-bell-55',
          type: 'success'
        });
        this.btnDisabled = false;
        this.AddModal = false;
        this.fetchDepartmentProduct();
        this.errors = [];
      })
      .catch((err: AxiosError) => {
        if (this.csvFile) {
          this.$notify({
            title: 'Warning',
            message: 'Please import the correct file',
            duration: 3000,
            iconClass: 'ni ni-bell-55',
            type: 'warning'
          });
        } else {
          this.errors = err.response?.data.errors;
        }
        this.btnDisabled = false;
      });
  }
  importFileUpdate() {
    this.btnDisabled = true;
    let form = new FormData();
    form.append('bulkStock', this.csvFile);
    this.$http
      .post(this.$api('/stock/bulk-import/update'), form)
      .then((response: AxiosResponse) => {
        this.$notify({
          title: 'Success',
          message: response.data.message,
          duration: 3000,
          iconClass: 'ni ni-bell-55',
          type: 'success'
        });
        this.btnDisabled = false;
        this.UpdateModal = false;
        this.fetchDepartmentProduct();
        this.errors = [];
      })
      .catch((err: AxiosError) => {
        if (this.csvFile) {
          this.$notify({
            title: 'Warning',
            message: 'Please import the correct file',
            duration: 3000,
            iconClass: 'ni ni-bell-55',
            type: 'warning'
          });
        } else {
          this.errors = err.response?.data.errors;
        }
        this.btnDisabled = false;
      });
  }
  async onClickDownload() {
    this.downloading = true;
    try {
      let { data, headers } = await this.$http.get(this.$api('/stock/bulk-export?download'), {
        responseType: 'arraybuffer'
      });
      const blob = await new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = await URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({ title: 'Something wants wrong!', type: 'warning', message: 'Unable to download reports.' });
    } finally {
      this.downloading = false;
    }
  }

  // Lifecycle hooks +++
  fetchDepartmentProduct() {
    this.loading = true;
    this.$http.get(this.$api(`/stocks?page=${this.pagination.currentPage}`)).then(response => {
      this.stocks = response.data.stocks;
      this.setPagination(response.data);
      this.loading = false;
    });
  }

  fetchDepartment() {
    this.$http.get(this.$api(`/departments`)).then(response => {
      this.departments = response.data.departments;
    });
  }
  currentUser() {
    this.$http.get(this.$api(`/owner-status`)).then(response => {
      this.is_owner = response.data.is_owner == 1 ? true : false;
    });
  }

  created() {
    this.currentUser();
    this.fetchDepartmentProduct();
    this.fetchDepartment();
  }

  // Lifecycle hooks ---
}

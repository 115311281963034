






















































































//@ts-ignore
import {Component, Mixins, Vue, Watch} from 'vue-property-decorator';
import Payment from '@/intefaces/Payment';
import CreatePaymentForm from '@/views/payment/CreatePaymentForm.vue';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import GIFModal from '@/components/tour/GIFModal.vue';

@Component({
  components: {CreatePaymentForm, GIFModal}
})
export default class AdvancePaymentList extends Mixins(PaginationComponentMixin) {
  private entities: Payment[] = [];
  private showConfirmModal: boolean = false;
  private isActive: boolean = false;
  private currentEntity!: Payment;
  private form: Payment = {
    advance: false,
    amount: null,
    created_by: 0,
    department_id: null,
    invoices: [],
    method: null,
    paid_at: null
  };
  loading = false;
  private showGIF: boolean = false;
  private demoTourEntity: string = 'paymentList';
  private GIFs: Array<any> = [
    {
      label: 'Create a payment',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a payment',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a payment',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of payment';

  mounted() {
    this.fetchEntity();
  }

  @Watch('pagination.currentPage')
  async fetchEntity() {
    this.loading = true;
    let {data} = await
      this.$http.get(this.$api(`/payments?advance=1&page=${this.pagination.currentPage}&with=department,seller`));
    this.entities = data.payments;
    this.setPagination(data);
    this.loading = false;
  }

  entityCreated(department: Payment) {
    this.entities.unshift(department);
  }
}

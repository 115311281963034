































import {Component, Prop, Vue} from "vue-property-decorator";
import FileInput from '@/components/argon-core/Inputs/FileInput.vue'
import {AxiosError, AxiosResponse} from "axios";

@Component({
  name: 'CreateBulkUsers',
  components: {
    FileInput
  }
})
export default class CreateBulkUsers extends Vue {
  private btnDisabled: boolean = false;
  private csvFile: Blob|string = '';
  private errors: Array<string> = [];

  @Prop() showBulkUsersModal!: boolean;

  close() {
    this.errors = [];
    this.$emit('update:showBulkUsersModal', false);
  }

  _import() {
    this.btnDisabled = true;
    let form = new FormData();
    form.append('users', this.csvFile);
    this.$http.post(this.$api('/import-users'), form)
      .then((response: AxiosResponse) => {
        this.$notify({
          title: 'Success',
          message: response.data.message,
          duration: 3000,
          iconClass: 'ni ni-bell-55',
          type: 'success'
        });
        this.btnDisabled = false;
        this.errors = [];
        this.$emit('update:showBulkUsersModal', false);
        //@ts-ignore
        this.$parent.fetchUser();
      }).catch((err: AxiosError) => {
        this.errors = err.response?.data.errors;
        this.btnDisabled = false;
    });
  }

  filesChange(files: FileList) {
    this.csvFile = files[0];
  }
}

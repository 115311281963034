































































































import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator';
import Target from '@/intefaces/Target';
import UserAutocomplete from '@/components/lazyDropdown/UserLazydropdown.vue';
import ProductLazydropdown from '@/components/lazyDropdown/ProductLazydropdown.vue';
import TaxonomyMixin from '@/mixins/TaxonomyComponent';
import RegularTargetComponent from '@/views/user/target/RegularTargetComponent.vue';
import RouteWiseTargetComponent from '@/views/user/target/RouteWiseTargetComponent.vue';
import RouteWiseTargetLists from '@/intefaces/RouteWiseTargetLists';
import { TabPane, Tabs } from 'element-ui';
import FileInput from '@/components/argon-core/Inputs/FileInput.vue';
import { AxiosError, AxiosResponse } from 'axios';
@Component({
  components: {
    UserAutocomplete,
    ProductLazydropdown,
    RegularTargetComponent,
    RouteWiseTargetComponent,
    FileInput,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane
  },
  name: 'TargetForm'
})
export default class TargetForm extends Mixins(TaxonomyMixin) {
  @Prop() isBulkActive!: boolean;
  @Prop() isUpdate!: boolean;
  @Prop() currentTraget!: Target;
  @Prop() currentLableForInvoice!: string;
  @Prop() passTargetAssignId!: number;
  @Prop() routeColumnTitle!: string;
  private disableSaveBtn = false;
  private routeWiseTargetLists: RouteWiseTargetLists[] = [];
  public BulkAddModal: boolean = false;
  public BulkUpdateModal: boolean = false;
  private csvFile: Blob | string = '';
  public actionOn: string = '';
  public theScheme: string = '';
  public errors: Array<string> = [];
  public downloading: boolean = false;
  public btnDisabled: boolean = false;

  data() {
    return {
      types: [
        {
          value: 'invoice',
          label: 'Invoice'
        },
        {
          value: 'order',
          label: 'Order'
        },
        {
          value: 'delivery',
          label: 'Delivery'
        }
      ],
      schemes: [
        {
          val: 'onetime',
          label: 'ONETIME'
        }
      ],
      value: '',
      val: ''
    };
  }

  filesChange(files: FileList) {
    this.csvFile = files[0];
  }
  modalAddView() {
    this.isBulkActive = false;
    this.BulkAddModal = true;
  }
  modalUpdateView() {
    this.BulkUpdateModal = true;
    this.isBulkActive = false;
  }
  _import() {
    this.btnDisabled = true;
    let form = new FormData();
    form.append('bulkTarget', this.csvFile);
    this.$http
      .post(this.$api(`/target/bulk-import/add?target_name_id=${this.passTargetAssignId}&action_on=${this.actionOn}&scheme=${this.theScheme}`), form)
      .then((response: AxiosResponse) => {
        this.$notify({
          title: 'Success',
          message: response.data.message,
          duration: 3000,
          iconClass: 'ni ni-bell-55',
          type: 'success'
        });
        this.btnDisabled = false;
        this.BulkAddModal = false;
        this.actionOn = '';
        this.theScheme = '';
        this.errors = [];
        this.$emit('created', response.data);
      })
      .catch((err: any) => {
        if (this.csvFile) {
          if (err.response.status == 422) {
            this.errors = err.response?.data.errors;
          } else {
            this.$notify({
              title: 'Warning',
              message: 'Please import the correct file',
              duration: 3000,
              iconClass: 'ni ni-bell-55',
              type: 'warning'
            });
          }
        } else {
          this.errors = err.response?.data.errors;
        }
        this.btnDisabled = false;
      });
  }
  importFileUpdate() {
    this.btnDisabled = true;
    let form = new FormData();
    form.append('bulkTarget', this.csvFile);
    this.$http
      .post(
        this.$api(`/target/bulk-import/update?target_name_id=${this.passTargetAssignId}&action_on=${this.actionOn}&scheme=${this.theScheme}`),
        form
      )
      .then((response: AxiosResponse) => {
        this.$notify({
          title: 'Success',
          message: response.data.message,
          duration: 3000,
          iconClass: 'ni ni-bell-55',
          type: 'success'
        });
        this.btnDisabled = false;
        this.BulkUpdateModal = false;
        this.actionOn = '';
        this.theScheme = '';
        this.errors = [];
        this.$emit('created', response.data);
      })
      .catch((err: any) => {
        if (this.csvFile) {
          if (err.response.status == 422) {
            this.errors = err.response?.data.errors;
          } else {
            this.$notify({
              title: 'Warning',
              message: 'Please import the correct file',
              duration: 3000,
              iconClass: 'ni ni-bell-55',
              type: 'warning'
            });
          }
        } else {
          this.errors = err.response?.data.errors;
        }
        this.btnDisabled = false;
      });
  }
  async onClickDownload() {
    this.downloading = true;
    try {
      let { data, headers } = await this.$http.get(this.$api('/target/bulk-export?download'), {
        responseType: 'arraybuffer'
      });
      const blob = await new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = await URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({ title: 'Something wants wrong!', type: 'warning', message: 'Unable to download reports.' });
    } finally {
      this.downloading = false;
    }
  }

  notify(title: string, message: string, type: 'success' | 'warning' | 'info' | 'error') {
    this.$notify({
      title: title,
      message: message,
      duration: 3000,
      iconClass: 'ni ni-bell-55',
      type: type
    });
  }
  closeBulkUpload() {
    this.BulkAddModal = false;
    this.errors = [];
    this.theScheme = '';
    this.actionOn = '';
  }
  close() {
    this.$emit('update:isBulkActive', false);
    this.$emit('assignIdFromTargetAssignReset', (this.passTargetAssignId = 0));
    this.routeWiseTargetLists = [];
    this.disableSaveBtn = false;
  }
}

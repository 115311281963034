















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {Drawer} from "element-ui";
import TerritoryLazydropdown from "@/components/lazyDropdown/TerritoryLazydropdown.vue";
import ProductLazydropdown from "@/components/lazyDropdown/ProductLazydropdown.vue";
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
// @ts-ignore
const flatPicker =require("vue-flatpickr-component");
import "flatpickr/dist/flatpickr.css";
@Component({
    components: {
        TerritoryLazydropdown,
        ProductLazydropdown,
        UserLazydropdown,
        flatPicker
    }
})
export default class RevenueTargetVsAchievementFilter extends Vue{
    @Prop()
    isActive!: boolean;
    @Prop({
        default: () => ({
            type: 'all',
            territories: [],
            fieldForces: [],
            products: [],
            range: 'string'
        })
    })
    filters!: {
        type: string
        territories: any,
        fieldForces: any,
        products: any,
        range: string
    }

    private data: any = [];
    private types: any = [
        {label: 'All', value: 'all'},
        {label: 'Territory', value: 'territory'},
        {label: 'Field Force', value: 'field_force'},
        {label: 'Product', value: 'product'},
    ]
    private query: any = [];
    private afterQuery: boolean = false;
    private selectedType: any = 'All';

    @Watch('filters', {immediate: true, deep: true})
    onChangeFilter(newVal: any){
        this.$emit('updateFilter', this.filters)
    }

    close(){
        this.$emit('update:isActive', false);
    }
}

















































import { Vue, Component, Prop } from 'vue-property-decorator';
import Statuses from "@/intefaces/Statuses";
import { Button, Popover, Table, TableColumn, Tooltip } from "element-ui";

@Component({
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Popover.name]: Popover,
    [Button.name]: Button,
  }
})
export default class BaseOrderComment extends Vue {
  @Prop() commentFromSr!: string;
  @Prop() remarks!: Statuses;
  @Prop() commentor!: string;
}


var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xl-6 col-md-6 "},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.showModal = true}}},[_c('stats-card',{directives:[{name:"can",rawName:"v-can",value:(_vm.permissionEnum.PRODUCT_BASED_REPORT),expression:"permissionEnum.PRODUCT_BASED_REPORT"}],attrs:{"title":"","type":"gradient-green","sub-title":"Product Summary Report","icon":"ni ni-bullet-list-67"}},[_c('template',{slot:"footer"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"}),_c('div',{staticClass:"col-md-6"},[_c('el-tooltip',{staticClass:"ml-3",attrs:{"content":"Download Product Summary Report","placement":"right"}},[_c('i',{staticClass:"fas fa-info-circle",staticStyle:{"color":"#5e72e4","font-size":"28px","margin-right":"0.60rem","float":"right"}})])],1)])])],2)],1),(_vm.showModal)?_c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('modal',{staticClass:"modal-dialog-scrollable",attrs:{"show":_vm.showModal,"size":"lg"},on:{"close":_vm.close}},[_c('template',{slot:"header"},[_c('h5',{staticClass:"modal-title"},[_vm._v(" Product Summary Report")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('base-input',{attrs:{"addon-left-icon":"ni ni-calendar-grid-58","label":"Select Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var focus = ref.focus;
var blur = ref.blur;
return _c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":_vm.dateSelectConfig},on:{"on-open":focus,"on-close":blur},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})}}],null,true)})],1),_c('div',{staticClass:"col-3"},[_c('UserLazydropdown',{attrs:{"disabled":!_vm.date,"label":"Select User(Optional)","add-button":false},model:{value:(_vm.user_id),callback:function ($$v) {_vm.user_id=$$v},expression:"user_id"}})],1)]),_c('div',{staticClass:"card"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"table-responsive  table-flush table-striped",attrs:{"header-row-class-name":"thead-light","data":_vm.productSummaryReport}},[_c('el-table-column',{attrs:{"label":"SL","min-width":"100px","prop":"name","sortable":"","type":"index"}}),_c('el-table-column',{attrs:{"label":"SKU","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.sku))])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Product Name","prop":"Product Name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.product_name))])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Variant","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(" - ")])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Batch Name","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.batch_name))])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"QTY","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.qty))])]}}],null,true)})],1),_c('div',{staticClass:"card-footer"},[_vm._v(" Total QTY : "+_vm._s(_vm.productSummaryReport.reduce(function (n, ref) {
	var qty = ref.qty;

	return n + qty;
}, 0))+" ")])],1),_c('template',{slot:"footer"},[_c('base-button',{attrs:{"type":"secondary"},on:{"click":_vm.close}},[_vm._v("Close")]),_c('base-button',{attrs:{"disabled":!_vm.date,"loading":_vm.downloading,"type":"primary"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.onClickDownload)}}},[_c('i',{staticClass:"fa fa-download"})]),_c('base-button',{attrs:{"disabled":!_vm.date,"loading":_vm.printing,"type":"secondary"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.onClickPrint)}}},[_c('i',{staticClass:"fa fa-print"})])],1)],2)]}}],null,false,3715710334)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }










































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Dropdown, DropdownItem, DropdownMenu, Upload} from 'element-ui';
import VariantCategory from "../../../intefaces/VariantCategory";
import CreateVariant from "@/views/product/productModalComponent/CreateVariant.vue";

@Component({
  components: {
    CreateVariant
  }
})
export default class ViewProductVariant extends Vue {
  @Prop() isActive!: boolean;
  @Prop() product!: any;
  private isUpdateProductVariant: boolean = false;
  private isActiveCreateVariant: boolean = false;
  private productVariant: VariantCategory[] = [];
  private loading: boolean = false;
  private currentProductVariant: any = [];
  private showDeleteModal: boolean = false;
  private singleVariant = {
    value: '',
    variant: '',
  }

  onDelete() {
    this.$http.delete(this.$api(`/product-variants/${this.currentProductVariant.id}`)).then(response => {
      this.showDeleteModal = false;
      this.$notify({
        title: 'Success',
        message: 'Successfully Deleted',
        duration: 3000,
        iconClass: 'ni ni-bell-55',
        type: 'success'
      });
      this.productVariant.splice(this.productVariant.findIndex(variant => variant.id == this.currentProductVariant.id),
        1)
    })
  }

  onEdit(row: any) {
    this.currentProductVariant = row;
    this.singleVariant = {
      value: this.currentProductVariant.value,
      variant: this.currentProductVariant.variant_category_id,
    }
    this.isUpdateProductVariant = true;
    this.isActiveCreateVariant = true;
  }

  updateVariant(event: any) {
    this.productVariant.splice(this.productVariant.findIndex(variant => variant.id === event.id), 1, event);
    this.isActiveCreateVariant = false;
    this.isUpdateProductVariant = false;
  }

  addVariant(event: any) {
    this.productVariant.unshift(event);
  }

  fetchVariant() {
    this.loading = true;
    this.$http.get(this.$api(`/products/${this.product.id}?with=variants`)).then(response => {
      this.productVariant = response.data.product.variants;
      this.loading = false;
    })
  }

  created() {
    this.fetchVariant();
  }

  close() {
    this.$emit('update:isActive', false);
  }
}

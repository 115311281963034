


















































































































































import {Vue, Component, Prop, Watch, Mixins} from 'vue-property-decorator';
import RequisitionStatusAutocomplete from "@/components/resources-autocomplete/RequisitionStatusAutocomplete.vue";
import RequisitionStatuses from "@/data/OrderStatus";
import {AxiosResponse} from "axios";
import OrderRevision from "@/intefaces/OrderRevision";
import RevisionList from "@/views/order/RevisionList.vue";
import AsyncImage from "@/components/AsyncImage.vue";
import TaxonomyMixin from "@/mixins/TaxonomyComponent";
import { Permission as PermissionEnum } from '@/enums/Permission'

@Component({
  name: 'RequisitionDetails',
  components: {
    AsyncImage,
    RequisitionStatusAutocomplete,
    RevisionList
  }
})
export default class RequisitionDetails extends Mixins(TaxonomyMixin) {
  @Prop() isActive!: boolean;
  @Prop() currentRequisition!: any;
  @Prop() filter!: any;
  private lastestOrderRevision: OrderRevision[] = [];
  private orderRevisions: OrderRevision[] = [];
  private statuses: any = [];
  private loading = false;
  private isStatusHistoryActive: boolean = false;
  private isViewRevision: boolean = false;
  private isRequisition: boolean = false;
  private requisitionPagination = {
    currentPage: 1,
    total: 0,
    perPage: 0
  };
  private isStatusUpdateActive: boolean = false;
  private form: any = {
    status: '',
    remarks: ''
  }
  private filterStatus: any = '';
  requisitionStatuses = RequisitionStatuses;

  get permissionEnum () {
    return PermissionEnum;
  }

  deleteRejectStatus(status: any) {
    if (status.find((status: any, index: any) => status.status == 'accepted') && this.requisitionStatuses.find(status => status.value == 'rejected')) {
      this.requisitionStatuses.splice(this.requisitionStatuses.findIndex((status: any) => status.value == 'rejected'), 1);
    } else if (!status.find((status: any, index: any) => status.status == 'accepted') && !this.requisitionStatuses.find(status => status.value == 'rejected')) {
      this.requisitionStatuses.splice(3, 0, {title: 'Rejected', value: 'rejected'});
    }
  }

  async fetchImageSrc(image: any) {
    await this.$blob2Url(image).then((response: any) => {
      return response;
    });
  }

  save() {
    this.$http.post(this.$api(`/orders/${this.currentRequisition.id}/statuses`),
      {status: this.form.status, remarks: this.form.remarks})
      .then(response => {
        this.isStatusUpdateActive = false;
        // this.$emit('created',response.data.status);
        this.statuses.unshift(response.data.status);
        this.deleteRejectStatus(this.statuses);
        let data = [...this.statuses];
        this.currentRequisition.statuses = data.reverse();
        this.$emit('update:currentRequistion', this.currentRequisition);
      })
  }

  @Watch('isActive')
  fetchStatus() {
    let isFirstPage = this.requisitionPagination.currentPage < 2;
    let url = isFirstPage
      ? `/orders/${this.currentRequisition.id}/statuses`
      : `/orders/${this.currentRequisition.id}/statuses?page=${this.requisitionPagination.currentPage}`;
    this.$http.get(this.$api(url)).then(response => {
      this.statuses = response.data.statuses;

      this.deleteRejectStatus(this.statuses);

      this.requisitionPagination.total = response.data.total;
      this.requisitionPagination.perPage = response.data.per_page;
    })

    this.$http.get(this.$api(`/orders/${this.currentRequisition.id}/revisions`)).then(response => {
      this.orderRevisions = response.data.revisions
      this.lastestOrderRevision = response.data.revisions[0];
    })
  }

  get getCurrency() {
    return this.$store.getters['Settings/getCurrency']
  }

  // @Watch('requisitionPagination.currentPage')
  // private async fetchStatus(){
  //     let {data} = await this.$http.get(this.$api(`/requisitions/${this.currentRequisition.id}/statuses?page=${this.requisitionPagination.currentPage}`));
  //     this.statuses = data.statuses;
  //     this.requisitionPagination.total = data.total;
  //     this.requisitionPagination.perPage = data.per_page;
  // }

  close() {
    this.$emit('update:isActive', false);
  }
}
















































































































import {Vue, Component, Mixins, Watch} from 'vue-property-decorator';
import CreateBadgeForm from '@/views/department/CreateBadgeForm.vue';
import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tooltip, Tag} from 'element-ui';
import Badge from '@/intefaces/Badge';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import GIFModal from '@/components/tour/GIFModal.vue';

@Component({
  components: {
    CreateBadgeForm,
    GIFModal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag
  }
})
export default class BadgeList extends Mixins(PaginationComponentMixin) {
  private badges: Badge[] = [];
  private showDeleteModal = false;
  private isActive = false;
  private isUpdate = false;
  private showGIF: boolean = false;
  private currentEntity!: Badge;
  protected form = {
    name: '',
    mrp_discount: 0
  };
  private demoTourEntity: string = 'badge';
  private GIFs: Array<any> = [
    {
      label: 'Create a badge',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a badge',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a badge',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of badge';

  created() {
    this.fetchEntity();
  }

  entityCreated(entity: Badge) {
    this.badges.unshift(entity);
  }

  entityUpdated(entity: Badge): void {
    this.badges.splice(
      this.badges.findIndex(type => type.id == entity.id),
      1,
      entity
    );
  }

  onEdit(row: Badge) {
    this.isUpdate = true;
    this.currentEntity = row;
    this.form = {
      name: this.currentEntity.name,
      mrp_discount: this.currentEntity.mrp_discount
    };
    this.isActive = true;
  }

  @Watch('pagination.currentPage')
  fetchEntity(): void {
    this.loading = true;
    this.$http.get(this.$api(`/badges?page=${this.pagination.currentPage}`)).then(response => {
      this.badges = response.data.badges;
      this.setPagination(response.data);
      this.loading = false;
    });
  }

  deleteEntity(): void {
    this.$http.delete(this.$api(`/badges/${this.currentEntity.id}`)).then(response => {
      this.showDeleteModal = false;
      this.badges.splice(
        this.badges.findIndex(value => value.id == this.currentEntity.id),
        1
      );
      this.$notify({
        title: 'Success',
        message: 'Successfully Deleted',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'success'
      });
    });
  }
}




















import { Component, Mixins, Prop } from "vue-property-decorator";
import LazyDropdown from "../LazyDropdown.vue";
import DropdownField from "@/mixins/DropdownField";
import CreateUser from "@/views/user/user/CreateUser.vue";

@Component({
  components: {
    LazyDropdown,
    CreateUser
  }
})
export default class DepartmentLocationTypeLazyDropdown extends Mixins(DropdownField) {
  @Prop({default: 'department-location-types'})
  public endPoint!: string;

  @Prop({default: false})
  public collapseTags!: boolean;

  @Prop({default: 'name'})
  public searchableKey!: string;


  @Prop({default: true})
  public fetchOnChangeDisable!: boolean;

}

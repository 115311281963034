























































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {QueryBuilder} from "@/classes/QueryBuilder";
import moment from "moment";
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import printJS from "print-js";
import { Permission as PermissionEnum } from '@/enums/Permission'

const flatPicker = require('vue-flatpickr-component');
@Component({
  components: {
    UserLazydropdown,
    flatPicker,
  }
})
export default class ProductSummaryReport extends Vue {
  public downloading: boolean = false;
  public loading: boolean = false;
  public printing: boolean = false;
  private showModal: boolean = false;
  public user_id: number | null = null;
  public queryBuilder: QueryBuilder = new QueryBuilder();
  public productSummaryReport = [];
  public date: string = '';
  public dateSelectConfig = {
    allowInput: true,
    disable: [
      function (date: Date) {
        return moment().diff(date) < 0;
      }
    ]
  }

  get permissionEnum () {
    return PermissionEnum;
  }

  todaysDate() {
    var currentDate = new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, '/');
    return currentDate;
  }

  @Watch('queryBuilder.watch')
  async getProductSummaryReport() {
    this.loading = true;
    try {
      let {data} = await this.$http.get(this.$api('/product-summary-report' + this.queryBuilder.getFilters(true)))
      this.productSummaryReport = data.productSummaryReport;
    } catch (e) {
      this.$notify({message: "Please contact your service provider.", title: "Something went wrong!", type: 'warning'})
    } finally {
      this.loading = false;
    }
  }

  async onClickDownload() {
    this.downloading = true;
    try {
      let {data, headers} = await this.$http.get(this.$api('/product-summary-report?download&' + this.queryBuilder.getFilters()), {responseType: 'arraybuffer'});
      const blob = await new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const link = document.createElement("a");
      link.href = await URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({title: 'Something wants wrong!', type: 'warning', message: 'Unable to download reports.',})
    } finally {
      this.downloading = false;
    }
  }

  async reportObjectUrl() {
    let {data} = await this.$http.get(
        this.$api('/product-summary-report?download&' + this.queryBuilder.getFilters()),
        {
          responseType: 'blob'
        }
    );
    const blob = await new Blob([data], {type: 'application/pdf'});
    let fileURL = await URL.createObjectURL(blob);
    return fileURL;
  }

  async onClickPrint() {
    this.printing = true;
    try {
      await printJS({printable: await this.reportObjectUrl()});
    } catch (e) {
      this.$notify({title: 'Something Wrong!', message: 'Please Try again.', type: 'warning'});
    } finally {
      this.printing = false;
    }
  }

  @Watch('user_id')
  onChangeUserId(value: number) {
    this.queryBuilder.addListener('user_id', value);
  }

  @Watch('date')
  onChangeRange(value: number) {
    this.queryBuilder.addListener('date', value);
  }

  close() {
    this.showModal = false;
  }
}




















































































import {Vue, Component, Mixins, Watch} from 'vue-property-decorator';
import CreateTransportForm from '@/views/user/transport/CreateTransportForm.vue';
import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tooltip, Tag} from 'element-ui';
import Transport from '@/intefaces/Transport';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import GIFModal from '@/components/tour/GIFModal.vue';

@Component({
  components: {
    CreateTransportForm,
    GIFModal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag
  }
})
export default class TransportList extends Mixins(PaginationComponentMixin) {
  private transports: Transport[] = [];
  private isActive = false;
  private isUpdate = false;
  private showGIF: boolean = false;
  private currentEntity!: any;
  protected form = {
    name: '',
  };
  private demoTourEntity: string = 'transport';
  private GIFs: Array<any> = [
    {
      label: 'Create a transport',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a transport',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a transport',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of transport';

  created() {
    this.fetchEntity();
  }

  entityCreated(entity: Transport) {
    this.transports.unshift(entity);
  }

  entityUpdated(entity: Transport): void {
    this.transports.splice(
      this.transports.findIndex(type => type.id == entity.id),
      1,
      entity
    );
  }

  onEdit(row: Transport) {
    this.isUpdate = true;
    this.currentEntity = row;
    this.form = {
      name: this.currentEntity.name,
    };
    this.isActive = true;
  }


  @Watch('pagination.currentPage')
  fetchEntity(): void {
    this.loading = true;
    this.$http.get(this.$api(`/transports?page=${this.pagination.currentPage}`)).then(response => {
      this.transports = response.data.transports;
      this.setPagination(response.data);
      this.loading = false;
    });
  }

}














































































































import {Component, Mixins, Prop} from "vue-property-decorator";
import ProductLazydropdown from "@/components/lazyDropdown/ProductLazydropdown.vue";
import {Tag} from "element-ui";
import TaxonomyMixin from "@/mixins/TaxonomyComponent";

@Component({
  components: {
    ProductLazydropdown,
    [Tag.name]: Tag
  }
})

export default class SalesReturnAdd extends Mixins(TaxonomyMixin) {
  @Prop() isActive!: boolean;
  @Prop() salesReturns!: any;
  @Prop() fullProductList!: any;
  @Prop() updateAmountCount!: boolean;
  @Prop() form!: any;
  @Prop() entityAmount!: number;
  @Prop() calculateSalesReturn!: any;
  public salesReturnPrice: any = [];
  private buyerDepartment: any = [];
  private reasons: any = ['damaged', 'outdated', 'others'];
  private totalSalesReturn = 0;
  private saleReturnValidationTooltip = '';
  private amount: any = []

  addNewSalesReturn() {
    this.salesReturns.push({
      product_id: '',
      qty: '',
      amount: 0,
      paid: 0,
      reason: '',
      price: 0
    })
  }

  public async getPrice(selectedProduct: any, row: any) {
    row.price = 0;
    row.qty = 0;
    row.amount = 0;
    await this.$http.get(this.$api(`/departments/${this.form.departmentId}`)).then(resposneDepartment => {
      this.buyerDepartment = resposneDepartment.data.department;
    })

    this.$http.get(this.$api(`/products/${selectedProduct}`)).then(responseProductPrice => {
      responseProductPrice.data.product.prices.forEach((price: any) => {
        if (price.priceable_type == "depttype" && this.buyerDepartment && price.priceable_id == this.buyerDepartment.department_type_id) {
          row.return_rate = price.price;
        }
      })
    })
  }

  getSalesReturnPrice(salesReturn: any, index: number) {
    this.amount[index] = salesReturn.qty * salesReturn.return_rate;
    this.salesReturnPrice[index] = salesReturn.qty * salesReturn.return_rate;
    this.totalSalesReturn = 0;
    this.salesReturns[index].amount = this.amount[index];
    if (this.updateAmountCount) {
      this.salesReturns.forEach((salesReturn: any, index: any) => {
        this.salesReturnPrice[index] = this.totalSalesReturn + salesReturn.qty * salesReturn.return_rate;
      })
      this.salesReturnPrice.forEach((salesReturn: any) => {
        this.totalSalesReturn = this.totalSalesReturn + salesReturn;
      })
    } else {
      this.salesReturnPrice.forEach((salesReturn: any) => {
        this.totalSalesReturn = this.totalSalesReturn + salesReturn;
      })
    }
  }

  save() {
    this.$emit('update:isActive', false);
    this.$emit('update:salesReturns', this.salesReturns);
    this.$emit('update:calculateSalesReturn', this.totalSalesReturn);
  }

  deleteSalesReturn(salesReturn: any, index: number) {
    this.salesReturns.splice(index, 1);
    this.salesReturnPrice.splice(index, 1);
    this.getSalesReturnPrice(salesReturn, index);
  }

  close() {
    this.amount = [];
    this.$emit('update:isActive', false);
    this.$emit('updateSalesReturn', null);
  }
}

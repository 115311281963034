










































import Card from '@/components/argon-core/Cards/Card.vue'
import { Component, Prop, Vue } from "vue-property-decorator";
import RouteBreadCrumb from "./argon-core/Breadcrumb/RouteBreadcrumb.vue";

@Component({
  name: 'dashboard-stats-card',
  components: {
    Card
  }
})

export default class DashboardStatsCard extends Vue {
  @Prop({default: 'primary'}) type!: {
    type: string,
  }
  @Prop() icon!: string;
  @Prop() title!: string;
  @Prop({default: 0}) subTitle!: number;
  @Prop() iconClasses!: string;
  @Prop() notLoading!: boolean;

  clickEvent(event: any) {
    this.$parent.$emit('click', event);
    this.$emit('click', event);
  }
}







































//@ts-ignore
import { Vue, Component } from 'vue-property-decorator';
import RouteBreadCrumb from '@/components/argon-core/Breadcrumb/RouteBreadcrumb.vue';

import Route from '@/intefaces/Route';
import SettingsRoutes from '@/router/client-routes/SettingsRoutes';
import {RouteConfig} from "vue-router";
import AttendanceRoutes from "@/router/client-routes/AttendanceRoutes";
@Component({
  components: { RouteBreadCrumb }
})
class Setting extends Vue {
  activeTab: number | null = 0;
  showModal: boolean = false;
  // routes = SettingsRoutes;
  formErrors: any = {};
  formInputs: any = {};

  get routes(): RouteConfig {
    return SettingsRoutes;
  }

  get SettingsRoutes() {
    return SettingsRoutes;
  }

  created() {
    if(this.routes.children){
      this.routes.children.forEach((route, index) => {
        if (this.$route.name === route.name) {
          this.activeTab = index;
        }
      });
    }
  }

  setCurrentTab(tab = null) {
    if (tab === null) {
      this.activeTab = 0;
    } else {
      this.activeTab = tab;
    }
  }

  closeAndResetForm() {
    this.showModal = false;
    this.formInputs = {};
    this.formErrors = {};
  }
}

export default Setting;

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('modal',{staticClass:"modal-dialog-scrollable",attrs:{"show":_vm.isActive,"size":"lg"},on:{"close":_vm.close}},[_c('template',{slot:"header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel"}},[_vm._v("View Staff")])]),_c('div',[_c('el-table',{staticClass:"table-responsive align-items-center",attrs:{"data":_vm.departmentStaffs}},[_c('el-table-column',{attrs:{"label":"Name","min-width":"150px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.name))])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Email","min-width":"200px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.email))])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Phone","min-width":"100px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.phone))])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Status","min-width":"100px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.active ? 'Active' : 'Inactive'))])]}}],null,true)})],1)],1),_c('template',{slot:"footer"},[_c('base-button',{attrs:{"type":"secondary"},on:{"click":_vm.close}},[_vm._v("Close")])],1)],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
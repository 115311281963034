
























































import { Vue, Component, Mixins, Watch, Prop } from 'vue-property-decorator';
import DepartmentType from '@/intefaces/DepartmentType';
//@ts-ignore
import CreateChannelForm from '@/views/department/CreateChannelForm.vue';
import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tooltip, Tag } from 'element-ui';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import GIFModal from '@/components/tour/GIFModal.vue';
import { Permission as PermissionEnum } from '@/enums/Permission'
import AsyncImage from "@/components/AsyncImage.vue";
import User from "@/intefaces/User";
import Territory from "@/intefaces/Territory";
import MemberAutocomplete from "@/components/resources-autocomplete/MemberAutocomplete.vue";

@Component({
  components: {MemberAutocomplete, AsyncImage}
})
export default class ManagerList extends Mixins(PaginationComponentMixin) {
  public modal: boolean = false
  public loading: boolean = false
  public refresh: boolean = true
  public managers: User[] = [];
  public addableManagers: number[] = [];

  @Prop({required: true})
  public territory!: Territory;

  @Watch('modal')
  async onChangeModelState(value: boolean) {
    this.addableManagers = []
    if (value) {
      this.loading = true;
      try {
        let {data} = await this.$http.get(this.$api(`/territories/${this.territory.id}/managers`))
        this.managers = data.territoryManagers
      } catch (e) {
        this.$notify({title: 'Something went wrong.', message: 'Unable to load manager.', type: "error"});
      } finally {
        this.loading = false;
      }
    }
  }

  onClickRemoveManager(manager: User) {
    this.$conformation({
      title: `Are you sure want to remove "${manager.name}" ?`,
      onAccept: async () => {
        try {
          await this.$http.delete(this.$api(`/territories/${this.territory.id}/managers/${manager.id}`))
          this.managers.splice(this.managers.findIndex(value => value.id == manager.id), 1)
          this.$notify({title: 'Successful!', message: 'Manager removed successfully.'})
        } catch (e) {
          this.$notify({title: 'Something went wrong.', message: 'Unable to remove manager.', type: "error"})
        }
      }
    });
  }

  onClickAddManager() {
    this.$conformation({
      title: `Are you sure want to add manager ?`,
      onAccept: async () => {
        try {
          let {data} = await this.$http.post(this.$api(`/territories/${this.territory.id}/managers`),
            {
              'managed_by':
              this.addableManagers
            })
          this.managers = data.territoryManagers
          this.addableManagers = []
          this.$notify({title: 'Successful!', message: 'Manager added successfully.'})
        } catch (e) {
          this.$notify({title: 'Something went wrong.', message: 'Unable to remove manager.', type: "error"})
        }
      }
    });
  }

  onClickModalClose() {
    if (this.addableManagers.length > 0) {
      this.$conformation({
        title: "You have unsaved managers. Are you sure you want to leave?",
        onAccept: async () => {
          this.modal = false
        }
      })
    } else {
      this.modal = false;
    }
  }

  @Watch('managers')
  refreshMemberAutocomplete() {
    this.refresh = false
    this.$nextTick(function () {
      this.refresh = true
    })
  }
}

















































































































  import {Vue, Component, Prop, Mixins, Watch} from 'vue-property-decorator';
import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tooltip, Button } from 'element-ui';
import Survey from '@/intefaces/Survey';
import CreateSurveyForm from '@/views/inspection/survey/CreateSurveyForm.vue';
import CreateQuestionForm from '@/views/inspection/survey/CreateQuestionForm.vue';
import SurveyQuestionDetailsModal from '@/views/inspection/survey/SurveyQuestionDetailsModal.vue';
import Question from '@/intefaces/Question';
import PaginationComponentMixin from "@/mixins/PaginationComponent";
import QuestionLazyDropdown from "@/components/lazyDropdown/QuestionLazyDropdown.vue";
import {QueryBuilder} from "@/classes/QueryBuilder";
import User from "@/intefaces/User";
import CustomTooltip from "@/components/CustomTooltip.vue";

@Component({
  components: {
    CreateQuestionForm,
    SurveyQuestionDetailsModal,
    CreateSurveyForm,
    QuestionLazyDropdown,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [Button.name]: Button,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    CustomTooltip
  }
})
export default class SurveyQuestionList extends Mixins(PaginationComponentMixin) {
  @Prop()
  public currentSurvey!: Survey;
  private questions: Survey[] = [];
  private showDeleteModal: boolean = false;
  private isActive: boolean = false;
  private isActiveDetails: boolean = false;
  private isUpdate: boolean = false;
  private currentEntity!: Question;
  private selectedQuestions: Question[] = [];
  private addingQuestion: boolean = false;
  private form: Question = {
    for: '',
    options: ['', ''],
    survey_id: '',
    title: '',
    type: null
  };
  queryBuilder = new QueryBuilder();

  @Watch('questions')
  onChangePagination(value: Question[]) {
    this.queryBuilder.addListener('except_id', value.map(question => question.id).join(','));
  }

  async created() {
     if(this.currentSurvey == null){
      await this.$router.push("/inspection")
    }
    this.fetchEntity();
  }
  entityCreated(survey: Survey) {
    this.questions.unshift(survey);
  }

  onEdit(row: Question) {
    this.isUpdate = true;
    this.currentEntity = row;
    this.form = {
      for: this.currentEntity.for,
      options: this.currentEntity.options,
      survey_id: this.currentEntity.survey_id,
      title: this.currentEntity.title,
      type: this.currentEntity.type
    };
    this.isActive = true;
  }

  onDetails(row: Question) {
    this.isActiveDetails = true;
    this.currentEntity = row;
  }

  @Watch('pagination.currentPage')
  async fetchEntity() {
    this.loading = true;
    if (!this.currentSurvey) return;
    const { data } = await this.$http.get(this.$api(`/surveys/${this.currentSurvey.id}/questions?page=${this.pagination.currentPage}`));
    this.questions = data.questions;
    this.setPagination(data)
    this.loading = false;
  }

  entityUpdated(survey: Survey) {
    this.questions.splice(
      this.questions.findIndex(value => value.id == survey.id),
      1,
      survey
    );
  }

  async addQuestion() {
    this.addingQuestion = true;
    for (const value of this.selectedQuestions) {
      let { data } = await this.$http.post(this.$api(`/surveys/${this.currentSurvey.id}/questions`), { question_id: value});
      this.questions.push(data.question);
    }
    this.addingQuestion = false;
    this.selectedQuestions = [];
    this.$notify({
      title: 'Success',
      message: 'Successfully Added',
      duration: 5000,
      iconClass: 'ni ni-bell-55',
      type: 'success'
    });
  }

  deleteEntity(): void {
    this.$http.delete(this.$api(`/surveys/${this.currentSurvey.id}/questions/${this.currentEntity.id}`)).then(response => {
      this.showDeleteModal = false;
      this.questions.splice(
        this.questions.findIndex(value => value.id == this.currentEntity.id),
        1
      );
      this.$notify({
        title: 'Success',
        message: 'Successfully Deleted',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'success'
      });
    });
  }
}

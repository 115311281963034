































import { Component, Prop, Vue } from 'vue-property-decorator';
import FileInput from '@/components/argon-core/Inputs/FileInput.vue';
import { AxiosError, AxiosResponse } from 'axios';

@Component({
  name: 'CreateBulkDepartments',
  components: {
    FileInput,
  },
})
export default class CreateBulkDepartments extends Vue {
  private btnDisabled: boolean = false;
  private update: boolean = false;
  private csvFile: Blob | string = '';
  private errors: Array<string> = [];

  @Prop() showBulkDepartmentsModal!: boolean;

  close() {
    this.errors = [];
    this.$emit('update:showBulkDepartmentsModal', false);
  }

  _import() {
    this.btnDisabled = true;
    let form = new FormData();
    form.append('departments', this.csvFile);
    if (this.update) {
      form.append('_method', 'patch');
    }
    this.$http
      .post(this.$api('/import-departments'), form)
      .then((response: AxiosResponse) => {
        this.$notify({
          title: 'Success',
          message: response.data.message,
          duration: 3000,
          iconClass: 'ni ni-bell-55',
          type: 'success',
        });
        this.btnDisabled = false;
        this.errors = [];
        this.$emit('update:showBulkDepartmentsModal', false);
        //@ts-ignore
        this.$parent.fetchDepartments();
      })
      .catch((err: AxiosError) => {
        this.errors = err.response?.data.errors;
        this.btnDisabled = false;
      });
  }

  filesChange(files: FileList) {
    this.csvFile = files[0];
  }
}

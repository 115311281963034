







































import { Component, Mixins, Vue, Watch } from 'vue-property-decorator';
// @ts-ignore
import emailModal from '@/views/Report/emailModal.vue';
import GIFModal from '@/components/tour/GIFModal.vue';
import { Permission as PermissionEnum } from '@/enums/Permission'

@Component({
  components: {
    emailModal,
    GIFModal
  }
})
export default class Sales extends Vue {
  private salesReports: any[] = [];
  private isShowEmailReport: boolean = false;
  private currentReport = '';
  private demoTourEntity: string = 'salesReport';
  private GIFs: Array<any> = [
    {
      label: 'Create a sales report',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a sales report',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a sales report',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of sales report';

  fetchSalesReportTab() {
    this.salesReports = [
      { name: 'Sales Order Report', tooltip: 'Report will be generated only for delivered orders.', type: 'order',
        permission: PermissionEnum.SALES_ORDER_REPORT },
      { name: 'SR Wise Sales Report', tooltip: 'Report will be generated for field force performance.', type:
          'field_force', permission: PermissionEnum.SR_WISE_SALES_REPORT}
    ];
  }
  todaysDate() {
    var currentDate = new Date()
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, '/');
    return currentDate;
  }

  created() {
    this.fetchSalesReportTab();
  }
}

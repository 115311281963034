







import Vue from "vue";
import {Component, Mixins} from "vue-property-decorator";
import LazyDropdown from "../LazyDropdown.vue";
import DropdownField from "../../mixins/DropdownField";
import CampaignForm from "@/views/inspection/campaign/CampaignForm.vue"
@Component({
    components: {
        LazyDropdown,
        CampaignForm
    }
})
export default class CampaignAutocomplete extends Mixins(DropdownField) {
}
















































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Radio, Steps, Step } from 'element-ui';
import RegisterInfo from '@/views/auth/Register/RegisterInfo.vue';
import PackageSelection from '@/views/auth/Register/PackageSelection.vue';
const auth = namespace('Auth');
@Component({
  components: {
    PackageSelection,
    RegisterInfo,
    [Steps.name]: Steps,
    [Step.name]: Step
  }
})
export default class Register extends Vue {
  currentStep: number = 1;
  active: number = 0;
  selectedPackage: any = {
    scheme_id: null,
    user_quota: null,
    coupon_code: null
  };

  nextStep() {
    this.currentStep = 2;
    this.active = 1;
  }
  previousStep() {
    this.currentStep = 1;
    this.active = 0;
  }




}

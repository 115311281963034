



























































import {Component, Prop, Vue} from "vue-property-decorator";
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip
} from "element-ui";
import {Invoice} from "@/intefaces/Invoice";

@Component({
  components: {
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  }
})
export default class InvoiceAmountDetailsForm extends Vue {
  @Prop() invoiceDetails!: Invoice;
  @Prop() isShowAmountDetails!: boolean;

  get currency() {
    return this.$store.getters['Settings/getCurrency']
  }

  close() {
    this.$emit('update:isShowAmountDetails', false);
  }
  
}


































import {Vue, Component, Prop} from 'vue-property-decorator';
import AsyncImage from "@/components/AsyncImage.vue";
import {Carousel, CarouselItem, Image} from "element-ui";
import LoadingPanel from "@/components/argon-core/LoadingPanel.vue";

@Component({
  components: {
    LoadingPanel,
    AsyncImage,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    [Image.name]: Image
  }
})
export default class ImagesModal extends Vue {
  @Prop()
  images!: Array<any>;
  imagesUrls: String[] = []
  // private images = ["/users/1/propic", "/users/1/propic", "/users/1/propic"]
  @Prop({type: Boolean, default: false})
  public showImage!: boolean;

  mounted() {
    this.images.forEach(async value => {
      let data = null;
      if (process.env.NODE_ENV === 'production') {
        data = (await this.$http.get(this.$api('/file?path=' + value))).data;
      } else {
        data = await this.$blob2Url('/file?path=' + value);
      }
      this.imagesUrls.push(data);
    })
  }

  close() {
    this.$emit('update:showImage', false);
  }

}

























































import {Component, Prop, Mixins, Watch} from 'vue-property-decorator';
import TaxonomyMixin from "../../../mixins/TaxonomyComponent";
import {Invoice} from "@/intefaces/Invoice";
import OrderDepartmentInfo from "@/views/order/Components/OrderDepartmentInfo.vue";
import OrderRevision from "@/intefaces/OrderRevision";
import BaseOrderProducts from "@/views/order/Components/BaseOrderProducts.vue";
import BaseOrderComment from "@/views/order/BaseOrderComment.vue";
import {OrderStatus as OrderStatusEnum} from "@/enums/OrderStatus";
import BaseOrderCal from "@/views/order/Components/BaseOrderCal.vue";
import OrderStatus from "@/views/order/Components/OrderStatusButtons.vue";
import OrderEdit from "@/views/order/Components/OrderEdit.vue";
import {Permission as PermissionEnum} from '@/enums/Permission';

@Component({
  components: {
    OrderDepartmentInfo,
    BaseOrderProducts,
    BaseOrderComment,
    OrderStatus,
    BaseOrderCal,
    OrderEdit
  }
})

export default class ReadyForDeliveryInvoiceDetails extends Mixins(TaxonomyMixin) {
  @Prop() title: string | undefined;
  @Prop() isActive: boolean | undefined;
  @Prop() currentEntity!: any;
  private editable: boolean = false;
  private revisionList: OrderRevision[] = [];
  private statuses: any = [];
  private subTotal: number = 0;
  private salesReturn: number = 0;
  private discount: number = 0;
  private returnProducts: any = [];
  private saveEditedOrder: boolean = false;

  @Watch('isActive')
  fetchRevisionAndStatusList(newVal: boolean) {
    if (newVal) {
      this.$http.get(this.$api(`/invoices/${this.currentEntity.id}/revisions`)).then(resposne => {
        this.revisionList = resposne.data.revisions;
      })
      this.$http.get(this.$api(`/orders/${this.currentEntity.order_id}/statuses?statuses=
      ${OrderStatusEnum.STATUS_ACCEPTED},${OrderStatusEnum.STATUS_CANCELLED}`)).then(response => {
        this.statuses = response.data.statuses;
      })
      this.$http.get(this.$api(`/sales-returns?order_id=${this.currentEntity.id}`)).then(resposne => {
        this.returnProducts = resposne.data.salesReturns[0].returned_products;
        this.salesReturn = resposne.data.salesReturns[0].total_amount;
      })
      this.subTotal = this.currentEntity.latest_revision.amount;
    }
  }

  updateStatus(event: any) {
    this.$emit('updatedStatus', event.invoice);
  }

  get permissionEnum() {
    return PermissionEnum;
  }

  close(): void {
    this.$emit('update:isActive', false);
    this.editable = false;
    this.saveEditedOrder = false;
  }
}








































































import {Component, Prop, Mixins, Watch} from 'vue-property-decorator';
import Territory from '@/intefaces/Territory';
import BaseCUModalComponentMixin from '@/mixins/BaseCUModalComponent';
import { AxiosResponse } from 'axios';
import { Select, Option, Autocomplete, Row, Col } from 'element-ui';
import User from '@/intefaces/User';
import TerritoryTypeAutocomplete from '@/components/resources-autocomplete/TerritoryTypeAutocomplete.vue';
import MemberAutocomplete from '@/components/resources-autocomplete/MemberAutocomplete.vue';
import TerritoryAutocomplete from '@/components/resources-autocomplete/TerritoryAutocomplete.vue';

@Component({
  components: {
    TerritoryAutocomplete,
    TerritoryTypeAutocomplete,
    MemberAutocomplete,
    [Row.name]: Row,
    [Col.name]: Col,
    [Select.name]: Select,
    [Option.name]: Option,
    [Autocomplete.name]: Autocomplete
  }
})
export default class CreateTerritoryForm extends Mixins(BaseCUModalComponentMixin) {
  @Prop()
  currentEntity!: Territory;

  @Prop({
    default: () => ({
      name: '',
      code: '',
      territory_type_id: null,
      parent_id: null,
      managed_by: '',
      manager: null
    })
  })
  form!: {
    name: string;
    code: string;
    territory_type_id: number | null;
    parent_id: number | null;
    parent: Territory | null;
    managed_by: [];
    manager: null | User;
  };

  public state: string = '';
  public requireParent: boolean = false;

  get createApi(): string {
    return '/territories';
  }
  get updateApi(): string {
    return `/territories/${this.currentEntity.id}`;
  }

  @Prop()
  private territories!: Territory[];

  formReset(): any {
    this.form.name = '';
    this.form.code = '';
    this.form.territory_type_id = null;
    this.form.parent_id = null;
    this.form.parent = null;
    this.form.managed_by = [];
    this.form.manager = null;
    return this.form;
  }

  async afterCreate(response: AxiosResponse<any>): Promise<void> {
    this.$emit('created', response.data.territory);
  }

  afterUpdate(response: AxiosResponse<any>): void {
    this.$emit('updated', response.data.territory);
  }

  onChangeTerritoryLevel(value: any) {
    this.requireParent = value > 1 ? true : false;
  }

  @Watch('isUpdate')
  onChangeUpdate(value: boolean){
    if ( value ){
      this.requireParent = !!this.form.parent_id
    }
  }

  @Watch('form.parent_id',{deep:true})
  onChangeParentId( value: number){
    if (this.isUpdate && value == this.currentEntity.id){
      this.$nextTick(() => this.form.parent_id = null);
      this.$notify({message: 'Invalid Parent Territory Selected', title:'Something Went Wrong!', type:'warning'})
    }
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"can",rawName:"v-can",value:(_vm.permissionEnum.HR_TRAVEL_ALLOWANCE_REPORT),expression:"permissionEnum.HR_TRAVEL_ALLOWANCE_REPORT"}],staticClass:"row pl-3"},[_c('div',{staticClass:"col-xl-12 col-md-12 col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-3 col-md-3 col-lg-3"},[_c('DateRange',{attrs:{"label-prop":"Date Range"},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1),_c('div',{staticClass:"col-xl-3 col-md-3 col-lg-3"},[_c('UserLazydropdown',{attrs:{"disabled":!_vm.range,"label":"Select User(Optional)","add-button":false},model:{value:(_vm.created_by),callback:function ($$v) {_vm.created_by=$$v},expression:"created_by"}})],1),_c('div',{staticClass:"offset-3 col-xl-3 col-md-3 col-lg-3 text-right"},[_c('base-button',{attrs:{"disabled":!_vm.range && _vm.travelAllowanceReport.length==0,"loading":_vm.printing,"type":"secondary"},on:{"click":function($event){$event.preventDefault();return _vm.onClickPrint($event)}}},[_c('i',{staticClass:"fa fa-print"})]),_c('base-button',{attrs:{"disabled":!_vm.range && _vm.travelAllowanceReport.length==0,"loading":_vm.downloading,"type":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.onClickDownload($event)}}},[_c('i',{staticClass:"fa fa-download"})])],1)])]),_c('div',{staticClass:"card-body"},[(_vm.travelAllowanceReport.length>0)?_c('p',{staticClass:"text-primary text-bold"},[_vm._v(" Total Expense: "+_vm._s(this.travelAllowanceReport.reduce(function (n, ref) {
	var expense = ref.expense;

	return n + expense;
}, 0) + ' ' + _vm.$currency())+" ")]):_vm._e(),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"table-responsive  table-flush table-striped",attrs:{"header-row-class-name":"thead-light","data":_vm.travelAllowanceReport}},[_c('el-table-column',{attrs:{"label":"SL","min-width":"100px","prop":"name","sortable":"","type":"index"}}),_c('el-table-column',{attrs:{"label":"Date of visit","prop":"Date of visit","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(_vm._f("datetime")(row.created_at)))])]}}])}),_c('el-table-column',{attrs:{"label":"Visit Reason","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.reason)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Start Location","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.start_location))])]}}])}),_c('el-table-column',{attrs:{"label":"End Location","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.end_location))])]}}])}),_c('el-table-column',{attrs:{"label":"Hours","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.travel_duration))])]}}])}),_c('el-table-column',{attrs:{"label":"Amount","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.expense +' '+ _vm.$currency()))])]}}])}),_c('el-table-column',{attrs:{"label":"Approved By","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',{class:{'text-warning':!row.actor}},[_vm._v(_vm._s(row.actor ? row.actor.name : 'Not Approved Yet'))])]}}])})],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }




















import {Component, Vue} from 'vue-property-decorator';
import VisitTargetReport from "@/views/Report/Visit/VisitTargetReport.vue";
import TrackingReport from "@/views/Report/Visit/TrackingReport.vue";

@Component({
  components: {TrackingReport, VisitTargetReport}
})
export default class VisitReports extends Vue {

}

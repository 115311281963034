









































































































































import {Vue, Component, Mixins, Watch} from 'vue-property-decorator';
import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tooltip} from 'element-ui';
import CreateLeaveRequestForm from '@/views/attendance/CreateLeaveRequestForm.vue';
import LeaveRequest from '@/intefaces/LeaveRequest';
import GIFModal from '@/components/tour/GIFModal.vue';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import { Permission as PermissionEnum } from '@/enums/Permission'

@Component({
  components: {
    CreateLeaveRequestForm,
    GIFModal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu
  }
})
export default class LeaveList extends Mixins(PaginationComponentMixin) {
  private leaveRequests: LeaveRequest[] = [];
  private showApproveModal: boolean = false;
  private showRejectModal: boolean = false;
  private isActive: boolean = false;
  private isUpdate: boolean = false;
  private loadingApproval: boolean = false;
  private currentEntity!: LeaveRequest;
  private demoTourEntity: string = 'attendanceLeave';
  private showGIF: boolean = false;
  private GIFs: Array<any> = [
    {
      label: 'Create an leave',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update an leave',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete an leave',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of leave';
  private form: LeaveRequest = {
    actor_id: null,
    approved_at: null,
    reason: '',
    user_id: null,
    user: null,
    actor: null
  };

  get permissionEnum () {
    return PermissionEnum;
  }

  entityCreated(leaveRequest: LeaveRequest) {
    this.leaveRequests.unshift(leaveRequest);
  }

  onEdit(row: LeaveRequest) {
    this.isUpdate = true;
    this.currentEntity = row;
    this.form = {
      actor_id: this.currentEntity.actor_id,
      approved_at: this.currentEntity.approved_at,
      id: this.currentEntity.id,
      reason: this.currentEntity.reason,
      user_id: this.currentEntity.user_id,
      user: this.currentEntity.user,
      actor: this.currentEntity.actor
    };
    this.isActive = true;
  }

  @Watch('pagination.currentPage')
  async fetchEntity() {
    this.loading = true;
    let uri: string = this.$hasPermission('manageLeaveRequest')
      ? `/leave-requests?page=${this.pagination.currentPage}`
      : `/own-leave-requests?page=${this.pagination.currentPage}`;
    const {data} = await this.$http.get(this.$api(uri));
    this.leaveRequests = data.leaveRequests;
    this.setPagination(data);
    this.loading = false;
  }

  entityUpdated(leaveRequest: LeaveRequest) {
    this.leaveRequests.splice(
      this.leaveRequests.findIndex(value => value.id == leaveRequest.id),
      1,
      leaveRequest
    );
  }

  async approveLeaveRequest() {
    this.loadingApproval = true;
    try {
      const {data} = await this.$http.post(this.$api('/approved-leave-requests'), {leave_request_id: this.currentEntity.id});
      const levelRequest = this.leaveRequests[this.leaveRequests.findIndex(value => value.id == data.approvedLeaveRequest.id)];
      levelRequest.approved_at = data.approvedLeaveRequest.approved_at;
      levelRequest.actor_id = this.$user.id;
      levelRequest.actor = this.$user;
      this.$notify({
        title: 'Success',
        message: 'Request approved.',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'success'
      });
    } catch (e) {
      this.$notify({title: 'Failed', message: 'Something want wrong', duration: 5000, iconClass: 'ni ni-bell-55', type: 'warning'});
    }
    this.showApproveModal = false;
    this.loadingApproval = false;
  }

  async rejectLeaveRequest() {
    this.loadingApproval = true;
    try {
      const {data} = await this.$http.delete(this.$api(`/approved-leave-requests/${this.currentEntity.id}`));
      this.leaveRequests[this.leaveRequests.findIndex(value => value.id == this.currentEntity.id)].approved_at = null;
      this.$notify({
        title: 'Success',
        message: 'Request rejected.',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'success'
      });
    } catch (e) {
      this.$notify({title: 'Failed', message: 'Something want wrong', duration: 5000, iconClass: 'ni ni-bell-55', type: 'warning'});
    }
    this.showRejectModal = false;
    this.loadingApproval = false;
  }

  created() {
    this.fetchEntity();
  }
}






















import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import { Select, Option } from 'element-ui';
import CreateUser from '@/views/user/user/CreateUser.vue';
import DropdownField from '@/mixins/DropdownField';
import LazyDropdown from '@/components/LazyDropdown.vue';

@Component({
  components: {
    LazyDropdown,
    CreateUser,
    [Select.name]: Select,
    [Option.name]: Option
  }
})
export default class MemberAutocomplete extends Mixins(DropdownField) {
  @Prop({default: 'users'})
  public endPoint!:string;
}



























































































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tooltip, Tabs, TabPane} from 'element-ui';
import AsyncImage from "@/components/AsyncImage.vue";

@Component({
  components: {
    AsyncImage,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
  }
})
export default class AssignedOutletsModal extends Vue {
  private assignedOutlets: any = [];
  private outsideOutlets: any = [];
  private activeName: string = 'insideRoute';
  @Prop({type: Boolean, default: false})
  public showOutlets!: boolean;
  public showSelfie: boolean = false;
  private selfieURL: string = '';

  @Prop()
  currentUserId!: number;

  close() {
    this.$emit('update:showOutlets', false);
  }

  @Watch('activeName')
  async onChangeActiveName() {
    let {data} = await this.$http.get(this.$api(`/users/${this.currentUserId}/todays-unplanned-check-ins`));
    this.outsideOutlets = data.unplannedCheckIns;
  }

  async mounted() {
    let {data} = await this.$http.get(this.$api(`/users/${this.currentUserId}/todays-outlets`));
    this.assignedOutlets = data.todaysOutlets;
  }
}

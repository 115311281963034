




































































































































import {Component, Prop, Vue} from "vue-property-decorator";

import {
    Table,
    TableColumn,
    DropdownMenu,
    DropdownItem,
    Dropdown,
    Tooltip,
} from "element-ui";
@Component({
    name: 'OrderDetails',
    components: {
        [Tooltip.name]: Tooltip,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
    }
})
export default class OrderDetails extends Vue{
    @Prop() showOrder!: boolean;
    @Prop() currentOrderDetails!: any;

    close(){
        this.$emit('update:showOrder', false);
    }
}











































































import {Component, Vue, Watch} from "vue-property-decorator";
import {Popover, Switch, Select, Option} from "element-ui";
import Currencies from '@/assets/json/Currencies.json';

@Component({
  components: {
    [Switch.name]: Switch,
    [Popover.name]: Popover,
    [Select.name]: Select,
    [Option.name]: Option
  }
})
export default class Miscellaneous extends Vue {
  private approval: boolean = false;
  private currency: string = "";
  private retrievedSettings: any = ''
  private currencies: any = Currencies;
  private loading: boolean = false;

  updateSettings() {
    this.loading = true
    this.$http.patch(this.$api('/settings/1'), {order_approval: this.approval, currency: this.currency}).then(response => {
      this.updateSettingsNotification();
      this.currencySyncLocalStorage();
    }).catch(error => {
      (this.$refs.formValidator as Vue & {
        setErrors: (errors: []) => any;
      }).setErrors(error.response.data.errors);
      this.$notify({
        message: "Please contact your service provider.",
        title: "Something went wrong!",
        type: 'warning'
      })
    }).finally(
      () => (this.loading = false)
    );
  }

  updateSettingsNotification() {
    this.$notify({
      title: 'Success',
      message: "Settings Updated",
      duration: 5000,
      iconClass: 'ni ni-bell-55',
      // @ts-ignore
      verticalAlign: 'bottom',
      horizontalAlign: 'right',
      type: 'success'
    });
  }

  async currencySyncLocalStorage() {
    if (localStorage.settings) {
      this.retrievedSettings = JSON.parse(localStorage.settings);
      if (this.retrievedSettings.currency) {
        this.retrievedSettings.currency = this.currency;
      } else {
        this.retrievedSettings.push({currency: this.currency});
      }
      localStorage.removeItem('settings');
      this.$store.dispatch('Settings/updateSettings', this.retrievedSettings);
      this.$store.dispatch('Settings/updateCurrency', this.currency);
    } else {
      let settings = [];
      settings.push({currency: this.currency});
      this.$store.dispatch('Settings/updateSettings', this.retrievedSettings);
      this.$store.dispatch('Settings/updateCurrency', this.currency);
    }
  }

  fetchSettings() {
    this.$http.get(this.$api(`/settings`)).then(response => {
      this.approval = Boolean(response.data.settings.order_approval);
      this.currency = response.data.settings.currency;
    })
  }

  created() {
    this.fetchSettings();
  }
}

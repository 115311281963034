








import { Component, Mixins } from 'vue-property-decorator';
import CreateBadgeForm from '@/views/department/CreateBadgeForm.vue';
import LazyDropdown from '@/components/LazyDropdown.vue';
import DropdownField from '@/mixins/DropdownField';

@Component({
  components: {
    LazyDropdown,
    CreateBadgeForm
  }
})
export default class BadgeAutocomplete extends Mixins(DropdownField) {}











































































































































































































//@ts-ignore
import {Vue, Component, Mixins, Watch} from 'vue-property-decorator';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import {Tooltip} from 'element-ui';

const flatPicker = require('vue-flatpickr-component');
import 'flatpickr/dist/flatpickr.css';
import SingleImageUploader from '@/components/ui/SingleImageUploader.vue';

const moment = require('moment');
import countryCodes from '@/assets/json/CountryCodes.json';
import AsyncImage from "@/components/AsyncImage.vue";

@Component({
  components: {
    AsyncImage,
    [Tooltip.name]: Tooltip,
    flatPicker,
    SingleImageUploader
  }
})
export default class UserProfile extends Mixins(PaginationComponentMixin) {
  private me: Object = {};
  activeTab: number | null = 0;
  showModal: boolean = false;
  disabled: boolean = true;
  countryCode: string = '';
  phoneNumber: any = '';
  formErrors: any = {};
  formInputs: any = {};
  propic: any = '';
  imageSource: string = '';
  countryCodes: any = countryCodes;
  model: any = {
    name: '',
    dob: '',
    address: '',
    gender: '',
    phone: '',
    propic_url: ''
  };

  get user_id() {
    return this.$store.getters['Auth/user'].id;
  }

  get imageSrc() {
    return this.imageSource;
  }

  get getGender() {
    if (this.model.gender == 'm') {
      return 'Male';
    } else if (this.model.gender == 'f') {
      return 'Female'
    } else {
      return 'Others';
    }
  }

  private selectGender = [
    {
      label: 'Male',
      value: 'm'
    },
    {
      label: 'Female',
      value: 'f'
    },
    {
      label: 'Others',
      value: 'o'
    }
  ];

  get birthday() {
    if (!this.model.dob) {
      return '';
    } else {
      return moment().diff(moment(this.model.dob), 'years');
    }
  }

  async updateProfile(model: any) {
    let form = new FormData();
    form.append('propic', this.propic);
    form.append('name', model.name);
    form.append('address', model.address ? model.address : '');
    form.append('gender', model.gender);
    form.append('phone', this.phone);
    form.append('dob', model.dob ? model.dob : '');
    form.append('_method', 'patch');
    let {data} = await this.$http.post(
      this.$api(`/users/${this.user_id}/profiles/`),
      this.propic
        ? form
        : {
          name: model.name,
          address: model.address ? model.address : '',
          gender: model.gender,
          phone: this.phone,
          dob: model.dob ? model.dob : '',
          _method: 'patch'
        }
    );

    await this.fetchEntity();
    await this.$store.dispatch('Auth/updateCurrentUser', data.profile);
    await localStorage.setItem('currentUser', JSON.stringify(data.profile));
    this.$store.dispatch('Auth/updateNavbarKey');
    this.propic = '';
    this.disabled = true;
  }

  get phone() {
    return this.countryCode + '-' + this.phoneNumber;
  }

  created() {
    this.fetchEntity();
  }

  fetchEntity(): void {
    this.$http.get(this.$api(`/users/${this.user_id}/profiles/`)).then(response => {
      this.me = response.data.profile;
      this.model = response.data.profile;
      if (this.model.phone != null) {
        let phone = this.model.phone.split('-');
        this.countryCode = phone[0];
        this.phoneNumber = phone[1];
      }
    });
  }
}

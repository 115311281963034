































































import {Component, Prop, Mixins, Vue} from 'vue-property-decorator';
import BaseCUModalComponent from '@/mixins/BaseCUModalComponent';
import {AxiosResponse} from 'axios';
import {RadioGroup, Radio, Badge, Button} from 'element-ui';
import Question from '@/intefaces/Question';
import Survey from '@/intefaces/Survey';

@Component({
  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Badge.name]: Badge,
    [Button.name]: Button
  }
})
export default class CreateQuestionForm extends Mixins(BaseCUModalComponent) {
  @Prop()
  currentEntity!: Question;
  @Prop()
  currentSurvey!: Survey;
  @Prop()
  form!: {
    for: string;
    options: Array<string | number> | null;
    survey_id: number | null;
    title: string;
    type: string | null;
  };
  private surveyTypes = [
    {value: 'radio', label: 'MCQ'},
    {value: 'checkbox', label: 'MCQ with multiple ans'},
    {value: 'rating', label: 'Rating'},
    {value: 'textarea', label: 'Textarea'}
  ];
  private surveyFor = [
    {value: 'consumer', label: 'Customer'},
    {value: 'outlet', label: 'Outlet'}
  ];

  formReset(): any {
    this.form.for = '';
    this.form.options = ['', ''];
    this.form.survey_id = null;
    this.form.title = '';
    this.form.type = null;
    return this.form;
  }

  async afterCreate(response: AxiosResponse<any>): Promise<void> {
    this.$emit('created', response.data.question);
  }

  afterUpdate(response: AxiosResponse<any>): void {
    this.$emit('updated', response.data.question);
  }

  get createApi(): string {
    return `/surveys/${this.currentSurvey.id}/questions`;
  }

  get updateApi(): string {
    return `/surveys/${this.currentSurvey.id}/questions/${this.currentEntity.id}`;
  }

  private addClue() {
    if(!this.form.options) return;
    this.form.options.push('');
  }

  private removeClue(index: number) {
    if(!this.form.options) return;
    this.form.options.splice(index, 1);
  }

  formData(): any {
    let question = {
      for: this.form.for,
      options: this.form.options,
      survey_id: this.form.survey_id,
      title: this.form.title,
      type: this.form.type
    };
    if (this.form.type === 'rating') {
      question.type = 'radio';
      question.options = [1, 2, 3, 4, 5];
    }
    if (this.form.type === 'textarea') {
      // delete question.options;
      question.options = []
    }

    return question;
  }

}

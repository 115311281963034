








































































import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tooltip, Tag } from 'element-ui';
import PaginationComponentMixin from '@/mixins/PaginationComponent';

@Component({
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
  },
})
export default class PaymentList extends Mixins(PaginationComponentMixin) {
  private payments: any[] = [];

  created() {
    this.fetchEntity();
  }

  @Watch('pagination.currentPage')
  fetchEntity(): void {
    this.loading = true;
    this.$http.get(this.$landLordApi(`/org-payments?page=${this.pagination.currentPage}`)).then((response) => {
      this.payments = response.data.orgPayments;
      this.setPagination(response.data);
      this.loading = false;
    });
  }
}

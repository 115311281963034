import { render, staticRenderFns } from "./ProposedRoutePlan.vue?vue&type=template&id=2a21eec7&scoped=true&"
import script from "./ProposedRoutePlan.vue?vue&type=script&lang=ts&"
export * from "./ProposedRoutePlan.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a21eec7",
  null
  
)

export default component.exports






























































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Col, Drawer, Row } from 'element-ui';
import MultiOptionSearchBox from '@/components/MultiOptionSearchBox.vue';
import DateRange from '@/components/DateRange.vue';
import InvoiceStatuses from '@/data/InvoiceStatus';
import DepartmentLazydropdown from '@/components/lazyDropdown/DepartmentLazydropdown.vue';
import { QueryBuilder } from '@/classes/QueryBuilder';
import UserLazydropdown from '@/components/lazyDropdown/UserLazydropdown.vue';
import TaxonomyMixin from "@/mixins/TaxonomyComponent";
import moment from "moment";

@Component({
  components: {
    UserLazydropdown,
    [Drawer.name]: Drawer,
    [Row.name]: Row,
    [Col.name]: Col,
    MultiOptionSearchBox,
    DepartmentLazydropdown,
    DateRange
  }
})
export default class FilterDrawer extends Mixins(TaxonomyMixin) {
  @Prop({required: true})
  isFilterModelActive!: boolean;
  @Prop({required: true})
  title!: string;

  public queryBuilder: QueryBuilder = new QueryBuilder();

  public invoiceType: string = '';
  public invoiceStatus: string = '';
  public deliveryStatus: string = '';
  public buyerDepartmentId: [] = [];
  public sellerDepartmentId: [] = [];
  public dateRange: string = '';
  public createdBy: [] = [];

  get invoiceStatuses() {
    return InvoiceStatuses;
  }

  created() {
    this.queryBuilder.setParam('created_between', moment().format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD'))
  }

  @Watch('queryBuilder.watch')
  onChangeQuery(value: string) {
    this.$emit('filters', this.queryBuilder.getFilters());
  }

  @Watch('invoiceType')
  onChangeInvoiceType(value: string) {
    this.queryBuilder.addListener('is_requisition', Number(value == 'requisition'));
  }

  @Watch('invoiceStatus')
  onChangeInvoiceStatus(value: string) {
    this.queryBuilder.addListener('status', value);
  }

  @Watch('deliveryStatus')
  onChangeDeliveryStatus(value: string) {
    this.queryBuilder.addListener('delivered', Number(value == 'delivered'));
  }

  @Watch('buyerDepartmentId')
  onChangeBuyerDepartmentId(value: []) {
    this.queryBuilder.addListener('buyer_id', value.join(','));
  }

  @Watch('sellerDepartmentId')
  onChangeSellerDepartmentId(value: []) {
    this.queryBuilder.addListener('seller_id', value.join(','));
  }

  @Watch('dateRange')
  onChangeDateRange(value: string) {
    this.queryBuilder.addListener('created_between', value);
  }

  @Watch('createdBy')
  onChangeCreatedBy(value: []) {
    this.queryBuilder.addListener('created_by', value.join(','));
  }

  resetBtn() {
    this.sellerDepartmentId = [];
    this.buyerDepartmentId = [];
    this.createdBy = [];
    this.deliveryStatus = '';
    this.invoiceType = '';
    this.invoiceStatus = '';
    this.dateRange = '';
  }

  close(): void {
    this.$emit('update:isFilterModelActive', false);
  }
}














































































































































































































































import { Vue, Component, Watch, Mixins } from 'vue-property-decorator';
import Order from '@/intefaces/Order';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Table,
  TableColumn,
  Tooltip,
  Tag,
  Drawer,
  ButtonGroup,
  Button
} from 'element-ui';
import OrderDetailsForm from '@/views/order/OrderDetailsForm.vue';
import OrderStatusAutocomplete from '@/components/resources-autocomplete/OrderStatusAutocomplete.vue';
import statuses from '@/data/OrderStatus';
import GIFModal from '@/components/tour/GIFModal.vue';
import TaxonomyMixin from "@/mixins/TaxonomyComponent";
import { Permission as PermissionEnum } from '@/enums/Permission'
import { QueryBuilder } from "@/classes/QueryBuilder";
import DateRange from "@/components/DateRange.vue";
import FilterDrawerComponent from "@/components/FilterDrawerComponent.vue";
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import TerritoryLazydropdown from "@/components/lazyDropdown/TerritoryLazydropdown.vue";
import DepartmentLazydropdown from "@/components/lazyDropdown/DepartmentLazydropdown.vue";
import MultiSelectRow from "@/mixins/MultiSelectRow";
import BaseOrderDetails from "@/views/order/BaseOrderDetails.vue";
import HasCursorPaginationMixin from "@/mixins/HasCursorPagination";
import CursorPagination from "@/components/CursorPagination.vue";
import moment from "moment";
import swal from "sweetalert2";

@Component({
  components: {
    CursorPagination,
    DepartmentLazydropdown,
    UserLazydropdown,
    TerritoryLazydropdown,
    GIFModal,
    OrderStatusAutocomplete,
    OrderDetailsForm,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Drawer.name]: Drawer,
    [Tag.name]: Tag,
    [ButtonGroup.name]: ButtonGroup,
    [Button.name]: Button,
    FilterDrawerComponent,
    DateRange,
    BaseOrderDetails
  },
  filters: {
    currentStatus: function (value: Order) {
      if (!value) return '';
      return value.statuses[value.statuses.length - 1].status.toString().toUpperCase();
    }
  }
})

export default class OrderList extends Mixins(HasCursorPaginationMixin, MultiSelectRow, TaxonomyMixin) {
  private orders: Order[] = [];
  private isActive: boolean = false;
  private isFilterModelActive: boolean = false;
  private queryBuilder: QueryBuilder = new QueryBuilder();
  private showGIF: boolean = false;
  private demoTourEntity: string = 'orders';
  private dialogVisible: boolean = false;
  private filterApplied: boolean = false;
  private orderEditPermission: string = '';
  private orderApprovePermission: string = '';
  private selectedButton: string = 'Today';
  private showDownloadModal: boolean = false;
  private recipient: string = '';

  private bulkForm = {
    remarks: '',
  }

  async updateDateRange(range: string) {
    this.selectedButton = range;
    if (range === 'Today') {
      await this.queryBuilder.setParam('created_between', moment().format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD'));
    }
    if (range === 'WTD') {
      await this.queryBuilder.setParam('created_between', moment().day(-1).format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD'));
    }
    if (range === 'MTD') {
      await this.queryBuilder.setParam('created_between', moment().startOf('month').format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD'));
    }
    if (range === 'QTD') {
      await this.queryBuilder.setParam('created_between', moment().startOf('quarter').format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD'));
    }
    if (range === 'YTD') {
      await this.queryBuilder.setParam('created_between', moment().startOf('year').format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD'));
    }
    this.$nextTick(function () {
      this.applyFilter();
    })
  }

  private currentEntity = {
    ordered_products: []
  };

  get statuses() {
    let data = [...statuses];
    data.unshift({title: 'All ' + this.getTaxonomy('order') + 's', value: ''});
    return data;
  }

  private filters: any = {
    orderDateRange: '',
    orderId: '',
    orderBy: '',
    departmentId: '',
    territoryId: '',
    status: '',
    minimumAmount: '',
    maximumAmount: ''
  }

  private GIFs: Array<any> = [
    {
      label: 'Create an order',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update an order',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete an order',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of order';

  get permissionEnum() {
    return PermissionEnum;
  }

  get amountRange() {
    if (this.filters.minimumAmount && this.filters.maximumAmount) {
      return `${this.filters.minimumAmount},${this.filters.maximumAmount}`
    }
    return '';
  }

  @Watch('isActive')
  getPermission(newVal: boolean) {
    this.orderApprovePermission = this.permissionEnum.APPROVE_ORDER;
    this.orderEditPermission = this.permissionEnum.CREATE_ORDER_MOBILE;
  }

  async mounted() {
    await this.queryBuilder.setParam('created_between', moment().format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD'));
    this.$nextTick(function () {
      this.applyFilter()
    })
  }

  updateOrder(event: any) {
    this.$http.get(this.$api(`/orders/${event.order_id}`)).then(response => {
      this.orders.splice(this.orders.findIndex((order: any) => order.id == response.data.order.id), 1, response.data.order);
    })
  }

  entityCreated(entity: Order) {
    this.orders.unshift(entity);
  }

  entityUpdated(entity: Order): void {
    this.orders.splice(
      this.orders.findIndex(type => type.id == entity.id),
      1,
      entity
    );
    this.isActive = false;
  }

  @Watch('currentCursor')
  async fetchOrder(cursor: string | null) {
    this.loading = true;
    let {data} = await this.$http.get(
      this.$api2(`/orders${cursor ? '?cursor=' + cursor + '&' : ''}${this.queryBuilder.getFilters(cursor == null)}`)
    )
    this.orders = data.data;
    this.setPagination(data);
    this.loading = false;
  }

  @Watch('filters.orderDateRange', {deep: true})
  onChangeSubmissionDateRange(value: string) {
    this.queryBuilder.addListener('created_between', value);
  }

  @Watch('filters.orderId', {deep: true})
  onChangeCodeFilter(value: string) {
    this.queryBuilder.addListener('code', value);
  }

  @Watch('filters.orderBy', {deep: true})
  onChangeNameFilter(value: string) {
    this.queryBuilder.addListener('created_by', value);
  }

  @Watch('filters.departmentId', {deep: true})
  onChangeDepartmentFilter(value: number) {
    this.queryBuilder.addListener('buyer_id', value);
  }

  @Watch('filters.territoryId', {deep: true})
  onChangeTerritoryFilter(value: number) {
    this.queryBuilder.addListener('buyer_territory_id', value);
  }

  @Watch('filters.status', {deep: true})
  onChangeStatusFilter(value: string) {
    this.queryBuilder.addListener('status', value);
  }

  @Watch('filters.minimumAmount', {deep: true})
  onChangeMinimumAmountFilter() {
    if (this.filters.maximumAmount && (this.filters.minimumAmount <= this.filters.maximumAmount)) {
      this.queryBuilder.addListener('amount_between', this.amountRange);
    }
  }

  @Watch('filters.maximumAmount', {deep: true})
  onChangeMaximumAmountFilter() {
    if (this.filters.minimumAmount && (this.filters.maximumAmount >= this.filters.minimumAmount)) {
      this.queryBuilder.addListener('amount_between', this.amountRange);
    }
  }


  bulkActionDispatchNotification() {
    this.$notify({
      title: 'Success',
      message: "Bulk Actions Applied",
      duration: 5000,
      iconClass: 'ni ni-bell-55',
      // @ts-ignore
      verticalAlign: 'bottom',
      horizontalAlign: 'right',
      type: 'success'
    });
  }

  submitBulkAction(status: string) {
    this.loading = true;
    this.dialogVisible = false;
    this.$http
      .post(this.$api(`/bulk-order-status?${this.queryBuilder.getFilters()}`), this.allItemSelected ? {
        id: '',
        status_action: status,
        remarks: this.bulkForm.remarks
      } : {
        id: this.selectedRows.map(order => order.id),
        status_action: status,
        remarks: this.bulkForm.remarks
      }).then(response => {
      this.bulkActionDispatchNotification();
      this.selectedRows = [];
      this.bulkForm.remarks = '';
      this.fetchOrder(null);
    })
      .catch(error => {
        (this.$refs.formValidator as Vue & {
          setErrors: (errors: []) => any;
        }).setErrors(error.response.data.errors);
      }).finally(() => (
      this.loading = false
    ));
  }

  async downloadOrder(status: string) {
    this.loading = true;
    await this.queryBuilder.setParam('recipient', this.recipient);
    await this.queryBuilder.setParam('type', 'csv');
    if (!this.allItemSelected) {
      await this.queryBuilder.setParam('id', this.selectedRows.map(order => order.id));
    }
    try {
      let {data, headers} = await
        this.$http.get(this.$api(
          `/download/bulk-order${this.queryBuilder.getFilters(true)}`
        ))
      await swal.fire(
        "Report Sent",
        "You will receive the report within a few minutes.",
        "success"
      );
    } catch (e) {
      this.$notify({title: 'Something wants wrong!', type: 'warning', message: 'Unable to download reports.',})
    } finally {
      this.loading = false;
      this.showDownloadModal = false;
      await this.queryBuilder.setParam('recipient', null);
      await this.queryBuilder.setParam('type', null);
      await this.queryBuilder.setParam('id', null);
    }
  }

  closeBtn() {
    this.isFilterModelActive = false;
    this.applyFilter()
  }

  handleClose(done: any) {
    done();
  }

  applyFilter() {
    this.filterApplied = true;
    this.fetchOrder(null);
  }

  resetBtn() {
    this.filters.orderDateRange = '';
    this.filters.orderBy = '';
    this.filters.orderId = '';
    this.filters.status = '';
    this.filters.territoryId = '';
    this.filters.minimumAmount = '';
    this.filters.maximumAmount = '';
    this.filters.selectedOrders = ''
    this.fetchOrder(null);
  }

}

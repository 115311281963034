








































import { Component, Mixins, Vue, Watch } from 'vue-property-decorator';
// @ts-ignore
import emailModal from '@/views/Report/emailModal.vue';
import GIFModal from '@/components/tour/GIFModal.vue';
import { Permission as PermissionEnum } from '@/enums/Permission'

@Component({
  components: {
    emailModal,
    GIFModal
  }
})
export default class Product extends Vue {
  private productReports: any[] = [];
  private isShowEmailReport: boolean = false;
  private currentReport = '';
  private demoTourEntity: string = 'productReport';
  private GIFs: Array<any> = [
    {
      label: 'Create a product report',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a product report',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a product report',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of product report';

  fetchSalesReportTab() {
    this.productReports = [
      { name: 'Product Wise Sales Report', tooltip: 'Reports will be generated for product stock.', type: 'product',
        permission: PermissionEnum.PRODUCT_WISE_SALES_REPORT }
    ];
  }
  todaysDate() {
    var currentDate = new Date()
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, '/');
    return currentDate;
  }

  created() {
    this.fetchSalesReportTab();
  }
}

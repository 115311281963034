








import {Component, Mixins} from "vue-property-decorator";
import LazyDropdown from "../LazyDropdown.vue";
import DropdownField from "../../mixins/DropdownField";
import CreateCentralTransportForm from "@/views/order/transport/CreateCentralTransportForm.vue";
@Component({
    components: {
      LazyDropdown,
      CreateCentralTransportForm
    }
})
export default class CentralTransportLazydropdown extends Mixins(DropdownField) {
}

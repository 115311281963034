





























































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import Asset from '@/intefaces/Asset';
import { Tooltip, Button } from 'element-ui';
import Question from '@/intefaces/Question';
import BaseCUModalComponentMixin from '@/mixins/BaseCUModalComponent';
import { AxiosResponse } from 'axios';

@Component({
  name: 'Question',
  components: {
    [Tooltip.name]: Tooltip,
    [Button.name]: Button
  }
})
export default class QuestionForm extends Mixins(BaseCUModalComponentMixin) {
  @Prop() currentEntity!: any;
  @Prop({
    default: () => ({
      title: '',
      type: '',
      options: []
    })
  })
  form!: {
    title: string;
    type: string | null;
    options: Array<string | number> | null;
  };

  private Types = [
    { value: 'radio', label: 'MCQ' },
    { value: 'checkbox', label: 'MCQ with multiple ans' },
    { value: 'rating', label: 'Rating' },
    { value: 'textarea', label: 'Textarea' },
    { value: 'image', label: 'Image' },
  ];

  formReset(): any {
    this.form.options = ['', ''];
    this.form.title = '';
    this.form.type = null;
    return this.form;
  }

  get createApi(): string {
    return '/questions';
  }

  async afterCreate(response: AxiosResponse<any>): Promise<void> {
    this.$emit('created', response.data.question);
  }

  get updateApi(): string {
    return `/questions/${this.currentEntity.id}`;
  }

  afterUpdate(response: AxiosResponse<any>): void {
    this.$emit('updated', response.data.question);
  }

  private addClue() {
    if (!this.form.options) return;
    this.form.options.push('');
  }

  private removeClue(index: number) {
    if (!this.form.options) return;
    this.form.options.splice(index, 1);
  }

  formData(): any {
    let question = {
      options: this.form.options,

      title: this.form.title,
      type: this.form.type
    };
    if (this.form.type === 'rating') {
      question.type = 'radio';
      question.options = [1, 2, 3, 4, 5];
    }
    if (this.form.type === 'textarea') {
      // delete question.options;
      question.options = [];
    }

    return question;
  }
}




































































import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import Survey from '@/intefaces/Survey';
import BaseCUModalComponentMixin from '@/mixins/BaseCUModalComponent';
import { AxiosResponse } from 'axios';
import User from '@/intefaces/User';
import UserLazydropdown from '@/components/lazyDropdown/UserLazydropdown.vue';
import { QueryBuilder } from '@/classes/QueryBuilder';

@Component({
  components: { UserLazydropdown }
})
export default class SurveyAssigneeListModal extends Mixins(PaginationComponentMixin, BaseCUModalComponentMixin) {
  @Prop({ default: null })
  currentEntity!: Survey;
  @Prop()
  isActiveAssigneeList!: boolean;
  loading: boolean = false;
  assignees: User[] = [];
  form: any;
  showDeleteModal: boolean = false;
  addingAssignee: boolean = false;
  currentAssignee!: User;
  deletingAssignee: boolean = false;
  selectedAssignees: User[] = [];
  queryBuilder = new QueryBuilder();

  @Watch('isActiveAssigneeList')
  async onOpenModal() {
    if (this.isActiveAssigneeList) {
      this.loading = true;
      let { data } = await this.$http.get(this.$api(`/surveys/${this.currentEntity.id}/assignees`));
      this.assignees = data.assignees;
      this.setPagination(data);
      this.loading = false;
    }
  }
  @Watch('assignees')
  onChangePagination(value: User[]) {
    this.queryBuilder.addListener('except_id', value.map(user => user.id).join(','));
  }
  async afterCreate(response: AxiosResponse<any>): Promise<void> {
    return Promise.resolve(undefined);
  }

  afterUpdate(response: AxiosResponse<any>): void {}

  get createApi(): string {
    return '';
  }

  formReset(): any {}

  get updateApi(): string {
    return '';
  }

  async removeAssignee() {
    this.deletingAssignee = true;
    await this.$http.delete(this.$api(`/surveys/${this.currentEntity.id}/assignees/${this.currentAssignee.id}`));
    this.assignees.splice(
      this.assignees.findIndex(value => value.id == this.currentAssignee.id),
      1
    );
    this.$notify({
      title: 'Success',
      message: 'Successfully Deleted',
      duration: 5000,
      iconClass: 'ni ni-bell-55',
      type: 'success'
    });
    this.showDeleteModal = false;
    this.deletingAssignee = false;
  }
  async addAssignee() {
    this.addingAssignee = true;
    for (const value of this.selectedAssignees) {
      let { data } = await this.$http.post(this.$api(`/surveys/${this.currentEntity.id}/assignees`), { user_id: value });
      this.assignees.push(data.assignee);
    }
    this.addingAssignee = false;
    this.selectedAssignees = [];
    this.$notify({
      title: 'Success',
      message: 'Successfully Added',
      duration: 5000,
      iconClass: 'ni ni-bell-55',
      type: 'success'
    });
  }
  close(): void {
    // super.close();
    this.$emit('update:isActiveAssigneeList', false);
  }
}










import { Component, Mixins, Prop } from "vue-property-decorator";
import LazyDropdown from "../LazyDropdown.vue";
import DropdownField from "../../mixins/DropdownField";
import CreateRole from "@/views/user/role/CreateRole.vue";

@Component({
  components: {
    LazyDropdown,
    CreateRole
  }
})
export default class RoleLazydropdown extends Mixins(DropdownField) {
  @Prop({default: true}) addButton!: boolean;
}







































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {QueryBuilder} from "@/classes/QueryBuilder";
import moment from "moment";
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import printJS from "print-js";
import DateRange from "@/components/DateRange.vue";
import {Permission as PermissionEnum} from "@/enums/Permission";

const flatPicker = require('vue-flatpickr-component');
@Component({
  components: {
    UserLazydropdown,
    flatPicker,
    DateRange
  }
})
export default class TrackingReport extends Vue {
  public downloading: boolean = false;
  public loading: boolean = false;
  public printing: boolean = false;
  private showModal: boolean = false;
  public created_by: number | null = null;
  public queryBuilder: QueryBuilder = new QueryBuilder();
  public reports = [];
  public range: string = '';
  public rangeSelectConfig = {
    allowInput: true,
    disable: [
      function (range: Date) {
        return moment().diff(range) < 0;
      }
    ]
  }

  todaysDate() {
    var currentDate = new Date()
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, '/');
    return currentDate;
  }

  @Watch('queryBuilder.watch')
  async getReport() {
    if (!this.range) {
      this.reports = [];
      return
    }
    this.loading = true;
    try {
      let {data} = await this.$http.get(
        this.$api('/location-tracking-report' + this.queryBuilder.getFilters(true))
      );
      this.reports = data.locationTrackingReport;
    } catch (e) {
      this.$notify({message: "Please contact your service provider.", title: "Something went wrong!", type: 'warning'})
    } finally {
      this.loading = false;
    }
  }

  async onClickDownload() {
    this.downloading = true;
    try {
      let {
        data,
        headers
      } = await this.$http.get(
        this.$api('/location-tracking-report?download&' + this.queryBuilder.getFilters()
        ), {
          responseType:
            'arraybuffer'
        });
      const blob = await new Blob([data], {type: 'application/csv'});
      const link = document.createElement("a");
      link.href = await URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({title: 'Something wants wrong!', type: 'warning', message: 'Unable to download reports.',})
    } finally {
      this.downloading = false;
    }
  }

  async onClickPrint() {
    this.printing = true;
    try {
      let {data} = await this.$http.get(this.$api('/location-tracking-report?' + this.queryBuilder.getFilters()));
      await printJS({
        printable: data.locationTrackingReport,
        properties: [
          {field: 'user.code', displayName: 'Employee code'},
          {field: 'user.name', displayName: 'Name'},
          {field: 'lat', displayName: 'Latitude'},
          {field: 'long', displayName: 'Longitude'},
          {field: 'created_at', displayName: 'Date/Time(24hr format)'},
          {field: 'address', displayName: 'Address'}
        ],
        type: 'json',
        header: 'Tracking Report'
      })
    } catch (e) {
      this.$notify({title: 'Something Wrong!', message: 'Please Try again.', type: 'warning'});
    } finally {
      this.printing = false;
    }
  }

  @Watch('created_by')
  onChangeUser(value: number) {
    this.queryBuilder.addListener('user_id', value);
  }

  @Watch('range')
  onChangeRange(value: number) {
    this.queryBuilder.addListener('range', value);
  }

  close() {
    this.showModal = false;
  }

  get permissionEnum() {
    return PermissionEnum;
  }
}






















































































































import {Vue, Component, Mixins, Watch} from 'vue-property-decorator';
import {Button, Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tooltip, Tag, Popover, Row, Col} from
    'element-ui';
import Message from '@/intefaces/Messages';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import GIFModal from '@/components/tour/GIFModal.vue';
import FilterDrawerComponent from "@/components/FilterDrawerComponent.vue";
import {QueryBuilder} from "@/classes/QueryBuilder";
import moment from "moment";

const flatPicker = require("vue-flatpickr-component");

@Component({
  components: {
    GIFModal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Popover.name]: Popover,
    [Row.name]: Row,
    [Col.name]: Col,
    FilterDrawerComponent,
    flatPicker
  }
})
export default class MessageList extends Mixins(PaginationComponentMixin) {
  private messages: Message[] = [];
  private showGIF: boolean = false;
  private queryBuilder: QueryBuilder = new QueryBuilder();
  private isFilterModelActive: boolean = false;
  private startDate: string = '';
  private endDate: string = '';
  public enable = true;
  protected form = {
    name: '',
  };
  private demoTourEntity: string = 'message';
  private GIFs: Array<any> = [
    {
      label: 'Create a message',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a message',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a message',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of message';

  get dateRange() {
    if (this.startDate && this.endDate) {
      return this.startDate + ',' + this.endDate;
    } else {
      return '';
    }
  }

  get getConfig(): any {
    let self = this;
    return {
      allowInput: true,
      disable: [
        function (date: Date) {
          return moment(self.startDate).diff(date) > 0
        }
      ]
    }
  }

  @Watch('startDate')
  async onChangeStartDate(value: string) {
    this.endDate = '';
    this.$nextTick(() => this.enable = false);
    this.$nextTick(() => this.enable = true);
    this.$nextTick(() => (this.$refs.endDate as Vue & { $el: { focus: Function; }; }).$el.focus());
  }

  onClickClear() {
    this.startDate = '';
    this.endDate = '';
    this.queryBuilder.addListener('range', '');
  }

  @Watch('dateRange')
  onChangeDateRange(value: string) {
    if (this.startDate && this.endDate) {
      this.queryBuilder.addListener('range', value);
    }
  }

  created() {
    this.fetchEntity();
  }

  @Watch('queryBuilder.watch')
  @Watch('pagination.currentPage')
  fetchEntity(): void {
    this.loading = true;
    this.$http.get(this.$api(`/messages?page=${this.pagination.currentPage}&${this.queryBuilder.getFilters()}`)).then(response => {
      this.messages = response.data.messages;
      this.setPagination(response.data);
      this.loading = false;
    });
  }

  closeBtn() {
    this.isFilterModelActive = false;
    this.onClickClear();
  }

  handleClose(done: any) {
    done();
  }

}


































































import {Component, Vue, Watch} from 'vue-property-decorator';

@Component({
    components: {}
})
export default class SetupWelcome extends Vue{

    get userName(){
       var user: any = localStorage.getItem('user');
       var name = JSON.parse(user);
       return name.name;
    }
}





















import { Vue, Component, Prop, Mixins } from 'vue-property-decorator';
import BaseCUModalComponent from '@/mixins/BaseCUModalComponent';
import { AxiosResponse } from 'axios';
import Feature from "@/intefaces/Feature";

@Component({
    components: {}
})
export default class CreateFeatureForm extends Mixins(BaseCUModalComponent){
    @Prop()
    currentEntity!:Feature;

    @Prop({default: () => ({ title: ''}) })
    form!: {
        title: string;
    }

    formReset(): any {
        this.form.title = ''
        return this.form;
    }

    async afterCreate(response: AxiosResponse<any>): Promise<void> {
        this.$emit('created', response.data.feature);
    }

    afterUpdate(response: AxiosResponse<any>): void {
        this.$emit('updated', response.data.feature)
    }

    landlordCheck(): boolean {
        return true;
    }

    get createApi(): string {
        return '/features';
    }

    get updateApi(): string{
        return `/features/${this.currentEntity.id}`;
    }
}

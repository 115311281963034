































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {}
})
export default class SummaryReviewDiagram extends Vue {
  @Prop() currentEntities!: any;
  @Prop() step!: number;

  updateStep() {
    this.$store.dispatch('SetupWizard/updateCurrentStep', this.step);
    this.$emit('triggerEdit', null);
  }
}





































































import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Timeline, TimelineItem, Tooltip} from "element-ui";
import Target from "@/intefaces/Target";
import PaginationComponentMixin from "@/mixins/PaginationComponent";
import TargetAchievement from "@/intefaces/TargetAchievement";

@Component({
  components: {
    [Tooltip.name]: Tooltip,
    [Timeline.name]: Timeline,
    [TimelineItem.name]: TimelineItem,
  },
  name: 'TargetAchievedHistory'
})
export default class TargetAchievedHistory extends Vue {
  @Prop()
  public isActive!: boolean;
  @Prop()
  public currentTarget!: Target;
  private targetAchievements: TargetAchievement[] = [];
  private root_scrollbar__wrap!: Element & ElementCSSInlineStyle;
  private firstItemTrigger!: Element & ElementCSSInlineStyle;
  private lastItemTrigger!: Element & ElementCSSInlineStyle;
  private observing = false;
  private observer!: IntersectionObserver;
  private targetProducts: any = [];
  private pagination: any = {
    currentPage: 1,
    per_page: 0,
    next_page_url: null,
    last_page: null,
  };

  @Watch('targetAchievements')
  onChangeEntity(value: any[]) {
    this.$emit('targetAchievements', value);
    this.$nextTick(() => {
      if(value.length > 0) {
        if (this.pagination.next_page_url && !this.observing) {
          this.initInterceptionObserver();
        }
      }
    })
  }

  initInterceptionObserver() {
    let firstItemTrigger = this.$refs.firstItemTrigger as Element;
    let lastItemTrigger = this.$refs.lastItemTrigger as Element;
    this.observer.observe(firstItemTrigger);
    this.observer.observe(lastItemTrigger);
    this.observing = true;
  }

  mounted() {
    this.root_scrollbar__wrap = this.$el.querySelector('.el-scrollbar__wrap') as Element & ElementCSSInlineStyle;
    this.firstItemTrigger = this.$el.querySelector('#firstItemTrigger') as Element & ElementCSSInlineStyle;
    this.lastItemTrigger = this.$el.querySelector('#lastItemTrigger') as Element & ElementCSSInlineStyle;
    this.observer = new IntersectionObserver(this.intersectionHandler, { root: this.root_scrollbar__wrap });
  }

  async intersectionHandler(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
    for await (const entry of entries) {
      if (entry.target.id == 'lastItemTrigger' && entry.isIntersecting && this.pagination.next_page_url) {
        this.handleLoadMore();
      }
    }
  }

    handleLoadMore() {
    if(this.pagination.last_page > this.pagination.currentPage) {
      this.pagination.currentPage += 1;
    }
  }

  @Watch('pagination.currentPage')
  async fetchTarget() {
    this.$http.get(this.$api(`/targets/${this.currentTarget.id}/achievements?with=achievedBy&page=${this.pagination.currentPage}`)).then(response => {
      this.pagination.last_page = response.data.last_page;
      this.pagination.next_page_url = response.data.next_page_url;
      if (this.pagination.currentPage == 1) {
        this.targetAchievements = response.data.targetAchievements;
      } else {
        response.data.targetAchievements.forEach((item: any) => {
          this.targetAchievements.push(item);
        })
      }
    });
  }

  @Watch('currentTarget', {immediate: true, deep: true})
  fetchTargetProduct(newVal: any) {
    if(newVal.type == "product") {
      this.$http.get(this.$api(`/targets/${this.currentTarget.id}/target-products`)).then(response => {
        this.targetProducts = response.data.targetProducts;
      })
    }
  }

  close() {
    this.$emit('update:isActive', false);
  }

  created() {
    this.fetchTarget();
  }
}














































































































































































































import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import Department from '@/intefaces/Department';
import CreateDepartmentForm from './CreateDepartmentForm.vue';
import CreateBulkDepartments from './CreateBulkDepartments.vue';
import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tooltip, Row, Col, Tag } from 'element-ui';
import DepartmentFilters from '@/views/department/DepartmentFilters.vue';
import DepartmentFilterDrawer from '@/views/department/DeparmentFilterDrawer.vue';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import GIFModal from '@/components/tour/GIFModal.vue';
import TaxonomyMixin from "@/mixins/TaxonomyComponent";
import { AxiosResponse } from "axios";
import { Permission as PermissionEnum } from '@/enums/Permission'
import User from "@/intefaces/User";
import ViewDepartmentUser from "@/views/department/ViewDepartmentUser.vue";
import AsyncImage from "@/components/AsyncImage.vue";
import StaffList from "@/views/department/StaffList.vue";
import { QueryBuilder } from "@/classes/QueryBuilder";

@Component({
  components: {
    AsyncImage,
    StaffList,
    DepartmentFilters,
    CreateDepartmentForm,
    CreateBulkDepartments,
    DepartmentFilterDrawer,
    GIFModal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [Row.name]: Row,
    [Col.name]: Col,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
    ViewDepartmentUser,
  }
})
export default class DepartmentList extends Mixins(PaginationComponentMixin, TaxonomyMixin) {
  private departments: Department[] = [];
  private showBulkDepartmentsModal: boolean = false;
  private showDeleteModal: boolean = false;
  private isActive: boolean = false;
  private isUpdate: boolean = false;
  private isFilterModelActive: boolean = false;
  private showGIF: boolean = false;
  private departmentStaff: User[] = [];
  private isActiveStaff: boolean = false;
  private currentEntity: any = [];
  loading = false;
  downloading = false;
  public filterQuery: string = '';
  private form: any = {
    address: '',
    created_by: null,
    lat: null,
    long: null,
    owner_name: '',
    phone_no: '',
    photo: '',
    photo_url: '',
    name: '',
    code: '',
    department_type_id: null,
    territory_id: null,
    territory: null,
    credit_limit: 0,
    outstanding_balance: 0,
    badge_id: null,
    supplier: null,
    supplier_id: null,
    users: null,
    is_visitable: true,
    staff: [],
    department_category_id: null,
    department_location_type_id: null,
    owner_dob: null,
  };
  private demoTourEntity: string = 'department';
  private GIFs: Array<any> = [
    {
      label: 'Create a department',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a department',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a department',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of department';

  get permissionEnum() {
    return PermissionEnum;
  }

  created() {
    this.fetchDepartments();
  }

  entityCreated(department: Department) {
    this.departments.unshift(department);
  }

  onEdit(row: Department) {
    this.isUpdate = true;
    this.currentEntity = row;
    var routePlan: any = [];
    var userList: any = [];
    if (this.currentEntity.users) {
      this.currentEntity.users.forEach((user: User) => {
        userList.push(user.id);
      });
    }
    this.form = {
      id: this.currentEntity.id,
      address: this.currentEntity.address,
      created_by: Number(this.currentEntity.created_by),
      lat: this.currentEntity.lat,
      long: this.currentEntity.long,
      owner_name: this.currentEntity.owner_name,
      phone_no: this.currentEntity.phone_no,
      photo: this.currentEntity.photo,
      photo_url: this.currentEntity.photo_url,
      name: this.currentEntity.name,
      code: this.currentEntity.code,
      department_type_id: Number(this.currentEntity.department_type_id),
      territory_id: Number(this.currentEntity.territory_id),
      territory: this.currentEntity.territory,
      credit_limit: this.currentEntity.credit_limit,
      outstanding_balance: this.currentEntity.outstanding_balance,
      badge_id: this.currentEntity.badge_id ? Number(this.currentEntity.badge_id) : null,
      supplier_id: Number(this.currentEntity.supplier_id),
      supplier: this.currentEntity.supplier,
      staffs: userList,
      is_visitable: Boolean(this.currentEntity.is_visitable),
      department_category_id: this.currentEntity.department_category_id,
      department_location_type_id: this.currentEntity.department_location_type_id,
      owner_dob: this.currentEntity.owner_dob,
    };
    this.isActive = true;
  }

  entityUpdated(department: Department) {
    this.departments.splice(
      this.departments.findIndex(value => value.id == department.id),
      1,
      department
    );
  }

  @Watch('pagination.currentPage')
  fetchDepartments() {
    this.loading = true
    this.$http.get(this.$api(`/departments?page=${this.pagination.currentPage}${this.filterQuery ?
      '&' + this.filterQuery : ''}`))
      .then((response: AxiosResponse) => {
        this.departments = response.data.departments;
        this.setPagination(response.data);
      })
      .finally(() => this.loading = false);
  }

  @Watch('filterQuery')
  onChangeFilterQuery() {
    this.pagination.currentPage = 1
    this.fetchDepartments();
  }

  deleteEntity(): void {
    this.$http.delete(this.$api(`/departments/${this.currentEntity.id}`)).then(response => {
      this.showDeleteModal = false;
      this.departments.splice(
        this.departments.findIndex(value => value.id == this.currentEntity.id),
        1
      );
      this.$notify({
        title: 'Success',
        message: 'Successfully Deleted',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'success'
      });
    });
  }

  async download() {
    const link = document.createElement("a");
    link.href = this.$api(`/department-bulk-download?download${this.filterQuery ? '&' + this.filterQuery : ''}`);
    link.target = '_blank';
    link.click();
  }
}























import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator';
import { Select, Option } from 'element-ui';
import Territory from '@/intefaces/Territory';
import DropdownField from '@/mixins/DropdownField';
import LazyDropdown from '@/components/LazyDropdown.vue';

@Component({
  components: {
    LazyDropdown,
    CreateNewTerritoryForm: () => import('@/views/territory/CreateTerritoryForm.vue'),
    [Select.name]: Select,
    [Option.name]: Option,
  },
})
export default class TerritoryAutocomplete extends Mixins(DropdownField) {
  @Prop({default: 'territories'})
  public endPoint!: string;

  @Prop({default: true})
  public fetchOnChangeDisable!: boolean;
}

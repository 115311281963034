var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('side-bar',{attrs:{"logo":(_vm.$cdn + "/img/logo.png")}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Home',
          icon: 'ni ni-planet text-primary',
          path: '/',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Subscription',
          path: '/admin/subscription/features',
          icon: 'ni ni-bullet-list-67 text-primary',
        }}})],1)],2),_c('div',{staticClass:"main-content"},[_c('admin-dashboard-navbar',{attrs:{"type":_vm.$route.name === 'alternative' ? 'light' : 'default'}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('dashboard-content')],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
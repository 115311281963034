



































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ProductBrandLazydropdown from '@/components/lazyDropdown/ProductBrandLazydropdown.vue';
import ProductCategoryAutocomplete from '@/components/lazyDropdown/ProductCategoryLazydropdown.vue';
import ProductUnitModal from "@/views/product/productModalComponent/ProductUnitModal.vue";

@Component({
  components: {
    ProductUnitModal,
    ProductBrandLazydropdown,
    ProductCategoryAutocomplete
  }
})
export default class UpdateProductModal extends Vue {
  @Prop() currentProduct!: any;
  @Prop() isActive!: boolean;
  @Prop({
    default: () => ({
      name: '',
      is_free: false,
      productBrandId: '',
      productCategoryId: '',
      description: '',
      usp: '',
      sku: '',
      units: [],
    })
  })
  form!: {
    name: string;
    productBrandId: any;
    productCategoryId: any;
    description: string;
    usp: string;
    sku: string;
    units: Array<Object>;
  };
  private loading = true;
  private showUnitModal = false;
  private disableButton = false;

  formReset() {
    this.form.name = '';
    this.form.productBrandId = '';
    this.form.productCategoryId = '';
    this.form.description = '';
    this.form.usp = '';
    this.form.sku = '';
    this.form.units = [];
    this.disableButton = false;
  }

  updateProduct() {
    this.disableButton = true;
    this.$http.patch(this.$api(`/base-products/${this.currentProduct.id}`), {
      name: this.form.name,
      sku: this.form.sku,
      product_brand_id: this.form.productBrandId,
      product_category_id: this.form.productCategoryId,
      description: this.form.description,
      usp: this.form.usp,
      units: this.form.units,
    })
      .then(response => {
        this.$emit('updated', response.data.baseProduct);
        this.$notify({
          title: 'Success',
          message: 'Successfully Updated',
          duration: 3000,
          iconClass: 'ni ni-bell-55',
          type: 'success'
        });
        this.formReset();
        this.$nextTick(() => {
          (this.$refs.formValidator as HTMLFormElement).reset();
        });
      })
      .catch(error => {
        this.disableButton = false;
        (this.$refs.formValidator as Vue & {
          setErrors: (errors: []) => any;
        }).setErrors(error.response.data.errors);
      });
  }

  close() {
    this.$parent.$emit('update:isActive', false);
    this.$emit('update:isActive', false);
    this.$emit('update:form', {
      name: '',
      productBrandId: '',
      productCategoryId: '',
      description: '',
      usp: '',
      sku: '',
      units: []
    });
    this.disableButton = false;
  }
}

































































































import {Component, Mixins, Watch} from "vue-property-decorator";
import{
    Table,
    TableColumn,
    DropdownMenu,
    DropdownItem,
    Dropdown,
    Tooltip,
} from "element-ui";
import PaginationComponentMixin from "@/mixins/PaginationComponent";
import Feature from "@/intefaces/Feature";
import CreateFeatureForm from "@/views/admin/subscription/feature/CreateFeatureForm.vue"

@Component({
    components: {
        [Tooltip.name]: Tooltip,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        CreateFeatureForm
    }
})
export default class FeatureList extends Mixins(PaginationComponentMixin){
    // Variable section +++
    private features: Feature[] = [];
    private showDeleteModal = false;
    private currentFeature!: Feature;
    private isActive: boolean = false;
    private isUpdate: boolean = false;
    private form = {
        title: ''
    }
    // Variable section ---

    //method +++
    deleteFeature(){
        this.$http.delete(this.$landLordApi(`/features/${this.currentFeature.id}`)).then(response =>{
            this.showDeleteModal = false;
            this.features.splice(this.features.findIndex(feature => feature.id == this.currentFeature.id),1);
            this.$notify({
                title: 'Success',
                message: 'Successfully Deleted',
                duration: 5000,
                iconClass: 'ni ni-bell-55',
                type: 'success'
            });
        })
    }

    save(){
        alert(1);
    }

    entityCreated(entity: Feature){
        this.features.unshift(entity);
    }

    entityUpdated(entity: Feature){
        this.features.splice(this.features.findIndex(feature => feature.id == entity.id),1, entity);
    }

    onEdit(row: Feature){
        this.isUpdate = true;
        this.currentFeature = row;
        this.form = {
            title: this.currentFeature.title
        };
        this.isActive = true;
    }
    //method --

    // Watcher +++
    @Watch('pagination.currentPage')
    fetchFeatures(){
       this.loading = true;
       this.$http.get(this.$landLordApi(`/features?page=${this.pagination.currentPage}`))
         .then(response => {
            this.features = response.data.features;
            this.setPagination(response.data);
            this.loading = false;
         })
    }
    // Watcher ---

    // Lifecycle hooks +++
    created(){
        this.fetchFeatures();
    }
    // Lifecycle hooks ---
}













































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import AuditableOutletForm from '@/views/inspection/auditable/AuditableOutletForm.vue';
import AssignAssetUpdateForm from '@/views/inspection/auditable/AuditableOutletUpdateForm.vue';
import AssignAsset from '@/intefaces/AssignAsset';
import filters from '@/data/InspectionFilter';
import {Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip, Drawer} from 'element-ui';
import GIFModal from '@/components/tour/GIFModal.vue';
import Asset from '@/intefaces/Asset';
import { Permission as PermissionEnum } from '@/enums/Permission'

@Component({
  components: {
    GIFModal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Drawer.name]: Drawer,
    AssignAssetUpdateForm,
    AuditableOutletForm
  }
})
export default class AuditableOutletList extends Vue {
  private isActiveAssignAsset = false;
  private isUpdate = false;
  private outlets: any = [];
  private assets: Asset[] = [];
  private outletAssets: AssignAsset[] = [];
  private currentAuditableOutlet: any = [];
  private showDeleteModal = false;
  private disableBtn = false;
  private loading: boolean = false;
  private filter = '';
  private selectedFilter = 'all';
  private isFilterModelActive: boolean = false;
  private form = {
    type: 'asset',
    outlets: [],
    auditable: '',
    providedAt: [],
    expiresAt: [],
    qty: []
  };
  private formUpdate = {
    auditable: '',
    prodivedAt: '',
    expiresAt: '',
    qty: ''
  };
  private pagination = {
    currentPage: 1,
    total: 0,
    perPage: 0
  };
  private showGIF: boolean = false;
  private demoTourEntity: string = 'inspectionOutlet';
  private GIFs: Array<any> = [
    {
      label: 'Create an outlet',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update an outlet',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete an outlet',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of outlet';

  get permissionEnum () {
    return PermissionEnum;
  }

  get filters() {
    let data = [...filters];
    return data;
  }

  @Watch('selectedFilter')
  filterAuditableOutlet(newVal: any) {
    this.fetchOutletAuditables();
  }

  onEdit(row: any) {
    this.isUpdate = true;
    this.currentAuditableOutlet = row;
    this.formUpdate = {
      auditable: this.currentAuditableOutlet.auditable_id,
      prodivedAt: this.currentAuditableOutlet.provided_at,
      expiresAt: this.currentAuditableOutlet.expires_at,
      qty: this.currentAuditableOutlet.qty
    };
  }

  deleteAssignAsset() {
    this.disableBtn = true;
    this.$http
      .delete(this.$api(`/outlet-assets/${this.currentAuditableOutlet.id}`))
      .then(response => {
        this.showDeleteModal = false;
        this.disableBtn = false;
        this.outletAssets.splice(
          this.outletAssets.findIndex(outletAsset => outletAsset.id == this.currentAuditableOutlet.id),
          1
        );
        this.$notify({
          title: 'Success',
          message: 'Successfully Deleted',
          duration: 5000,
          iconClass: 'ni ni-bell-55',
          type: 'success'
        });
      })
      .catch(error => {
        this.disableBtn = false;
        (this.$refs.formValidator as Vue & {
          setErrors: (errors: []) => any;
        }).setErrors(error.response.data.errors);
      });
  }

  AssignAsset(outletAsset: any) {
    this.isActiveAssignAsset = false;
    this.outletAssets.unshift(outletAsset);
  }

  updateAssignAsset(assignAsset: any) {
    this.isUpdate = false;
    this.outletAssets.splice(
      this.outletAssets.findIndex(outletAsset => outletAsset.id == assignAsset.id),
      1,
      assignAsset
    );
  }

  fetchOutlet() {
    this.$http.get(this.$api(`/departments?has_outlet=1`)).then(response => {
      this.outlets = response.data.departments;
    });
  }

  fetchAsset() {
    this.$http.get(this.$api('/assets')).then(response => {
      this.assets = response.data.assets;
    });
  }

  fetchOutletAuditables() {
    this.loading = true;
    let isFirstPage = this.pagination.currentPage < 2;
    let url = isFirstPage
      ? `/outlet-auditables?auditable_type=${this.selectedFilter != 'all' ? this.selectedFilter : ''}`
      : `/outlet-auditables?auditable_type=${this.selectedFilter}?page=${this.pagination.currentPage}`;

    this.$http.get(this.$api(url)).then(response => {
      this.outletAssets = response.data.outletAuditables;
      this.pagination.total = response.data.total;
      this.pagination.perPage = response.data.per_page;
      this.loading = false;
    });
  }

  created() {
    this.fetchAsset();
    this.fetchOutlet();
    this.fetchOutletAuditables();
  }

  closeBtn() {
    this.isFilterModelActive = false;
  }

  handleClose(done: any) {
    done();
  }

  resetBtn() {
    this.selectedFilter = 'all';
  }
}










































































































import {Component, Mixins, Prop} from "vue-property-decorator";
import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tag, Tooltip} from "element-ui";
import moment from "moment";
import TaxonomyMixin from "@/mixins/TaxonomyComponent";

@Component({
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag
  },

  filters: {
    toDays(value: any) {
      return moment(value).fromNow();
    }
  }

})
export default class LastDueInvoices extends Mixins(TaxonomyMixin) {
  @Prop() isActive!: boolean;
  @Prop() dues !: any;

  close() {
    this.$emit('update:isActive', false);
  }
}
















































































































import {Component, Vue} from "vue-property-decorator";
import {Card, Option, Popover, Select, Switch} from "element-ui";
import TaxonomyJson  from '@/assets/json/Taxonomy.json';

@Component({
  components: {
    [Switch.name]: Switch,
    [Popover.name]: Popover,
    [Select.name]: Select,
    [Option.name]: Option,
    [Card.name]: Card,
  }
})
export default class Taxonomy extends Vue {
  private taxonomyArray: any = [];
  private retrievedSettings: any = [];
  private form: any = {
    order: '',
    requisition: '',
    invoice: '',
    salesReturn: '',
    department: ''
  }

  updateTaxonomy () {
    this.taxonomyArray = [
      {"order": this.form.order},
      {"requisition": this.form.requisition},
      {"salesReturn": this.form.salesReturn},
      {"invoice": this.form.invoice},
      {"department": this.form.department}
    ]
    this.$http.patch(this.$api('/settings/1'), {taxonomy: this.taxonomyArray}).then(response => {
      this.updateSMSNotification(response.status);
      this.taxonomySyncLocalStorage();
      location.reload();
    })
  }

  updateSMSNotification(status: number) {
    this.$notify({
      title: status == 200 ? 'Success' : 'Error',
      message: status == 200 ? "Taxonomy settings updated" : "Taxonomy settings could not be updated",
      duration: 5000,
      iconClass: 'fa fa-clone',
      // @ts-ignore
      verticalAlign: 'bottom',
      horizontalAlign: 'right',
      type: status == 200 ? 'success' : 'warning'
    });
  }

  getFormData (taxonamyArray: any) {
    taxonamyArray.forEach((taxonomy: any) => {
      this.form[Object.keys(taxonomy)[0]] = Object.values(taxonomy)[0]
    });
  }

  fetchSettings() {
    this.$http.get(this.$api(`/settings`)).then(response => {
      if (response.data.settings.taxonomy != null) {
        this.getFormData(JSON.parse(response.data.settings.taxonomy));
      } else {
        this.taxonomyArray = TaxonomyJson
        this.getFormData(this.taxonomyArray);
        this.$http.patch(this.$api('/settings/1'), {taxonomy: this.taxonomyArray});
        this.taxonomySyncLocalStorage();
      }
    })
  }

  async taxonomySyncLocalStorage() {
    if (localStorage.settings) {
      this.retrievedSettings = JSON.parse(localStorage.settings);
      if(this.retrievedSettings) {
        this.retrievedSettings.taxonomy = this.taxonomyArray;
      }
      localStorage.removeItem('settings');
      this.$store.dispatch('Settings/updateSettings', this.retrievedSettings);
      this.$store.dispatch('Settings/updateTaxonomy', this.taxonomyArray);
    } else {
      let settings = [];
      settings.push({taxonomy: this.taxonomyArray});
      this.$store.dispatch('Settings/updateSettings', this.retrievedSettings);
      this.$store.dispatch('settings/updateTaxonomy', this.taxonomyArray);
    }
  }

  created() {
    this.fetchSettings();
  }
}

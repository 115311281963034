














































import {Component, Vue, Watch} from 'vue-property-decorator';
import TerritoryLazydropdown from "@/components/lazyDropdown/TerritoryLazydropdown.vue";
import DateRange from "@/components/DateRange.vue";
import {QueryBuilder} from "@/classes/QueryBuilder";
import { Permission as PermissionEnum } from '@/enums/Permission'

const flatPicker = require('vue-flatpickr-component');
@Component({
  components: {
    TerritoryLazydropdown,
    flatPicker,
    DateRange
  }
})
export default class PerformanceReport extends Vue {
  public downloading: boolean = false;
  private showDownloadModal: boolean = false;
  public territory_id: number | null = null;
  public queryBuilder: QueryBuilder = new QueryBuilder();
  public date: string = '';

  todaysDate() {
    var currentDate = new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, '/');
    return currentDate;
  }

  get permissionEnum () {
    return PermissionEnum;
  }

  async onClickDownload() {
    this.downloading = true;
    try {
      let {data, headers} = await this.$http.get(this.$api('/performance-monitoring-report' + this.queryBuilder.getFilters(true)), {responseType: 'arraybuffer'});
      const blob = await new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const link = document.createElement("a");
      link.href = await URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({title: 'Something wants wrong!', type: 'warning', message: 'Unable to download reports.',})
    } finally {
      this.downloading = false;
      this.showDownloadModal = false;
    }
  }

  @Watch('territory_id')
  onChangeTerritoryId(value: number) {
    this.queryBuilder.addListener('territory_id', value);
  }

  @Watch('date')
  onChangeRange(value: number) {
    this.queryBuilder.addListener('range', value);
  }

  close() {
    this.showDownloadModal = false;
  }
}



































































































































































































import {Component, Mixins, Vue, Watch} from 'vue-property-decorator';
import User from '@/intefaces/User.js';
import CreateUser from '@/views/user/user/CreateUser.vue';
import CreateBulkUsers from '@/views/user/user/CreateBulkUsers.vue';
import GIFModal from '@/components/tour/GIFModal.vue';
import ViewUserAcl from '@/views/user/user/ViewUserAcl.vue';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import FilterDrawerComponent from "@/components/FilterDrawerComponent.vue";
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import TerritoryLazydropdown from "@/components/lazyDropdown/TerritoryLazydropdown.vue";

import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tag, Tooltip} from 'element-ui';
import RoleLazydropdown from "@/components/lazyDropdown/RoleLazydropdown.vue";
import {QueryBuilder} from "@/classes/QueryBuilder";
import AsyncImage from "@/components/AsyncImage.vue";
import {Permission as PermissionEnum} from '@/enums/Permission'

@Component({
  components: {
    AsyncImage,
    RoleLazydropdown,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
    CreateUser,
    CreateBulkUsers,
    ViewUserAcl,
    GIFModal,
    FilterDrawerComponent,
    UserLazydropdown,
    TerritoryLazydropdown,
  }
})
export default class UserList extends Mixins(PaginationComponentMixin) {
  private users: User[] = [];
  private downloadingUsers = false;
  private isActive = false;
  private isUpdate = false;
  private showBulkUsersModal = false;
  private showDeleteModal = false;
  private showUserAclList = false;
  private currentUser: any = [];
  private showGIF = false;
  private query: any = [];
  private isFilterModelActive = false;
  private afterQuery: boolean = false;
  private statuses = [{label: 'Active', value: '1'}, {label: 'Inactive', value: '0'}]
  private form = {
    name: '',
    email: '',
    phone: '',
    address: '',
    gender: '',
    territory_id: '',
    password: '',
    password_confirmation: '',
    permissions: [],
    roles: [],
    status: true,
    code: '',
    device_id: ''
  };
  private filters: any = {
    name: '',
    code: '',
    phone: '',
    territory_id: '',
    role: '',
    status: '',
  }
  private queryBuilder = new QueryBuilder();
  private demoTourEntity: string = 'user';
  private GIFs: Array<any> = [
    {
      label: 'Create a user',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a user',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a user',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of user';

  get permissionEnum() {
    return PermissionEnum;
  }

  onEdit(row: User) {
    this.isUpdate = true;
    this.currentUser = row;
    var userPermission: any = [];
    var userRole: any = [];
    this.currentUser.permissions.forEach((permission: any) => {
      userPermission.push(permission.id);
    });
    this.currentUser.roles.forEach((role: any) => {
      userRole.push(role.id);
    });
    this.form = {
      name: this.currentUser.name,
      email: this.currentUser.email,
      phone: this.currentUser.phone,
      address: this.currentUser.address,
      gender: this.currentUser.gender,
      territory_id: this.currentUser.territory ? this.currentUser.territory.id : null,
      password: '',
      password_confirmation: '',
      permissions: this.currentUser.permissions.length > 0 ? userPermission : [],
      roles: this.currentUser.roles ? userRole : '',
      status: this.currentUser.active == 1 ? true : false,
      code: this.currentUser.code,
      device_id: this.currentUser.device_id || '',
    };
    this.isActive = true;
  }

  get user_id() {
    return this.$store.getters['Auth/user'].id;
  }

  created() {
    this.fetchUser();
  }

  onDelete() {
    this.$http
      .delete(this.$api(`/users/${this.currentUser.id}`))
      .then(response => {
        this.showDeleteModal = false;
        this.users.splice(
          this.users.findIndex(user => user.id == this.currentUser.id),
          1
        );
        this.$notify({
          title: 'Success',
          message: 'Successfully Deleted',
          duration: 5000,
          iconClass: 'ni ni-bell-55',
          type: 'success'
        });
      })
      .catch(error => {
        (this.$refs.formValidator as Vue & {
          setErrors: (errors: []) => any;
        }).setErrors(error.response.data.errors);
      });
  }

  userCreated(user: any) {
    this.isActive = false;
    this.users.unshift(user);
  }

  userUpdated(updatedUser: any) {
    this.isUpdate = false;
    this.users.splice(
      this.users.findIndex(user => user.id == updatedUser.id),
      1,
      updatedUser
    );
    this.isActive = false;
  }

  @Watch('filters.name', {deep: true})
  onChangeNameFiler(newVal: string) {
    this.queryBuilder.addListener('q', newVal);
  }

  @Watch('filters.code', {deep: true})
  onChangeCodeFiler(newVal: string) {
    this.queryBuilder.addListener('code', newVal);
  }

  @Watch('filters.phone', {deep: true})
  onChangePhoneFiler(newVal: string) {
    this.queryBuilder.addListener('phone', newVal);
  }

  @Watch('filters.territory_id', {deep: true})
  onChangeTerritoryFiler(newVal: string) {
    this.queryBuilder.addListener('t', newVal);
  }

  @Watch('filters.role', {deep: true})
  onChangeRoleFiler(newVal: string) {
    this.queryBuilder.addListener('role', newVal);
  }

  @Watch('filters.status', {deep: true})
  onChangeStatusPhoneFiler(newVal: string) {
    this.queryBuilder.addListener('active', newVal);
  }

  @Watch('pagination.currentPage')
  onChangePage(newVal: any) {
    this.queryBuilder.addListener('page', newVal);
  }

  resetBtn() {
    this.filters.name = '';
    this.filters.code = '';
    this.filters.phone = '';
    this.filters.territory_id = '';
    this.filters.role = '';
    this.filters.status = ''
  }

  onFilterSubmit() {
    this.isFilterModelActive = false;
  }

  @Watch('queryBuilder.watch')
  fetchUser(): void {
    this.loading = true;
    this.$http.get(this.$api(`/users${this.queryBuilder.getFilters(true)}`)).then(response => {
      this.users = response.data.users;
      this.setPagination(response.data);
      this.loading = false;
    });
  }

  async onClickResetUserDevice(row: any) {
    await this.$http.patch(this.$api("/users/" + row.id + '/reset-device'))
    let index = await this.users.findIndex(user => user.id == row.id);
    this.users[index].device_id = null
    this.$notify({title: 'Success', message: 'Device reset successfully.', type: 'success'})
  }

  async downloadUser(){
    this.downloadingUsers = true;
    try {
      const link = document.createElement("a");
      link.href = this.$api(`/user-bulk-download?download&${this.queryBuilder.getFilters(false)}`);
      link.click();
    } catch (e) {
      this.$notify({type: 'warning', message: 'Unable to download.', title: 'Something went wrong!'});
    } finally {
      this.downloadingUsers = false;
    }
  }
}

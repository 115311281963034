












import { Component, Mixins, Prop } from "vue-property-decorator";
import LazyDropdown from "../LazyDropdown.vue";
import DropdownField from "@/mixins/DropdownField";
import CreateUser from "@/views/user/user/CreateUser.vue";

@Component({
  components: {
    LazyDropdown,
    CreateUser
  }
})
export default class UserLazydropdown extends Mixins(DropdownField) {
  @Prop({default: 'users'}) endPoint!: string;
  @Prop({default: true}) addButton!: boolean;
  @Prop({default: false}) collapseTags!: boolean;
  @Prop({default: 'q'}) searchableKey!: string;
  @Prop({default: 'name'}) itemText!: string;
  @Prop({default: true})
  public fetchOnChangeDisable!: boolean;

}






























































































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'PromotionViewForm'
})
export default class PromotionViewForm extends Vue {
  @Prop() isView!: boolean;
  @Prop() currentPromotion!: any;
  private product: any = [];


  fetchProduct() {
    if (this.currentPromotion.promotion_on == "product") {
      this.$http.get(this.$api(`/products/${this.currentPromotion.promotionable_id}`)).then(response => {
        this.product = response.data.product
      })
    }
  }

  created() {
    this.fetchProduct();
  }

  close() {
    this.$emit('update:isView', false);
  }
}

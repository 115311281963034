















































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AxiosPromise, AxiosResponse } from 'axios';
import { Radio } from 'element-ui';
import { localize } from 'vee-validate';
import countryCodes from '@/assets/json/CountryCodes.json';
import en from 'vee-validate/dist/locale/en.json';
import SingleImageUploader from "@/components/ui/SingleImageUploader.vue";

localize('en', en);
localize({
  en: {
    names: {
      domain: 'Domain',
      org_name: 'Organization Name',
      email: 'Email',
      password: 'Password',
      password_confirmation: 'Password Confirmation',
      name: 'Name',
      gender: 'Gender',
      phone: 'Phone',
      address: 'Address',
    },
  },
});

const auth = namespace('Auth');

@Component({
  components: {
    [Radio.name]: Radio,
    SingleImageUploader
  },
})
export default class RegisterInfo extends Vue {
  @Prop({required: true})
  selectedPackage!: any;
  private countryIndex: number = 18;
  private countryPhoneCode: string = '880';
  private phoneNumber: any = '';
  private image: any = '';
  public countryCodes: any = countryCodes;
  private currency: string = 'BDT';
  private maxPhoneDigit = 10;
  private showPassword: boolean = false;
  private creatingDB: boolean = false;

  fields = {
    domain: '',
    org_name: '',
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    device_name: '',
    gender: '',
    phone: '',
    address: '',
    scheme_id: '',
    user_quota: '',
    coupon_code: '',
    currency: 'BDT',
  };
  loading: boolean = false;
  @auth.Getter
  public isLoggedIn!: boolean;

  @auth.Action
  public register!: (payload: any) => AxiosPromise<any>;

  @Watch('countryIndex')
  onChangeCountryIndex(newVal: any) {
    this.currency = this.countryCodes[newVal].currency;
    this.countryPhoneCode = this.countryCodes[newVal].phone;
    this.maxPhoneDigit = this.countryCodes[newVal].maxPhoneDigit;
  }

  async onSubmit() {
    this.loading = true;
    let form = new FormData();
    if (this.image) {
      form.append('domain', this.fields.domain);
      form.append('org_name', this.fields.org_name);
      form.append('name', this.fields.name);
      form.append('email', this.fields.email);
      form.append('password', this.fields.password);
      form.append('password_confirmation', this.fields.password_confirmation);
      form.append('gender', this.fields.gender);
      form.append('address', this.fields.address);
      form.append('scheme_id', this.selectedPackage.scheme_id);
      form.append('logo', this.image);
      form.append('device_name', navigator.userAgent);
      if (this.selectedPackage.coupon_code) {
        form.append('coupon_code', this.selectedPackage.coupon_code);
      }
      if (this.selectedPackage.user_quota) {
        form.append('user_quota', this.selectedPackage.user_quota);
      }
      form.append('phone', '+' + this.countryPhoneCode + '-' + this.phoneNumber);
      form.append('currency', this.currency);
    }
    this.fields.device_name = navigator.userAgent;
    this.fields.scheme_id = this.selectedPackage.scheme_id;
    this.fields.user_quota = this.selectedPackage.user_quota;
    this.selectedPackage.coupon_code ? (this.fields.coupon_code = this.selectedPackage.coupon_code) : delete this.fields.coupon_code;
    this.fields.phone = '+' + this.countryPhoneCode + '-' + this.phoneNumber;
    this.fields.currency = this.currency;
    let self = this;
    try {
      await this.register({vm: this, fields: this.image ? form : this.fields})
      this.creatingDB = true;
      setInterval(async () => {
        try {
          await this.$http.post(Vue.prototype.$landLordApi('/validate-org'), {domain: this.fields.domain})
          this.creatingDB = false
          this.$notify({
            title: 'Success',
            message: 'Registration successful',
            duration: 5000,
            iconClass: 'ni ni-bell-55',
            type: 'success'
          });
          window.location.replace(this.$appBaseUrl.replace('app', this.$store.getters['Auth/getDomain']));
        } catch (e) {
          this.creatingDB = true
        }
      }, 3500)
    } catch (error) {
      (self.$refs.formValidator as Vue & {
        setErrors: (errors: []) => any;
      }).setErrors(error.response.data.errors);
    } finally {
      self.loading = false;
    }
  }

  mounted() {
    // swal.fire("The Internet?", "That thing is still around?", "question");
  }
}




















import { Component, Mixins } from 'vue-property-decorator';
import LazyDropdown from '../LazyDropdown.vue';
import DropdownField from '../../mixins/DropdownField';
import CreateFeatureForm from '@/views/admin/subscription/feature/CreateFeatureForm.vue';

@Component({
  components: {
    LazyDropdown,
    CreateFeatureForm,
  },
})
export default class FeatureLazydropdown extends Mixins(DropdownField) {}















































import { Component, Mixins, Vue, Watch } from 'vue-property-decorator';
import { Permission as PermissionEnum } from '@/enums/Permission';
import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tooltip, Button } from 'element-ui';

@Component({
  components: {
    [Button.name]: Button
  }
})
export default class HRReport extends Vue {
  private reports: any[] = [
    {
      name: 'TA Report',
      tooltip: 'A detailed Report on Travel Allowances.',
      permission: PermissionEnum.HR_TRAVEL_ALLOWANCE_REPORT,
      path: 'ta-report'
    },
    {
      name: 'Tracking Report',
      tooltip: 'A detailed Report on Trackings of users.',
      permission: PermissionEnum.HR_TRACKING_REPORT,
      path: 'tracking-report'
    },
    {
      name: 'Check-In Report',
      tooltip: 'A detailed Report on Check-Ins of users.',
      permission: PermissionEnum.HR_CHECKIN_REPORT,
      path: 'checkin-report'
    },
    {
      name: 'Attendance Report',
      tooltip: 'A detailed Report on Attendance of users.',
      permission: PermissionEnum.HR_CHECKIN_REPORT,
      path: 'attendance-report'
    }
  ];

  todaysDate() {
    var currentDate = new Date()
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, '/');
    return currentDate;
  }
}

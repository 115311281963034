var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isActive)?_c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('modal',{staticClass:"modal-dialog-scrollable",attrs:{"show":_vm.isActive},on:{"close":_vm.close}},[_c('template',{slot:"header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel"}},[_vm._v("Add new Travel Allowance")])]),_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('base-input',{attrs:{"name":"reason","rules":{required:true},"label":"Reason","placeholder":"e.g. Office tour, Site visit etc."},model:{value:(_vm.form.reason),callback:function ($$v) {_vm.$set(_vm.form, "reason", $$v)},expression:"form.reason"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('base-input',{attrs:{"label":"Travel Date","placeholder":"yyyy-mm-dd","name":"travel date","input-classes":"form-control-alternative","min":"2021","rules":{required:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var focus = ref.focus;
var blur = ref.blur;
return _c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{allowInput: true},"placeholder":_vm.form.travel_date ||'Select travel Date.',"minDate":"today"},on:{"on-open":focus,"on-close":blur},model:{value:(_vm.form.travel_date),callback:function ($$v) {_vm.$set(_vm.form, "travel_date", $$v)},expression:"form.travel_date"}})}}],null,true),model:{value:(_vm.form.travel_date),callback:function ($$v) {_vm.$set(_vm.form, "travel_date", $$v)},expression:"form.travel_date"}})],1),_c('div',{staticClass:"col-6"},[_c('transport-lazydropdown',{attrs:{"label":"Mode of Transport","name":"transport id","rules":{required:true},"placeholder":"Select Transport Type"},model:{value:(_vm.form.transport_id),callback:function ($$v) {_vm.$set(_vm.form, "transport_id", $$v)},expression:"form.transport_id"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('base-input',{attrs:{"name":"start location","rules":{required:true},"label":"Start Location","placeholder":"e.g. Mohammadpur"},model:{value:(_vm.form.start_location),callback:function ($$v) {_vm.$set(_vm.form, "start_location", $$v)},expression:"form.start_location"}})],1),_c('div',{staticClass:"col-6"},[_c('base-input',{attrs:{"name":"end location","rules":{required:true},"label":"End Location","placeholder":"e.g. Gulshan"},model:{value:(_vm.form.end_location),callback:function ($$v) {_vm.$set(_vm.form, "end_location", $$v)},expression:"form.end_location"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('base-input',{attrs:{"label":"Start Time","name":"start time","prepend-icon":"fas fa-clock","rules":{required:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var focus = ref.focus;
var blur = ref.blur;
return _c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{ allowInput: true, enableTime: true, noCalendar: true, dateFormat: 'H:i:ss' }},on:{"on-open":focus,"on-close":blur},model:{value:(_vm.form.start_time),callback:function ($$v) {_vm.$set(_vm.form, "start_time", $$v)},expression:"form.start_time"}})}}],null,true)})],1),_c('div',{staticClass:"col-6"},[_c('base-input',{attrs:{"label":"End Time","name":"end time","prepend-icon":"fas fa-clock","rules":{required:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var focus = ref.focus;
var blur = ref.blur;
return _c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{ allowInput: true, enableTime: true, noCalendar: true, dateFormat: 'H:i:ss' }},on:{"on-open":focus,"on-close":blur},model:{value:(_vm.form.end_time),callback:function ($$v) {_vm.$set(_vm.form, "end_time", $$v)},expression:"form.end_time"}})}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('base-input',{attrs:{"name":"comment","label":"Comments (Optional)","placeholder":"e.g Need to meet manager"},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}})],1),_c('div',{staticClass:"col-6"},[_c('base-input',{attrs:{"type":"number","min":"0","name":"expense","label":"Expense","placeholder":"e.g 100 BDT","rules":{required:true}},model:{value:(_vm.form.expense),callback:function ($$v) {_vm.$set(_vm.form, "expense", $$v)},expression:"form.expense"}})],1)]),_c('div',{staticClass:"row d-flex justify-content-center"},[_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"font-weight-500"},[_vm._v("Image (Optional)")]),_c('div',{staticClass:"modal-dialog-scrollable"},[_c('el-upload',{ref:"upload",staticClass:"avatar-uploader",attrs:{"action":"#","multiple":"","limit":5,"file-list":_vm.fileList,"on-change":_vm.handleChange,"on-remove":_vm.handleChange,"auto-upload":false,"list-type":"picture-card","show-file-list":""}},[_c('el-button',{attrs:{"slot":"trigger","size":"small","type":"secondary"},slot:"trigger"},[_vm._v("Select files ")])],1)],1)])])]),_c('template',{slot:"footer"},[_c('base-button',{attrs:{"type":"secondary"},on:{"click":_vm.close}},[_vm._v("Close")]),_c('base-button',{attrs:{"disabled":_vm.disableBtn,"type":"success","loading":_vm.loading},on:{"click":function($event){return handleSubmit(_vm.createTA)}}},[_vm._v("Save ")])],1)],2)]}}],null,false,3001299908)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }




















































































































































































































































































































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tag, Tooltip, Button, Popover } from "element-ui";
import { Invoice } from "@/intefaces/Invoice";
import TaxonomyMixin from "@/mixins/TaxonomyComponent";

@Component({
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Popover.name]: Popover
  }
})
export default class InvoiceDetails extends Mixins(TaxonomyMixin) {
  @Prop() isActive!: boolean;
  @Prop() invoiceDetails!: Invoice;
  @Prop() invoicedProduct!: any;
  @Prop() isDeliver!: boolean;
  @Prop() isRevisitionDetails!: boolean;

  @Prop() revision!: any;


  close() {
    this.$emit('update:isActive', false);
    this.$emit('update:isRevisitionDetails', false);
  }
}















































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import DepartmentType from '@/intefaces/DepartmentType';
import CreateChannelForm from '@/views/department/CreateChannelForm.vue';
import { clone } from 'lodash';
@Component({
  components: { CreateChannelForm }
})
export default class SetupOrganization extends Vue {
  @Prop() departmentTypes !: DepartmentType[];
  private showDeleteModal: boolean = false;
  private isUpdate: boolean = false;
  private isActive: boolean = false;
  protected form: DepartmentType = {
    label: '',
    name: '',
    has_outlet: false,
    self_managed: false
  };
  private currentEntity!: DepartmentType;

  // Lifecycle hooks +++
  mounted() {
    this.$emit('departmentTypes', this.departmentTypes);
  }
  // Lifecycle hooks ---

  // Watcher +++
  @Watch('departmentTypes')
  onChangeDepartmentType() {
    this.$emit('departmentTypes', this.departmentTypes);
  }
  // Watcher ---

  onEdit(row: DepartmentType) {
    this.isUpdate = true;
    this.currentEntity = row;
    this.form = {
      self_managed: this.currentEntity.self_managed ? true : false,
      name: this.currentEntity.name,
      label: this.currentEntity.label,
      has_outlet: this.currentEntity.has_outlet ? true : false
    };
    this.isActive = true;
  }

  onClickAdd() {
    this.isActive = true;
  }

  removeEntity() {
    this.showDeleteModal = false;
    this.departmentTypes.splice(
      this.departmentTypes.findIndex((value, index) => value.name == this.currentEntity.name),
      1
    );
  }

  save() {
    if (this.isUpdate) {
      let itemIndex = this.departmentTypes.findIndex((value, index) => this.currentEntity.name == value.name);
      this.departmentTypes.splice(itemIndex, 1, clone(this.form));
    } else {
      this.departmentTypes.push(clone(this.form));
    }
    this.close();
  }

  close() {
    this.isActive = false;
    this.isUpdate = false;
    this.formReset();
  }

  formReset(): any {
    this.form.name = '';
    this.form.label = '';
    this.form.has_outlet = false;
    this.form.self_managed=false;
    return this.form;
  }
}





























import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import Order from "@/intefaces/Order";
import RevisionList from "@/views/order/Components/RevisionList.vue";
import OrderRevision from "@/intefaces/OrderRevision";
import Department from "@/intefaces/Department";

@Component({
  components: {
    RevisionList,
  }
})
export default class OrderDepartmentInfo extends Vue {
  @Prop() title!: string;
  @Prop() currentEntity!: Order;
  @Prop() revisionList!: OrderRevision[];
  private isActiveRevision: boolean = false;
  private buyerDepartment = '';
  private sellerDepartment = '';

  created() {
    this.$http.get(this.$api(`/departments/${this.currentEntity.buyer_id}`)).then(response => {
      this.buyerDepartment = response.data.department;
    })
    this.$http.get(this.$api(`/departments/${this.currentEntity.seller_id}`)).then(response => {
      this.sellerDepartment = response.data.department;
    })

  }
}

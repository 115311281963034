
















































































































import { Vue, Component, Prop, Mixins } from 'vue-property-decorator';
import OrderRevision from "@/intefaces/OrderRevision";
import AsyncImage from "@/components/AsyncImage.vue";
import PaginationComponentMixin from "@/mixins/PaginationComponent";
import { Timeline, TimelineItem } from "element-ui";

@Component({
  components: {
    AsyncImage,
    [Timeline.name]: Timeline,
    [TimelineItem.name]: TimelineItem,
  }
})

export default class RevisionList extends Mixins(PaginationComponentMixin) {
  @Prop() isActiveRevision!: boolean;
  @Prop() entityId!: number;
  @Prop() revisionList!: OrderRevision[];

  get getCurrency() {
    return this.$store.getters['Settings/getCurrency']
  }

  close(): void {
    this.$emit('update:isActiveRevision', false);
  }
}








































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import Delivery from '@/intefaces/Delivery';
import DeliveryDetails from '@/views/order/delivery/DeliveryDetails.vue';
import GIFModal from '@/components/tour/GIFModal.vue';
// import InvoiceDetails from "@/views/order/invoice/InvoiceDetails.vue";
import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tag, Tooltip} from 'element-ui';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import TaxonomyMixin from "@/mixins/TaxonomyComponent";

@Component({
  components: {
    GIFModal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
    DeliveryDetails
  }
})
export default class DeliveryList extends Mixins(PaginationComponentMixin, TaxonomyMixin) {
  private deliveries: Delivery[] = [];
  private isActiveInvoice: boolean = false;
  private invoiceDetail: any = [];
  private isDeliver: boolean = false;
  private showGIF: boolean = false;
  private demoTourEntity: string = 'delivery';
  private GIFs: Array<any> = [
    {
      label: 'Create a delivery',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a delivery',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a delivery',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of delivery';

  @Watch('pagination.currentPage')
  async fetchDelivery() {
    this.loading = true;
    try {
      let {data} = await this.$http.get(
        this.$api(`/deliveries?page=${this.pagination.currentPage}`)
      );
      this.setPagination(data);
      this.deliveries = data.deliveries;
    } catch (e) {
      this.$notify({title: 'Something Went Wrong!', message: 'Please Try again.', type: 'error'});
    } finally {
      this.loading = false;
    }
  }

  invoiceDetails(row: any) {
    this.isActiveInvoice = true;
    this.invoiceDetail = row;
    this.isDeliver = true;
  }

  created() {
    this.fetchDelivery();
  }
}

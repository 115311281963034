






























import { Vue, Component, Prop } from 'vue-property-decorator';
import Package from "@/intefaces/Package";
@Component({
    components: {
    }
})
export default class ViewPackageFeature extends Vue{
    @Prop() isActive!: boolean;
    @Prop() currentPackage!: Package;

    close(){
        this.$emit('update:isActive', false);
    }
}















































































































































import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tag, Tooltip,} from "element-ui";
import {Invoice} from "@/intefaces/Invoice";
import TaxonomyMixin from "@/mixins/TaxonomyComponent";

@Component({
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag
  }
})
export default class InvoiceDetails extends Mixins(TaxonomyMixin) {
  @Prop() isActive!: boolean;
  @Prop() invoiceDetails!: Invoice;
  @Prop() isDeliver!: boolean;

  close() {
    this.$emit('update:isActive', false);
  }
}

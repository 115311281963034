



























































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Col, Drawer, Row } from "element-ui";
import MultiOptionSearchBox from '@/components/MultiOptionSearchBox.vue';
import LazyDropdown from "@/components/LazyDropdown.vue";
import DepartmentTypeAutocomplete from '@/components/resources-autocomplete/DepartmentTypeAutocomplete.vue';
import TerritoryTypeAutocomplete from '@/components/resources-autocomplete/TerritoryTypeAutocomplete.vue';
import MemberAutocomplete from '@/components/resources-autocomplete/MemberAutocomplete.vue';
import Department from '@/intefaces/Department';
import TaxonomyMixin from "@/mixins/TaxonomyComponent";
import DateRange from '@/components/DateRange.vue';
import { QueryBuilder } from "@/classes/QueryBuilder";

@Component({
  components: {
    [Drawer.name]: Drawer,
    [Row.name]: Row,
    [Col.name]: Col,
    MultiOptionSearchBox,
    LazyDropdown,
    DepartmentTypeAutocomplete,
    TerritoryTypeAutocomplete,
    MemberAutocomplete,
    DateRange
  }
})
export default class DepartmentFilterDrawer extends Mixins(TaxonomyMixin) {
  @Prop({required: true})
  isFilterModelActive!: boolean

  public queryBuilder: QueryBuilder = new QueryBuilder();
  private drawer: boolean = false;

  private filters: any = {
    department_type_id: [],
    badges_id: [],
    territory_id: null,
    territory_type_id: [],
    nameORcode: {type: null, value: null},
    manager: null,
    created_by: [],
    range: '',
    creditLimitORoutstandingBalance: {type: null, value: null}
  };

  @Watch('isFilterModelActive')
  changeDrawerValue(newVal: any) {
    if (newVal) {
      this.drawer = true
    }
  }

  @Watch('filters.department_type_id', {immediate: true, deep: true})
  onChangeDepartmentType(value: []) {
    this.queryBuilder.addListener('dt', value.join())
  }

  @Watch('filters.badges_id')
  onChangeBadgeId(value: []) {
    this.queryBuilder.addListener('b', value?.join())
  }

  @Watch('filters.territory_id', {deep: true})
  onChangeTerritoryId(value: number) {
    this.queryBuilder.addListener('ut', value)
  }

  @Watch('filters.territory_type_id')
  onChangeTerritoryTypeId(value: []) {
    this.queryBuilder.addListener('tt', value?.join())
  }

  @Watch('filters.nameORcode', {immediate: true, deep: true})
  onChangeNameOrCode(value: { type: string, value: string }) {
    this.queryBuilder.addListener('name', null);
    this.queryBuilder.addListener('code', null);
    this.queryBuilder.addListener(value.type, value.value)
  }

  @Watch('filters.creditLimitORoutstandingBalance', {immediate: true, deep: true})
  onChangeCreditLimitORoutstandingBalance(value: { type: string, value: string }) {
    this.queryBuilder.addListener('ctgt', null);
    this.queryBuilder.addListener('cllt', null);
    this.queryBuilder.addListener('obgt', null);
    this.queryBuilder.addListener('oblt', null);
    this.queryBuilder.addListener(value.type, value.value)
  }

  @Watch('filters.manager', {immediate: true, deep: true})
  onChangeManager(value: number) {
    this.queryBuilder.addListener('manager', value)
  }

  @Watch('filters.created_by', {immediate: true, deep: true})
  onChangeCreatedBy(value: []) {
    this.queryBuilder.addListener('created_by', value?.join())
  }

  @Watch('filters.range', {immediate: true, deep: true})
  onChangeRange(value: string) {
    this.queryBuilder.addListener('range', value)
  }

  @Watch('queryBuilder.watch')
  onChangeFilter(value: string) {
    this.$emit('filters', value);
  }

  save(): void {

  }

  closeBtn(): void {
    this.drawer = false
    this.$emit('update:isFilterModelActive', false);
  }

  handleClose(done: any) {
    this.$emit('update:isFilterModelActive', false);
    done();
  }

  resetBtn() {
    this.$emit('update:isFilterModelActive', false);
    this.filters.territory_id = '';
    this.filters.territory_type_id = [];
    this.filters.nameORcode.type = null;
    this.filters.nameORcode.value = null;
    this.filters.department_type_id = [];
    this.filters.badges_id = [];
    this.filters.manager = null;
    this.filters.range = '';
    this.filters.creditLimitORoutstandingBalance.type = null;
    this.filters.creditLimitORoutstandingBalance.value = null;
    this.filters.created_by = null;
  }
}

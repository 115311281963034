





















































import { Vue, Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tooltip, Button } from 'element-ui';
import PaginationComponentMixin from '@/mixins/PaginationComponent';

@Component({
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [Button.name]: Button,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu
  }
})
export default class FieldForceCheckIns extends Mixins(PaginationComponentMixin) {
  @Prop()
  public currentEntity!: any;
  private users: any[] = [];

  created() {
    this.fetchEntity();
  }

  @Watch('pagination.currentPage')
  async fetchEntity() {
    this.loading = true;
    if (!this.currentEntity) return;
    this.$http.get(this.$api(`/checked-in-users?created_at=${this.currentEntity}&page=${this.pagination.currentPage}`)).then(response => {
      this.users = response.data.users;
      this.setPagination(response.data);
      this.loading = false;
    });
  }
}

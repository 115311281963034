

































































































































import { Component, Mixins } from 'vue-property-decorator';
import GIFModal from '@/components/tour/GIFModal.vue';
import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tag, Tooltip } from 'element-ui';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import TaxonomyMixin from "@/mixins/TaxonomyComponent";
import CreateTransportationInfoForm from "@/views/order/transport/CreateTransportationInfoForm.vue";
import TransportationAttachments from "@/views/order/transport/TransportationAttachments.vue";

@Component({
  components: {
    TransportationAttachments,
    CreateTransportationInfoForm,
    GIFModal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
  }
})
export default class TransportInfoList extends Mixins(PaginationComponentMixin, TaxonomyMixin) {
  private transportationInfo: any[] = [];
  private isActiveTransportationInfo: boolean = false;
  private isUpdate: boolean = false;
  private isAttachmentsActive: boolean = false;
  private showDeleteModal: boolean = false;
  private showGIF: boolean = false;
  private currentEntity: any = '';
  private demoTourEntity: string = 'transportationInfo';
  private form = {}
  private GIFs: Array<any> = [
    {
      label: 'Create a Transportation',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a Transportation',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a Transportation',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of transportation';

  async fetchTransportInfo() {
    this.loading = true;
    try {
      let {data} = await this.$http.get(
        this.$api(`/transportation-info?with=centralTransport,driver,courier,courierBranch,numberPlate,invoice&page=${this.pagination.currentPage}`)
      );
      this.setPagination(data);
      this.transportationInfo = data.transportationInfo;
    } catch (e) {
      this.$notify({title: 'Something Went Wrong!', message: 'Please Try again.', type: 'error'});
    } finally {
      this.loading = false;
    }
  }

  created() {
    this.fetchTransportInfo();
  }

  deleteEntity(): void {
    this.$http.delete(this.$api(`/transportation-info/${this.currentEntity.id}`)).then(response => {
      this.showDeleteModal = false;
      this.transportationInfo.splice(
        this.transportationInfo.findIndex(value => value.id == this.currentEntity.id),
        1
      );
      this.$notify({
        title: 'Success',
        message: 'Successfully Deleted',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'success'
      });
    });
  }

  updateTRInfo(updatedTRInfo: any) {
    this.fetchTransportInfo();
    this.isUpdate = false;
  }

  showAttachments(row: any) {
    this.currentEntity = row;
    this.isAttachmentsActive = true;
  }

  onEdit(row: any) {
    this.currentEntity = row;
    this.isActiveTransportationInfo = true;
    this.isUpdate = true;
  }
}






























import {Vue, Component, Prop} from 'vue-property-decorator';
import AsyncImage from "@/components/AsyncImage.vue";
import {Button, Carousel, CarouselItem, Image, Upload} from "element-ui";

@Component({
  components: {
    AsyncImage,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    [Image.name]: Image,
    [Upload.name]: Upload,
    [Button.name]: Button,
  }
})
export default class ImagesModal extends Vue {
  // @Prop()
  // images!: any;
  private images = ["/users/1/propic", "/users/1/propic", "/users/1/propic"]
  @Prop({type: Boolean, default: false})
  public showUploadImagesModal!: boolean;
  private showFullImage: boolean = false;
  public fileList = [];

  close() {
    this.$emit('update:showUploadImagesModal', false);
  }

  showFullImagea() {
    // this.showFullImage =
  }

  handleChange (File: any, fileList: any) {
    this.fileList = fileList;
  }


}

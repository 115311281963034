












































































































































































































































































import { Component, Mixins, Watch, Prop } from 'vue-property-decorator';
import TargetForm from '@/views/user/target/TargetForm.vue';
import TargetFormBulkAssign from '@/views/user/target/TargetFormBulkAssign.vue';
import TargetAssignForm from '@/views/user/target/TargetAssignForm.vue';
import GIFModal from '@/components/tour/GIFModal.vue';
import TargetViewForm from '@/views/user/target/TargetViewForm.vue';
import { Badge, Drawer, Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tag, Tooltip } from 'element-ui';
import Target from '@/intefaces/Target';
import TaxonomyMixin from '@/mixins/TaxonomyComponent';
import { QueryBuilder } from '@/classes/QueryBuilder';
import FilterDrawerComponent from '@/components/FilterDrawerComponent.vue';
import TargetAchievedHistory from '@/views/user/target/TargetAchievedHistory.vue';
import AsyncImage from '@/components/AsyncImage.vue';
import { Permission as PermissionEnum } from '@/enums/Permission';
import DateRange from '@/components/DateRange.vue';

const flatPicker = require('vue-flatpickr-component');

@Component({
  components: {
    AsyncImage,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Badge.name]: Badge,
    [Drawer.name]: Drawer,
    [Tag.name]: Tag,
    TargetForm,
    TargetFormBulkAssign,
    TargetAssignForm,
    TargetViewForm,
    GIFModal,
    FilterDrawerComponent,
    flatPicker,
    TargetAchievedHistory,
    DateRange
  }
})
export default class TargetList extends Mixins(TaxonomyMixin) {
  public targetAssign: number = 0;
  private isActive = false;
  private isBulkActive = false;
  private isTargetName = false;
  private isActiveView = false;
  private targetType = '';
  private targetProducts: any = [];
  private productList: any = [];
  private targets: Target[] = [];
  private filter: any = ['created', 'assigned'];
  private filters: any = {
    type: '',
    targetType: '',
    assigneeName: '',
    role: '',
    expiresAtDateRange: '',
    startFromDateRange: '',
    unit: '',
    status: ''
  };
  private showDeleteModal = false;
  private currentTarget: any = [];
  private isUpdate = false;
  private userList: any = [];
  private loading: boolean = false;
  private isShowAchievedHistory = false;
  private isFilterModelActive: boolean = false;
  private showGIF: boolean = false;
  private queryBuilder: QueryBuilder = new QueryBuilder();
  private routeColumnTitle: string = 'Route';
  private form: any = {
    type: '',
    expiresDate: '',
    amount: '',
    unit: '',
    assignTo: '',
    scheme: '',
    start_from: '',
    status: true,
    territory_id: ''
  };
  private pagination = {
    currentPage: 1,
    total: 0,
    perPage: 0
  };

  private demoTourEntity: string = 'target';
  private GIFs: Array<any> = [
    {
      label: 'Create a target',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a target',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a target',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of target';

  get permissionEnum() {
    return PermissionEnum;
  }
  assignIdFromTargetAssign(data: any) {
    this.targetAssign = data;
  }
  assignIdFromTargetAssignReset(data: any) {
    this.targetAssign = data;
  }

  TargetNameUpdate(data: any) {
    this.isTargetName = data;
    if (this.targetAssign > 0) {
      this.isActive = true;
    }
  }
  TargetNameUpdateForBulkAssign(data: any) {
    this.isTargetName = data;
    if (this.targetAssign > 0) {
      this.isBulkActive = true;
    }
  }

  @Watch('filters.type', { deep: true })
  filterTarget(value: string) {
    this.queryBuilder.addListener('filter', value);
  }

  @Watch('filters.targetType', { deep: true })
  onChangeTargetTypeFilter(value: string) {
    this.queryBuilder.addListener('target_type', value);
  }

  @Watch('filters.assigneeName', { deep: true })
  onChangeNameFilter(value: string) {
    this.queryBuilder.addListener('assignee_name', value);
  }

  @Watch('filters.role', { deep: true })
  onChangeRoleFilter(value: string) {
    this.queryBuilder.addListener('assignee_role', value);
  }

  @Watch('filters.startFromDateRange', { deep: true })
  onChangeStartDateFilter(value: string) {
    this.queryBuilder.addListener('start_from_date_range', value);
  }

  @Watch('filters.expiresAtDateRange', { deep: true })
  onChangeExpiresAtFilter(value: string) {
    this.queryBuilder.addListener('expires_at_date_range', value);
  }

  @Watch('filters.unit', { deep: true })
  onChangeUnitFilter(value: string) {
    this.queryBuilder.addListener('unit_name', value);
  }

  @Watch('filters.status', { deep: true })
  onChangeStatusFilter(value: string) {
    this.queryBuilder.addListener('active', value);
  }

  viewProduct(row: any) {
    this.$http.get(this.$api(`/targets/${row.id}/target-products`)).then(response => {
      this.targetProducts = response.data.targetProducts;
    });
  }

  acheivedResult(row: any) {
    var achieved = false;
    if (row) {
      row.forEach((product: any) => {
        if (product.achieved >= product.amount) {
          achieved = true;
        } else {
          achieved = false;
        }
      });
    }
    if (achieved == false) {
      return false;
    }
  }

  onEdit(row: any) {
    this.currentTarget = row;
    this.isActive = true;
    this.form = {
      type: this.currentTarget.type,
      expiresDate: this.currentTarget.expires_at,
      amount: this.currentTarget.unit_value,
      unit: this.currentTarget.unit_name,
      assignTo: this.currentTarget.assigned_to,
      scheme: this.currentTarget.scheme,
      start_from: this.currentTarget.start_from,
      status: Boolean(this.currentTarget.active),
      territory_id: this.currentTarget.territory_id
    };
    this.isUpdate = true;
  }

  deleteTarget() {
    this.$http.delete(this.$api(`/targets/${this.currentTarget.id}`)).then(response => {
      this.showDeleteModal = false;
      this.targets.splice(
        this.targets.findIndex((target: any) => target.id == this.currentTarget.id),
        1
      );
    });
  }

  targetCreated(target: any) {
    this.isActive = false;
    this.targets.unshift(target);
  }

  bulkTargetCreated(target: any) {
    location.reload();
  }

  targetUpdate(targetUpdate: any) {
    this.isUpdate = false;
    this.targets.splice(
      this.targets.findIndex((target: any) => target.id == targetUpdate.id),
      1,
      targetUpdate
    );
    this.isActive = false;
  }

  @Watch('queryBuilder.watch')
  @Watch('pagination.currentPage')
  async fetchTarget() {
    this.loading = true;
    let isFirstPage = this.pagination.currentPage < 2;
    let url = isFirstPage
      ? `/targets?${this.queryBuilder.getFilters()}`
      : `/targets?${this.queryBuilder.getFilters()}&page=${this.pagination.currentPage}`;

    await this.$http.get(this.$api(url)).then(response => {
      this.targets = response.data.targets;
      this.pagination.total = response.data.total;
      this.pagination.perPage = response.data.per_page;
      this.loading = false;
    });
  }

  bulkLoadingPage() {
    this.fetchTarget();
  }

  created() {
    this.fetchTarget();
    this.getTerritoryType();
  }

  closeBtn() {
    this.isFilterModelActive = false;
  }

  handleClose(done: any) {
    done();
  }

  async getTerritoryType() {
    let { data } = await this.$http.get(this.$api(`/territory-types?   has_outlet=1 `));
    this.routeColumnTitle = data?.territoryTypes[0]?.name ?? 'Route';
  }

  resetBtn() {
    this.filters.type = '';
    this.filters.targetType = '';
    this.filters.unit = '';
    this.filters.status = '';
    this.filters.role = '';
    this.filters.assigneeName = '';
    this.filters.expiresAtDateRange = '';
    this.filters.startFromDateRange = '';
    this.isFilterModelActive = false;
    this.fetchTarget();
  }
}


















































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ProductLazydropdown from "@/components/lazyDropdown/ProductLazydropdown.vue";
import ProductBrandLazydropdown from '@/components/lazyDropdown/ProductBrandLazydropdown.vue';
import ProductCategoryAutocomplete from '@/components/lazyDropdown/ProductCategoryLazydropdown.vue';

@Component({
  components: {
    ProductLazydropdown,
    ProductBrandLazydropdown,
    ProductCategoryAutocomplete
  }
})

export default class BaseProductComponent extends Vue {
  @Prop() productForm!: any;
}

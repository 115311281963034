




































































import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator';
import { QueryBuilder } from '@/classes/QueryBuilder';
import moment from 'moment';
import DepartmentLazydropdown from '@/components/lazyDropdown/DepartmentLazydropdown.vue';
import printJS from 'print-js';
import DateRange from '@/components/DateRange.vue';
import { Permission as PermissionEnum } from '@/enums/Permission';
import { Tag } from 'element-ui';
import TerritoryTypeAutocomplete from '@/components/resources-autocomplete/TerritoryTypeAutocomplete.vue';
import UserLazydropdown from '@/components/lazyDropdown/UserLazydropdown.vue';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import 'flatpickr/dist/flatpickr.css';
import swal from 'sweetalert2';

const flatPicker = require('vue-flatpickr-component');
@Component({
  components: {
    UserLazydropdown,
    TerritoryTypeAutocomplete,
    DepartmentLazydropdown,
    flatPicker,
    DateRange,
    [Tag.name]: Tag
  }
})
export default class PerformanceBoardReport extends Mixins(PaginationComponentMixin) {
  public downloading: boolean = false;
  public loading: boolean = false;
  public printing: boolean = false;
  public created_by: number | null = null;
  public queryBuilder: QueryBuilder = new QueryBuilder();
  public theManagers: any = [];
  public targetList: any = [];
  public showDownloadModal: boolean = false;
  public recipient: string = '';
  public targetNameId: string = '';
  public startFrom: string = '';
  public rangeSelectConfig = {
    allowInput: true,
    disable: [
      function(range: Date) {
        return moment().diff(range) < 0;
      }
    ]
  };

  @Watch('queryBuilder.watch')
  @Watch('pagination.currentPage')
  async fetchTarget() {
    this.loading = true;
    this.$http.get(this.$api(`/target-name-list?page=${this.pagination.currentPage}`)).then(response => {
      this.targetList = response.data.TargetNameList;
      this.setPagination(response.data);
      this.loading = false;
    });
  }

  created() {
    this.fetchTarget();
  }

  public filters: any = {
    range: '',
    territory_type: null,
    user_id: null
  };
  onValue(row: any) {
    this.targetNameId = row.id;
    this.startFrom = row.start_from;
    this.showDownloadModal = true;
  }

  async downloadPerformanceBoard(status: string) {
    this.loading = true;
    try {
      let { data, headers } = await this.$http.get(
        this.$api(
          '/performance-board-report?' + 'target_name_id=' + this.targetNameId + '&' + 'range=' + this.startFrom + '&' + 'recipient=' + this.recipient
        )
      );
      await swal.fire('Thanks for entering your mail', 'You will receive the report within a few minutes.', 'success');
    } catch (e) {
      this.$notify({ title: 'Something wants wrong!', type: 'warning', message: 'Unable to download reports.' });
    } finally {
      this.loading = false;
      this.showDownloadModal = false;
      this.targetNameId = '';
      this.recipient = '';
      this.startFrom = '';
    }
  }
  get permissionEnum() {
    return PermissionEnum;
  }
}











import {Component, Mixins, Prop} from "vue-property-decorator";
import LazyDropdown from "../LazyDropdown.vue";
import DropdownField from "../../mixins/DropdownField";
import UnitForm from "@/components/products/UnitForm.vue";

@Component({
  components: {
    LazyDropdown,
    UnitForm
  }
})
export default class UnitCategoryLazydropdown extends Mixins(DropdownField) {
  @Prop() addButton!: boolean;
}

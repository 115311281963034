












import { Component, Mixins, Prop } from "vue-property-decorator";
import LazyDropdown from "../LazyDropdown.vue";
import DropdownField from "@/mixins/DropdownField";
import CreateDriverForm from "@/views/order/transport/CreateDriverForm.vue";

@Component({
  components: {
    LazyDropdown,
    CreateDriverForm
  }
})
export default class DriverLazydropdown extends Mixins(DropdownField) {
  @Prop({default: 'drivers'}) endPoint!: string;
  @Prop({default: true}) addButton!: boolean;
  @Prop({default: false}) collapseTags!: boolean;
  @Prop({default: 'q'}) searchableKey!: string;
  @Prop({default: 'name'}) itemText!: string;
  @Prop({default: true})
  public fetchOnChangeDisable!: boolean;

}

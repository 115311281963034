






















































import { Component, Vue, Watch } from 'vue-property-decorator';
import TerritoryLazydropdown from "@/components/lazyDropdown/TerritoryLazydropdown.vue";
import { QueryBuilder } from "@/classes/QueryBuilder";
import { Permission as PermissionEnum } from '@/enums/Permission'
import swal from "sweetalert2";

const flatPicker = require('vue-flatpickr-component');
@Component({
  components: {
    TerritoryLazydropdown,
    flatPicker
  }
})
export default class TrackingReport extends Vue {
  public downloading: boolean = false;
  private showDownloadModal: boolean = false;
  public territory_id: number | null = null;
  public recipient: string | null = null;
  public queryBuilder: QueryBuilder = new QueryBuilder();
  public date: string = '';

  todaysDate() {
    var currentDate = new Date()
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, '/');
    return currentDate;
  }

  get permissionEnum() {
    return PermissionEnum;
  }

  async onClickDownload() {
    this.downloading = true;
    try {
      await this.$http.get(this.$api('/tracking-report' + this.queryBuilder.getFilters(true)));
      swal.fire(
        "Report Sent",
        "You will receive the report within a few minutes.",
        "success"
      );
    } catch (e) {
      this.$notify({title: 'Something wants wrong!', type: 'warning', message: 'Unable to download reports.',})
    } finally {
      this.downloading = false;
      this.showDownloadModal = false;
    }
  }

  @Watch('territory_id')
  onChangeTerritoryId(value: number) {
    this.queryBuilder.addListener('territory_id', value);
  }

  @Watch('date')
  onChangeRange(value: number) {
    this.queryBuilder.addListener('date', value);
  }

  @Watch('recipient')
  onRecipient(value: string) {
    this.queryBuilder.addListener('recipient', value);
  }

  close() {
    this.showDownloadModal = false;
  }
}























































































































import {Component, Mixins, Vue, Watch} from 'vue-property-decorator';
import Productable from '@/intefaces/Productable';
import CategoryForm from '@/components/products/CategoryForm.vue';
import GIFModal from '@/components/tour/GIFModal.vue';
import {Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip, Tag} from 'element-ui';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import { Permission as PermissionEnum } from '@/enums/Permission'

@Component({
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
    CategoryForm,
    GIFModal
  }
})
export default class ProductCategoryList extends Mixins(PaginationComponentMixin) {
  private productCategories: Productable[] = [];
  private currentProductCategory!: Productable;
  private isActiveCategory = false;
  private isUpdate = false;
  private showDeleteCategoryModal = false;
  private showGIF: boolean = false;
  private form: { name: string; parent_id: number | string, active: boolean } = {
    name: '',
    parent_id: '',
    active: true,
  };
  private demoTourEntity: string = 'productCategory';
  private GIFs: Array<any> = [
    {
      label: 'Create a category',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a category',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a category',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of category';

  deleteProductCategory() {
    this.$http.delete(this.$api(`/product-categories/${this.currentProductCategory.id}`)).then(response => {
      this.showDeleteCategoryModal = false;
      this.productCategories.splice(
        this.productCategories.findIndex(productCategory => productCategory.id == this.currentProductCategory.id),
        1
      );
      this.$notify({
        title: 'Success',
        message: 'Successfully Deleted',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'success'
      });
    });
  }

  get permissionEnum () {
    return PermissionEnum;
  }

  onEdit(row: Productable) {
    this.isUpdate = true;
    this.currentProductCategory = row;
    let parent = this.currentProductCategory.parent_id == null;
    this.form = {
      name: this.currentProductCategory.name,
      parent_id: parent ? '' : this.currentProductCategory.parent_id,
      active: Boolean(this.currentProductCategory.active),
    };
    this.isActiveCategory = true;
  }

  categoryCreated(productCategory: Productable) {
    this.isActiveCategory = false;
    this.productCategories.unshift(productCategory);
  }

  categoryUpdated(productCategory: Productable) {
    this.isActiveCategory = false;
    this.isUpdate = false;
    this.productCategories.splice(
      this.productCategories.findIndex(category => category.id == productCategory.id),
      1,
      productCategory
    );
  }

  @Watch('pagination.currentPage')
  fetchProductCategories() {
    this.loading = true;
    this.$http.get(this.$api(`/product-categories?allStatus&page=${this.pagination.currentPage}`)).then(response => {
      this.productCategories = response.data.productCategories;
      this.setPagination(response.data);
      this.loading = false;
    });
  }

  created() {
    this.fetchProductCategories();
  }
}


























































import {Vue, Component, Mixins, Watch} from 'vue-property-decorator';
import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tooltip, Tag } from 'element-ui';
import PaginationComponentMixin from "@/mixins/PaginationComponent";
import ViewListOfSubscription from "@/views/admin/subscription/subscriber/ViewListOfSubscription.vue";
@Component({
    components: {
        [Tooltip.name]: Tooltip,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        [Tag.name]: Tag,
        ViewListOfSubscription
    }
})
export default class SubscriberList extends Mixins(PaginationComponentMixin){
    private orgs: any = [];
    private currentOrg: any = [];
    private isActive: boolean = false;

    @Watch('pagination.currentPage')
    fetchSubscriber(){
        this.loading = true;
        this.$http.get(this.$landLordApi(`/orgs?page=${this.pagination.currentPage}`)).then(response => {
            this.orgs = response.data.orgs;
            this.setPagination(response.data);
            this.loading = false;
        })
    }

    created(){
        this.fetchSubscriber();
    }
}

























































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import SingleImageShow from '@/components/ui/SingleImageShow.vue';
import filters from '@/data/InspectionFilter';
import {Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip, Drawer} from 'element-ui';
import GIFModal from '@/components/tour/GIFModal.vue';

@Component({
  components: {
    GIFModal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Drawer.name]: Drawer,
    SingleImageShow
  }
})
export default class Audit extends Vue {
  private assetAudits: any = [];
  private loading: boolean = false;
  private isFilterModelActive: boolean = false;
  private pagination = {
    currentPage: 1,
    total: 0,
    perPage: 0
  };
  private types = [
    {name: 'asset', label: 'Assets'},
    {name: 'campaign', label: 'Merchandise'},
    {name: 'product', label: 'Product'}
  ];
  private filter = '';
  private selectedFilter = 'All';

  get filters() {
    let data = [...filters];
    return data;
  }

  private showGIF: boolean = false;
  private demoTourEntity: string = 'inspectionAudit';
  private GIFs: Array<any> = [
    {
      label: 'Create a audit',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a audit',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a audit',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of audit';

  @Watch('selectedFilter')
  filterAudit(newVal: any) {
    this.fetchAssetAudit();
  }

  fetchAssetAudit() {
    this.loading = true;
    let isFirstPage = this.pagination.currentPage < 2;
    let url = isFirstPage
      ? `/audits?auditable_type=${this.selectedFilter != 'All' ? this.selectedFilter : ''}`
      : `/audits?auditable_type=${this.selectedFilter != 'All' ? this.selectedFilter : ''}?page=${this.pagination.currentPage}`;

    this.$http.get(this.$api(url)).then(response => {
      this.assetAudits = response.data.audits;
      this.pagination.total = response.data.total;
      this.pagination.perPage = response.data.per_page;
      this.loading = false;
    });
  }

  created() {
    this.fetchAssetAudit();
  }

  closeBtn() {
    this.isFilterModelActive = false;
  }

  handleClose(done: any) {
    done();
  }

  resetBtn() {
    this.selectedFilter = 'All';
  }
}

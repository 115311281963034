

























































































































import {Vue, Component, Watch} from 'vue-property-decorator';
import TerritoryType from '@/intefaces/TerritoryType';
import CreateTerritoryTypeForm from '@/views/territory/CreateTerritoryTypeFrom.vue';
import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Switch, Tooltip, Tag} from 'element-ui';
import ElTableDraggable from '@/components/tables/ElementUiElTableDraggable.vue';
import GIFModal from '@/components/tour/GIFModal.vue';
import {clone} from 'lodash';
import { Permission as PermissionEnum } from '@/enums/Permission'

@Component({
  components: {
    CreateTerritoryTypeForm,
    GIFModal,
    [Switch.name]: Switch,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
    ElTableDraggable
  }
})
export default class TerritoryTypeList extends Vue {
  private territoryTypes: Array<TerritoryType> = [];
  private showDeleteModal = false;
  private isActive = false;
  private isUpdate = false;
  private draggable: boolean = false; // disable
  private currentEntity!: TerritoryType;
  private showGIF: boolean = false;
  private demoTourEntity: string = 'territoryTypes';
  private hasOutlet: boolean = false;
  private GIFs: Array<any> = [
    {
      label: 'Create a territory-type',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a territory-type',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a territory-type',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of territory-type';
  protected form: TerritoryType = {
    name: '',
    description: '',
    has_outlet: false,
    level: 1
  };
  private paginate = {
    currentPage: 1,
    total: 0,
    perPage: 0
  };

  get permissionEnum () {
    return PermissionEnum;
  }

  created() {
    this.fetchEntity();
  }

  checkTerritoryTypesHasOutlet() {
    this.territoryTypes.forEach((territoryType: any) => {
      if(territoryType.has_outlet == 1) {
        this.hasOutlet = true;
      }
    })
  }

  territoryTypeCreated(territoryType: TerritoryType) {
    this.territoryTypes.forEach(value => {
      if (value.level >= territoryType.level) {
        value.level++;
      }
    });
    this.territoryTypes.unshift(territoryType);
    this.checkTerritoryTypesHasOutlet();
  }

  territoryTypeUpdated(territoryType: TerritoryType): void {
    let indexOfSwapTerritoryType = this.territoryTypes.findIndex(type => type.level == territoryType.level);
    let indexOfTargetTerritoryType = this.territoryTypes.findIndex(type => type.id == territoryType.id);
    this.swapOfLevel(clone(this.territoryTypes[indexOfTargetTerritoryType]), this.territoryTypes[indexOfSwapTerritoryType]);
    this.territoryTypes.splice(indexOfTargetTerritoryType,  1, territoryType);
    this.checkTerritoryTypesHasOutlet();
  }

  onEdit(row: TerritoryType) {
    this.isUpdate = true;
    this.currentEntity = row;
    this.form = {
      name: this.currentEntity.name,
      description: this.currentEntity.description,
      has_outlet: this.currentEntity.has_outlet ? true : false,
      level: this.currentEntity.level
    };
    this.isActive = true;
  }

  fetchEntity(): void {
    this.$http.get(this.$api('/territory-types')).then(response => {
      this.territoryTypes = response.data.territoryTypes;
      this.checkTerritoryTypesHasOutlet();
    });
  }

  deleteEntity(): void {
    this.$http.delete(this.$api(`/territory-types/${this.currentEntity.id}`)).then(response => {
      this.showDeleteModal = false;
      this.territoryTypes.splice(
        this.territoryTypes.findIndex(value => value.id == this.currentEntity.id),
        1
      );
      if(this.currentEntity.has_outlet){
        this.hasOutlet = false;
      }
      this.$notify({
        title: 'Success',
        message: 'Successfully Deleted',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'success'
      });
    });
  }

  onDrop({targetObject, list, newIndex, oldIndex}: { targetObject: any; list: any; newIndex: any; oldIndex: any }) {
    let targetTerritoryType = clone(targetObject);
    let newlySortedTargetTerritoryTypeList = list;
    let swapTerritoryType: any = null;
    if (Math.sign(newIndex - oldIndex) < 0) {
      // If drag down to up ⬆
      swapTerritoryType = newlySortedTargetTerritoryTypeList[newIndex + 1];
    } else if (Math.sign(newIndex - oldIndex) > 0) {
      // If drag up to down ⬇
      swapTerritoryType = newlySortedTargetTerritoryTypeList[newIndex - 1];
    }
    this.swapOfLevel(targetTerritoryType, swapTerritoryType);

    //TODO : implement the error response handler
    targetTerritoryType.level = swapTerritoryType.level;
    this.$http.patch(this.$api(`/territory-types/${targetTerritoryType.id}`), targetTerritoryType).then(res => {
    });
  }

  swapOfLevel(targetTerritoryType: TerritoryType, swapTerritoryType: TerritoryType) {
    // Swap the Level of two territory at front-end.
    this.territoryTypes.forEach(value1 => {
      if (value1.id === targetTerritoryType.id) {
        value1.level = swapTerritoryType.level;
      }
      if (value1.id === swapTerritoryType.id) {
        value1.level = targetTerritoryType.level;
      }
    });
  }

  get territorySortByLevel() {
    return JSON.parse(JSON.stringify(this.territoryTypes)).sort((a: any, b: any) => a.level - b.level);
  }
}

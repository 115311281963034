






















import {Vue, Component, Prop, Mixins, Watch} from 'vue-property-decorator';
import InvoiceOrderStatusAutocomplete from "@/components/resources-autocomplete/InvoiceOrderStatusAutocomplete.vue";
import {
  Tooltip,
  Timeline,
  TimelineItem,
  Card
} from 'element-ui';
import Order from "@/intefaces/Order";
@Component({
  components: {
    [Tooltip.name]: Tooltip,
    [Timeline.name]: Timeline,
    [TimelineItem.name]: TimelineItem,
    [Card.name]: Card,
    InvoiceOrderStatusAutocomplete,
  }
})

export default class InvoiceStatusDetails extends Vue {
  @Prop({type: Boolean, default: false})
    isActive!: Boolean;

  @Prop() currentInvoice!: any;

  @Prop({
    type: Object,
    default: function () {
      return {remarks: '', status: ''};
    }
  })
  form!: {
    remarks: string;
    status: string;
  };

  save() {
    this.$http.post(this.$api(`/invoices/${this.currentInvoice.id}/statuses`), this.form).then(response => {
      this.$emit('updatedStatus', response.data.invoice);
      this.$emit('update:isActive', false);
    })
  }

  close(): void {
    this.$emit('update:isActive', false);
  }
}

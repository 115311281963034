











































































































const flatPicker = require('vue-flatpickr-component');
import {Component, Vue, Watch} from "vue-property-decorator";
import {Popover, Switch, Select, Option} from "element-ui";

@Component({
  components: {
    [Switch.name]: Switch,
    [Popover.name]: Popover,
    [Select.name]: Select,
    [Option.name]: Option,
    flatPicker
  }
})
export default class Attendance extends Vue {
  private form = {
    in_time_start: '',
    in_time_end: '',
    out_time_start: '',
    out_time_end: ''
  };
  private loading = false;

  updateAttendanceLimits() {

    this.loading = true;
    this.$http.patch(this.$api('/settings/1'), {attendance: this.form}).then(response => {

      if (response.status == 200) {
        this.updateAttendanceNotification(response.status);
      } else {
        this.updateAttendanceNotification(response.status);
      }
    }).catch(error => {
      (this.$refs.formValidator as Vue & {
        setErrors: (errors: []) => any;
      }).setErrors(error.response.data.errors);
      this.$notify({
        message: "Please contact your service provider.",
        title: "Something went wrong!",
        type: 'warning'})
    }).finally(
      () => (this.loading = false)
    );
  }


  fetchSettings() {
    this.$http.get(this.$api(`/settings`)).then(response => {
      if (response.data.settings.attendance != null) {
        this.form = JSON.parse(response.data.settings.attendance);
      }
    });
  }

  updateAttendanceNotification(status: number) {
    this.$notify({
      title: status == 200 ? 'Success' : 'Error',
      message: status == 200 ? "Attendance settings updated" : "Attendance settings could not be updated",
      duration: 5000,
      iconClass: 'ni ni-bell-55',
      // @ts-ignore
      verticalAlign: 'bottom',
      horizontalAlign: 'right',
      type: 'success'
    });
  }

  created() {
    this.fetchSettings();
  }


}












import {Component, Mixins, Prop} from "vue-property-decorator";
import LazyDropdown from "../LazyDropdown.vue";
import DropdownField from "../../mixins/DropdownField";

@Component({
  components: {
    LazyDropdown,
    CreateNewTerritoryForm: () => import('@/views/territory/CreateTerritoryForm.vue'),
  }
})
export default class TerritoryLazydropdown extends Mixins(DropdownField) {
  @Prop({default: 'territories'}) endPoint!: string;
  @Prop({default: 'name'}) searchableKey!: string;
  @Prop({default: true}) addButton!: boolean;
  @Prop({default: false}) hasAllOption!: boolean
  @Prop({default: false}) multiple!: boolean;
  @Prop({default: false}) disabled!: boolean;
}







































































import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
import {Dropdown, DropdownItem, DropdownMenu, Upload} from 'element-ui';
import DepartmentType from "../../../intefaces/DepartmentType";
import ChannelLazydropdown from "@/components/lazyDropdown/ChannelLazydropdown.vue";
import DepartmentLazydropdown from "@/components/lazyDropdown/DepartmentLazydropdown.vue";
import TaxonomyMixin from "@/mixins/TaxonomyComponent";

@Component({
  [Dropdown.name]: Dropdown,
  [DropdownItem.name]: DropdownItem,
  [DropdownMenu.name]: DropdownMenu,
  components: {
    ChannelLazydropdown,
    DepartmentLazydropdown
  }
})
export default class CreateProductPrice extends Mixins(TaxonomyMixin) {
  @Prop() isActive!: boolean;
  @Prop() isUpdate!: boolean;
  @Prop() departmentTypes!: DepartmentType;
  @Prop() currentProductId!: any;
  @Prop() productPrice!: any;
  @Prop() isUpdateSingleProductPrice!: any;
  @Prop() departments!: any;
  @Prop() singlePrice!: any;
  @Prop() currentProductPrice!: any;
  private priceType = ['dept', 'depttype'];

  createProductPrice() {
    this.$http
      .post(this.$api('/product-prices'), {
        product_id: this.currentProductId,
        priceable_id: this.singlePrice.priceable_id,
        priceable_type: this.singlePrice.priceable_type,
        price: this.singlePrice.price
      })
      .then(response => {
        this.$emit('update:singlePrice', {priceable_id: '', price: '', priceable_type: ''})
        this.$emit('addPrice', response.data.productPrice);
        this.notification('Success', 'Successfully Added', 'success');
        this.$emit('update:isActive', false);
      })
      .catch(error => {
        this.notification('Warning', error.response.data.errors, 'warning');
        (this.$refs.formValidator as Vue & {
          setErrors: (errors: []) => any;
        }).setErrors(error.response.data.errors);
      });
  }

  updateProductPrice() {
    this.$http.patch(this.$api(`/product-prices/${this.currentProductPrice.id}`), {
      product_id: this.currentProductId,
      priceable_id: this.singlePrice.priceable_id,
      priceable_type: this.singlePrice.priceable_type,
      price: this.singlePrice.price
    })
      .then(response => {
        this.$emit('update:singlePrice', {priceable_id: '', price: '', priceable_type: ''})
        this.$emit('updatePrice', response.data.productPrice);
        this.$emit('update:isUpdateSingleProductPrice', false);
        this.$emit('update:isActive', false);
        this.notification('Success', 'Successfully Updated', 'success');
      })
      .catch(error => {
        (this.$refs.formValidator as Vue & {
          setErrors: (errors: []) => any;
        }).setErrors(error.response.data.errors);
      });
  }

  save() {
    this.isUpdateSingleProductPrice ? this.updateProductPrice() : this.createProductPrice();
  }

  notification(title: string, message: string, type: "success" | "warning" | "info" | "error" | undefined) {
    this.$notify({
      title: title,
      message: message,
      duration: 3000,
      iconClass: 'ni ni-bell-55',
      type: type
    });
  }

  close() {
    this.$emit('update:isActive', false);
    this.$emit('update:isUpdateSingleProductPrice', false);
    this.$emit('update:singlePrice', {priceable_id: '', price: '', priceable_type: ''})
  }

}



















import { Component, Prop, Vue } from 'vue-property-decorator';
import {Tag} from "element-ui";

@Component({
  components: {
    [Tag.name]: Tag,
  }
})

export default class CustomTooltip extends Vue{
  @Prop({default: null}) entities!: string;
  @Prop() title!: string;
  @Prop() buttonText!: string;

  onClick() {
    this.$emit('onClick');
  }
}










import {Component, Mixins} from "vue-property-decorator";
import LazyDropdown from "../LazyDropdown.vue";
import DropdownField from "../../mixins/DropdownField";
import CreateBatch from "@/views/product/CreateBatch.vue";
    @Component({
        components: {
            LazyDropdown,
            CreateBatch
        }
    })
    export default class DepartmentTypeLazydropdown extends Mixins(DropdownField) {
    }

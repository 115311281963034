




































































































import {Component, Mixins, Vue, Watch} from 'vue-property-decorator';
import CampaignForm from '@/views/inspection/campaign/CampaignForm.vue';
import GIFModal from '@/components/tour/GIFModal.vue';
import {Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip} from 'element-ui';
import Campaign from '@/intefaces/Campaign';
import Asset from '@/intefaces/Asset';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import { Permission as PermissionEnum } from '@/enums/Permission'

@Component({
  components: {
    GIFModal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    CampaignForm
  }
})
export default class CampaignList extends Mixins(PaginationComponentMixin) {
  private isActive = false;
  private isUpdate = false;
  private currentCampaign: any = [];
  private campaigns: Campaign[] = [];
  private showDeleteModal = false;
  private disableBtn = false;
  private form = {
    name: '',
    qty: 0
  };
  private showGIF: boolean = false;
  private demoTourEntity: string = 'inspectionMerchandise';
  private GIFs: Array<any> = [
    {
      label: 'Create a merchandise',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a merchandise',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a merchandise',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of merchandise';

  get permissionEnum () {
    return PermissionEnum;
  }

  createCampaign(campaign: Campaign) {
    this.isActive = false;
    this.campaigns.unshift(campaign);
  }

  updateCampaign(campaign: Campaign) {
    this.isUpdate = false;
    this.campaigns.splice(
      this.campaigns.findIndex(campaignItem => campaignItem.id == campaign.id),
      1,
      campaign
    );
    this.isActive = false;
  }

  onEdit(row: Campaign) {
    this.isUpdate = true;
    this.currentCampaign = row;
    this.form = {
      name: this.currentCampaign.name,
      qty: this.currentCampaign.qty
    };
    this.isActive = true;
  }

  deleteCampaign() {
    this.disableBtn = true;
    this.$http
      .delete(this.$api(`/campaign-items/${this.currentCampaign.id}`))
      .then(response => {
        this.disableBtn = false;
        this.showDeleteModal = false;
        this.campaigns.splice(
          this.campaigns.findIndex(campaign => campaign.id == this.currentCampaign.id),
          1
        );
        this.$notify({
          title: 'Success',
          message: 'Successfully Deleted',
          duration: 5000,
          iconClass: 'ni ni-bell-55',
          type: 'success'
        });
      })
      .catch(error => {
        this.disableBtn = false;
        (this.$refs.formValidator as Vue & {
          setErrors: (errors: []) => any;
        }).setErrors(error.response.data.errors);
      });
  }

  @Watch('pagination.currentPage')
  fetchCampaign() {
    this.loading = true;
    this.$http.get(this.$api(`/campaign-items?page=${this.pagination.currentPage}`)).then(response => {
      this.campaigns = response.data.campaignItems;
      this.setPagination(response.data);
      this.loading = false;
    });
  }

  created() {
    this.fetchCampaign();
  }
}

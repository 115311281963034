











































































































import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
// import CreateBadgeForm from '@/views/organization/CreateBadgeForm.vue';
import CreateCouponForm from '@/views/admin/subscription/coupon/CreateCouponForm.vue';
import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tooltip, Tag } from 'element-ui';
import Coupon from '@/intefaces/Coupon';
import PaginationComponentMixin from '@/mixins/PaginationComponent';

@Component({
  components: {
    // CreateBadgeForm,
    CreateCouponForm,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
  },
})
export default class CouponList extends Mixins(PaginationComponentMixin) {
  private coupons: Coupon[] = [];
  private showDeleteModal = false;
  private isActive = false;
  private isUpdate = false;
  private currentEntity!: Coupon;
  private form: Coupon = {
    code: '',
    discount: 0,
    expires_at: '',
  };

  created() {
    this.fetchEntity();
  }

  entityCreated(entity: Coupon) {
    this.coupons.unshift(entity);
  }

  entityUpdated(entity: Coupon): void {
    this.coupons.splice(
      this.coupons.findIndex((type) => type.id == entity.id),
      1,
      entity
    );
  }

  onEdit(row: Coupon) {
    this.isUpdate = true;
    this.currentEntity = row;
    this.form = {
      code: this.currentEntity.code,
      discount: this.currentEntity.discount,
      expires_at: this.currentEntity.expires_at,
    };
    this.isActive = true;
  }

  @Watch('pagination.currentPage')
  fetchEntity(): void {
    this.loading = true;
    this.$http.get(this.$landLordApi(`/coupons?page=${this.pagination.currentPage}`)).then((response) => {
      this.coupons = response.data.coupons;
      this.setPagination(response.data);
      this.loading = false;
    });
  }

  deleteEntity(): void {
    this.$http.delete(this.$landLordApi(`/coupons/${this.currentEntity.id}`)).then((response) => {
      this.showDeleteModal = false;
      this.coupons.splice(
        this.coupons.findIndex((value) => value.id == this.currentEntity.id),
        1
      );
      this.$notify({
        title: 'Success',
        message: 'Successfully Deleted',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'success',
      });
    });
  }
}




















































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import TerritoryType from "../../intefaces/TerritoryType";
import CreateRole from "@/views/user/role/CreateRole.vue";
import {clone} from "lodash";
import Role from "@/intefaces/Role";

@Component({
  components: {
    CreateRole
  }
})
export default class SetupRole extends Vue {
  @Prop() userRolesPrimary!: Role[];
  @Prop() userRoles!: Role[];
  private showDeleteModal: boolean = false;
  private currentEntity: any = [];
  private roleType: any = '';
  private isUpdate: boolean = false;
  protected form: any = {
    name: '',
    label: '',
    permissions: []
  };
  private isActive: boolean = false;

  @Watch('userRolesPrimary')
  onChangeRolePrimary() {
    this.$emit('userRolesPrimary', this.userRolesPrimary);
  }

  @Watch('userRoles')
  onchageRole() {
    this.$emit('userRoles', this.userRoles);
  }


  onEdit(row: any) {
    this.isUpdate = true;
    this.currentEntity = row;
    this.form = {
      name: this.currentEntity.name,
      label: this.currentEntity.label,
      permissions: this.currentEntity.permissions,
    };
    this.isActive = true;
  }

  removeEntity() {
    this.showDeleteModal = false;
    if (this.roleType == 'primary') {
      this.userRoles.splice(
        this.userRoles.findIndex((value, index) => value.name == this.currentEntity.name),
        1
      );
    } else {
      this.userRolesPrimary.splice(
        this.userRolesPrimary.findIndex((value, index) => value.name == this.currentEntity.name),
        1
      );
    }
  }

  // Lifecycle hooks +++
  mounted() {
    this.$emit('userRolesPrimary', this.userRolesPrimary);
    this.$emit('userRoles', this.userRoles);
  }

  // Lifecycle hooks ---

  save() {
    if (this.isUpdate) {
      if (this.roleType == 'primary') {
        let itemIndex = this.userRoles.findIndex((value, index) => this.currentEntity.name == value.name);
        this.userRoles.splice(itemIndex, 1, clone(this.form));
      } else {
        let itemIndex = this.userRolesPrimary.findIndex((value, index) => this.currentEntity.name == value.name);
        this.userRolesPrimary.splice(itemIndex, 1, clone(this.form));
      }
    } else {
      if (this.roleType == 'primary') {
        this.userRoles.push(clone(this.form));
      } else {
        this.userRolesPrimary.push(clone(this.form));
      }
    }
    this.close();
  }

  close() {
    this.isActive = false;
    this.isUpdate = false;
    this.formReset();
  }

  formReset(): any {
    this.form.name = '';
    this.form.label = '';
    return this.form;
  }
}

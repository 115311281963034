














































































































import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import DepartmentType from '@/intefaces/DepartmentType';
//@ts-ignore
import CreateChannelForm from '@/views/department/CreateChannelForm.vue';
import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tooltip, Tag } from 'element-ui';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import GIFModal from '@/components/tour/GIFModal.vue';
import { Permission as PermissionEnum } from '@/enums/Permission'

@Component({
  components: {
    CreateChannelForm,
    GIFModal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag
  }
})
export default class ChannelList extends Mixins(PaginationComponentMixin) {
  private departmentTypes: Array<DepartmentType> = [];
  private showDeleteModal = false;
  private isActive = false;
  private isUpdate = false;
  private currentEntity!: DepartmentType;
  private showGIF: boolean = false;
  protected form: DepartmentType = {
    name: '',
    label: '',
    has_outlet: false,
    self_managed: false
  };
  private demoTourEntity: string = 'channel';
  private GIFs: Array<any> = [
    {
      label: 'Create a channel',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a channel',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a channel',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of channel';

  get permissionEnum () {
    return PermissionEnum;
  }

  created() {
    this.fetchEntity();
  }

  entityCreated(entity: DepartmentType) {
    this.departmentTypes.unshift(entity);
  }

  entityUpdated(entity: DepartmentType): void {
    this.departmentTypes.splice(
      this.departmentTypes.findIndex(type => type.id == entity.id),
      1,
      entity
    );
  }

  onEdit(row: DepartmentType) {
    this.isUpdate = true;
    this.currentEntity = row;
    this.form = {
      name: this.currentEntity.name,
      label: this.currentEntity.label,
      has_outlet: Boolean(this.currentEntity.has_outlet),
      self_managed: Boolean(this.currentEntity.self_managed)
    };
    this.isActive = true;
  }
  @Watch('pagination.currentPage')
  fetchEntity(): void {
    this.loading = true;
    this.$http.get(this.$api(`/department-types?page=${this.pagination.currentPage}`)).then(response => {
      this.departmentTypes = response.data.departmentTypes;
      this.setPagination(response.data);
      this.loading = false;
    });
  }

  deleteEntity(): void {
    this.$http.delete(this.$api(`/department-types/${this.currentEntity.id}`)).then(response => {
      this.showDeleteModal = false;
      this.departmentTypes.splice(
        this.departmentTypes.findIndex(value => value.id == this.currentEntity.id),
        1
      );
      this.$notify({
        title: 'Success',
        message: 'Successfully Deleted',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'success'
      });
    });
  }
}













































import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
import RouteWiseTargetLists from "@/intefaces/RouteWiseTargetLists";
import Territory from "@/intefaces/Territory";

@Component({
  components: {}
})
export default class RouteWiseTargetListComponent extends Vue {
  @Prop() routeWiseTargetLists!: RouteWiseTargetLists;
  @Prop() routeColumnTitle!: string;

  onEdit(row: RouteWiseTargetLists, index: number) {
    this.$emit('editTarget', {row, index});
  }

  onDelete(index: number) {
    this.$emit('deleteTarget', index);
  }
}










import {Component, Mixins} from "vue-property-decorator";
import LazyDropdown from "../LazyDropdown.vue";
import DropdownField from "../../mixins/DropdownField";
import CreateTransportForm from "@/views/user/transport/CreateTransportForm.vue";
@Component({
    components: {
      LazyDropdown,
      CreateTransportForm,

    }
})
export default class TransportLazydropdown extends Mixins(DropdownField) {
}











import {Component, Mixins} from "vue-property-decorator";
import LazyDropdown from "../LazyDropdown.vue";
import DropdownField from "../../mixins/DropdownField";
import CreateNumberPlateForm from "@/views/order/transport/CreateNumberPlateForm.vue";
@Component({
    components: {
      LazyDropdown,
      CreateNumberPlateForm,

    }
})
export default class NumberPlateLazydropdown extends Mixins(DropdownField) {
}

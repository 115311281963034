














































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import TerritoryType from '@/intefaces/TerritoryType';
import CreateTerritoryForm from '@/views/territory/CreateTerritoryForm.vue';
import CreateTerritoryTypeFrom from '@/views/territory/CreateTerritoryTypeFrom.vue';
import {clone} from 'lodash';

@Component({
  components: {CreateTerritoryTypeFrom, CreateTerritoryForm}
})
export default class SetupTerritory extends Vue {
  @Prop() territoryTypes!: any[];
  private showDeleteModal: boolean = false;
  private isUpdate: boolean = false;
  private isActive: boolean = false;
  private hasOutlet: boolean = false;
  protected form: TerritoryType = {
    name: '',
    description: '',
    has_outlet: false,
    level: 1
  };

  private currentEntity!: TerritoryType;

  // Lifecycle hooks +++
  mounted() {
    this.$emit('territoryTypes', this.territoryTypes);
    this.checkTerritoryTypesHasOutlet();
  }

  // Lifecycle hooks ---

  // Watcher +++
  @Watch('territoryTypes')
  onChangeTerritoryType() {
    this.$emit('territoryTypes', this.territoryTypes);
  }

  // Watcher ---

  // Computed property +++
  get territoryTypeByLevel(): TerritoryType[] {
    return this.territoryTypes.sort((a, b) => a.level - b.level);
  }

  // Computed property ---

  onEdit(row: TerritoryType) {
    this.isUpdate = true;
    this.currentEntity = row;
    this.form = {
      name: this.currentEntity.name,
      description: this.currentEntity.description,
      has_outlet: this.currentEntity.has_outlet ? true : false,
      level: this.currentEntity.level
    };
    this.isActive = true;
  }

  removeEntity() {
    this.showDeleteModal = false;
    if (this.territoryTypes.find((value, index: number) => value.level == this.currentEntity.level).has_outlet) {
      this.territoryTypes[this.territoryTypes.length ===
      this.currentEntity.level ? this.territoryTypes.length - 2 : this.territoryTypes.length - 1].has_outlet = true;
    }
    this.territoryTypes.splice(
      this.territoryTypes.findIndex((value, index) => value.level == this.currentEntity.level),
      1
    );
    this.resortTheLevel();
  }

  resortTheLevel() {
    this.territoryTypeByLevel.forEach((value, index) => {
      this.territoryTypes[this.territoryTypes.findIndex(value1 => value.level == value1.level)].level = index + 1;
    });
  }

  checkTerritoryTypesHasOutlet() {
    this.territoryTypes.forEach((territoryType: any) => {
      if (territoryType.has_outlet == 1) {
        this.hasOutlet = true;
      }
    })
  }

  save() {
    if (this.isUpdate) {
      let indexOfSwapTerritoryType = this.territoryTypes.findIndex((value, index) => this.form.level == value.level);
      let indexOfTargetTerritoryType = this.territoryTypes.findIndex((value, index) => value.level == this.currentEntity.level);
      if (this.form.level !== this.currentEntity.level) {
        this.territoryTypes[indexOfSwapTerritoryType].level = this.currentEntity.level;
      }
      this.territoryTypes.splice(indexOfTargetTerritoryType, 1, clone(this.form));
    } else {
      this.territoryTypes.forEach(value => {
        if (value.level >= this.form.level) {
          value.level++;
        }
      });
      this.territoryTypes.push(clone(this.form));
    }
    this.checkTerritoryTypesHasOutlet();
    this.close();
  }

  close() {
    this.isActive = false;
    this.isUpdate = false;
    this.formReset();
  }

  formReset(): any {
    this.form.name = '';
    this.form.description = '';
    this.form.has_outlet = false;
    this.form.level = 1;
    return this.form;
  }
}











































































































































//@ts-ignore
import {Component, Mixins, Watch} from 'vue-property-decorator';
import Payment from '@/intefaces/Payment';
import CreatePaymentForm from '@/views/payment/CreatePaymentForm.vue';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import GIFModal from '@/components/tour/GIFModal.vue';
import {InvoiceDTO} from "@/intefaces/Invoice";
import moment from "moment";
import TaxonomyMixin from "@/mixins/TaxonomyComponent";
import { Permission as PermissionEnum } from '@/enums/Permission'

@Component({
  components: {CreatePaymentForm, GIFModal}
})
export default class InvoiceList extends Mixins(PaginationComponentMixin, TaxonomyMixin) {
  loading = false;
  private entities: InvoiceDTO[] = [];
  private showConfirmModal: boolean = false;
  private isActive: boolean = false;
  private currentEntity!: InvoiceDTO;
  private form: Payment = {
    advance: false,
    amount: null,
    created_by: 0,
    department_id: null,
    invoices: [],
    method: null,
    paid_at: null,
    seller_id: null,
  };
  private showGIF: boolean = false;
  private demoTourEntity: string = 'paymentList';
  private GIFs: Array<any> = [
    {
      label: 'Create a payment',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a payment',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a payment',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of payment';

  get permissionEnum () {
    return PermissionEnum;
  }

  mounted() {
    this.fetchEntity();
  }

  onDetails(row: any) {
    this.$router.push({name: 'invoice-payments', params: {invoiceId: row.id, currentInvoice: row}});
  }

  @Watch('pagination.currentPage')
  async fetchEntity() {
    this.loading = true;
    let {data} = await this.$http.get(this.$api(`/invoices?page=${this.pagination.currentPage}&with=payments`));
    this.entities = data.invoices.map((e: any) => new InvoiceDTO(
      e.amount,
      e.buyer_id,
      e.buyer,
      e.code,
      e.delivered,
      e.discount,
      e.id,
      e.invoiceable_id,
      e.payable,
      e.promotion_discount,
      e.remainder,
      e.seller_id,
      e.created_at,
      e.status,
      e.latest_status,
      e.payments.length > 0 ? moment(e.payments[0].paid_at).fromNow() : '',
      e.initiator,
      e.order_id,
      e.latest_revision,
      e.sales_return,
      e.buyer_meta,
      e.seller_meta,
      e.creator_meta,
      e.buyer_name,
      e.seller_name,
      e.creator_name
    ));
    // moment().diff(moment(lastCheckinTime).format('lll'), 'minute');
    this.setPagination(data);
    this.loading = false;
  }


}







































































































































































/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
//@ts-ignore
import SetupWizard from '@/views/setup-wizard';
import DashboardNavbar from '@/components/layouts/argon/DashboardNavbar.vue';
import ContentFooter from '@/components/layouts/argon/ContentFooter.vue';
import DashboardContent from '@/components/layouts/argon/Content.vue';
import {Component, Mixins} from 'vue-property-decorator';
import TaxonomyMixin from "@/mixins/TaxonomyComponent";

function hasElement(className: string) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className: string) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

@Component({
  components: {
    DashboardNavbar,
    ContentFooter,
    DashboardContent,
    SetupWizard,
  },
})
export default class Dashboard extends Mixins(TaxonomyMixin) {
  get permissions() {
    return this.$store.getters['Auth/menus'];
  }

  get logoUrl() {
    return this.$store.getters['Theme/getLogoUrl'];
  }

  initScrollbar() {
    let isWindows = navigator.platform.startsWith('Win');
    if (isWindows) {
      initScrollbar('scrollbar-inner');
    }
  }

  mounted() {
    this.initScrollbar();
  }
}

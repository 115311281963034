var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_vm._m(0),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"table-responsive align-items-center table-flush table-striped",attrs:{"header-row-class-name":"thead-light","data":_vm.payments}},[_c('el-table-column',{attrs:{"label":"Invoice No.","min-width":"140px","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.invoice.code))])]}}])}),_c('el-table-column',{attrs:{"label":"ORG","min-width":"120px","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.org ? row.org.name : 'Anonymous'))])]}}])}),_c('el-table-column',{attrs:{"label":"Payment Method","min-width":"160px","prop":"created_at","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.medium.charAt(0).toUpperCase() + row.medium.slice(1)))])]}}])}),_c('el-table-column',{attrs:{"label":"Transaction Id","min-width":"150px","prop":"created_at","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.txn_no))])]}}])}),_c('el-table-column',{attrs:{"label":"Amount","min-width":"120px","prop":"created_at","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.invoice.amount))])]}}])}),_c('el-table-column',{attrs:{"label":"Paid At","min-width":"150px","prop":"created_at","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(_vm._f("datetime")(row.invoice.paid_at,'ll')))])]}}])}),_c('el-table-column',{attrs:{"label":"Status","min-width":"150px","prop":"created_at","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{attrs:{"content":row.rejection_reason,"placement":"top"}},[_c('a',{attrs:{"href":""}},[(row.rejected_at !== null)?_c('b',{staticStyle:{"color":"#fb6340"}},[_vm._v("Rejected")]):_vm._e()])]),(row.approved_at !== null)?_c('b',{staticStyle:{"color":"green"}},[_vm._v("Approved")]):_vm._e(),(row && (!row.rejected_at && !row.approved_at ))?_c('b',{staticStyle:{"color":"#11cdef"}},[_vm._v("Payment Requested")]):_vm._e()]}}])})],1),(_vm.payments.length > 0)?_c('base-pagination',{staticClass:"mt-3",attrs:{"perPage":_vm.pagination.entityPerPage,"total":_vm.pagination.totalEntity,"align":"center","disabled":_vm.loading},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header border-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Payments")])])])])}]

export { render, staticRenderFns }
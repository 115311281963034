

















































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Divider } from "element-ui";
import OrderRevision from "@/intefaces/OrderRevision";

@Component({
  components: {
    [Divider.name]: Divider,
  }
})

export default class BaseOrderProducts extends Vue {
  @Prop() currentProductEntity!: any;
  @Prop() returnProductEntity!: any;
  @Prop() title!: string;
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('side-bar',{attrs:{"logo":_vm.logoUrl}},[_c('template',{slot:"links"},[_c('sidebar-item',{class:['/miscellaneous', '/sales-growth', '/dashboard'].includes(this.$route.path)  ? '' : 'foo',style:(_vm.permissions.find(function (permission) { return permission.dashboards == false; }) ? 'display: none' : ''),attrs:{"link":{
            name: 'Dashboard',
            icon: 'ni ni-planet text-sokrio',
            path: '/',
          }}}),_c('sidebar-item',{style:(_vm.permissions.find(function (permission) { return permission.user == false; }) ? 'display: none' : ''),attrs:{"link":{
            name: 'User',
            icon: 'ni ni-single-02 text-sokrio',
            path: '/user',
          }}}),_c('sidebar-item',{style:(_vm.permissions.find(function (permission) { return permission.organization == false; }) ? 'display: none' : ''),attrs:{"link":{
            name: 'Territory',
            path: '/territory',
            icon: 'fa fa-sitemap text-sokrio',
          }}}),_c('sidebar-item',{style:(_vm.permissions.find(function (permission) { return permission.organization == false; }) ? 'display: none' : ''),attrs:{"link":{
            name: 'Department',
            path: '/department',
            icon: 'fas fa-store text-sokrio',
          }}}),_c('sidebar-item',{style:(_vm.permissions.find(function (permission) { return permission.inventory == false; }) ? 'display: none' : ''),attrs:{"link":{
              name: 'Inventory',
              icon: 'ni ni-app text-sokrio',
              path: '/inventory',
            }}}),_c('sidebar-item',{style:(_vm.permissions.find(function (permission) { return permission.tracking == false; }) ? 'display: none' : ''),attrs:{"link":{
            name: 'Tracking',
            icon: 'fa fa-binoculars text-sokrio',
            path: '/tracking',
          }}}),_c('sidebar-item',{style:(_vm.permissions.find(function (permission) { return permission.attendance == false; }) ? 'display: none' : ''),attrs:{"link":{
            name: 'Attendance',
            path: '/attendance',
            icon: 'fas fa-hand-paper text-sokrio',
          }}}),_c('sidebar-item',{style:(_vm.permissions.find(function (permission) { return permission.inspection == false; }) ? 'display: none' : ''),attrs:{"link":{
            name: 'Inspection',
            path: '/inspection/survey',
            icon: 'fas fa-lightbulb text-sokrio',
          }}}),_c('sidebar-item',{style:(_vm.permissions.find(function (permission) { return permission.order == false; }) ? 'display: none' : ''),attrs:{"link":{
            name: _vm.getTaxonomy('order'),
            icon: 'ni ni-book-bookmark text-sokrio',
            path: '/order',
          }}}),_c('sidebar-item',{style:(_vm.permissions.find(function (permission) { return permission.payment == false; }) ? 'display: none' : ''),attrs:{"link":{
            name: 'Payment',
            icon: 'ni ni-credit-card text-sokrio',
            path: '/payment',
          }}}),_c('sidebar-item',{style:(_vm.permissions.find(function (permission) { return permission.stock == false; }) ? 'display: none' : ''),attrs:{"link":{
            name: 'Stock',
            path: '/stock',
            icon: 'fas fa-cubes text-sokrio',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Report',
            path: '/report',
            icon: 'fa fa-file text-sokrio',
          }}}),_c('sidebar-item',{style:(_vm.permissions.find(function (permission) { return permission.setting == false; }) ? 'display: none' : ''),attrs:{"link":{
            name: 'Settings',
            path: '/settings',
            icon: 'fas fa-cog text-sokrio',
          }}}),_c('sidebar-item',{style:(_vm.permissions.find(function (permission) { return permission.sms == false; }) ? 'display: none' : ''),attrs:{"link":{
            name: 'SMS',
            path: '/sms',
            icon: 'fa fa-envelope text-sokrio',
          }}})],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.name === 'alternative' ? 'light' : 'default'}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('dashboard-content')],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1),_c('SetupWizard')],1)}
var staticRenderFns = []

export { render, staticRenderFns }















import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
    name: 'SingleImageShow'
})
export default class SingleImageShow extends Vue {
    @Prop() image!: any;
    private imageSrc: any = '';
    private hasError: any = false;
    private isShowImagePreview: boolean = false;

    fetchProductImage(image: any) {
        this.$blob2Url(image).then((response:any) => {
            this.imageSrc = response;
        });
    }
    created(){
        this.fetchProductImage(this.image);
    }

    close(){
        this.isShowImagePreview = false;
    }
}
































import { Vue, Component, Prop } from 'vue-property-decorator';
import AsyncImage from "@/components/AsyncImage.vue";
import { Carousel, CarouselItem, Image } from "element-ui";
import LoadingPanel from "@/components/argon-core/LoadingPanel.vue";

@Component({
  components: {
    LoadingPanel,
    AsyncImage,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    [Image.name]: Image
  }
})
export default class TransportationAttachments extends Vue {
  @Prop()
  attachments!: any;
  attachmentsUrls: any = [];
  @Prop({type: Boolean, default: false})
  public isAttachmentsActive!: boolean;

  mounted() {
    let parsedAttachments = JSON.parse(this.attachments)
    parsedAttachments.forEach(async (attachmentPath: string) => {
      let data = {};
      if (process.env.NODE_ENV === 'production') {
        data = (await this.$http.get(this.$api('/file?path=' + attachmentPath))).data;
      } else {
        data = await this.$blob2Url('/file?path=' + attachmentPath);
      }
      this.attachmentsUrls.push(data);
    })
  }

  close() {
    this.$emit('update:isAttachmentsActive', false);
  }

}

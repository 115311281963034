












































import {Component, Prop, Vue} from "vue-property-decorator";
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip
} from "element-ui";
import DepartmentProduct from "@/intefaces/DepartmentProduct";
import ProductImage from "@/components/products/ProductImage.vue";

@Component({
  components: {
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    ProductImage
  }
})
export default class ProductDetailsForm extends Vue {
  @Prop() currentDepartmentProduct!: DepartmentProduct;
  @Prop() isActive!: boolean;

  close() {
    this.$emit('update:isActive', false);
  }
}


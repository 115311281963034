







import {Component, Mixins} from "vue-property-decorator";
import LazyDropdown from "../LazyDropdown.vue";
import DropdownField from "../../mixins/DropdownField";
import AssetForm from "@/views/inspection/auditable/AssetForm.vue"
@Component({
    components: {
        LazyDropdown,
        AssetForm
    }
})
export default class AssignAutocomplete extends Mixins(DropdownField) {
}

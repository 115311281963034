






























































































































































































































































































// @ts-ignore
import { CollapseTransition } from 'vue2-transitions';
import BaseNav from '@/components/argon-core/Navbar/BaseNav.vue';
import Modal from '@/components/argon-core/Modal.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const auth = namespace('AdminAuth');

@Component({
  components: {
    // CollapseTransition,
    BaseNav
    // Modal
  }
})
export default class AdminDashboardNavbar extends Vue {
  private activeNotifications: boolean = false;
  private showMenu: boolean = false;
  private searchModalVisible: boolean = false;
  private searchQuery: string = '';

  @Prop({ required: false, type: String, default: 'Developer' })
  readonly type!: string;

  @auth.Action
  public logout!: (payload: any) => Promise<any>;

  get routeName() {
    const { name }: any = this.$route;
    return this.capitalizeFirstLetter(name);
  }

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  toggleNotificationDropDown() {
    this.activeNotifications = !this.activeNotifications;
  }
  closeDropDown() {
    this.activeNotifications = false;
  }

  toggleSidebar() {
    this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
  }

  hideSidebar() {
    this.$sidebar.displaySidebar(false);
  }

  signOut() {
    this.logout({ vm: this }).then(response => {
      this.$notify({
        title: 'Success',
        message: 'Logout successful',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'success'
      });
      this.$router.push({ name: 'admin-login' });
    });
  }
}






















  import {Component, Mixins, Prop, Vue} from 'vue-property-decorator';
import Productable from '../../intefaces/Productable';
  import BaseCUModalComponentMixin from "@/mixins/BaseCUModalComponent";
  import { AxiosResponse } from 'axios';
  import ProductBrandParentLazydropdown from "@/components/lazyDropdown/ProductBrandParentLazydropdown.vue";

@Component({
  name: 'BrandForm',
  components: ({
    ProductBrandParentLazydropdown
  })
})
export default class BrandForm extends Mixins(BaseCUModalComponentMixin) {
  @Prop({
    default: () => ({
      name: '',
      parent_id: ''
    })
  })
  form!: {
    name: string;
    parent_id: string;
  };
  @Prop({ required: false }) isUpdate!: boolean;
  @Prop({ required: false, default: null }) currentEntity!: Productable;

  formReset(): any {
    this.form.name = '';
    this.form.parent_id = '';
    return this.form;
  }

  get createApi(): string
  {
    return '/product-brands'
  }

  async afterCreate(response: AxiosResponse<any>): Promise<void> {
    this.$emit('created', response.data.productBrand);
  }

  get updateApi(): string {
    return `/product-brands/${this.currentEntity.id}`
  }

  afterUpdate(response: AxiosResponse<any>): void {
    this.$emit('updated', response.data.productBrand);
  }
}










































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import User from "@/intefaces/User";

@Component({
  name: 'viewDepartmentUser',
  components: {}
})
export default class ViewDepartmentUser extends Vue {
  @Prop() isActive!: boolean;
  @Prop() departmentStaffs!: User;

  close() {
    this.$emit('update:isActive', false);
  }
}

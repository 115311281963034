




















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Announcement from '@/intefaces/Announcement';
import Permission from '@/intefaces/Permission';
const flatPicker = require('vue-flatpickr-component');

@Component({
  name: 'CreateAnnouncement',
  components: {
    flatPicker,
  },
})
export default class CreateAnnouncement extends Vue {
  @Prop() newAnnouncement!: boolean;
  @Prop() isUpdate!: boolean;
  @Prop() isForSetupWizard!: boolean;
  @Prop() currentAnnouncement!: any;
  @Prop({
    default: () => ({
      title: '',
      body: '',
      type: '',
      starts_at: '',
      ends_at: '',
    }),
  })
  form!: {
    title: string;
    body: string;
    type: string;
    starts_at: string;
    ends_at: string;
  };
  private is_remove_permission = false;
  private permissions: Permission[] = [];
  private disableBtn = false;
  private isActivePermission: boolean = false;

  createAnnouncement() {
    this.disableBtn = true;
    this.$http
      .post(this.$api('/announcements'), this.form)
      .then((response) => {
        this.$notify({
          title: 'Success',
          message: 'Successfully Created',
          duration: 5000,
          iconClass: 'ni ni-bell-55',
          type: 'success',
        });
        this.$emit('created', response.data.announcement);
        this.form.title = '';
        this.form.body = '';
        this.form.type = '';
        this.form.starts_at = '';
        this.form.ends_at = '';
        this.disableBtn = false;
      })
      .catch((error) => {
        this.disableBtn = false;
        (
          this.$refs.formValidator as Vue & {
            setErrors: (errors: []) => any;
          }
        ).setErrors(error.response.data.errors);
      });
  }

  save() {
    this.createAnnouncement();
  }
}


















































import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
import VariantCategoryLazydropdown from "@/components/lazyDropdown/VariantCategoryLazydropdown.vue";
import VariantCategory from "@/intefaces/VariantCategory";

@Component({
  components: {
    VariantCategoryLazydropdown,
  }
})
export default class ProductVariantComponent extends Vue {
  @Prop() productVariants!: any;
  private disabled = true;
  private variantError = false;
  private variantCategories: VariantCategory[] = []
  private singleVariant = {
    value: '',
    variant: '',
    id: '',
  }

  @Watch('singleVariant', {deep: true})
  enableAddButton() {
    if (this.singleVariant.value && this.singleVariant.variant) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  }

  singleVariantReset() {
    this.singleVariant.value = '';
    this.singleVariant.variant = '';
  }

  deleteVaraint(index: number) {
    this.productVariants.splice(index, 1);
  }

  saveProductVariant() {
    if (this.productVariants.length > 0) {
      if (this.productVariants.find((element: any) => element.variant == this.singleVariant.variant)) {
        this.variantError = true;
      } else {
        this.productVariants.push({...this.singleVariant});
        this.singleVariantReset();
        this.variantError = false;
      }
    } else {
      this.productVariants.push({...this.singleVariant});
      this.singleVariantReset();
    }
  }

  newCreatedEntity(event: VariantCategory) {
    this.variantCategories.unshift(event);
  }


  fetchVariantCategory() {
    this.$http.get(this.$api('/variant-categories')).then(response => {
      this.variantCategories = response.data.variantCategories;
    })
  }

  created() {
    this.fetchVariantCategory();
  }
}

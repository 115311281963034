









































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Tag} from "element-ui";
import User from "@/intefaces/User";
import swal from 'sweetalert2';
import SummaryReviewDiagram from "@/views/setup-wizard/SummaryReviewDiagram.vue";
import {SetupWizardSteps} from "@/enums/SetupWizardSteps";

@Component({
  components: {
    [Tag.name]: Tag,
    SummaryReviewDiagram
  }
})
export default class SummaryReview extends Vue {
  @Prop() isActive!: boolean;
  @Prop() territoryTypes!: any[];
  @Prop() userRolesPrimary!: any[];
  @Prop() userRoles!: any[];
  @Prop() departmentTypes!: any[];
  private department: any = {
    address: '',
    badge_id: null,
    code: null,
    created_by: null,
    credit_limit: 0,
    department_type_id: 1,
    lat: null,
    long: null,
    name: '',
    outstanding_balance: 0,
    owner_name: '',
    phone_no: '',
    photo: '',
    photo_url: '',
    territory_id: 1,
    supplier: null,
    supplier_id: null
  };
  private disabled: boolean = false;
  private loading: boolean = false;
  private errorMsg: string = '';

  async save() {
    await this.saveSetupData();
  }

  updateStep() {
    this.$emit('updateRole', this.userRolesPrimary, this.userRoles)
    this.$store.dispatch('SetupWizard/updateCurrentStep', '3');

  }

  close() {
    this.$store.commit('SetupWizard/previousStep');
    this.$emit('update:isActive', false);
  }

  updateTerritoryType() {
    this.$emit('updatedTerritoryTypes', this.territoryTypes);
  }

  updateChannels() {
    this.$emit('updateChannels', this.departmentTypes);
  }

  private async saveSetupData() {
    var setupData: any = {};
    this.disabled = true;
    this.loading = true;
    setupData['territoryTypes'] = this.territoryTypes;
    setupData['departmentTypes'] = this.departmentTypes;
    setupData['roles'] = this.userRolesPrimary.concat(this.userRoles);
    let {data} = await this.$http.get(this.$api('/setup-wizard-status'))

    if(data.completed) {
      this.showWelcomeMessage(data.completed);
      this.$store.commit('SetupWizard/nextStep');
    } else {
      this.$http.post(this.$api('/setup-wizard'), setupData).then(response => {
        this.disabled = false;
        this.loading = false;
        this.showWelcomeMessage();
        this.$store.commit('SetupWizard/nextStep');
      })
        .catch(error => {
          this.errorMsg = error.response.data.message
          this.showErrorMessage();
          localStorage.setItem('currentStep', SetupWizardSteps.Welcome.toString());
          this.$store.commit('SetupWizard/errorStep');
          this.disabled = false;
          this.loading = false;
        });
    }
  }

  private async saveTerritoryTypeStructure() {
    let warehouse;
    for (let i = 0; i < this.territoryTypes.sort((a, b) => a.level - b.level).length; i++) {
      let {data} = await this.$http.post(this.$api('/territory-types'), this.territoryTypes[i]);
      if (i == 0) warehouse = data.territoryType;
    }
    let territory: any = {
      code: `${warehouse.name}-1`,
      managed_by: await this.$store.getters['Auth/user'].id,
      // name: `Default Territory for type ${warehouse.name}`,
      name: 'Bangladesh',
      parent_id: null,
      territory_type_id: 1
    };
    await this.$http.post(this.$api('/territories'), territory);
  }

  private async saveDepartmentTypeStructure() {
    for (let i = 0; i < this.departmentTypes.length; i++) {
      await this.$http.post(this.$api('/department-types'), this.departmentTypes[i]);
    }
    await this.saveWarehouse();
  }

  private async saveWarehouse() {
    let domain = await this.$store.getters['Auth/getDomain'];
    let user: User = await this.$store.getters['Auth/user'];
    this.department.name = domain.charAt(0).toUpperCase() + domain.slice(1) + ' central warehouse';
    this.department.code = `${domain}-warehouse-1`;
    this.department.owner_name = user.name;
    this.department.phone_no = user.phone || null;

    await this.$http.post(this.$api('/departments'), this.department);
  }

  private async saveRoleStructure() {
    for (let i = 0; i < this.userRoles.length; i++) {
      await this.$http.post(this.$api('/roles'), this.userRoles[i]);
    }
  }

  private showWelcomeMessage(setupWizardCompleted: boolean = false) {
    swal.fire({
      title: 'Congratulations!',
      text: setupWizardCompleted ? 'Looks like you have already completed the wizard.' : 'You have successfully set up your company profile.',
      icon: 'success',
      confirmButtonText: 'Next'
    });
  }

  private showErrorMessage() {
    swal.fire({
      title: 'Warning!',
      text: this.errorMsg,
      icon: 'error',
      confirmButtonText: 'Close'
    });
  }
}

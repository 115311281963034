























import { Vue, Component, Prop, Mixins } from 'vue-property-decorator';
import BaseCUModalComponent from '@/mixins/BaseCUModalComponent';
import { AxiosResponse } from 'axios';
import CourierBranch from '@/intefaces/CourierBranch.js';

@Component({
  components: {}
})
export default class CreateCourierBranchForm extends Mixins(BaseCUModalComponent) {
  @Prop()
  currentEntity!: CourierBranch;

  @Prop()
  courier_id!: number | string;

  @Prop({default: () => ({name: ''})})
  form!: {
    name: string;
    courier_id: number | string;
  };

  formReset(): any {
    this.form.name = '';
    this.form.courier_id = '';
    return this.form;
  }

  async afterCreate(response: AxiosResponse<any>): Promise<void> {
    this.$emit('created', response.data.courierBranch);
  }

  afterUpdate(response: AxiosResponse<any>): void {
    this.$emit('updated', response.data.courierBranch);
  }

  get createApi(): string {
    return '/courier-branches';
  }

  get updateApi(): string {
    return `/courier-branches/${this.currentEntity.id}`;
  }

  mounted() {
    this.form.courier_id = this.courier_id;
  }
}







































//@ts-ignore
import {Vue, Watch, Component, Mixins} from 'vue-property-decorator';
import RouteBreadCrumb from '@/components/argon-core/Breadcrumb/RouteBreadcrumb.vue';

import Route from '@/intefaces/Route';
import OrderRoutes from '@/router/client-routes/OrderRoutes';
import { RouteConfig } from 'vue-router';
import TaxonomyMixin from "@/mixins/TaxonomyComponent";

@Component({
  components: { RouteBreadCrumb },
})
class Order extends Mixins(TaxonomyMixin) {
  activeTab: number | null = 0;
  showModal: boolean = false;
  routes: RouteConfig = OrderRoutes;
  formErrors: any = {};
  formInputs: any = {};
  store: any = []

  get OrderRoutes() {
    return OrderRoutes;
  }

  created(): void {
    if (this.routes.children === undefined) return;
    this.routes.children.forEach((route, index) => {
      if (this.$route.name === route.name) {
        this.activeTab = index;
      }
    });
  }

  setCurrentTab(tab = null) {
    if (tab === null) {
      this.activeTab = 0;
    } else {
      this.activeTab = tab;
    }
  }

  closeAndResetForm() {
    this.showModal = false;
    this.formInputs = {};
    this.formErrors = {};
  }
}
export default Order;

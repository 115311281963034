








































import { Component, Prop, Vue} from 'vue-property-decorator';
import AssignAsset from "@/intefaces/AssignAsset";
const flatPicker =require("vue-flatpickr-component");
import "flatpickr/dist/flatpickr.css";

@Component({
    name: 'AuditableOutletUpdateForm.vue',
    components: {
        flatPicker,
    }
})
export default class AuditableOutletUpdateForm extends Vue{
    @Prop() isUpdate!: boolean;
    @Prop() currentAuditableOutlet!: any;
    @Prop() formUpdate!: {
        auditable: number | null,
        prodivedAt: string,
        expiresAt: string,
        qty: number | null
    }
    @Prop() outlets!: any;
    private disableBtn = false;

    updateAssignAsset(){
        this.disableBtn = true;
        this.$http.patch(this.$api(`/outlet-auditables/${this.currentAuditableOutlet.id}`), {
            auditable_id: this.currentAuditableOutlet.auditable_id,
            department_id: this.currentAuditableOutlet.department_id,
            provided_at: this.formUpdate.prodivedAt,
            expires_at: this.formUpdate.expiresAt,
            auditable_type: this.currentAuditableOutlet.auditable_type,
            qty: this.formUpdate.qty
        })
        .then(response => {
            this.disableBtn = false;
            this.$notify({
                title: 'Success',
                message: 'Successfully Updated',
                duration: 3000,
                iconClass: 'ni ni-bell-55',
                type: 'success'
            });
            this.$emit('updated', response.data.outletAuditable);
            this.formUpdate.prodivedAt = '';
            this.formUpdate.expiresAt = '';
        })
        .catch(error => {
            this.disableBtn = false;
            (this.$refs.formValidator as Vue & {
                setErrors: (errors: []) => any;
            }).setErrors(error.response.data.errors);
        });
    }

    close(){
        this.$emit('update:isUpdate', false);
    }
}

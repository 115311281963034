









import {Component, Mixins, Prop} from "vue-property-decorator";
import LazyDropdown from "../LazyDropdown.vue";
import DropdownField from "../../mixins/DropdownField";
import CreateDepartmentForm from "@/views/department/CreateDepartmentForm.vue";

    @Component({
        components: {
            LazyDropdown,
            CreateDepartmentForm
        }
    })
    export default class DepartmentLazydropdown extends Mixins(DropdownField) {
        @Prop({default:'departments'})
        endPoint!:string;
        @Prop({default: false})
        disabled!: boolean;
    }
































import {Vue, Component, Prop} from 'vue-property-decorator';
import {
  Tooltip,
  Timeline,
  TimelineItem,
  Card
} from 'element-ui';

@Component({
  components: {
    [Tooltip.name]: Tooltip,
    [Timeline.name]: Timeline,
    [TimelineItem.name]: TimelineItem,
    [Card.name]: Card
  }
})
export default class TrackingModal extends Vue {
  private allTrackings: any = [];

  @Prop()
  currentUserId!: number;

  @Prop({type: Boolean, default: false})
  public showTracking!: boolean;

  close() {
    this.$emit('update:showTracking', false);
  }

  async mounted() {
    let {data} = await this.$http.get(this.$api(`/users/${this.currentUserId}/trackings?page=1`));
    this.allTrackings = data.trackings.slice(0, 10);
  }

}

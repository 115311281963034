







































































































































import {Vue, Component, Prop, Mixins, Watch} from 'vue-property-decorator';
import BaseCUModalComponent from '@/mixins/BaseCUModalComponent';
import {AxiosResponse} from 'axios';
import Order from '@/intefaces/Order.js';
import OrderStatusAutocomplete from '@/components/resources-autocomplete/OrderStatusAutocomplete.vue';
import RequisitionStatuses from "@/data/OrderStatus";
import OrderRevision from "@/intefaces/OrderRevision";
import RevisionList from "@/views/order/RevisionList.vue";
import AsyncImage from "@/components/AsyncImage.vue";
import {
  Tooltip,
  Timeline,
  TimelineItem,
  Card
} from 'element-ui';
import TaxonomyMixin from "@/mixins/TaxonomyComponent";
import { Permission as PermissionEnum } from '@/enums/Permission'

@Component({
  components: {
    [Tooltip.name]: Tooltip,
    [Timeline.name]: Timeline,
    [TimelineItem.name]: TimelineItem,
    [Card.name]: Card,
    AsyncImage,
    OrderStatusAutocomplete,
    RevisionList
  }
})
export default class OrderDetailsForm extends Mixins(BaseCUModalComponent, TaxonomyMixin) {
  isStatusUpdateActive: Boolean = false;
  isStatusHistoryActive: Boolean = false;
  private statuses: any = [];
  private historyPerPage = 0;
  private totalHistory = 0;
  private currentHistoryPage = 1;
  private lastestOrderRevision: OrderRevision[] = [];
  private orderRevisions: OrderRevision[] = [];
  private isViewRevision: boolean = false;
  requisitionStatuses = RequisitionStatuses;

  @Prop()
  currentEntity!: Order;

  @Prop({
    type: Object,
    default: function () {
      return {remarks: '', status: ''};
    }
  })
  form!: {
    remarks: string;
    status: string;
  };

  get permissionEnum () {
    return PermissionEnum;
  }

  @Watch('isActive')
  getRevision() {
    this.$http.get(this.$api(`/orders/${this.currentEntity.id}/revisions`)).then(response => {
      this.orderRevisions = response.data.revisions
      this.lastestOrderRevision = response.data.revisions[0];
    })
  }

  @Watch('currentEntity')
  onChangeCurrentEntity(value: any) {
    if (value == null) {
      return;
    }
    this.fetchStatus();
  }

  formReset(): any {
    this.form.remarks = '';
    this.form.status = '';
    return this.form;
  }

  deleteRejectStatus(status: any) {
    if (status.find((status: any, index: any) => status.status == 'accepted') && this.requisitionStatuses.find(status => status.value == 'rejected')) {
      this.requisitionStatuses.splice(this.requisitionStatuses.findIndex((status: any) => status.value == 'rejected'), 1);
    } else if (!status.find((status: any, index: any) => status.status == 'accepted') && !this.requisitionStatuses.find(status => status.value == 'rejected')) {
      this.requisitionStatuses.splice(3, 0, {title: 'Rejected', value: 'rejected'});
    }
  }

  async afterCreate(response: AxiosResponse<any>): Promise<void> {
    this.$emit('created', response.data.status);
    this.statuses.unshift(response.data.status);
    this.deleteRejectStatus(this.statuses);
    let data = [...this.statuses];
    this.currentEntity.statuses = data.reverse();
    this.$emit('update:currentEntity', this.currentEntity);
  }

  afterUpdate(response: AxiosResponse<any>): void {
  }

  get createApi(): string {
    return `/orders/${this.currentEntity.id}/statuses`;
  }

  get updateApi(): string {
    return `/orders/${this.currentEntity.id}/statuses`;
  }

  protected createNotification() {
    this.notification('Updated');
  }

  @Watch('currentHistoryPage')
  private async fetchStatus() {
    let {data} = await this.$http.get(this.$api(`/orders/${this.currentEntity.id}/statuses?page=${this.currentHistoryPage}`));
    this.statuses = data.statuses;

    this.deleteRejectStatus(this.statuses);

    this.totalHistory = data.total;
    this.historyPerPage = data.per_page;
  }

  close(): void {
    if (this.isStatusUpdateActive) {
      this.formReset();
      this.isStatusUpdateActive = false;
      return;
    }
    this.$emit('update:isActive', false);
  }
}






















import { Component, Mixins } from 'vue-property-decorator';
import TargetModalData from '@/mixins/TargetModalData';

@Component({
  components: {}
})
export default class TargetNameComponent extends Mixins(TargetModalData) {}






















































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import AsyncImageForLandLord from "@/components/AsyncImageForLandLord.vue";

const auth = namespace('Auth');
@Component({
  components: {AsyncImageForLandLord}
})
export default class Login extends Vue {
  fields = {
    email: '',
    password: '',
    device_name: '',
    remember_me: false,
  };
  private showError: boolean = false;
  private error: string = '';
  private loading: boolean = false;
  private is_validateRoute: boolean = false;

  get logo() {
    return this.$store.getters['Theme/orgLogo'];
  }

  get orgName() {
    return this.$store.getters['Theme/orgName'];
  }

  @auth.Getter
  public isLoggedIn!: boolean;

  @auth.Action
  public login!: (payload: any) => Promise<any>;

  loginErrorNotification(message: string) {
    this.$notify({
      title: 'Error',
      message: message,
      type: 'warning',
      position: 'top-left',
      duration: 5000,
      iconClass: 'ni ni-bell-55',
    });
  }

  get permissionmenu() {
    return this.$store.getters['Auth/menus'];
  }

  onSubmit() {
    this.fields.device_name = navigator.userAgent;
    this.loading = true;
    this.login({vm: this, fields: this.fields})
      .then((response) => {
        this.$notify({
          title: 'Success',
          message: 'Login successful',
          duration: 5000,
          iconClass: 'ni ni-bell-55',
          type: 'success',
        });
        this.permissionmenu.forEach((menu: any) => {
          if (Object.values(menu)[0] === true && !this.is_validateRoute) {
            this.is_validateRoute = true;
            return this.$router.push({name: Object.keys(menu)[0]});
          }
        });
      })
      .catch((error) => {
        this.error = error.response.data.errors.message[0];
        this.showError = true;
        this.loginErrorNotification(error.response.data.errors.message[0]);
      })
      .finally(() => (this.loading = false));
  }
}
































import {Component, Mixins, Vue, Watch} from 'vue-property-decorator';
import LandingPage from '@/views/product/productCreateComponent/LandingPage.vue';
import AddNewProductForm from '@/views/product/productCreateComponent/AddNewProductForm.vue';
import AddBatchForExistingProductForm from '@/views/product/productCreateComponent/AddBatchForExistingProductForm.vue';
import {Button} from "element-ui";
@Component({
  components: {
    [Button.name]: Button,
    LandingPage,
    AddNewProductForm,
    AddBatchForExistingProductForm,
  }
})
export default class CreateProduct extends Vue {
  private clickAddNewProductBtn: boolean = false;
  private clickAddBatchForExistingProductBtn: boolean = false;
}

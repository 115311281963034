









































































































import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator';
import Target from '@/intefaces/Target';
import { QueryBuilder } from '@/classes/QueryBuilder';
import UserAutocomplete from '@/components/lazyDropdown/UserLazydropdown.vue';
import ProductLazydropdown from '@/components/lazyDropdown/ProductLazydropdown.vue';
import RegularTargetComponent from '@/views/user/target/RegularTargetComponent.vue';
import TargetNameComponent from '@/views/user/target/TargetNameComponent.vue';
import RouteWiseTargetComponent from '@/views/user/target/RouteWiseTargetComponent.vue';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import { TabPane, Tabs, TableColumn } from 'element-ui';
import Role from '@/intefaces/Role';

@Component({
  components: {
    UserAutocomplete,
    ProductLazydropdown,
    RegularTargetComponent,
    TargetNameComponent,
    RouteWiseTargetComponent,
    [Tabs.name]: Tabs,
    [TableColumn.name]: TableColumn,
    [TabPane.name]: TabPane
  },
  name: 'TargetAssignForm'
})
export default class TargetAssignForm extends Mixins(PaginationComponentMixin) {
  @Prop() isActive!: boolean;
  @Prop() isTargetName!: boolean;
  @Prop() currentTraget!: Target;
  @Prop() currentLableForInvoice!: string;
  @Prop() form!: {
    type: string;
    expiresDate: string;
    amount: number;
    unit: string;
    assignTo: any;
    scheme: string;
    target_name: string;
    start_from: string;
    status: boolean;
    territory_id: number | string;
  };
  @Prop() routeColumnTitle!: string;
  private disableSaveBtn = false;
  public queryBuilder: QueryBuilder = new QueryBuilder();
  private activeTab: string = 'targetName';
  private targetAssignId: number = 0;
  public loading: boolean = false;
  public targetNames: string[] = [];
  public upHere = false;
  private currentEntity = [];
  private showDeleteModal = false;
  private currentTargetName: any = [];

  created() {
    this.fetchEntity();
  }

  formReset() {
    this.form.target_name = '';
    this.form.start_from = '';
    this.activeTab = 'targetName';
    (this.$refs.formValidator as Vue & {
      reset: () => any;
    }).reset();
  }

  notify(title: string, message: string, type: 'success' | 'warning' | 'info' | 'error') {
    this.$notify({
      title: title,
      message: message,
      duration: 3000,
      iconClass: 'ni ni-bell-55',
      type: type
    });
  }

  @Watch('pagination.currentPage')
  fetchEntity(): void {
    this.loading = true;
    this.$http.get(this.$api(`/target-name-with-range?page=${this.pagination.currentPage}`)).then(response => {
      this.targetNames = response.data.target_names_info;
      this.setPagination(response.data);
      this.loading = false;
    });
  }
  onValue(row: any) {
    this.$emit('targetAssignId', (this.targetAssignId = row.id));
    this.$emit('TargetNameUpdate', (this.isTargetName = false));
    this.formReset();
  }
  onValueForBulkAssign(row: any) {
    this.$emit('targetAssignId', (this.targetAssignId = row.id));
    this.$emit('TargetNameUpdateForBulkAssign', (this.isTargetName = false));
    this.formReset();
  }

  save() {
    this.disableSaveBtn = true;
    this.$http
      .post(this.$api(`/target-name-with-range`), {
        target_name: this.form.target_name,
        start_from: this.form.start_from
      })
      .then(response => {
        this.notify('Success', 'Successfully Created', 'success');
        this.afterCreated(response.data.target_name);
        this.formReset();
        this.disableSaveBtn = false;
      })
      .catch(error => {
        this.disableSaveBtn = false;
        (this.$refs.formValidator as Vue & {
          setErrors: (errors: []) => any;
        }).setErrors(error.response.data.errors);
      });
  }
  async afterCreated(response: any) {
    this.targetNames.unshift(response);
  }

  onDelete() {
    this.$http
      .delete(this.$api(`/target-name-with-range/${this.currentTargetName.id}`))
      .then(response => {
        this.showDeleteModal = false;
        this.targetNames.splice(
          this.targetNames.findIndex((currentId: any) => currentId.id == this.currentTargetName.id),
          1
        );
        this.$notify({
          title: 'Success',
          message: 'Successfully Deleted',
          duration: 5000,
          iconClass: 'ni ni-bell-55',
          type: 'success'
        });
      })
      .catch(error => {
        (this.$refs.formValidator as Vue & {
          setErrors: (errors: []) => any;
        }).setErrors(error.response.data.errors);
      });
  }

  close() {
    this.formReset();
    // this.$emit('update:isActive', false);
    this.$emit('targetAssignId', (this.targetAssignId = 0));
    this.$emit('TargetNameUpdate', (this.isTargetName = false));
    this.activeTab = 'targetName';
    this.disableSaveBtn = false;
  }
}

let days = [
    {id: 'sun', name: 'Sunday'},
    {id: 'mon', name: 'Monday'},
    {id: 'tue', name: 'TuesDay'},
    {id: 'wed', name: 'Wednesday'},
    {id: 'thu', name: 'Thursday'},
    {id: 'fri', name: 'Friday'},
    {id: 'sat', name: 'Saturday'}
];
export default days;

































































































































































































































































































































































import { Vue, Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import SingleImageUploader from "@/components/ui/SingleImageUploader.vue";

const flatPicker = require('vue-flatpickr-component');
import 'flatpickr/dist/flatpickr.css';
import { Upload, Button, Tooltip, Tabs, TabPane, Popover } from 'element-ui';
import CentralTransportLazydropdown from "@/components/lazyDropdown/CentralTransportLazydropdown.vue";
import UploadImagesModal from "@/views/user/ta/UploadImagesModal.vue";
import DriverLazydropdown from "@/components/lazyDropdown/DriverLazydropdown.vue";
import NumberPlateLazydropdown from "@/components/lazyDropdown/NumberPlateLazydropdown.vue";
import CourierBranchLazydropdown from "@/components/lazyDropdown/CourierBranchLazydropdown.vue";
import CourierLazydropdown from "@/components/lazyDropdown/CourierLazydropdown.vue";


@Component({
  components: {
    UploadImagesModal,
    flatPicker,
    SingleImageUploader,
    ElUpload: Upload,
    [Button.name]: Button,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Popover.name]: Popover,
    CentralTransportLazydropdown,
    DriverLazydropdown,
    NumberPlateLazydropdown,
    CourierLazydropdown,
    CourierBranchLazydropdown,
  }
})
export default class CreateTransportationInfoForm extends Vue {
  @Prop()
  showTransportationInfo!: boolean;

  @Prop()
  isUpdate!: boolean;

  @Prop()
  currentInvoiceId!: string;

  private form = {
    id: '',
    central_transport_id: '',
    driver_id: '',
    number_plate_id: '',
    courier_id: '',
    courier_branch_id: '',
    dispatch_time: '',
    dispatch_date: '',
    cost: '',
    phone: '',
    attachments: []
  };

  public fileList = [];
  private disableBtn: boolean = false;
  private loading: boolean = false;
  private isCourierUpdatedOnce: boolean = false;
  private activeName: string = 'companyProvided';
  private initialStageCourierId: number | string = 0;

  @Watch('form.courier_id')
  updateCourierBranch(newValue: any) {
    if(this.isCourierUpdatedOnce) {
      this.form.courier_branch_id = '';
    }
    if (!this.form.courier_id || this.form.courier_id != this.initialStageCourierId) {
      this.form.courier_branch_id = '';
      this.isCourierUpdatedOnce = true;
    }
  }

  formReset(): any {
    this.form.id = '';
    this.form.central_transport_id = '';
    this.form.driver_id = '';
    this.form.number_plate_id = '';
    this.form.courier_id = '';
    this.form.courier_branch_id = '';
    this.form.dispatch_time = '';
    this.form.dispatch_date = '';
    this.form.cost = '';
    this.form.phone = '';
    this.form.attachments = [];
  }

  inputObject() {
    let inputs = new FormData();

    inputs.append('type', this.activeName);
    inputs.append('invoice_id', this.currentInvoiceId);
    inputs.append('central_transport_id', this.activeName !== 'courier' && this.form.central_transport_id ? this.form.central_transport_id : '');
    inputs.append('driver_id', this.activeName !== 'courier' && this.form.driver_id ? this.form.driver_id : '');
    inputs.append('number_plate_id', this.activeName !== 'courier' && this.form.number_plate_id ? this.form.number_plate_id : '')
    inputs.append('courier_id', this.activeName === 'courier' && this.form.courier_id ? this.form.courier_id : '');
    inputs.append('courier_branch_id', this.activeName === 'courier' && this.form.courier_branch_id ? this.form.courier_branch_id : '');
    inputs.append('phone', this.activeName !== 'courier' && this.form.phone ? this.form.phone : '')
    inputs.append('dispatch_time', this.form.dispatch_time ? this.form.dispatch_time : '');
    inputs.append('dispatch_date', this.form.dispatch_date ? this.form.dispatch_date : '');
    inputs.append('cost', this.form.cost ? this.form.cost : '');
    inputs.append('status', 'active');
    this.form.attachments && typeof this.form.attachments != 'string' && this.form.attachments.forEach((value: any,
                                                                                                        index: number) =>
      inputs.append(`attachments[${index}]`, value));
    this.isUpdate ? inputs.append('_method', 'patch') : '';

    return inputs;
  }

  save() {
    this.isUpdate ? this.updateTransportationInfo() : this.createTransportationInfo();
  }

  createTransportationInfo() {
    this.disableBtn = true;
    this.$http.post(this.$api("/transportation-info"), this.inputObject(), {
      headers: {'Content-Type': 'multipart/form-data'}
    })
      .then(response => {
        this.successEvents(response);
      })
      .catch(error => {
        this.errorEvents(error);
      });
  }

  updateTransportationInfo() {
    this.disableBtn = true;
    this.$http.post(this.$api(`/transportation-info/${this.form.id}`), this.inputObject(), {
      headers: {'Content-Type': 'multipart/form-data'}
    })
      .then(response => {
        this.successEvents(response);
      })
      .catch(error => {
        this.errorEvents(error);
      }).finally(() => {
      this.disableBtn = false;
    });
  }

  private errorEvents(error: any) {
    this.formValidator(error);
    this.errorNotification(error);
  }

  private successEvents(response: any) {
    this.successNotification('Created');
    this.isUpdate ? this.afterUpdate(response) : this.afterCreate(response);
    this.close();
    this.formReset();
  }

  private formValidator(error: any) {
    (this.$refs.formValidator as Vue & {
      setErrors: (errors: []) => any;
    }).setErrors(error.response.data.errors);
  }

  private errorNotification(error: any) {
    this.$notify({
      title: 'Error',
      message: error.response.data.errors[Object.keys(error.response.data.errors)[0]][0],
      duration: 5000,
      iconClass: 'ni ni-bell-55',
      type: 'warning'
    });
  }

  private successNotification(action: string) {
    this.$notify({
      title: 'Success',
      message: 'Successfully ' + action,
      duration: 5000,
      iconClass: 'ni ni-bell-55',
      type: 'success'
    });
  }

  afterCreate(response: any) {
    this.$emit('created', response.data.transportationInfo);
  }

  afterUpdate(response: any) {
    this.$emit('updated', response.data.transportationInfo);
  }

  handleChange(File: any, fileList: any) {
    this.form.attachments = fileList.map((x: any) => x.raw);
  }

  close() {
    this.formReset();
    this.$emit('update:showTransportationInfo', false);
    this.$emit('update:isUpdate', false);
    this.$emit('update:currentInvoiceId', '');
  }

  async fetchTransportationInfo() {
    this.loading = true;
    let {data} = await this.$http.get(this.$api(`/transportation-info?invoice_id=${this.currentInvoiceId}`));
    this.form = data.transportationInfo[0];
    this.initialStageCourierId = this.form.courier_id;
    this.activeName = data.transportationInfo[0].type;
    this.loading = false;
  }

  mounted() {
    if (this.isUpdate) {
      this.fetchTransportationInfo();
    }
  }

}

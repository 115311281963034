




















































































































import {Component, Mixins, Vue, Watch} from "vue-property-decorator";
import {Popover, Switch, Select, Option, Table, TableColumn} from "element-ui";
import MultiSelectRow from '@/mixins/MultiSelectRow';
import PaginationComponentMixin from "@/mixins/PaginationComponent";
import RoleLazydropdown from "@/components/lazyDropdown/RoleLazydropdown.vue";
import TerritoryLazydropdown from "@/components/lazyDropdown/TerritoryLazydropdown.vue";

@Component({
  components: {
    [Switch.name]: Switch,
    [Popover.name]: Popover,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RoleLazydropdown,
    TerritoryLazydropdown
  }
})
export default class Bulk extends Mixins(PaginationComponentMixin, MultiSelectRow) {
  private selectedTerritory: Array<Number> = [];
  private selectedRole: Array<Number> = [];
  private isActive: boolean = false;
  private multiple: boolean = true;
  private smsBody: string = '';
  private users: any = [];


  bulkSmsDispatchNotification() {
    this.$notify({
      title: 'Success',
      message: "Bulk SMS campaign dispatched",
      duration: 5000,
      iconClass: 'ni ni-bell-55',
      // @ts-ignore
      verticalAlign: 'bottom',
      horizontalAlign: 'right',
      type: 'success'
    });
  }

  submitBulkSMSCampaign() {
    this.isActive = false;
    this.$http
      .post(this.$api(`/bulk-messages`), {
        users: this.selectedRows.map(user => user.id),
        message: this.smsBody
      }).then(response => {
      this.bulkSmsDispatchNotification();
      this.selectedRole = [];
      this.selectedRows = [];
      this.selectedTerritory = [];
      this.smsBody = ''
      this.$nextTick(() => {
        (this.$refs.formValidator as HTMLFormElement).reset();
      })
    })
      .catch(error => {
        (this.$refs.formValidator as Vue & {
          setErrors: (errors: []) => any;
        }).setErrors(error.response.data.errors);
      });
  }

  fetchUser() {
    this.loading = true;
    this.$http.get(this.$api(`/users?role=${this.selectedRole.join()}&t=${this.selectedTerritory.join()}&page=${this.pagination.currentPage}`))
      .then(response => {
        this.users = response.data.users;
        this.setPagination(response.data);
        this.loading = false;
      })
  }

  @Watch('selectedRole')
  updateUserOnRoleChange() {
    this.fetchUser();
    if (this.selectedRole.length == 0) {
      this.selectedTerritory = [];
    }
  }

  @Watch('selectedTerritory')
  updateUserOnTerritoryChange() {
    this.fetchUser();
  }

}































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TerritoryLazydropdown from "@/components/lazyDropdown/TerritoryLazydropdown.vue";
import ProductLazydropdown from "@/components/lazyDropdown/ProductLazydropdown.vue";
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
// @ts-ignore
const flatPicker =require("vue-flatpickr-component");
import "flatpickr/dist/flatpickr.css";
@Component({
    components: {
        TerritoryLazydropdown,
        ProductLazydropdown,
        UserLazydropdown,
        flatPicker
    }
})

export default class SalesGrowthFilter extends Vue{
    @Prop() isActive!: boolean;
    @Prop() range!: any;
    @Prop({
        default: () => ({
            type: 'territory',
            interval: 'daily',
            territories: [],
            fieldForces: [],
            products: [],
            range: ''
        })
    })
    filters!:{
        type: string,
        interval: string,
        range: any,
        territories: any,
        products: any,
        fieldForces: any
    }

    private types: any = [
        {label: 'Territory', value: 'territory'},
        {label: 'Field Force', value: 'field_force'},
        {label: 'Product', value: 'product'}
    ]

    private intervals: any = [
        'daily',
        'weekly',
        'monthly',
        'yearly'
    ];

    @Watch('filters', {immediate: true, deep: true})
    onChangeFilter(newVal: any){
        this.$emit('updateFilter', this.filters)
    }

    closeFilter(){
        this.$emit('update:isActive', false);
    }


    close(){
        this.$emit('update:isActive', false);
        this.$emit('update:filters', {
            type: 'territory',
            interval: 'daily',
            territories: [],
            fieldForces: [],
            products: [],
            range: this.range
        })
    }
}

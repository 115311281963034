
























































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip
} from "element-ui";
import { Invoice } from "@/intefaces/Invoice";

@Component({
  components: {
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  }
})
export default class InvoicePaymentDetailsForm extends Vue {
  @Prop() invoiceDetails!: Invoice;
  @Prop() isShowPaymentDetails!: boolean;
  public details: any = {}
  public loading: boolean = true;

  close() {
    this.$emit('update:isShowPaymentDetails', false);
  }

  async mounted() {
    this.loading = true;
    let {data} = await this.$http.get(this.$api2(`/invoices/${this.invoiceDetails.id}`))
    this.details = data.invoice
    this.loading = false;
  }
}





































































































































































































































































































































































































import {Component, Mixins, Prop} from "vue-property-decorator";
import {Button, Dropdown, DropdownItem, DropdownMenu, Popover, Table, TableColumn, Tag, Tooltip} from "element-ui";
import {Invoice} from "@/intefaces/Invoice";
import TaxonomyMixin from "@/mixins/TaxonomyComponent";
import {Permission as PermissionEnum} from '@/enums/Permission'

@Component({
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
    [Popover.name]: Popover,
    [Button.name]: Button
  }
})
export default class InvoiceDetails extends Mixins(TaxonomyMixin) {
  @Prop() isActive!: boolean;
  @Prop() invoiceDetails!: Invoice;
  @Prop() isDeliver!: boolean;
  @Prop() isRevisitionDetails!: boolean;

  private nextDeliveryStatus: string = '';
  private cancelDeliveryStatus = '';
  private isUpdate: boolean = true;

  get permissionEnum() {
    return PermissionEnum;
  }

  // get totalPayment() {
  //   let calculatedDue = 0;
  //   //@ts-ignore
  //   for (var i = 0; i <= this.invoiceDetails.payments.length; i++) {
  //     //@ts-ignore
  //     calculatedDue += this.invoiceDetails.payments[i].amount;
  //   }
  //   return calculatedDue;
  // }

  updateDeliveryStatus(nextDeliveryStatus: string) {
    this.$http.post(this.$api(`/invoices/${this.invoiceDetails.id}/statuses?for=${this.invoiceDetails.latest_status
      == 'readyToDispatch' ? this.permissionEnum.APPROVE_READY_TO_DISPATCH : this.permissionEnum.APPROVE_INVOICE}`),
      {status: nextDeliveryStatus}).then(response => {
      if (response.status == 200 || response.status == 201) {
        this.updateDeliveryStatusNotification(true);
        this.isUpdate = false;
        this.$emit('update:invoiceDetails', response);
        this.$emit('update:isActive', false);
      } else {
        this.updateDeliveryStatusNotification(false);
      }
    });
  }

  updateDeliveryStatusNotification(status: boolean) {
    this.$notify({
      title: status ? 'Success' : 'Failed',
      message: status ? "Delivery Status Updated" : "Failed to Update",
      duration: 5000,
      iconClass: 'ni ni-bell-55',
      // @ts-ignore
      verticalAlign: 'bottom',
      horizontalAlign: 'right',
      type: status ? 'success' : 'warning'
    });
  }

  close() {
    this.$emit('update:isActive', false);
    this.$emit('update:isRevisitionDetails', false);
  }

  mounted() {
    if (this.invoiceDetails.latest_status === 'accepted') {
      this.nextDeliveryStatus = 'readyToDispatch'
    } else {
      this.nextDeliveryStatus = 'ontheway'
    }
    if (this.invoiceDetails.latest_status === 'onTheWay') {
      this.cancelDeliveryStatus = 'deliveryRejected'
    } else {
      this.cancelDeliveryStatus = 'cancelled'
    }
  }
}

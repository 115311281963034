
































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import {Invoice} from '@/intefaces/Invoice';
import OrgInvoice from '@/intefaces/OrgInvoice';
@Component({
  components: {},
})
export default class ManagePayment extends Vue {
  @Prop() isActive!: boolean;
  @Prop() currentPayment!: OrgInvoice;
  private showApproveModal = false;
  private showRejectModal = false;
  private reason = '';

  close() {
    this.$emit('update:isActive', false);
  }

  approve(row: OrgInvoice): void {
    let data = {
      payment_id: row.id,
    };
    this.$http.post(this.$landLordApi(`/approved-payments`), data).then((response) => {
      this.showApproveModal = false;
      this.close();
      // this.currentPayment.payments[0] = response.data.payment;
      this.$emit('approvedAt', response.data.payment.approved_at);
      this.$notify({
        title: 'Success',
        message: 'Payment Approved',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'success',
      });
    });
  }

  reject(row: OrgInvoice): void {
    let data = {
      payment_id: row.id,
    };
    let rejectionReason = {
      reason: this.reason,
    };
    this.$http.patch(this.$landLordApi(`/approved-payments/${data.payment_id}`), rejectionReason).then((response) => {
      this.showRejectModal = false;
      this.close();
      // this.currentPayment.payments[0] = response.data.payment;
      this.$emit('rejectedAt', response.data.payment.rejected_at);
      this.$notify({
        title: 'Success',
        message: 'Payment Rejected',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'warning',
      });
    });
  }
}









import {Component, Mixins} from "vue-property-decorator";
import LazyDropdown from "../LazyDropdown.vue";
import CreateDepartmentForm from "@/views/department/CreateDepartmentForm.vue";
import DropdownField from "../../mixins/DropdownField";

@Component({
    components: {
        LazyDropdown,
        CreateDepartmentForm
    }
})
export default class OutletLazydropdown extends Mixins(DropdownField) {
}

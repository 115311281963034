import { render, staticRenderFns } from "./CreateTerritoryTypeFrom.vue?vue&type=template&id=3b926ff7&scoped=true&"
import script from "./CreateTerritoryTypeFrom.vue?vue&type=script&lang=ts&"
export * from "./CreateTerritoryTypeFrom.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b926ff7",
  null
  
)

export default component.exports
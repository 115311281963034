





































































































































































































































































































































//@ts-ignore
import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
import Payment from '@/intefaces/Payment';
import CreatePaymentForm from '@/views/payment/CreatePaymentForm.vue';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import GIFModal from '@/components/tour/GIFModal.vue';
import {Invoice, InvoiceDTO} from "@/intefaces/Invoice";
import {Button} from "element-ui";
import InvoiceDetails from "@/views/payment/InvoiceDetails.vue";
import {PROPOSAL_STATUS, ProposedRoutePlanEntity} from "@/intefaces/ProposedRoutePlanModel";
import Swal from "sweetalert2";
import LastDueInvoices from "@/views/payment/LastDueInvoices.vue";
import AsyncImage from "@/components/AsyncImage.vue";
import TaxonomyMixin from "@/mixins/TaxonomyComponent";
import { Permission as PermissionEnum } from '@/enums/Permission'

@Component({
  components: {
    AsyncImage,
    LastDueInvoices, CreatePaymentForm, GIFModal,     [Button.name]: Button,
    InvoiceDetails,
  }
})
export default class InvoicePayments extends Mixins(PaginationComponentMixin, TaxonomyMixin) {

  @Prop()
  private currentInvoice?: InvoiceDTO;
  private tInvoice?: Invoice |null =null ;
  private tInvoiceProducts?: any |null =null ;
  private revision?: any | null = null;

  private entities: Payment[] = [];
  private showConfirmModal: boolean = false;
  private isActive: boolean = false;
  private isDueModalActive: boolean = false;
  private lastDues?: any|null = null;
  private currentEntity!: Payment;
  private form: Payment = {
    advance: false,
    amount: null,
    created_by: 0,
    department_id: null,
    invoices: [],
    method: null,
    paid_at: null,
    showPopOver: false
  };
  loading = false;
  private showGIF: boolean = false;
  private demoTourEntity: string = 'paymentList';
  private GIFs: Array<any> = [
    {
      label: 'Create a payment',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a payment',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a payment',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of payment';
  currency:String= 'BDT';
   mounted() {
     this.fetchEntity();
     this.fetchInvoiceDetails();
     this.fetchLast5Invoices();
     this.fetchDepartmentType();
     var j = localStorage.getItem('settings')
     this.currency= j!==null?JSON.parse(j).currency : 'BDT';
  }

  get permissionEnum () {
    return PermissionEnum;
  }

  @Watch('pagination.currentPage')
  async fetchEntity() {
    this.loading = true;
    let {data} = await
      this.$http.get(this.$api(`/invoices/${this.currentInvoice?.id}/payments?page=${this.pagination.currentPage}&with=payments`));
    this.entities = data.invoicePayments;
    this.setPagination(data);
    this.loading = false;
  }

  async fetchLast5Invoices() {
    this.loading = true;
    let {data} = await this.$http.get(this.$api(`/departments/${this.currentInvoice?.buyer.id}/due-invoices`));
    this.lastDues = data;
    this.loading = false;
  }

  async fetchInvoiceDetails() {
    this.loading = true;
    let {data} = await this.$http.get(this.$api(`/invoices/${this.currentInvoice?.id}/revisions`));
    this.tInvoice = data.revisions[0].invoice;
    this.tInvoiceProducts= data.revisions[0].invoiced_products;
    this.revision = data.revisions[0];

    this.loading = false;
  }
   departmentType:String|null = null;
  async fetchDepartmentType() {
    this.loading = true;
    let {data} = await this.$http.get(this.$api(`/department-types/${this.currentInvoice?.buyer?.department_type_id}`));
    this.departmentType = data?.departmentType?.name;

    this.loading = false;
  }

  onSelectAction(row: any, status: string) {
    if (row != null) {
      this.submitPaymentApproval(status, row.invoice_id, row.payment_id, row?.remark ?? '').then(
        value => {
          this.$notify({
            title: 'Success!',
            message: 'Your operation has been succeed',
          });
          this.updatePayment(value, status);

        }
      ).catch(
        reason => {
          this.$notify({
            title: 'Failed!',
            message: "Payment is already processed",

            type: "warning"
          });
        }
      )

    }


  }


  _updatePayment(updatedPayment: any) {
    this.entities.splice(
      this.entities.findIndex(route => route.id == updatedPayment.id),
      1,
      updatedPayment
    );
  }

  updatePayment(updatedPayment: any, status: string) {
   let objIndex   = this.entities.findIndex(e=>e.id ==updatedPayment.id);
    this.entities[objIndex].status=  status;
  }

  async submitPaymentApproval(status: string, invoiceId ?: number, paymentId ?: number, remark?: string,) {
    let response = await this.$http.post(
      this.$api(`/approved-invoice-payments`), {
        "remarks": remark,
        "status": status,
        "payment_id": paymentId,
        "invoice_id": invoiceId,
      }
    );
    return response.data.approvedPayment;
  }

  entityCreated(department: Payment) {
    this.entities.unshift(department);
  }

  showInvoiceDetails(){
    this.isActive=true;

  }

  showLastDues(){
    this.isDueModalActive=true;

  }
}



































































import {Component, Mixins, Vue, Watch} from "vue-property-decorator";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Table,
  TableColumn,
  Tooltip
} from "element-ui";
import PaginationComponentMixin from "../../mixins/PaginationComponent";
import Batch from "@/intefaces/Batch";
import CreateBatch from "@/views/product/CreateBatch.vue";
import GIFModal from "@/components/tour/GIFModal.vue";
import { Permission as PermissionEnum } from '@/enums/Permission'

@Component({
  components: {
    GIFModal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    CreateBatch
  }
})
export default class batchlist extends Mixins(PaginationComponentMixin) {
  private batches: Batch[] = [];
  private showDeleteModal: boolean = false;
  private currentVariantCategory: any = [];
  private isActive: boolean = false;
  private demoTourEntity: string = 'batch';
  private showGIF: boolean = false;
  private GIFs: Array<any> = [
    {
      label: 'Create a batch',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a batch',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a batch',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of batch';
  private form: { name: string } = {
    name: ''
  }

  get permissionEnum () {
    return PermissionEnum;
  }

  @Watch('pagination.currentPage')
  fetchBatch() {
    this.loading = true;
    this.$http.get(this.$api(`/batches?page=${this.pagination.currentPage}`))
      .then(response => {
        this.batches = response.data.batches;
        this.setPagination(response.data);
        this.loading = false;
      })
  }

  creatProduct(batch: any) {
    this.isActive = false;
    this.batches.unshift(batch);
  }

  created() {
    this.fetchBatch();
  }
}

























































import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';

@Component({
  components: {}
})
export default class LandingPage extends Vue {
  @Prop() clickAddNewProductBtn!: boolean;
  @Prop() clickAddBatchForExistingProductBtn!: boolean;

  onClickAddNewProduct() {
    this.$emit('update:clickAddNewProductBtn', true);
  }

  onClickAddBatchForExistingProduct() {
    this.$emit('update:clickAddBatchForExistingProductBtn', true);
  }
}














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class NotFound extends Vue {
  private is_validateRoute: boolean = false;

  get permissionmenu() {
    return this.$store.getters['Auth/menus']
  }
  redirectHome(){
    this.permissionmenu.forEach((menu: any) => {
      if(Object.values(menu)[0] === true && !this.is_validateRoute) {
        this.is_validateRoute = true;
        this.$router.push({ name: Object.keys(menu)[0] });
      }
    })
  }
}



















































































































































































import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tooltip, Tag } from 'element-ui';
import BaseTable from '@/components/argon-core/BaseTable.vue';
import FieldForce from '@/intefaces/FieldForce';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import GIFModal from '@/components/tour/GIFModal.vue';
import TrackingModal from '@/views/tracking/TrackingModal.vue';
import AssignedOutletsModal from '@/views/tracking/AssignedOutletsModal.vue';
import LastStatusMapView from '@/views/tracking/LastStatusMapView.vue';
import _ from 'lodash';
import AsyncImage from "@/components/AsyncImage.vue";
import FilterDrawerComponent from "@/components/FilterDrawerComponent.vue";
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import TerritoryLazydropdown from "@/components/lazyDropdown/TerritoryLazydropdown.vue";
import { QueryBuilder } from "@/classes/QueryBuilder";
import RoleLazydropdown from "@/components/lazyDropdown/RoleLazydropdown.vue";

const moment = require('moment');

@Component({
  components: {
    AsyncImage,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
    BaseTable,
    GIFModal,
    TrackingModal,
    AssignedOutletsModal,
    LastStatusMapView,
    FilterDrawerComponent,
    UserLazydropdown,
    TerritoryLazydropdown,
    RoleLazydropdown,
  }
})
export default class FieldForceList extends Mixins(PaginationComponentMixin) {
  private fieldforce: any[] = [];
  private fieldForceData!: FieldForce;
  private lastTracking: Array<any> = [];
  private showTracking = false;
  private showStatus = false;
  private showOutlets = false;
  private isActive = false;
  private isNeutral = false;
  private isInactive = false;
  public GoogleMapsLoader: any = null;
  private dateTimestamp: any;
  private currentUserId!: number;
  private demoTourEntity: string = 'fieldForceList';
  private showGIF: boolean = false;
  private isFilterModelActive = false;
  private statuses = [{label: 'Active', value: '1'}, {label: 'Inactive', value: '0'}];
  private queryBuilder = new QueryBuilder();
  private filters: any = {
    name: '',
    code: '',
    phone: '',
    territory_id: '',
    role: '',
    status: '',
  }
  private GIFs: Array<any> = [
    {
      label: 'Create a field force',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a field force',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a field force',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of field force';

  created() {
    this.fetchEntity();
  }

  changeCurrentTracking(lastTracking: any) {
    this.lastTracking = lastTracking;
  }

  @Watch('queryBuilder.watch')
  @Watch('pagination.currentPage')
  fetchEntity(): void {
    this.loading = true;
    //Users API Call
    this.$http.get(this.$api(`/users?can=checkinMobile&with_last_tracking&with_assigned_route_plans&page=${this.pagination.currentPage + '&' + this.queryBuilder.getFilters(false)}`)).then(async response => {
      let users = response.data.users;
      this.fieldforce = [];
      let userVisitInfo: any = [];
      await this.$http.post(this.$api(`/user-visit-info`), {'userIds': _.map(users, 'id')}).then(response => {
        userVisitInfo = response.data.userVisitInfo;
      })
      users.forEach((user: any) => {
        // assigning value in Field force starts
        this.fieldForceData = {
          id: user.id,
          name: user.name,
          todaysRoutes: user.assigned_route_plans[0] ? _.map(user.assigned_route_plans, 'name') : '',
          roles: user.roles[0]?.name ?? '',
          phone: user.phone,
          trackings: user.trackings,
          batteryPercent: user.trackings[0]?.battery_percent ?? '',
          createdAt: user.trackings[0]?.created_at ?? '',
          updatedAt: user.trackings[0]?.updated_at ?? '',
          plannedVisit: userVisitInfo.find((visitInfo: any) => visitInfo.id == user.id).planned_visit,
          unplannedVisit: userVisitInfo.find((visitInfo: any) => visitInfo.id == user.id).unplanned_visit,
          totalAssigned: userVisitInfo.find((visitInfo: any) => visitInfo.id == user.id).total_assigned,
          propicUrl: user.propic_url
        };

        this.fieldforce.push(this.fieldForceData);
        // assigning value in fieldforce ends
      });
      this.setPagination(response.data);
      this.loading = false;
    });
  }

  statusLabel(lastCheckinTime: string): string {
    let status = 'inactiveStatus fa fa-bed';
    var last = moment().diff(moment(lastCheckinTime).format('lll'), 'minute');

    if (last <= 15) {
      status = 'activeStatus fas fa-walking';
      return status;
    }

    if (last > 15 && last <= 30) {
      status = 'neutralStatus fas fa-walking';
      return status;
    }

    if (last > 30) {
      status = 'inactiveStatus fas fa-walking';
      return status;
    }
    return status;
  }

  @Watch('filters.name', {deep: true})
  onChangeNameFiler(newVal: string) {
    this.queryBuilder.addListener('q', newVal);
  }

  @Watch('filters.code', {deep: true})
  onChangeCodeFiler(newVal: string) {
    this.queryBuilder.addListener('code', newVal);
  }

  @Watch('filters.phone', {deep: true})
  onChangePhoneFiler(newVal: string) {
    this.queryBuilder.addListener('phone', newVal);
  }

  @Watch('filters.territory_id', {deep: true})
  onChangeTerritoryFiler(newVal: string) {
    this.queryBuilder.addListener('t', newVal);
  }

  @Watch('filters.role', {deep: true})
  onChangeRoleFiler(newVal: string) {
    this.queryBuilder.addListener('role', newVal);
  }

  @Watch('filters.status', {deep: true})
  onChangeStatusPhoneFiler(newVal: string) {
    this.queryBuilder.addListener('active', newVal);
  }

  resetBtn() {
    this.filters.name = '';
    this.filters.code = '';
    this.filters.phone = '';
    this.filters.territory_id = '';
    this.filters.role = '';
    this.filters.status = ''
  }

  onFilterSubmit() {
    this.isFilterModelActive = false;
  }
}


























































































































































































// @ts-ignore
import { CollapseTransition } from 'vue2-transitions';
import BaseNav from '@/components/argon-core/Navbar/BaseNav.vue';
import Modal from '@/components/argon-core/Modal.vue';
import SearchFocus from './SearchFocus.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import routeList from '../../../../public/routes.json';

const auth = namespace('Auth');
import OrderAproval from '@/views/setting/Miscellaneous/Miscellaneous.vue';
import AsyncImage from "@/components/AsyncImage.vue";

@Component({
  components: {
    AsyncImage,
    // CollapseTransition,
    BaseNav,
    SearchFocus,
    OrderAproval
    // Modal
  }
})
export default class DashboardNavbar extends Vue {
  private activeNotifications: boolean = false;
  private showMenu: boolean = false;
  private searchModalVisible: boolean = false;
  private searchQuery: string = '';
  private searchResults: any[] = [];
  private routes: any[] = routeList;
  private searchResultsVisible: boolean = false;
  private highlightedIndex: number = 0;

  @Prop({required: false, type: String, default: 'Developer'})
  readonly type!: string;

  @auth.Action
  public logout!: (payload: any) => Promise<any>;

  get routeName() {
    const {name}: any = this.$route;
    return this.capitalizeFirstLetter(name);
  }

  get themeColor() {
    return this.$store.getters['Theme/getThemeColor']
  }

  get navbarKey() {
    return this.$store.getters['Auth/getNavbarKey'];
  }

  // performSearch() {
  //   let matches = this.routes.filter((route) => {
  //     const regex = new RegExp(`^${this.searchQuery}`, 'gi');
  //     return route.name.match(regex) || route.abbr.match(regex);
  //   });
  //   this.searchResults = matches;
  //   return this.routes.filter((route) => {
  //     return route.name.match(this.searchQuery);
  //   });
  //   console.log(this.searchQuery);
  // }

  get filteredSearch() {
    return this.routes.filter(route => {
      return route.name.toLowerCase().startsWith(this.searchQuery.toLowerCase());
      // const regex = new RegExp(`^${this.searchQuery}`, 'gi');
      // return route.name.match(regex) || route.description.match(regex);
    });
  }

  gotoRoute(route: any) {
    if (this.$route.path !== route.path) {
      this.$router.push({path: route.path});
    }
    this.searchQuery = route.name;
    // console.log(this.$route.path);
    // console.log(route.path);
  }

  gotoLink() {
    if (this.filteredSearch[this.highlightedIndex]) {
      // window.location = this.filteredSearch[this.highlightedIndex].path;
      // this.searchQuery = this.filteredSearch[this.highlightedIndex].name;
      if (this.$route.path !== this.filteredSearch[this.highlightedIndex].path) {
        this.$router.push({path: this.filteredSearch[this.highlightedIndex].path});
      }
      this.searchQuery = this.filteredSearch[this.highlightedIndex].name;
    }
  }

  focusSearch(e: any) {
    if (e.key === '/') {
      // this.$refs.search.focus()
      (this.$refs.search as any).focus();
    }
  }

  softReset() {
    this.searchResultsVisible = true;
    this.highlightedIndex = 0;
  }

  highlightPrevious() {
    if (this.highlightedIndex > 0) {
      this.highlightedIndex = this.highlightedIndex - 1;
    }
  }

  highlightNext() {
    if (this.highlightedIndex < this.filteredSearch.length - 1) {
      this.highlightedIndex = this.highlightedIndex + 1;
    }
  }

  // mounted() {
  //   axios.get('./routes.json').then((response) => (this.routes = response.data));
  // }

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  toggleNotificationDropDown() {
    this.activeNotifications = !this.activeNotifications;
  }

  closeDropDown() {
    this.activeNotifications = false;
  }

  toggleSidebar() {
    this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
  }

  hideSidebar() {
    this.$sidebar.displaySidebar(false);
  }

  signOut() {
    this.logout({vm: this}).then(response => {
      this.$notify({
        title: 'Success',
        message: 'Logout successful',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'success'
      });
      this.$router.push({name: 'login'});
    });
  }
}

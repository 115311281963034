


















import {Component, Vue} from 'vue-property-decorator';
import PerformanceReport from "@/views/Report/KPI/PerformanceReport.vue";

@Component({
  components: {PerformanceReport}
})
export default class KPIReports extends Vue {

}

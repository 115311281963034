


































































































































































































































import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import { Drawer, Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tooltip } from 'element-ui';
import Survey from '@/intefaces/Survey';
import DateRange from '@/components/DateRange.vue';
import CreateSurveyForm from '@/views/inspection/survey/CreateSurveyForm.vue';
import SurveyQuestionList from '@/views/inspection/survey/SurveyQuestionList.vue';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import AssigneeSurveyForm from '@/views/inspection/survey/AssigneeSurveyForm.vue';
import GIFModal from '@/components/tour/GIFModal.vue';
import SurveyAssigneeListModal from '@/views/inspection/survey/SurveyAssigneeListModal.vue';
import swal from 'sweetalert2';
import { Permission as PermissionEnum } from '@/enums/Permission'
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import { QueryBuilder } from "@/classes/QueryBuilder";

@Component({
  components: {
    UserLazydropdown,
    SurveyAssigneeListModal,
    AssigneeSurveyForm,
    SurveyDetailsForm: SurveyQuestionList,
    CreateSurveyForm,
    GIFModal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Drawer.name]: Drawer,
    DateRange
  }
})
export default class SurveyList extends Mixins(PaginationComponentMixin) {
  private surveys: Survey[] = [];
  private showDeleteModal: boolean = false;
  private showReportModal: boolean = false;
  private reportDateRange: string = '';
  private reportTakenBy: number | null = null;
  private recipient: string = '';
  private downloadingReport: boolean = false;
  private isActive: boolean = false;
  private isAssigneeModel: boolean = false;
  private isActiveAssigneeList: boolean = false;
  public isUpdate: boolean = false;
  private currentEntity!: Survey;
  private isFilterModelActive: boolean = false;
  private selectedFilter: any = '';
  private filter: any = ['archived', 'On going'];
  private form: Survey = {
    name: '',
    expires_at: null,
    code: '',
    timespan: null,
    times: 0,
    start_form: null,
    for: [],
    active: true,
    is_default: true
  };
  private showGIF: boolean = false;
  private demoTourEntity: string = 'inspectionSurvey';
  private GIFs: Array<any> = [
    {
      label: 'Create a survey',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a survey',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a survey',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of survey';
  private queryBuilder: QueryBuilder = new QueryBuilder();

  get permissionEnum() {
    return PermissionEnum;
  }

  entityCreated(survey: Survey) {
    this.resetDefault(survey)
    this.surveys.unshift(survey);
  }

  onEdit(row: Survey) {
    this.isUpdate = true;
    this.currentEntity = row;
    this.form = {
      name: this.currentEntity.name,
      expires_at: this.currentEntity.expires_at,
      code: this.currentEntity.code,
      timespan: this.currentEntity.timespan,
      times: this.currentEntity.times,
      start_form: this.currentEntity.start_form,
      for: this.currentEntity.for,
      active: Boolean(this.currentEntity.active),
      is_default: Boolean(this.currentEntity.is_default),
    };
    this.isActive = true;
  }

  onDetails(row: any) {
    this.$router.push({name: 'survey-questions', params: {surveyId: row.id, currentSurvey: row}});
  }

  onAssignee(row: Survey) {
    this.isAssigneeModel = true;
    this.currentEntity = row;
  }

  onAssigneeDetails(row: Survey) {
    this.isActiveAssigneeList = true;
    this.currentEntity = row;
  }

  @Watch('selectedFilter')
  filterSurvey(newVal: any) {
    this.fetchEntity();
  }

  @Watch('pagination.currentPage')
  async fetchEntity() {
    this.loading = true;
    let url = this.selectedFilter == 'archived' ? `/surveys?page=${this.pagination.currentPage}&filter=trashed` : `/surveys?page=${this.pagination.currentPage}`;
    const {data} = await this.$http.get(this.$api(url));
    this.surveys = data.surveys;
    this.setPagination(data);
    this.loading = false;
  }

  async entityUpdated(survey: Survey) {
    this.resetDefault(survey);
    this.surveys.splice(
      this.surveys.findIndex(value => value.id == survey.id),
      1,
      survey
    );

  }

  resetDefault(survey:Survey){
    if (survey.is_default) {
      this.surveys.map(function (value) {
        value.is_default = false;
        return value;
      })
    }
  }

  deleteEntity(): void {
    this.$http.delete(this.$api(`/surveys/${this.currentEntity.id}`)).then(response => {
      this.showDeleteModal = false;
      this.surveys.splice(
        this.surveys.findIndex(value => value.id == this.currentEntity.id),
        1
      );
      this.$notify({
        title: 'Success',
        message: 'Successfully Deleted',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'success'
      });
    });
  }

  created() {
    this.fetchEntity();
  }

  @Watch('reportTakenBy')
  onChangeTakenBy(value: any) {
    this.queryBuilder.addListener('taken_by', value)
  }

  @Watch('reportDateRange')
  onChangeDateRange(value: any) {
    this.queryBuilder.addListener('range', value)
  }

  async downloadSurveyReport() {
    this.downloadingReport = true;
    try {
      let {data, headers} = await this.$http.post(this.$api(`/surveys/${this.currentEntity.id}/reports`),
        {
          range: this.reportDateRange,
          taken_by: this.reportTakenBy
        },
        {
          responseType:
            'arraybuffer'
        });
      const blob = await new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const link = document.createElement("a");
      link.href = await URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      swal.fire('Error', 'Something went wrong.', 'error');
    } finally {
      this.downloadingReport = false;
      this.showReportModal = false;
    }
  }

  closeBtn() {
    this.isFilterModelActive = false;
  }

  handleClose(done: any) {
    done();
  }

  resetBtn() {
    this.selectedFilter = '';
    this.isFilterModelActive = false;
  }
}





































































































































import {Vue, Component, Prop, Mixins} from 'vue-property-decorator';
import BaseCUModalComponent from '@/mixins/BaseCUModalComponent';
import {AxiosResponse} from 'axios';
import Badge from '@/intefaces/Badge.js';
import SingleImageUploader from "@/components/ui/SingleImageUploader.vue";

const flatPicker = require('vue-flatpickr-component');
import 'flatpickr/dist/flatpickr.css';
import {Upload, Button, Tooltip} from 'element-ui';
import TransportLazydropdown from "@/components/lazyDropdown/TransportLazydropdown.vue";
import UploadImagesModal from "@/views/user/ta/UploadImagesModal.vue";
import moment from 'moment';
import CreateTransportForm from "@/views/user/transport/CreateTransportForm.vue";


@Component({
  components: {
    UploadImagesModal,
    flatPicker,
    SingleImageUploader,
    ElUpload: Upload,
    [Button.name]: Button,
    TransportLazydropdown,
    CreateTransportForm
  }
})
export default class CreateTAForm extends Vue {
  @Prop()
  isActive!: boolean;

  form = {
    travel_date: '',
    reason: '',
    transport_id: '',
    expense: '',
    start_location: '',
    end_location: '',
    start_time: '',
    end_time: '',
    comment: '',
    photos: []
  };

  public fileList = [];
  private disableBtn: boolean = false;
  private loading: boolean = false;

  formReset(): any {
    this.form.reason = '';
    this.form.travel_date = '';
    this.form.transport_id = '';
    this.form.start_location = '';
    this.form.end_location = '';
    this.form.start_time = '';
    this.form.end_time = '';
    this.form.comment = '';
    this.form.photos = [];

    return this.form;
  }

  inputObject() {
    let inputs = new FormData();

    inputs.append('travel_date', this.form.travel_date);
    inputs.append('reason', this.form.reason);
    inputs.append('transport_id', this.form.transport_id);
    inputs.append('start_location', this.form.start_location);
    inputs.append('expense', this.form.expense);
    inputs.append('end_location', this.form.end_location);
    inputs.append('start_time', this.form.start_time);
    inputs.append('end_time', this.form.end_time);
    inputs.append('comment', this.form.comment);
    this.form.photos.forEach((value: any, index: number) => inputs.append(`photos[${index}]`, value));

    return inputs;
  }

  createTA() {
    this.disableBtn = true;
    this.$http.post(this.$api("/travel-allowances"), this.inputObject(), {
      headers: {'Content-Type': 'multipart/form-data'}
    })
      .then(response => {
        this.$notify({
          title: 'Success',
          message: 'Successfully Created',
          duration: 5000,
          iconClass: 'ni ni-bell-55',
          type: 'success'
        });
        this.afterCreate();
        this.close();
        this.formReset();
      })
      .catch(error => {
        this.disableBtn = false;
        (this.$refs.formValidator as Vue & {
          setErrors: (errors: []) => any;
        }).setErrors(error.response.data.errors);
        this.$notify({
          title: 'Error',
          message: error.response.data.errors,
          duration: 5000,
          iconClass: 'ni ni-bell-55',
          type: 'success'
        });
      });
  }

  async afterCreate() {
    this.$emit('created');
  }

  handleChange(File: any, fileList: any) {
    this.form.photos = fileList.map((x: any) => x.raw);
  }

  close() {
    this.$emit('update:isActive', false);
  }

}





























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TerritoryLazydropdown from '@/components/lazyDropdown/TerritoryLazydropdown.vue';
import ProductLazydropdown from '@/components/lazyDropdown/ProductLazydropdown.vue';
import UserLazydropdown from '@/components/lazyDropdown/UserLazydropdown.vue';
// @ts-ignore
const flatPicker = require('vue-flatpickr-component');
import 'flatpickr/dist/flatpickr.css';
@Component({
  components: {
    TerritoryLazydropdown,
    ProductLazydropdown,
    UserLazydropdown,
    flatPicker
  }
})
export default class ProductRevenueFilter extends Vue {
  @Prop() isActivePr!: boolean;
  @Prop() rangePR!: any;
  @Prop({
    default: () => ({
      unit_type: 'amount',
      order: 'fast_moving',
      territories: [],
      territory_id: '',
      rangePR: '',
      selectedDates: ''
    })
  })
  filtersProductRevenue!: {
    unit_type: string;
    order: string;
    territories: any;
    territory_id: any;
    rangePR: any;
    selectedDates: any;
  };

  private orders: any = [
    { label: 'Fast Moving', value: 'fast_moving' },
    { label: 'Slow Moving', value: 'slow_moving' }
  ];

  private unit_types: any = ['amount', 'qty'];

  @Watch('filtersProductRevenue', { immediate: true, deep: true })
  onChangeFilterPR(newVal: any) {
    this.$emit('updateFilterPR', this.filtersProductRevenue);
  }

  closeFilter() {
    this.$emit('update:isActivePr', false);
  }

  close() {
    this.$emit('update:isActivePr', false);
  }
}







































































































import {Component, Mixins, Vue, Watch} from 'vue-property-decorator';
import QuestionForm from '@/views/inspection/survey/QuestionForm.vue';
import GIFModal from '@/components/tour/GIFModal.vue';
import {Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip, Button} from 'element-ui';
import Question from '@/intefaces/Question';
import Asset from '@/intefaces/Asset';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import { Permission as PermissionEnum } from '@/enums/Permission'

@Component({
  components: {
    GIFModal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Button.name]: Button,
    QuestionForm
  }
})
export default class QuestionList extends Mixins(PaginationComponentMixin) {
  private isActive = false;
  private isUpdate = false;
  private currentQuestion: any = [];
  private questions: any = [];
  private showDeleteModal = false;
  private disableBtn = false;
  private form = {
    title: '',
    type: '',
    options: []
  };
  private showGIF: boolean = false;
  private demoTourEntity: string = 'inspectionQuestions';
  private GIFs: Array<any> = [
    {
      label: 'Create a question',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a question',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a question',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of question';

  get permissionEnum () {
    return PermissionEnum;
  }

  createQuestion(question: Question) {
    this.isActive = false;
    this.questions.unshift(question);
  }

  updateQuestion(question: Question) {
    this.isUpdate = false;
    this.questions.splice(
      this.questions.findIndex((questionItem: any) => questionItem.id == question.id),
      1,
      question
    );
    this.isActive = false;
  }

  onEdit(row: Question) {
    this.isUpdate = true;
    this.currentQuestion = row;
    this.form = {
      title: this.currentQuestion.title,
      type: this.currentQuestion.type,
      options: this.currentQuestion.options
    };
    this.isActive = true;
  }

  deleteQuestion() {
    this.disableBtn = true;
    this.$http
      .delete(this.$api(`/questions/${this.currentQuestion.id}`))
      .then(response => {
        this.disableBtn = false;
        this.showDeleteModal = false;
        this.questions.splice(
          this.questions.findIndex((question: any) => question.id == this.currentQuestion.id),
          1
        );
        this.$notify({
          title: 'Success',
          message: 'Successfully Deleted',
          duration: 5000,
          iconClass: 'ni ni-bell-55',
          type: 'success'
        });
      })
      .catch(error => {
        this.disableBtn = false;
        (this.$refs.formValidator as Vue & {
          setErrors: (errors: []) => any;
        }).setErrors(error.response.data.errors);
      });
  }

  @Watch('pagination.currentPage')
  fetchQuestion() {
    this.loading = true;
    this.$http.get(this.$api(`/questions?page=${this.pagination.currentPage}`)).then(response => {
      this.questions = response.data.questions;
      this.setPagination(response.data);
      this.loading = false;
    });
  }

  created() {
    this.fetchQuestion();
  }
}

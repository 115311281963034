




















































import { Component, Vue, Watch } from 'vue-property-decorator';
import DateRange from "@/components/DateRange.vue";
import TerritoryLazydropdown from "@/components/lazyDropdown/TerritoryLazydropdown.vue";
import { QueryBuilder } from "@/classes/QueryBuilder";
import { Permission as PermissionEnum } from '@/enums/Permission'
import swal from "sweetalert2";

@Component({
  components: {
    TerritoryLazydropdown,
    DateRange
  }
})
export default class VisitReport extends Vue {
  private visitReports: any[] = [
    {name: 'Visit Target Report', tooltip: 'Download Visit Target Report.'},
    {name: 'Tracking Report', tooltip: 'Download Tracking Report.'}
  ];
  public downloading: boolean = false;
  private showDownloadModal: boolean = false;
  public currentReport: { name: string } = {name: ''};
  public range: string = ''
  public territory_id: number | null = null;
  public queryBuilder: QueryBuilder = new QueryBuilder();
  public recipient: string | null = null;

  get permissionEnum() {
    return PermissionEnum;
  }

  todaysDate() {
    var currentDate = new Date()
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, '/');
    return currentDate;
  }

  async onClickDownload() {
    this.downloading = true;
    try {
      await this.$http.get(this.$api('/visit-target-report' + this.queryBuilder.getFilters(true)));
      swal.fire(
        "Report Sent",
        "You will receive the report within a few minutes.",
        "success"
      );
    } catch (e) {
      this.$notify({title: 'Something wants wrong!', type: 'warning', message: 'Unable to download reports.',})
    } finally {
      this.downloading = false;
      this.showDownloadModal = false;
    }
  }

  @Watch('recipient')
  onChangeRecipient(value: number) {
    this.queryBuilder.addListener('recipient', value);
  }

  @Watch('territory_id')
  onChangeTerritoryId(value: number) {
    this.queryBuilder.addListener('territory_id', value);
  }

  @Watch('range')
  onChangeRange(value: number) {
    this.queryBuilder.addListener('range', value);
  }

  close() {
    this.showDownloadModal = false;
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('modal',{attrs:{"show":_vm.newAnnouncement},on:{"close":function($event){return _vm.$emit('closed')}}},[_c('template',{slot:"header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel"}},[_vm._v(_vm._s(_vm.isUpdate ? 'Update' : 'Add New ')+" Announcement")])]),_c('div',[_c('base-input',{attrs:{"label":"Title","name":"title","placeholder":"e.g. Eid Vacation"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('base-input',{attrs:{"label":"Description","name":"body"}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.body),expression:"form.body"}],staticClass:"form-control",attrs:{"id":"body","rows":"3","placeholder":"e.g. Hello. We would like to announce..."},domProps:{"value":(_vm.form.body)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "body", $event.target.value)}}})]),_c('base-input',{attrs:{"label":"Type","name":"type"}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.type),expression:"form.type"}],staticClass:"form-control",attrs:{"id":"announcementtype"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"selected":"","disabled":"","value":""}},[_vm._v("Select Type")]),_c('option',{attrs:{"value":"event"}},[_vm._v("Event")]),_c('option',{attrs:{"value":"holiday"}},[_vm._v("Holiday")]),_c('option',{attrs:{"value":"promotion"}},[_vm._v("Promotion")]),_c('option',{attrs:{"value":"others"}},[_vm._v("Others")])])]),_c('base-input',{attrs:{"label":"Start From","name":"starts_at","prepend-icon":"fas fa-calendar"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var focus = ref.focus;
var blur = ref.blur;
return _c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{ allowInput: true }},on:{"on-open":focus,"on-close":blur},model:{value:(_vm.form.starts_at),callback:function ($$v) {_vm.$set(_vm.form, "starts_at", $$v)},expression:"form.starts_at"}})}}],null,true)}),_c('base-input',{attrs:{"label":"Ends On","name":"ends_at","prepend-icon":"fas fa-calendar"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var focus = ref.focus;
var blur = ref.blur;
return _c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{ allowInput: true }},on:{"on-open":focus,"on-close":blur},model:{value:(_vm.form.ends_at),callback:function ($$v) {_vm.$set(_vm.form, "ends_at", $$v)},expression:"form.ends_at"}})}}],null,true)})],1),_c('template',{slot:"footer"},[_vm._t("footer",[_c('base-button',{attrs:{"type":"secondary"},on:{"click":function($event){return _vm.$emit('closed')}}},[_vm._v("Close")]),_c('base-button',{attrs:{"type":"success","disabled":_vm.disableBtn},on:{"click":function($event){return handleSubmit(_vm.save)}}},[_vm._v("Save")])],{"handleSubmit":handleSubmit})],2)],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }
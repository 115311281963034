






































import { Component, Vue, Watch } from 'vue-property-decorator';
import { QueryBuilder } from '@/classes/QueryBuilder';
import DateRangeForReport from '@/components/DateRangeForReports.vue';
import { Permission as PermissionEnum } from '@/enums/Permission';
import { Tag } from 'element-ui';
import swal from 'sweetalert2';

const flatPicker = require('vue-flatpickr-component');
@Component({
  components: {
    flatPicker,
    DateRangeForReport,
    [Tag.name]: Tag
  }
})
export default class StockReport extends Vue {
  public downloading: boolean = false;
  public loading: boolean = false;
  private showModal: boolean = false;
  public queryBuilder: QueryBuilder = new QueryBuilder();
  public showDownloadModal: boolean = false;
  public recipient: string = '';
  public filters: any = {
    range: ''
  };
  async downloadActiveUserReport(status: string) {
    this.loading = true;
    try {
      let { data, headers } = await this.$http.get(
        this.$api('/active-user-report?' + this.queryBuilder.getFilters() + '&' + 'recipient=' + this.recipient)
      );
      await swal.fire('Thanks for entering your mail', 'You will receive the report within a few minutes.', 'success');
    } catch (e) {
      this.$notify({ title: 'Something wants wrong!', type: 'warning', message: 'Unable to download reports.' });
    } finally {
      this.loading = false;
      this.showDownloadModal = false;
      this.recipient = '';
      this.filters.range = '';
    }
  }

  @Watch('filters.range')
  onChangeRange(value: number) {
    this.queryBuilder.addListener('range', value);
  }

  close() {
    this.showModal = false;
  }

  get permissionEnum() {
    return PermissionEnum;
  }
}

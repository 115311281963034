















































































































































































































































































































































import {Vue, Component, Mixins, Watch} from 'vue-property-decorator';
import CreateTAForm from '@/views/user/ta/CreateTAForm.vue';
import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tooltip, Tag} from 'element-ui';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import GIFModal from '@/components/tour/GIFModal.vue';
import ImagesModal from "@/views/user/ta/ImagesModal.vue";
import AsyncImage from "@/components/AsyncImage.vue";
import { Permission as PermissionEnum } from '@/enums/Permission'
import FilterDrawerComponent from "@/components/FilterDrawerComponent.vue";
import {QueryBuilder} from "@/classes/QueryBuilder";
import TerritoryLazydropdown from "@/components/lazyDropdown/TerritoryLazydropdown.vue";
import DateRange from "@/components/DateRange.vue";
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import MultiSelectRow from "@/mixins/MultiSelectRow";
import _ from 'lodash'


const flatPicker = require("vue-flatpickr-component");

@Component({
  components: {
    UserLazydropdown,
    TerritoryLazydropdown,
    AsyncImage,
    ImagesModal,
    CreateTAForm,
    GIFModal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
    FilterDrawerComponent,
    flatPicker,
    DateRange
  }
})
export default class TAlist extends Mixins(PaginationComponentMixin, MultiSelectRow) {
  private travelAllowances: any = [];
  private isActive = false;
  private showGIF: boolean = false;
  private showImage: boolean = false;
  private demoTourEntity: string = 'TA';
  private currentImages!: Array<any>;
  private queryBuilder: QueryBuilder = new QueryBuilder();
  private isFilterModelActive: boolean = false;
  private dialogVisible: boolean = false;
  private filters: any = {
    submissionDateRange: '',
    taDateRange: '',
    name: '',
    code: '',
    status: '',
    territoryId: '',
    minimumAmount: '',
    maximumAmount: ''
  }

  private bulkForm = {
    remarks: '',
  }

  private form = {
    remarks: '',
    status: ''
  };
  private GIFs: Array<any> = [
    {
      label: 'Create a Travel Allowance',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a Travel Allowance',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a Travel Allowance',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of Travel Allowance';

  get permissionEnum () {
    return PermissionEnum;
  }

  UpdateStatusForTA(row: any) {
    this.$http.post(this.$api(`/travel-allowances/${row.id}/status`), {
      status_remark: this.form.remarks,
      status: this.form.status
    }).then(response => {
        this.$notify({
          title: 'Success',
          message: 'Successfully Changed',
          duration: 5000,
          iconClass: 'ni ni-bell-55',
          type: 'success'
        });
        this.fetchTAs();
      }
    ).catch(error => {
      this.$notify({
        title: 'Error',
        message: 'Already modified',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'warning'
      });
    })
  }


  get Currency() {
    return this.$store.getters['Settings/getCurrency']
  }

  get amountRange() {
    if (this.filters.minimumAmount && this.filters.maximumAmount &&
      (this.filters.minimumAmount <= this.filters.maximumAmount)) {
      return `${this.filters.minimumAmount},${this.filters.maximumAmount}`
    }
    return '';
  }

  created() {
    this.fetchTAs();
  }

  entityCreated() {
    this.fetchTAs();
  }

  @Watch('filters.submissionDateRange', {deep: true})
  onChangeSubmissionDateRange(value: string) {
    this.queryBuilder.addListener('range', value);
  }

  @Watch('filters.taDateRange', {deep: true})
  onChangeTaDateRange(value: string) {
    this.queryBuilder.addListener('travel_date_range', value);

  }

  @Watch('filters.name', {deep: true})
  onChangeNameFilter(value: string) {
    this.queryBuilder.addListener('created_by', value);
  }

  @Watch('filters.code', {deep: true})
  onChangeCodeFilter(value: string) {
    this.queryBuilder.addListener('created_by', value);
  }

  @Watch('filters.status', {deep: true})
  onChangeStatusFilter(value: string) {
    this.queryBuilder.addListener('status', value);
  }


  @Watch('filters.territoryId', {deep: true})
  onChangeTerritoryFilter(value: number) {
    this.queryBuilder.addListener('t', value);
  }

  @Watch('amountRange')
  OnChangeMinimumAmountFilter(newValue: any) {
      this.queryBuilder.addListener('expense_between', this.amountRange);
  }


 //  @Watch('filters.maximumAmount', {deep: true})
 // OnChangeMaximumAmountFilter(newValue: any) {
 //      this.queryBuilder.addListener('expense_between', this.amountRange);
 //  }

  @Watch('queryBuilder.watch')
  @Watch('pagination.currentPage')
  fetchTAs(): void {
    this.loading = true;
    this.$http.get(this.$api(`/travel-allowances?web&page=${this.pagination.currentPage}&${this.queryBuilder.getFilters()}`)).then(response => {
      this.travelAllowances = response.data.travelAllowances;
      this.setPagination(response.data);
      this.loading = false;
    });
  }

  closeBtn() {
    this.isFilterModelActive = false;
  }

  handleClose(done: any) {
    done();
  }

  bulkActionDispatchNotification() {
    this.$notify({
      title: 'Success',
      message: "Bulk Actions Applied",
      duration: 5000,
      iconClass: 'ni ni-bell-55',
      // @ts-ignore
      verticalAlign: 'bottom',
      horizontalAlign: 'right',
      type: 'success'
    });
  }

  submitBulkAction(status: string) {
    this.loading = true;
    this.dialogVisible = false;
    this.$http
      .post(this.$api(`/bulk-travel-allowances-status?${this.queryBuilder.getFilters()}`), this.allItemSelected ? {
        id: '',
        status_action: status,
        status_bulk_remark: this.bulkForm.remarks
      } : {
        id: this.selectedRows.map(ta => ta.id),
        status_action: status,
        status_bulk_remark: this.bulkForm.remarks
      }).then(response => {
      this.bulkActionDispatchNotification();
      this.selectedRows = [];
      this.bulkForm.remarks = '';
      this.loading = false;
      this.fetchTAs()
    })
      .catch(error => {
        (this.$refs.formValidator as Vue & {
          setErrors: (errors: []) => any;
        }).setErrors(error.response.data.errors);
        this.loading = false;
      });
  }

  textStyle(status: string) {
    if (status === 'rejected') {
      return 'text-danger'
    } else if (status === 'accepted') {
      return 'text-accepted'
    } else {
      return 'text-pending'
    }
  }


  resetBtn() {
    this.filters.submissionDateRange = '';
    this.filters.taDateRange = '';
    this.filters.name = '';
    this.filters.code = '';
    this.filters.status = '';
    this.filters.territoryId = '';
    this.filters.minimumAmount = '';
    this.filters.maximumAmount = ''

    if(this.$refs.formValidator){
      (this.$refs.formValidator as Vue & {
        reset: Function
      }).reset();
    }

  }

}






































//@ts-ignore
import { Vue, Component } from 'vue-property-decorator';
import RouteBreadCrumb from '@/components/argon-core/Breadcrumb/RouteBreadcrumb.vue';

import AttendanceRoutes from '@/router/client-routes/AttendanceRoutes';
import { RouteConfig } from 'vue-router';
@Component({
  components: { RouteBreadCrumb }
})
export default class Attendance extends Vue {
  activeTab: number | null = 0;
  showModal: boolean = false;
  formErrors: any = {};
  formInputs: any = {};

  get routes(): RouteConfig {
    return AttendanceRoutes;
  }

  created(): void {
    if (this.routes.children === undefined) return;
    this.routes.children.forEach((route, index) => {
      if (this.$route.name === route.name) {
        this.activeTab = index;
      }
    });
  }

  setCurrentTab(tab = null) {
    if (tab === null) {
      this.activeTab = 0;
    } else {
      this.activeTab = tab;
    }
  }
}





























import {Component, Prop, Vue} from "vue-property-decorator";
@Component({
    name: 'CreateRole'
})
export default class ViewUserAcl extends Vue{
    @Prop() showUserAclList!: boolean;
    @Prop() currentUser!: any;

    close(){
        this.$emit('update:showUserAclList', false);
    }
}

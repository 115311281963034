












































































































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import ProductDetailsModal from '@/views/product/productModalComponent/ProductDetailsModal.vue';
import ProductImage from '@/components/products/ProductImage.vue';
import AddProduct from '@/views/product/AddProduct.vue';
import ViewProductPrice from "@/views/product/productModalComponent/ViewProductPrice.vue";
import {Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip, Tag} from 'element-ui';
import Product from '@/intefaces/Product';
import ProductFilterDrawer from '@/views/product/ProductFilterDrawer.vue';
import GIFModal from '@/components/tour/GIFModal.vue';
import UpdateProductModal from "@/views/product/productModalComponent/UpdateProductModal.vue";
import UpdateBatch from "@/views/product/productModalComponent/UpdateBatch.vue";
import ViewProductVariant from "@/views/product/productModalComponent/ViewProductVariant.vue";
import {Permission as PermissionEnum} from '@/enums/Permission'

@Component({
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
    ProductDetailsModal,
    ProductImage,
    AddProduct,
    ProductFilterDrawer,
    GIFModal,
    ViewProductPrice,
    UpdateProductModal,
    UpdateBatch,
    ViewProductVariant
  }
})
export default class ProductList extends Vue {
  private products: Product[] = [];
  private isUpdate = false;
  private isActive = false;
  private isActiveProductDetails = false;
  private isActiveBatch: boolean = false;
  private showDeleteModal = false;
  private currentProduct: any = [];
  private loading = false;
  private isFilterModelActive: boolean = false;
  private isActivePrices: boolean = false;
  private showDeleteModalBatch: boolean = false;
  private isActiveVariant: boolean = false;
  private isUpdateVariant: boolean = false;
  private product: any = [];
  private showGIF: boolean = false;
  private form: any = {
    name: '',
    productBrandId: '',
    productCategoryId: '',
    description: '',
    usp: '',
    sku: ''
  };

  private batchForm: any = {
    batch_id: '',
    qty: 0,
    mrp: 0,
    mfg_date: '',
    exp_date: '',
    image: '',
    is_free: false,
  }

  private variant: any = {
    variantValues: [],
    variantCategories: [],
    prices: [],
    priceableId: [],
    priceableType: [],
    departmentId: [],
    variantNames: [],
    variantId: [],
    priceId: []
  };
  private name: string = '';
  private pagination = {
    currentPage: 1,
    totalEntity: 0,
    entityPerPage: 0
  };
  private demoTourEntity: string = 'product';
  private GIFs: Array<any> = [
    {
      label: 'Create a product',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a product',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a product',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of product';

  get permissionEnum () {
    return PermissionEnum;
  }

  onAddProduct() {
    this.$router.push({name: 'create'});
  }

  onEdit(row: any) {
    this.currentProduct = row;
    this.form = {
      name: this.currentProduct.name,
      is_free: Boolean(this.currentProduct.is_free),
      productBrandId: this.currentProduct.product_brand_id,
      productCategoryId: this.currentProduct.product_category_id,
      description: this.currentProduct.description != 'null' ? this.currentProduct.description : '',
      usp: this.currentProduct.usp != 'null' ? this.currentProduct.usp : '',
      sku: this.currentProduct.sku,
    };
    this.isActive = true;
  }

  onEditBatch(row: any) {
    this.currentProduct = row;
    this.batchForm = {
      batch_id: this.currentProduct.batch_id,
      qty: this.currentProduct.qty,
      mrp: this.currentProduct.mrp,
      mfg_date: this.currentProduct?.mfg_date ?? '',
      exp_date: this.currentProduct?.exp_date ?? '',
      purchase_amount: this.currentProduct.purchase_amount,
      active: Boolean(this.currentProduct.active),
    };
    this.isActiveBatch = true;
  }

  updateProduct(updatedProduct: any) {
    this.isUpdate = false;
    this.products.splice(
      this.products.findIndex(product => product.id == updatedProduct.id),
      1,
      updatedProduct
    );
    this.isActive = false;
  }

  updateBatch(updateBatch: any) {
    this.products.splice(
      this.products.findIndex(product => product.id == updateBatch.id),
      1,
      updateBatch
    );
    this.isActiveBatch = false;
  }

  deleteProduct() {
    this.$http.delete(this.$api(`/base-products/${this.currentProduct.id}`)).then(response => {
      this.showDeleteModal = false;
      this.products.splice(
        this.products.findIndex(baseProduct => baseProduct.id == this.currentProduct.id),
        1
      );
      this.$notify({
        title: 'Success',
        message: 'Successfully Deleted',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'success'
      });
    });
  }

  deleteBatch() {
    this.$http.delete(this.$api(`/products/${this.currentProduct.id}`)).then(response => {
      this.$http.get(this.$api(`/base-products/${this.currentProduct.base_product_id}`)).then(response => {
        this.products.splice(
          this.products.findIndex(baseProduct => baseProduct.id == response.data.baseProduct.id),
          1,
          response.data.baseProduct
        );
      })
      this.$notify({
        title: 'Success',
        message: 'Successfully Deleted',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'success'
      });
      this.showDeleteModalBatch = false;
    });
  }
}






































































































import { Component, Prop, Mixins } from 'vue-property-decorator';
import BaseCUModalComponent from '@/mixins/BaseCUModalComponent';
import { AxiosResponse } from 'axios';
import Survey from '@/intefaces/Survey';
const flatPicker = require('vue-flatpickr-component');
import 'flatpickr/dist/flatpickr.css';
import UserLazydropdown from '@/components/lazyDropdown/UserLazydropdown.vue';
import QuestionLazyDropdown from '@/components/lazyDropdown/QuestionLazyDropdown.vue'
import {Timespan} from "@/enums/Timespan";
import {Switch, Tag} from "element-ui";
@Component({
  components: { UserLazydropdown, flatPicker, QuestionLazyDropdown, [Tag.name]: Tag, [Switch.name]: Switch, }
})
export default class CreateSurveyForm extends Mixins(BaseCUModalComponent) {
  @Prop()
  currentEntity!: Survey;

  @Prop({ default: () => ({
      name: '',
      expires_at: null,
      code: '',
      timespan: null,
      times: 0,
      start_from: null,
      for: [],
      active: true,
      is_default: true,
  })
  })
  form!: {
    name: string;
    expires_at: string;
    code: string;
    timespan: string;
    times: number;
    start_form: string;
    for: string;
    active: boolean;
    is_default: boolean;
  };
  private users: any[] = [];
  private questions: any[] = [];
  private surveyTypes = [
    'consumer',
    'outlet',
    ]

  formReset(): any {
    this.form.name = '';
    this.form.expires_at = '';
    this.form.code = '';
    this.form.timespan = '';
    this.form.times = 0;
    this.form.start_form = '';
    this.form.for = '';
    this.users = [];
    return this.form;
  }

  async afterCreate(response: AxiosResponse<any>): Promise<void> {
    if (this.users.length > 0) {
      await this.assignUsers(response.data.survey);
    }

    if(this.questions.length > 0) {
      await this.assignQuestion(response.data.survey)
    }
    this.$emit('created', response.data.survey);
  }

  afterUpdate(response: AxiosResponse<any>): void {
    this.$emit('updated', response.data.survey);
  }

  get createApi(): string {
    return '/surveys';
  }

  get updateApi(): string {
    return `/surveys/${this.currentEntity.id}`;
  }

  get timespan(){
    return Timespan;
  }

  async assignUsers(survey: Survey) {
    for (const value of this.users) {
      await this.$http.post(this.$api(`/surveys/${survey.id}/assignees`), { user_id: value });
    }
  }

  async assignQuestion(survey: Survey) {
    for (const value of this.questions) {
      await this.$http.post(this.$api(`/surveys/${survey.id}/questions`), {question_id: value});
    }
  }
}




















import {Component, Vue} from 'vue-property-decorator';
import ProductSummaryReport from "@/views/Report/ProductBasedReport/ProductSummaryReport.vue";

@Component({
  components: {ProductSummaryReport}
})
export default class ProductBasedReport extends Vue {

}

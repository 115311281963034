































































































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseCUModalComponent from '@/mixins/BaseCUModalComponent';
import { AxiosInstance, AxiosResponse, AxiosStatic } from 'axios';
import LazyDropdown from '@/components/LazyDropdown.vue';
import Payment from '@/intefaces/Payment';
import { PaymentTypes } from '@/enums/PaymentTypes';

const flatPicker = require('vue-flatpickr-component');
import 'flatpickr/dist/flatpickr.css';
import SingleImageUploader from '@/components/ui/SingleImageUploader.vue';
import { MfsVendors } from '@/enums/MfsVendors';
import InvoiceAutocomplete from '@/components/resources-autocomplete/InvoiceAutocomplete.vue';

@Component({
  components: { InvoiceAutocomplete, SingleImageUploader, LazyDropdown, flatPicker }
})
export default class CreatePaymentForm extends Mixins(BaseCUModalComponent) {
  @Prop()
  currentEntity!: any;
  @Prop({
    default: () => ({
      advance: false,
      amount: null,
      department_id: null,
      method: null,
      paid_at: new Date().toISOString(),
      mfs_vendor: null,
      invoices: [],

      ac_no: '',
      ac_holder: '',
      txn_no: '',

      bank: '',
      branch: '',
      cheque_no: '',
      deposit_no: '',
      deposit_slip: '',
      seller_id: null,
    })
  })
  form!: Payment;

  private totalInvoiceAmount = null;
  private invoiceAmount: number = 0;
  private multipleInvoice: boolean = false;
  // Computed properties +++
  get createApi(): string {
    return '/payments';
  }

  get updateApi(): string {
    return `/payments/${this.currentEntity.id}`;
  }

  get paymentTypes() {
    return PaymentTypes;
  }

  get mfsVendors() {
    return MfsVendors;
  }

  // Computed properties ---
  formReset(): any {
    this.form.method = this.paymentTypes.METHOD_CASH;
    this.form.advance = false;
    this.form.amount = null;
    this.form.department_id = null;
    this.form.invoices = [];
    return this.form;
  }

  async afterCreate(response: AxiosResponse<any>): Promise<void> {
    this.$emit('created', response.data.payment);
    if (!this.form.advance) {
      await this.assignPaymentToInvoice(response.data.payment.id);
    }
  }

  private async assignPaymentToInvoice(paymentId: number): Promise<void> {
    let requests: any[] = [];
      requests.push(this.$http.post(this.$api(`/invoices/${this.form.invoices}/payments`), { payment_id: paymentId }));
    await (this.$http as AxiosStatic).all(requests);
  }

  afterUpdate(response: AxiosResponse<any>): void {
    this.$emit('updated', response.data.badge);
  }

  @Watch('form.advance')
  onChangeAdvance(advance: boolean) {
    this.form.amount = null;
  }

  @Watch('form.department_id')
  getSellerIdForAdvancePayment(newVal: number) {
    this.$http.get(this.$api(`/departments/${newVal}`)).then(response => {
      this.form.seller_id = response.data.department.supplier_id;
    });
  }
}

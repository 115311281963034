var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header border-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-inline"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Delivery")]),_c('el-tooltip',{staticClass:"ml-3",attrs:{"content":"List of delivered products for your organization.","placement":"right"}},[_c('i',{staticClass:"fas fa-info-circle",staticStyle:{"color":"grey","font-size":"18px"}})]),_c('el-tooltip',{staticClass:"ml-3",attrs:{"content":"Watch video tutorials","placement":"right"}},[_c('i',{staticClass:"fab fa-youtube",staticStyle:{"color":"grey","font-size":"18px","cursor":"pointer"},on:{"click":function($event){_vm.showGIF = true}}})])],1)])])]),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"table-responsive align-items-center table-flush table-striped",attrs:{"data":_vm.deliveries,"header-row-class-name":"thead-light"}},[_c('el-table-column',{attrs:{"label":_vm.getTaxonomy('order')+' No.',"min-width":"100px","prop":"invoice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(" #"+_vm._s(row.order ? row.order.code : ''))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.getTaxonomy('invoice')+' No.',"min-width":"100px","prop":"invoice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(" #"+_vm._s(row.order.invoice ? row.order.invoice.code : ''))])]}}])}),_c('el-table-column',{attrs:{"label":"Amount","min-width":"100px","prop":"invoice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(" "+_vm._s(row.amount))])]}}])}),_c('el-table-column',{attrs:{"label":"Discount","min-width":"100px","prop":"invoice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(" "+_vm._s(row.discount))])]}}])}),_c('el-table-column',{attrs:{"label":"Deliver At","min-width":"100px","prop":"invoice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.created_at != null)?_c('b',[_vm._v(" "+_vm._s(_vm._f("datetime")(row.created_at,'ll')))]):_c('b',[_vm._v(" - ")])]}}])}),_c('el-table-column',{attrs:{"label":"Details","min-width":"100px","prop":"invoice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('base-button',{attrs:{"outline":"","size":"sm","type":"info"},on:{"click":function($event){return _vm.invoiceDetails(row)}}},[_vm._v("View "+_vm._s(_vm.getTaxonomy('invoice')))])]}}])})],1),(_vm.deliveries && _vm.deliveries.length > 0)?_c('base-pagination',{staticClass:"mt-3",attrs:{"perPage":_vm.pagination.entityPerPage,"total":_vm.pagination.totalEntity,"align":"center","disabled":_vm.loading},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}}):_vm._e(),_c('delivery-details',{attrs:{"invoice-details":_vm.invoiceDetail,"is-active":_vm.isActiveInvoice,"is-deliver":_vm.isDeliver},on:{"update:isActive":function($event){_vm.isActiveInvoice=$event},"update:is-active":function($event){_vm.isActiveInvoice=$event},"update:isDeliver":function($event){_vm.isDeliver=$event},"update:is-deliver":function($event){_vm.isDeliver=$event}}}),_c('GIFModal',{attrs:{"GIFs":_vm.GIFs,"demoTourEntity":_vm.demoTourEntity,"demoTourTitle":_vm.demoTourTitle,"showGIF":_vm.showGIF},on:{"update:showGIF":function($event){_vm.showGIF=$event},"update:show-g-i-f":function($event){_vm.showGIF=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
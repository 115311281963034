























import {Component, Mixins, Prop, Vue} from 'vue-property-decorator';
import Variant from "@/intefaces/VariantCategory";
import BaseCUModalComponentMixin from "@/mixins/BaseCUModalComponent";
import {AxiosResponse} from "axios";

@Component({
    name: 'VariantCategoryForm'
})

export default class VariantCategoryForm extends Mixins(BaseCUModalComponentMixin){
    @Prop({
        default: () => ({
            name: ''
        })
    })
    form !: {name: string}
    @Prop() currentEntity! : Variant;

    formReset(): any {
        this.form.name = '';
        return this.form;
    }

    get createApi(): string
    {
        return '/variant-categories'
    }

    async afterCreate(response: AxiosResponse<any>): Promise<void> {
        this.$emit('created', response.data.variantCategory);
    }

    get updateApi(): string {
        return `/variant-categories/${this.currentEntity.id}`
    }

    afterUpdate(response: AxiosResponse<any>): void {
        this.$emit('updated', response.data.variantCategory);
    }
}










































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseProduct from "@/intefaces/BaseProduct";
import Department from "@/intefaces/Department";
import DepartmentType from "@/intefaces/DepartmentType";
import AsyncImage from "@/components/AsyncImage.vue";

@Component({
  components: {
    AsyncImage
  }
})

export default class ProductDetailsModal extends Vue {
  @Prop() isActive!: boolean;
  @Prop() currentBaseProduct!: BaseProduct;
  private baseProduct: BaseProduct[] = [];
  private departments: Department[] = [];
  private departmentTypes: DepartmentType[] = [];

  async fetchBaseProduct() {
    await this.$http.get(this.$api(`/base-products/${this.currentBaseProduct.id}?with=productBrand,productCategory,products.batch,products.variants,products.prices`))
      .then(response => {
        this.baseProduct = response.data.baseProduct;
      })
  }

  async fetchDepartment() {
    await this.$http.get(this.$api(`/departments`)).then(response => {
      this.departments = response.data.departments;
    })
  }

  async fetchDepartmentType() {
    await this.$http.get(this.$api(`/department-types`)).then(response => {
      this.departmentTypes = response.data.departmentTypes;
    })
  }

  created() {
    this.fetchBaseProduct();
    this.fetchDepartment();
    this.fetchDepartmentType();
  }

  close() {
    this.$emit('update:isActive', false);
  }
}

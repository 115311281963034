

















































































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import DepartmentLazydropdown from "@/components/lazyDropdown/DepartmentLazydropdown.vue";
import ProductBrandLazydropdown from "@/components/lazyDropdown/ProductBrandLazydropdown.vue";
import ProductCategoryLazydropdown from "@/components/lazyDropdown/ProductCategoryLazydropdown.vue";
import VariantCategoryLazydropdown from "@/components/lazyDropdown/VariantCategoryLazydropdown.vue";
import {Drawer} from "element-ui";
import TaxonomyMixin from "@/mixins/TaxonomyComponent";
import BatchLazydropdown from "@/components/lazyDropdown/BatchLazydropdown.vue";

@Component({
  components: {
    [Drawer.name]: Drawer,
    DepartmentLazydropdown,
    ProductBrandLazydropdown,
    ProductCategoryLazydropdown,
    VariantCategoryLazydropdown,
    BatchLazydropdown
  }
})
export default class DepartmentStockFilterDrawer extends Mixins(TaxonomyMixin) {
  @Prop({required: true})
  isFilterModelActive!: boolean;
  @Prop({required: true})
  entityPerPage!: number;
  @Prop({required: true})
  totalEntity!: number;
  @Prop({required: true, default: 1})
  entityCurrentPage!: number;

  private drawer: boolean = false;
  private query: any = [];
  private departmentQuery: any = '';
  private afterQuery: boolean = false;
  private filters: any = {
    buyerId: [],
    brand: [],
    belowProduct: '',
    category: [],
    product: '',
    variantId: [],
    variantValue: '',
    promotion: false,
    batchId: '',

  };

  @Watch('isFilterModelActive')
  changeDrawerValue(newVal: any) {
    if (newVal) {
      this.drawer = true
    }
  }

  @Watch('filters', {immediate: true, deep: true})
  onChangeFilter(newVal: any) {
    this.query = ['page=1'];
    this.afterQuery = true;
    newVal.belowProduct != '' ? this.query.push(`below=${newVal.belowProduct}`) : '';
    newVal.buyerId != '' ? this.query.push(`department_id=${newVal.buyerId}`) : '';
    newVal.brand != '' ? this.query.push(`brand_id=${newVal.brand}`) : '';
    newVal.category != '' ? this.query.push(`category_id=${newVal.category}`) : '';
    newVal.product != '' ? this.query.push(`q=${newVal.product}`) : '';
    newVal.variantId != '' ? this.query.push(`variant_id=${newVal.variantId}`) : '';
    newVal.variantValue != '' ? this.query.push(`variant_value=${newVal.variantValue}`) : '';
    newVal.promotion != '' ? this.query.push(`promotion=${newVal.promotion}`) : '';
    newVal.batchId != '' ? this.query.push(`batch_id=${newVal.batchId}`) : '';
    if (this.entityCurrentPage == 1) {
      this.fetchDepartmentStock();
      return;
    }
    this.$emit('update:entityCurrentPage', 1);
  }

  @Watch('entityCurrentPage')
  onChangePage(val: any, oldVal: any) {
    this.query[0] = `page=${this.entityCurrentPage}`;
    this.fetchDepartmentStock();
  }

  async fetchDepartmentStock() {
    this.$emit('update:loading', true);
    const {data} = await this.$http.get(this.$api(`/stocks${this.query.length > 0 ? '?' + this.query.join('&') : ''}`))
    this.$emit('update', data.stocks);
    this.$emit('update:entityPerPage', data.per_page);
    this.$emit('update:totalEntity', data.total);
    this.$emit('update:entityCurrentPage', data.current_page);
    this.$emit('update:loading', false);
  }

  closeBtn(): void {
    this.drawer = false
    this.$emit('update:isFilterModelActive', false);
  }

  filterReset() {
    this.filters.buyerId = [];
    this.filters.brand = [];
    this.filters.belowProduct = '';
    this.filters.category = [];
    this.filters.product = '';
    this.filters.manager = [];
    this.filters.variantId = [];
    this.filters.variantValue = ''
    this.filters.promotion = false;
    this.filters.batchId = '';
  }

  handleClose(done: any) {
    done();
    this.$emit('update:isFilterModelActive', false);
    this.filterReset();
  }

  resetBtn() {
    this.filterReset();
  }
}

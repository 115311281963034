






































































































































import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator';
import TargetModalData from '@/mixins/TargetModalData';

@Component({
  components: {}
})
export default class RegularTargetComponent extends Mixins(TargetModalData) {}

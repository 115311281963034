









import {Component, Mixins, Prop} from "vue-property-decorator";
import LazyDropdown from "../LazyDropdown.vue";
import DropdownField from "../../mixins/DropdownField";
import BrandForm from '@/components/products/BrandForm.vue';

    @Component({
        components: {
            LazyDropdown,
            BrandForm
        }
    })
    export default class ProductBrandLazydropdown extends Mixins(DropdownField) {
        @Prop() addButton!: boolean;
        @Prop() disabled!: boolean;
    }

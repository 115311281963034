


























































import Modal from '@/components/argon-core/Modal.vue';
// @ts-ignore
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {Component as componentCalendar} from '@fullcalendar/core';
import {Vue, Component, Mixins, Watch} from 'vue-property-decorator';
import CreateBadgeForm from '@/views/department/CreateBadgeForm.vue';
import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tooltip, Tag} from 'element-ui';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import GIFModal from '@/components/tour/GIFModal.vue';

const moment = require('moment');

@Component({
  components: {
    Modal,
    FullCalendar,
    CreateBadgeForm,
    GIFModal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag
  }
})
export default class AttendanceList extends Vue {
  private calendarPlugins: any = [dayGridPlugin, timeGridPlugin, interactionPlugin];
  private defaultView: any = 'dayGridMonth';
  private counter: number = 0;
  private currentYear = moment().format('YYYY');
  private demoTourEntity: string = 'attendance';
  private showGIF: boolean = false;
  private GIFs: Array<any> = [
    {
      label: 'Create an attendance',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update an attendance',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete an attendance',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of attendance';
  private model: any = {
    title: '',
    className: 'bg-default',
    start: '',
    end: ''
  };

  get currentMonthName(): string {
    return moment()
      .subtract(this.counter, 'month')
      .startOf('month')
      .format('MMMM, YYYY');
  }

  calendarApi() {
    return (this.$refs.fullCalendar as any).getApi();
  }

  changeView(viewType: any) {
    this.defaultView = viewType;
    this.calendarApi().changeView(viewType);
  }

  next() {
    this.calendarApi().next();
    this.counter--;
  }

  prev() {
    this.calendarApi().prev();
    this.counter++;
  }

  onDateClick({date}: any) {
    let formattedDate = moment(date).format('YYYY-MM-DD');
    this.model.start = formattedDate;
    this.model.end = formattedDate;

    this.$router.push({name: 'details', params: {currentEntity: formattedDate}});
  }

  onDetails(row: any) {
    this.$router.push({name: 'details', params: {currentEntity: row}});
  }
}

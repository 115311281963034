



















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from "vuex-class";
import ContentFooter from "@/components/layouts/argon/ContentFooter.vue";

const auth = namespace('Auth');
@Component({
  components: {ContentFooter}
})
export default class Welcome extends Vue {

  private loading: boolean = false;
  private domainError: boolean = false;
  fields = {
    domain: ''
  };

  domainErrorNotification() {
    this.$notify({
      title: 'Error',
      message: 'Domain not found',
      type: 'warning',
      position: 'top-left',
      duration: 5000,
      iconClass: 'ni ni-bell-55'
    });
  }

  async onSubmit() {
    await Vue.prototype.$http.get(Vue.prototype.$landLordApi('/sanctum/csrf-cookie', true));
    this.$http.post(Vue.prototype.$landLordApi('/validate-org'), {domain: this.fields.domain}).then(response => {
      if (response.status == 200) {
        window.location.replace(this.$appBaseUrl.replace('app', this.fields.domain));
      } else {
        this.domainErrorNotification();
        this.domainError = true;
      }
    }).catch(error => {
      if (error.response.status === 404) {
        this.domainError = true;
        this.domainErrorNotification();
      }
    })
  }

  goToRegister() {
    this.$router.push("register")
  }
}



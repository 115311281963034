



















































































































































import {Component, Mixins, Prop} from "vue-property-decorator";
import OrderRevision from "@/intefaces/OrderRevision";
import {Table, TableColumn, Tag} from "element-ui";
import InvoiceDetails from "@/views/order/invoice/InvoiceDetails.vue";
import AsyncImage from "@/components/AsyncImage.vue";
import TaxonomyMixin from "@/mixins/TaxonomyComponent";
import AsyncRender from "@/components/AsyncRender.vue";

@Component({
  components: {
    AsyncRender,
    AsyncImage,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tag.name]: Tag,
    InvoiceDetails
  }
})
export default class RevisionList extends Mixins(TaxonomyMixin) {
  @Prop() isViewRevision!: boolean;
  @Prop() currentEntity!: any;
  @Prop() orderRevisions!: OrderRevision;
  @Prop() isRequisition!: boolean;
  @Prop() isInvoiceForRevision!: boolean;
  private isRevisionDetails: boolean = false;
  private currentValue: any[] = [];
  private isInvoiceDetails: boolean = false;

  get getCurrency() {
    return this.$store.getters['Settings/getCurrency']
  }

  close() {
    this.$emit('update:isViewRevision', false);
    this.$emit('update:isRequisition', false);
  }

}

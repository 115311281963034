










































import { Vue, Component, Prop, Mixins } from 'vue-property-decorator';
import AdminCUModalComponent from '@/mixins/AdminCUModalComponent';
import { AxiosResponse } from 'axios';
import Coupon from '@/intefaces/Coupon';

const flatPicker = require('vue-flatpickr-component');
import 'flatpickr/dist/flatpickr.css';

@Component({
  components: { flatPicker },
})
export default class CreateCouponForm extends Mixins(AdminCUModalComponent) {
  @Prop()
  currentEntity!: Coupon;

  @Prop({ default: () => ({ code: '', discount: 0, expires_at: '' }) })
  form!: {
    code: string;
    discount: number;
    expires_at: string;
  };

  formReset(): any {
    this.form.code = '';
    this.form.discount = 0;
    this.form.expires_at = '';
    return this.form;
  }

  async afterCreate(response: AxiosResponse<any>): Promise<void> {
    this.$emit('created', response.data.coupon);
  }

  afterUpdate(response: AxiosResponse<any>): void {
    this.$emit('updated', response.data.coupon);
  }

  get createApi(): string {
    return '/coupons';
  }

  get updateApi(): string {
    return `/coupons/${this.currentEntity.id}`;
  }
}

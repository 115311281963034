



















import {Vue, Component, Prop} from 'vue-property-decorator';
import {Carousel, CarouselItem} from 'element-ui';

@Component({
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem
  }
})
export default class GIFModal extends Vue {

  @Prop()
  GIFs!: any;

  @Prop()
  demoTourEntity!: string;

  @Prop()
  demoTourTitle!: string;

  @Prop()
  showGIF!: boolean;

  close(){
    this.$emit('update:showGIF', false)
  }
}

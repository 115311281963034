




















































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import BatchLazydropdown from "@/components/lazyDropdown/BatchLazydropdown.vue";

const flatPicker = require("vue-flatpickr-component");
import "flatpickr/dist/flatpickr.css";
import SingleImageUploader from "@/components/ui/SingleImageUploader.vue";
import swal from "sweetalert2";
import moment from "moment";

@Component({
  components: {
    BatchLazydropdown,
    flatPicker,
    SingleImageUploader
  }
})

export default class ProductBatchComponent extends Vue {
  @Prop() batchForm!: any;

  @Watch('batchForm.active')
  showWarningAlert(newVal: boolean) {
    if (!newVal) {
      swal.fire({
        title: 'Info!',
        text: 'By this action, this product will be count as inactive in this system.',
        icon: 'info',
        confirmButtonText: 'Close'
      });
    }
  }

  get getConfig(): any {
    return {
      allowInput: true,
      minDate: this.batchForm.mfg_date
    }
  }
}

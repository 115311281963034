













































import { Component, Vue } from 'vue-property-decorator';
import { Permission as PermissionEnum } from '@/enums/Permission';
import { Button } from 'element-ui';

@Component({
  components: {
    [Button.name]: Button
  }
})
export default class StockReport extends Vue {
  private reports: any[] = [
    {
      name: 'The Stock Report',
      tooltip: 'The Stock Report.',
      permission: PermissionEnum.PRODUCT_BASED_REPORT,
      path: 'the-stock-report'
    }
  ];

  todaysDate() {
    var currentDate = new Date()
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, '/');
    return currentDate;
  }
}










import {Prop, Vue} from 'vue-property-decorator';
import Component from 'vue-class-component';
import statuses from "@/data/OrderStatus";

@Component({})
export default class RequisitionStatusAutocomplete extends Vue {
    @Prop({default: ''})
    value!: null | string;
    @Prop({default: 'status'})
    name!: null | string;
    @Prop({default: 'Select a status'})
    placeholder!: string;
    @Prop({default: null})
    label!: string | null;
    statuses = statuses;
}





















































































































































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import RequisitionForm from '@/views/order/requisition/RequisitionForm.vue';
import RevisionList from '@/views/order/RevisionList.vue';
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Table,
  TableColumn,
  Tag,
  Tooltip
} from 'element-ui';
import InvoiceDetails from '@/views/order/invoice/InvoiceDetails.vue';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import InvoiceFilters from '@/views/order/invoice/InvoiceFilters.vue';
import { Invoice } from '@/intefaces/Invoice';
import InvoiceAmountDetailsForm from '@/views/order/invoice/InvoiceAmountDetailsForm.vue';
import InvoicePaymentDetailsForm from '@/views/order/invoice/InvoicePaymentDetailsForm.vue';
import InvoiceFilterDrawer from '@/views/order/invoice/InoviceFilterDrawer.vue';
import OrderRevision from '@/intefaces/OrderRevision';
import GIFModal from '@/components/tour/GIFModal.vue';
import MultiSelectRow from '@/mixins/MultiSelectRow';
import printJS from 'print-js';
import FilterDrawer from '@/components/FilterDrawer.vue';
import { ElTable } from 'element-ui/types/table';
import InvoiceStatusDetails from "@/views/order/invoice/InvoiceStatusDetails.vue";
import TaxonomyMixin from "@/mixins/TaxonomyComponent";
import { Permission as PermissionEnum } from '@/enums/Permission';
import BaseInvoiceDetails from '@/views/order/invoice/BaseInvoiceDetails.vue';
import InvoiceRevision from "@/intefaces/InvoiceRevision";
import HasCursorPaginationMixin from "@/mixins/HasCursorPagination";
import CursorPagination from "@/components/CursorPagination.vue";
import moment from "moment";
import { QueryBuilder } from "@/classes/QueryBuilder";
import swal from "sweetalert2";

@Component({
  components: {
    CursorPagination,
    FilterDrawer,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [ButtonGroup.name]: ButtonGroup,
    [Button.name]: Button,
    [Tag.name]: Tag,
    GIFModal,
    InvoiceDetails,
    InvoiceFilters,
    InvoiceAmountDetailsForm,
    InvoicePaymentDetailsForm,
    InvoiceFilterDrawer,
    RequisitionForm,
    RevisionList,
    InvoiceStatusDetails,
    BaseInvoiceDetails
  }
})
export default class InvoiceList extends Mixins(HasCursorPaginationMixin, MultiSelectRow, TaxonomyMixin) {
  public invoiceFilter: string = '';
  public isInvoiceStatusUpdate: boolean = false;
  public renderKey: number = 0;
  private invoices: Invoice[] = [];
  private invoiceDetails: Invoice[] = [];
  private isShowAmountDetails: boolean = false;
  private isShowPaymentDetails: boolean = false;
  private isActiveInvoiceDetails: boolean = false;
  private isFilterModelActive: boolean = false;
  private isStatusUpdateActive: boolean = false;
  private currentInvoice: any = [];
  private isInvoice: boolean = false;
  private isActive: boolean = false;
  private isUpdate: boolean = false;
  private isViewRevision: boolean = false;
  private updateAmountCount: boolean = false;
  private allRevision: OrderRevision[] = [];
  private lastestRevision: OrderRevision[] = [];
  private isInvoiceForRevision: boolean = false;
  private isDelivery: boolean = false;
  private currentDelivery: any = '';
  private generating: boolean = false;
  private showGIF: boolean = false;
  private demoTourEntity: string = 'invoice';
  private selectedButton: string = 'Today';
  private queryBuilder: QueryBuilder = new QueryBuilder();
  private recipient: string = '';
  private showGenerateInvoiceModal: boolean = false;
  private GIFs: Array<any> = [
    {
      label: 'Create a invoice',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a invoice',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a invoice',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of invoice';

  get permissionEnum() {
    return PermissionEnum;
  }

  @Watch('isViewRevision')
  getRevision() {
    this.$http.get(this.$api(`/invoices/${this.currentInvoice.id}/revisions`)).then(response => {
      this.allRevision = response.data.revisions;
      this.lastestRevision = response.data.revisions[0];
    });
  }

  @Watch('invoiceDetails')
  async updateRenderKey() {
    // await this.fetchInvoices(null);
    this.renderKey += 1;
  }

  updateInvoice(event: InvoiceRevision) {
    this.$http.get(this.$api(`/invoices/${event.invoice_id}`)).then(response => {
      this.invoices.splice(this.invoices.findIndex((invoice: any) => invoice.id == response.data.invoice.id), 1, response.data.invoice);
    });
  }

  entityUpdated(entity: Invoice): void {
    this.invoices.splice(
      this.invoices.findIndex(type => type.id == entity.id),
      1,
      entity
    );
    this.isActive = false;
  }

  createDelivery(event: any) {
    this.isActive = false;
    this.isDelivery = false;
    this.currentDelivery = true;
  }

  @Watch('invoiceFilter')
  onChangeInvoiceFilter() {
    this.fetchInvoices(null);
    (this.$refs.multipleTable as ElTable).clearSelection();
  }

  @Watch('currentCursor')
  async fetchInvoices(cursor: string | null) {
    this.loading = true;
    try {
      let {data} = await this.$http.get(
          this.$api2(`/invoices${cursor ? '?cursor=' + cursor + '&' : ''}${cursor ? this.invoiceFilter :
            '?' + this.invoiceFilter}`)
          // this.$api2(`/invoices?with=payments&page=${this.pagination.currentPage}${this.invoiceFilter ? '&' + this.invoiceFilter : ''}`)
        )
      ;
      this.setPagination(data);
      this.invoices = data.data;
    } catch (e) {
      this.$notify({title: 'Something Went Wrong!', message: 'Please Try again.', type: 'error'});
    } finally {
      this.loading = false;
    }
  }

  async invoicesObjectUrl() {
    let {data} = await this.$http.post(
      this.$api(`/pdf/invoices${this.invoiceFilter ? '?' + this.invoiceFilter : ''}`),
      {invoice_id: this.allItemSelected ? null : this.selectedRows.map(value => value.id).join(',')},
      {
        responseType: 'blob'
      }
    );
    const blob = await new Blob([data], {type: 'application/pdf'});
    let fileURL = await URL.createObjectURL(blob);
    return fileURL;
  }

  async printInvoices() {
    this.generating = true;
    try {
      await printJS({printable: await this.invoicesObjectUrl()});
    } catch (e) {
      this.$notify({title: 'Something Wrong!', message: 'Please Try again.', type: 'error'});
    } finally {
      this.generating = false;
    }
  }


  async generateAdvanceInvoices() {
    this.generating = true;
    await this.queryBuilder.setParam('recipient', this.recipient);
    if (!this.allItemSelected) {
      await this.queryBuilder.setParam('invoice_id', this.selectedRows.map(invoice => invoice.id));
    }
    try {
      await
        this.$http.post(this.$api(
          `/pdf/advance-invoices${this.queryBuilder.getFilters(true)}&${this.invoiceFilter}`
        ))
      await swal.fire(
        "Report Sent",
        "You will receive the report within a few minutes.",
        "success"
      );
    } catch (e) {
      this.$notify({title: 'Something Wrong!', message: 'Please Try again.', type: 'error'});
    } finally {
      this.generating = false;
      this.showGenerateInvoiceModal = false;
      await this.queryBuilder.setParam('recipient', null);
      await this.queryBuilder.setParam('invoice_id', null);
    }
  }

  checkPromotionOn(promotion: string, promotion_on: string) {
    if (promotion) {
      if (promotion_on != 'all') {
        return promotion_on;
      } else {
        return 'product'
      }
    } else {
      return 'product'
    }
  }

  async downloadInvoices() {
    this.loading = true;
    try {
      let {data, headers} = await
        this.$http.get(
          this.$api(
            `/invoice-bulk-download?type=csv${this.allItemSelected ? '' : '&id=' + this.selectedRows.map(value =>
              value.id)}&${this.invoiceFilter ? this.invoiceFilter : ''}`
          ),
          {
            responseType:
              'arraybuffer'
          }
        )
      const blob = await new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const link = document.createElement("a");
      link.href = await URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({type: 'warning', message: 'Unable to download.', title: 'Something went wrong!'});
    } finally {
      this.loading = false;
    }
  }
}

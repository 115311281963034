






































import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tag, Tooltip} from "element-ui";
@Component({
    components: {
        [Tooltip.name]: Tooltip,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        [Tag.name]: Tag
    }
})
export default class ViewListOfSubscription extends Vue{
    @Prop() isActive!: boolean;
    @Prop() currentOrg!: any;
    private subscriptions: any = [];
    private pagination = {
        currentPage: 1,
        total: 0,
        perPage: 0
    };

    @Watch('isActive')
    fetchSubscription(){
        let isFirstPage = this.pagination.currentPage < 2;
        let url = isFirstPage
            ? `/orgs/${this.currentOrg.id}/subscriptions`
            : `/orgs/${this.currentOrg.id}/subscriptions?page=${this.pagination.currentPage}`;
        this.$http.get(this.$landLordApi(url)).then(response => {
            this.subscriptions = response.data.subscriptions;
            this.pagination.total = response.data.total;
            this.pagination.perPage = response.data.per_page;
        })
    }

    close(){
        this.$emit('update:isActive', false);
    }
}

















import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import {Drawer} from "element-ui";

@Component({
  components: {
    [Drawer.name]: Drawer,
  }
})
export default class FilterDrawerComponent extends Vue{
  @Prop({default: 'title'})
  title!: string;
  @Prop({default: false})
  visible!: boolean
  @Prop({default: 'rtl'})
  direction!: string

  closeBtn() {
    this.$emit('update:visible', false);
    this.$emit('closeBtn');
  }

  handleClose() {
    this.$emit('update:visible', false);
    this.$emit('handleClose');
  }

  resetBtn() {
    this.$emit('resetBtn')
  }

}

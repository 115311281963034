





































































































import {Component, Mixins, Vue, Watch} from 'vue-property-decorator';
import AssetForm from '@/views/inspection/auditable/AssetForm.vue';
import Asset from '@/intefaces/Asset';
import {Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip} from 'element-ui';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import GIFModal from '@/components/tour/GIFModal.vue';
import { Permission as PermissionEnum } from '@/enums/Permission'

@Component({
  components: {
    GIFModal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    AssetForm
  }
})
export default class AssetList extends Mixins(PaginationComponentMixin) {
  private isActiveAsset = false;
  private isUpdate = false;
  private showDeleteModal = false;
  private disableBtn = false;
  private form = {
    name: '',
    qty: 0
  };
  private assets: Asset[] = [];
  private currentAsset: any = [];
  private showGIF: boolean = false;
  private demoTourEntity: string = 'inspectionAsset';
  private GIFs: Array<any> = [
    {
      label: 'Create a asset',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a asset',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a asset',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of asset';

  get permissionEnum () {
    return PermissionEnum;
  }

  onEdit(row: Asset) {
    this.isUpdate = true;
    this.currentAsset = row;
    this.form = {
      name: this.currentAsset.name,
      qty: this.currentAsset.qty
    };
    this.isActiveAsset = true;
  }

  createAsset(asset: Asset) {
    this.isActiveAsset = false;
    this.assets.unshift(asset);
  }

  updateAsset(updatedAsset: Asset) {
    this.isUpdate = false;
    this.assets.splice(
      this.assets.findIndex(asset => asset.id == updatedAsset.id),
      1,
      updatedAsset
    );
    this.isActiveAsset = false;
  }

  deleteAsset() {
    this.disableBtn = true;
    this.$http
      .delete(this.$api(`/assets/${this.currentAsset.id}`))
      .then(response => {
        this.disableBtn = false;
        this.showDeleteModal = false;
        this.assets.splice(
          this.assets.findIndex(asset => asset.id == this.currentAsset.id),
          1
        );
        this.$notify({
          title: 'Success',
          message: 'Successfully Deleted',
          duration: 5000,
          iconClass: 'ni ni-bell-55',
          type: 'success'
        });
      })
      .catch(error => {
        this.disableBtn = false;
        (this.$refs.formValidator as Vue & {
          setErrors: (errors: []) => any;
        }).setErrors(error.response.data.errors);
      });
  }

  @Watch('pagination.currentPage')
  fetchAssets() {
    this.loading = true;
    this.$http.get(this.$api(`/assets?page=${this.pagination.currentPage}`)).then(response => {
      this.assets = response.data.assets;
      this.setPagination(response.data);
      this.loading = false;
    });
  }

  created() {
    this.fetchAssets();
  }
}

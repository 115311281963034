
























































import { Vue, Component, Mixins, Watch, Prop } from 'vue-property-decorator';
import DepartmentType from '@/intefaces/DepartmentType';
//@ts-ignore
import CreateChannelForm from '@/views/department/CreateChannelForm.vue';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import AsyncImage from "@/components/AsyncImage.vue";
import User from "@/intefaces/User";
import MemberAutocomplete from "@/components/resources-autocomplete/MemberAutocomplete.vue";
import Department from "@/intefaces/Department";

@Component({
  components: {MemberAutocomplete, AsyncImage}
})
export default class StaffList extends Mixins(PaginationComponentMixin) {
  public modal: boolean = false
  public loading: boolean = false
  public refresh: boolean = true
  public managers: any = [];
  public addableStaff: number[] = [];

  @Prop({required: true})
  public department!: Department;

  @Watch('modal')
  async onChangeModelState(value: boolean) {
    this.addableStaff = []
    if (value) {
      this.loading = true;
      try {
        this.managers = this.department.users
      } catch (e) {
        this.$notify({title: 'Something went wrong.', message: 'Unable to load staff.', type: "error"});
      } finally {
        this.loading = false;
      }
    }
  }

  onClickRemoveManager(manager: User) {
    this.$conformation({
      title: `Are you sure want to remove "${manager.name}" ?`,
      onAccept: async () => {
        try {
          await this.$http.delete(this.$api(`/users/${manager.id}/outlets/${this.department.id}`))
          this.managers.splice(this.managers.findIndex((value: any) => value.id == manager.id), 1)
          this.$notify({title: 'Successful!', message: 'Staff removed successfully.'})
        } catch (e) {
          this.$notify({title: 'Something went wrong.', message: 'Unable to remove manager.', type: "error"})
        }
      }
    });
  }

  onClickAddManager() {
    this.$conformation({
      title: `Are you sure want to add manager ?`,
      onAccept: async () => {
        try {
          this.addableStaff.forEach((staff: any) => {
            this.$http.post(this.$api(`/users/${staff}/outlets`),
              {
                'department_id': this.department.id
              }).then(response => {
              var staffName = '';
              this.$http.get(this.$api(`/users/${staff}`)).then(response => {
                this.managers.unshift(response.data.user)
                staffName = response.data.user.name;
              })
              this.$notify({title: 'Successful!', message: 'Staff added successfully.'})
            }).catch(error => {
              this.$notify({title: 'Something went wrong.', message: 'Unable to add staff.', type: "warning"})
            });
          })
          this.addableStaff = []
        } catch (e) {
          this.$notify({title: 'Something went wrong.', message: 'Unable to add staff.', type: "warning"})
        }
      }
    });
  }

  onClickModalClose() {
    if (this.addableStaff.length > 0) {
      this.$conformation({
        title: "You have unsaved staff. Are you sure you want to leave?",
        onAccept: async () => {
          this.modal = false
        }
      })
    } else {
      this.modal = false;
    }
  }

  @Watch('managers')
  refreshMemberAutocomplete() {
    this.refresh = false
    this.$nextTick(function () {
      this.refresh = true
    })
  }
}

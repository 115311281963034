
























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tooltip } from 'element-ui';

@Component({
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu
  },
  name: 'TragetViewForm'
})
export default class TragetViewForm extends Vue {
  @Prop() isActiveView!: boolean;
  @Prop() targetProduct!: any;
  private sum = 0;

  get total() {
    this.sum = 0;
    this.targetProduct.forEach((e: any) => {
      this.sum += e.amount;
    });
    return this.sum;
  }

  close() {
    this.$emit('update:isActiveView', false);
  }
}

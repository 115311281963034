





















import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import LazyDropdown from '@/components/LazyDropdown.vue';
import DropdownField from '@/mixins/DropdownField';
import {Invoice} from '@/intefaces/Invoice';

@Component({
  components: {
    LazyDropdown
  }
})
export default class InvoiceAutocomplete extends Mixins(DropdownField) {
  @Prop()
  public buyer_id!: number;
  @Prop()
  public status!: string;
  public entities: Invoice[] = [];
  public multiple!: boolean;

  @Watch('value')
  onChangeValue(value: any) {
      let totalInvoiceAmount = 0;
      this.entities.map((entity: any) => {
        if (value == entity.id) {
          totalInvoiceAmount += entity.remainder;
        }
      });
      this.$emit('totalAmount', totalInvoiceAmount == 0 ? null : totalInvoiceAmount);
  }
}

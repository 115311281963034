
























































































































import {Component, Vue, Watch} from "vue-property-decorator";
import {Popover, Switch, Select, Option} from "element-ui";

@Component({
  components: {
    [Switch.name]: Switch,
    [Popover.name]: Popover,
    [Select.name]: Select,
    [Option.name]: Option,
  }
})

export default class Tracking extends Vue {
  private form: any = {
    selfie_interval: null,
    checkin_limit_per_outlet: null,
    checkin_interval_per_outlet: null
  };
  private selfieInterval = false;
  private checkinLimitPerOutlet = false;
  private checkinIntervalPerOutlet = false;
  private loading = false;

  updateTrackingSettings() {

    this.loading = true;
    this.$http.patch(this.$api('/settings/1'), {
      tracking: {
        selfie_interval: this.selfieInterval ? this.form.selfie_interval : null,
        checkin_limit_per_outlet: this.checkinLimitPerOutlet ? this.form.checkin_limit_per_outlet : null,
        checkin_interval_per_outlet: this.checkinIntervalPerOutlet ? this.form.checkin_interval_per_outlet : null,
      }
    }).then(response => {

      if (response.status == 200) {
        this.updateTrackingNotification(response.status);
      } else {
        this.updateTrackingNotification(response.status);
      }
    }).catch(error => {
      (this.$refs.formValidator as Vue & {
        setErrors: (errors: []) => any;
      }).setErrors(error.response.data.errors);
      this.$notify({
        message: "Please contact your service provider.",
        title: "Something went wrong!",
        type: 'warning'
      })
    }).finally(
      () => (this.loading = false)
    );
  }


  fetchSettings() {
    this.$http.get(this.$api(`/settings`)).then(response => {
      if (response.data.settings.tracking != null) {
        this.form = JSON.parse(response.data.settings.tracking);
        this.selfieInterval = !!this.form.selfie_interval;
        this.checkinLimitPerOutlet = !!this.form.checkin_limit_per_outlet;
        this.checkinIntervalPerOutlet = !!this.form.checkin_interval_per_outlet;
      }
    });
  }

  updateTrackingNotification(status: number) {
    this.$notify({
      title: status == 200 ? 'Success' : 'Error',
      message: status == 200 ? "Settings successfully updated" : "Settings could not be updated",
      duration: 5000,
      iconClass: 'ni ni-bell-55',
      // @ts-ignore
      verticalAlign: 'bottom',
      horizontalAlign: 'right',
      type: 'success'
    });
  }

  created() {
    this.fetchSettings();
  }
}































































































































import {Component, Vue} from 'vue-property-decorator';
import Role from '@/intefaces/Role';
import CreateRole from '@/views/user/role/CreateRole.vue';
import Permission from '@/intefaces/Permission';
import ViewRoleAcl from '@/views/user/role/ViewRoleAcl.vue';
import GIFModal from '@/components/tour/GIFModal.vue';
import { Permission as PermissionEnum } from '@/enums/Permission'

import {Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip, Tag} from 'element-ui';

@Component({
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
    CreateRole,
    ViewRoleAcl,
    GIFModal
  }
})
export default class RoleList extends Vue {
  private roles: Role[] = [];
  private newRole = false;
  private isUpdate = false;
  private showDeleteModal = false;
  private showRoleAcl = false;
  private currentRole: any = [];
  // private permissions: Permission[] = [];
  private loading = false;
  private showGIF: boolean = false;
  private form = {
    name: '',
    label: '',
    permissions: ''
  };
  private pagination = {
    currentPage: 1,
    total: 0,
    perPage: 0
  };
  private demoTourEntity: string = 'role';
  private GIFs: Array<any> = [
    {
      label: 'Create a role',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a role',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a role',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of role';

  get permissionEnum () {
    return PermissionEnum;
  }

  onEdit(row: Role) {
    this.isUpdate = true;
    this.currentRole = row;
    var userPermission: any = [];
    this.currentRole.permissions.forEach((permission: any) => {
      userPermission.push(permission.id);
    });
    this.form = {
      label: this.currentRole.label,
      name: this.currentRole.name,
      permissions: this.currentRole.permissions ? userPermission : ''
    };
    this.newRole = true;
  }

  onDelete(row: Role) {
    this.$http
      .delete(this.$api(`/roles/${this.currentRole.id}`))
      .then(response => {
        this.showDeleteModal = false;
        this.roles.splice(
          this.roles.findIndex(role => role.id == this.currentRole.id),
          1
        );
        this.$notify({
          title: 'Success',
          message: 'Successfully Deleted',
          duration: 5000,
          iconClass: 'ni ni-bell-55',
          type: 'success'
        });
      })
      .catch(error => {
        (this.$refs.formValidator as Vue & {
          setErrors: (errors: []) => any;
        }).setErrors(error.response.data.errors);
      });
  }

  fetchRoles() {
    this.loading = true;
    let isFirstPage = this.pagination.currentPage < 2;
    let url = isFirstPage ? '/roles' : `/roles?page=${this.pagination.currentPage}`;

    this.$http.get(this.$api(url)).then(response => {
      this.roles = response.data.roles;
      this.pagination.total = response.data.total;
      this.pagination.perPage = response.data.per_page;
      this.loading = false;
    });
  }

  roleCreated(role: Role) {
    this.newRole = false;
    this.roles.unshift(role);
  }

  roleUpdated(role: Role) {
    this.isUpdate = false;
    this.newRole = false;
    this.roles.splice(
      this.roles.findIndex(userRole => userRole.id == role.id),
      1,
      role
    );
  }

  created() {
    this.fetchRoles();
  }
}

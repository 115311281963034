var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header border-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-inline"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.getTaxonomy('salesReturn')))]),_c('el-tooltip',{staticClass:"ml-3",attrs:{"content":'Monitor your '+_vm.getTaxonomy('salesReturn'),"placement":"right"}},[_c('i',{staticClass:"fas fa-info-circle",staticStyle:{"color":"grey","font-size":"18px"}})]),_c('el-tooltip',{staticClass:"ml-3",attrs:{"content":"Watch video tutorials","placement":"right"}},[_c('i',{staticClass:"fab fa-youtube",staticStyle:{"color":"grey","font-size":"18px","cursor":"pointer"},on:{"click":function($event){_vm.showGIF = true}}})])],1)])])]),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"table-responsive align-items-center table-flush table-striped",attrs:{"data":_vm.salesReturn,"header-row-class-name":"thead-light"}},[_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header border-0 p-5"},[_c('el-table',{staticClass:"table-responsive align-items-center table-flush table-striped",attrs:{"data":props.row.returned_products}},[_c('el-table-column',{attrs:{"label":"Product","min-width":"100px","prop":"product","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.product.base_product ? row.product.base_product.name : ''))])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Qty","min-width":"100px","prop":"product","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.qty))])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Price","min-width":"100px","prop":"product","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.price))])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Amount","min-width":"100px","prop":"product","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.amount))])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Paid","min-width":"100px","prop":"product","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.paid))])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Reason","min-width":"100px","prop":"product","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.reason))])]}}],null,true)})],1)],1)])]}}])}),_c('el-table-column',{attrs:{"label":"View","min-width":"100px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('a',{staticStyle:{"color":"cornflowerblue"},attrs:{"href":"#!"},on:{"click":function($event){$event.preventDefault();return _vm.onView(row)}}},[_vm._v("View "+_vm._s(row.is_requisition == 1 ? _vm.getTaxonomy('order') : _vm.getTaxonomy('requisition')))])]}}])}),_c('el-table-column',{attrs:{"label":"Return By","min-width":"110px","prop":"creator","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.creator ? row.creator.name : ''))])]}}])}),_c('el-table-column',{attrs:{"label":"Outlet","min-width":"110px","prop":"outlet","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.buyer ? row.buyer.name : '-'))])]}}])}),_c('el-table-column',{attrs:{"label":"Created At","min-width":"110px","prop":"created_at","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.created_at)?_c('b',[_vm._v(_vm._s(_vm._f("datetime")(row.created_at,'ll')))]):_c('b',[_vm._v(" - ")])]}}])})],1),(_vm.pagination.total > _vm.pagination.perPage)?_c('base-pagination',{staticClass:"mt-2",attrs:{"align":"center","total":_vm.pagination.total,"perPage":_vm.pagination.perPage,"disabled":_vm.loading},on:{"input":_vm.fetchSalesReturn},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}}):_vm._e(),_c('order-details',{attrs:{"show-order":_vm.showOrder,"current-order-details":_vm.currentOrderDetails},on:{"update:showOrder":function($event){_vm.showOrder=$event},"update:show-order":function($event){_vm.showOrder=$event}}}),_c('GIFModal',{attrs:{"GIFs":_vm.GIFs,"demoTourEntity":_vm.demoTourEntity,"demoTourTitle":_vm.demoTourTitle,"showGIF":_vm.showGIF},on:{"update:showGIF":function($event){_vm.showGIF=$event},"update:show-g-i-f":function($event){_vm.showGIF=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
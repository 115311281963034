









import {Component, Mixins} from "vue-property-decorator";
import LazyDropdown from "../LazyDropdown.vue";
import DropdownField from "../../mixins/DropdownField";
import CreateChannelForm from "@/views/department/CreateChannelForm.vue";
    @Component({
        components: {
            LazyDropdown,
            CreateChannelForm
        }
    })
    export default class DepartmentTypeLazydropdown extends Mixins(DropdownField) {
    }






















































import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator';
import UnitLazydropdown from "@/components/lazyDropdown/UnitLazydropdown.vue";
import Unit from "@/intefaces/Unit";
import { forEach } from "lodash";
import UnitForm from "@/components/products/UnitForm.vue";

@Component({
  components: {
    UnitLazydropdown,
  }
})
export default class ProductUnitComponent extends Vue {
  @Prop({ default: [] }) productUnits!: any;
  @Prop() product!: any;
  @Prop() isUpdate!: boolean;
  private disabled = true;
  private unitError: boolean = false;
  private loading: boolean = false;
  private isActive: boolean = false;
  private units: Unit[] = []
  private singleUnit = {
    value: '',
    name: '',
  }

  @Watch('singleUnit', {deep: true})
  enableAddButton() {
    if (this.singleUnit.value && this.singleUnit.name) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  }

  singleUnitReset() {
    this.singleUnit.value = '';
    this.singleUnit.name = '';
  }

  deleteUnit(index: number) {
    this.productUnits.splice(index, 1);
  }

  saveProductUnit() {
    if (this.productUnits.length > 0) {
      if (this.productUnits.find((element: any) => element.name == this.singleUnit.name)) {
        this.unitError = true;
      } else {
        this.productUnits.push({...this.singleUnit});
        this.singleUnitReset();
        this.unitError = false;
      }
    } else {
      this.productUnits.push({...this.singleUnit});
      this.singleUnitReset();
    }
  }

  newCreatedEntity(event: Unit) {
    this.units.unshift(event);
  }


  fetchUnit() {
    this.$http.get(this.$api('/units')).then(response => {
      this.units = response.data.units;
    })
  }

  created() {
    this.fetchUnit();
  }
}

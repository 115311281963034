





































//@ts-ignore
import { Vue, Component } from 'vue-property-decorator';
import RouteBreadCrumb from '@/components/argon-core/Breadcrumb/RouteBreadcrumb.vue';

import Route from '@/intefaces/Route';
import ProfileRoutes from '@/router/client-routes/ProfileRoutes';
@Component({
  components: { RouteBreadCrumb }
})
class Profile extends Vue {
  activeTab: number | null = 0;
  showModal: boolean = false;
  routes: Route = ProfileRoutes;
  formErrors: any = {};
  formInputs: any = {};

  get ProfileRoutes() {
    return ProfileRoutes;
  }

  created(): void {
    this.routes.children.forEach((route, index) => {
      if (this.$route.name === route.name) {
        this.activeTab = index;
      }
    });
  }

  setCurrentTab(tab = null) {
    if (tab === null) {
      this.activeTab = 0;
    } else {
      this.activeTab = tab;
    }
  }

  closeAndResetForm() {
    this.showModal = false;
    this.formInputs = {};
    this.formErrors = {};
  }
}

export default Profile;

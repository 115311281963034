


















import { Vue, Component, Prop, Mixins } from 'vue-property-decorator';
import BaseCUModalComponent from '@/mixins/BaseCUModalComponent';
import { AxiosResponse } from 'axios';
import Category from '@/intefaces/Category.js';

@Component({
  components: {}
})
export default class CreateCategoryForm extends Mixins(BaseCUModalComponent) {
  @Prop()
  currentEntity!: Category;

  @Prop({default: () => ({name: ''})})
  form!: {
    name: string;
  };

  formReset(): any {
    this.form.name = '';
    return this.form;
  }

  async afterCreate(response: AxiosResponse<any>): Promise<void> {
    this.$emit('created', response.data.departmentCategory);
  }

  afterUpdate(response: AxiosResponse<any>): void {
    this.$emit('updated', response.data.departmentCategory);
  }

  get createApi(): string {
    return '/department-categories';
  }

  get updateApi(): string {
    return `/department-categories/${this.currentEntity.id}`;
  }
}



































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip, Tag } from 'element-ui';
import OrgInvoice from '@/intefaces/OrgInvoice';

@Component({
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
})
export default class InvoiceDetails extends Vue {
  @Prop() isActive!: boolean;
  @Prop() invoiceDetails!: OrgInvoice;

  close() {
    this.$emit('update:isActive', false);
  }
}

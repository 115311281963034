















































//@ts-ignore
import {Component, Vue} from 'vue-property-decorator';
import RouteBreadCrumb from '@/components/argon-core/Breadcrumb/RouteBreadcrumb.vue';
import {RouteConfig} from "vue-router";
import UserRoutes from "@/router/client-routes/UserRoutes";

@Component({
  components: {RouteBreadCrumb}
})
class User extends Vue {
  activeTab: number | null = 0;
  showModal: boolean = false;
  routes: RouteConfig = UserRoutes;
  formErrors: any = {};
  formInputs: any = {};

  get UserRoutes() {
    return UserRoutes;
  }

  created(): void {
    if (this.routes.children === undefined) return;
    this.routes.children.forEach((route, index) => {
      if (this.$route.name === route.name) {
        this.activeTab = index;
      }
    });
  }

  setCurrentTab(tab = null) {
    if (tab === null) {
      this.activeTab = 0;
    } else {
      this.activeTab = tab;
    }
  }

  closeAndResetForm() {
    this.showModal = false;
    this.formInputs = {};
    this.formErrors = {};
  }
}

export default User;






























































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

const flatPicker = require('vue-flatpickr-component');
import 'flatpickr/dist/flatpickr.css';
import UserLazydropdown from '@/components/lazyDropdown/UserLazydropdown.vue';
import TerritoryLazydropdown from "@/components/lazyDropdown/TerritoryLazydropdown.vue";
import {Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip} from 'element-ui';
import RoutePlan from '@/intefaces/RoutePlan';
import {RoutePlanTimespan} from '@/enums/RoutePlanTimespan.ts';
import Days from '@/data/Days.ts';
import Territory from "@/intefaces/Territory";

@Component({
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    ElTable: Table,
    flatPicker,
    UserLazydropdown,
    TerritoryLazydropdown
  },
  name: 'RoutePlanForm'
})
export default class RoutePlanForm extends Vue {
  @Prop({type: Boolean, default: false})
  public isActive!: boolean;
  @Prop({type: Boolean, default: false})
  public isUpdate!: boolean;
  @Prop() currentRoutePlan!: RoutePlan;
  @Prop({
    default: () => ({
      routeName: '',
      timespans: '',
      user: [],
      days: [],
      expiresAt: '',
      tour_dates: [],
      reporting_time: '',
      territory: '',
      active: true,
      outletList: [],
    })
  })
  form!: {
    routeName: string;
    timespans: string;
    user: any;
    days: any;
    expiresAt: any;
    tour_dates: any;
    reporting_time: string;
    territory: any,
    active: boolean,
    outletList: any,
  };
  private outlets: any[] = [];

  private checkList = [];
  private disableBtn = false;
  private removeUser: boolean = false;
  private listOfAssignedUser: any = [];
  private territories: Territory[] = [];

  get tourDatesArray() {
    return this.form.tour_dates.split(',');
  }

  get timespans() {
    return RoutePlanTimespan;
  }

  get days() {
    return Days;
  }

  @Watch('isUpdate')
  outletList(newVal: any) {
    if (newVal == true) {
      this.$http(this.$api(`/route-plans/${this.currentRoutePlan.id}/outlets`)).then(response => {
        this.outlets = response.data.outlets;
      });
    } else {
      this.outlets = [];
    }
  }

  onChangeTerritory() {
    if (this.isUpdate) {
      this.form.user = '';
    } else {
      this.form.user = [];
    }
    this.listOfAssignedUser = [];
  }

  checkRoutePlanTerritoryUser(userId: number) {
    this.$http.get(this.$api(`/route-plan/territory/${this.form.territory}/user/${userId}`)).then(response => {
      if (response.data.routePlans) {
        response.data.routePlans.forEach((routePlan: RoutePlan) => {
          this.listOfAssignedUser.push(routePlan);
        })
      }
    });
  }

  @Watch('form.user')
  onUserChange(old: any, current: any) {
    if (this.isUpdate) {
      if (this.currentRoutePlan.assigned_to != old) {
        this.checkRoutePlanTerritoryUser(old);
      }
      if (this.listOfAssignedUser.length > 0) {
        this.listOfAssignedUser = [];
      }
    } else {
      if (old.length > 0 && !this.removeUser) {
        if (current.length > 0) {
          this.checkRoutePlanTerritoryUser(old[old.length - 1]);
        } else {
          this.checkRoutePlanTerritoryUser(old[0])
        }
      } else {
        this.removeUser = false;
      }
    }
  }

  @Watch('listOfAssignedUser')
  updateListOfAssignedUser() {
    if (this.listOfAssignedUser.length > 0) {
      this.disableBtn = true;
    } else {
      this.disableBtn = false;
    }
  }

  onRemoveUser(remove: any) {
    this.removeUser = true;
    let index = this.listOfAssignedUser.findIndex((assingedUser: any) => assingedUser.assigned_to === remove);
    if (index != -1) {
      this.listOfAssignedUser.splice(index, 1);
    }
  }

  formReset() {
    this.form.routeName = '';
    this.form.timespans = '';
    this.form.days = [];
    this.form.user = '';
    this.form.expiresAt = '';
    this.form.reporting_time = '';
    this.form.tour_dates = [];
    this.form.territory = '';
    this.form.active = true;
    this.disableBtn = false;
  }

  createRoute() {
    this.disableBtn = true;
    if (this.form.timespans == 'tour') {
      this.form.user.forEach((assignee: any) => {
        this.$http
          .post(this.$api('/route-plans'), {
            assigned_to: assignee,
            name: this.form.routeName,
            days: [],
            expires_at: this.form.expiresAt,
            timespan: this.form.timespans,
            tour_dates: this.tourDatesArray,
            reporting_time: this.form.reporting_time,
            territory_id: this.form.territory,
            active: this.form.active,
          })
          .then(response => {
            this.$notify({
              title: 'Success',
              message: 'Successfully Created',
              duration: 3000,
              iconClass: 'ni ni-bell-55',
              type: 'success'
            });

            this.$http.get(this.$api(`/route-plans/${response.data.routePlan.id}`)).then(responseRoute => {
              this.$emit('created', responseRoute.data.routePlan);
            });
            this.formReset();
          })
          .catch(error => {
            this.disableBtn = false;
            (this.$refs.formValidator as Vue & {
              setErrors: (errors: []) => any;
            }).setErrors(error.response.data.errors);
          });
      });
    } else {
      this.form.user.forEach((assignee: any) => {
        this.$http
          .post(this.$api('/route-plans'), {
            assigned_to: assignee,
            days: this.form.days,
            name: this.form.routeName,
            expires_at: this.form.expiresAt,
            timespan: this.form.timespans,
            tour_dates: [],
            reporting_time: this.form.reporting_time,
            territory_id: this.form.territory,
            active: this.form.active
          })
          .then(response => {
            this.form.outletList.forEach((outlet: any) => {
              this.$http.post(this.$api(`/route-plans/${response.data.routePlan.id}/outlets`), {department_id: outlet});
            });

            this.$notify({
              title: 'Success',
              message: 'Successfully Created',
              duration: 3000,
              iconClass: 'ni ni-bell-55',
              type: 'success'
            });

            this.$http.get(this.$api(`/route-plans/${response.data.routePlan.id}`)).then(responseRoute => {
              this.$emit('created', responseRoute.data.routePlan);
            });
            this.formReset();
          })
          .catch(error => {
            this.disableBtn = false;
            (this.$refs.formValidator as Vue & {
              setErrors: (errors: []) => any;
            }).setErrors(error.response.data.errors);
          });
      });
    }
  }

  updateRoute() {
    this.disableBtn = true;
    if (this.form.timespans == 'tour') {
      this.$http
        .patch(this.$api(`/route-plans/${this.currentRoutePlan.id}`), {
          name: this.form.routeName,
          days: [],
          expires_at: this.form.expiresAt,
          timespan: this.form.timespans,
          assigned_to: this.form.user,
          tour_dates: this.form.tour_dates.split(','),
          reporting_time: this.form.reporting_time,
          territory_id: this.form.territory,
          active: this.form.active
        })
        .then(response => {
          this.$emit('updated', response.data.routePlan);
          this.formReset();
          this.$notify({
            title: 'Success',
            message: 'Successfully Updated',
            duration: 3000,
            iconClass: 'ni ni-bell-55',
            type: 'success'
          });
        })
        .catch(error => {
          this.disableBtn = false;
          (this.$refs.formValidator as Vue & {
            setErrors: (errors: []) => any;
          }).setErrors(error.response.data.errors);
        });
    } else {
      this.$http
        .patch(this.$api(`/route-plans/${this.currentRoutePlan.id}`), {
          name: this.form.routeName,
          expires_at: this.form.expiresAt,
          timespan: this.form.timespans,
          days: this.form.days,
          tour_dates: [],
          assigned_to: this.form.user,
          reporting_time: this.form.reporting_time,
          territory_id: this.form.territory,
          active: this.form.active
        })
        .then(response => {
          this.$notify({
            title: 'Success',
            message: 'Successfully Updated',
            duration: 3000,
            iconClass: 'ni ni-bell-55',
            type: 'success'
          });
          this.$emit('updated', response.data.routePlan);
          this.formReset()
        })
        .catch(error => {
          this.disableBtn = false;
          (this.$refs.formValidator as Vue & {
            setErrors: (errors: []) => any;
          }).setErrors(error.response.data.errors);
        });
    }
  }

  save() {
    this.isUpdate ? this.updateRoute() : this.createRoute();
  }

  close() {
    this.$parent.$emit('update:isActive', false);
    this.$emit('update:isActive', false);
    this.listOfAssignedUser = [];
    this.$emit('update:form', {
      routeName: '',
      timespans: '',
      days: [],
      user: '',
      expiresAt: '',
      tourDates: [],
      reporting_time: '',
      active: true
    });
    this.$emit('update:isUpdate', false);
  }

  fetchTerritory() {
    this.$http.get(this.$api('/territories')).then(response => {
      this.territories = response.data.territories;
    })
  }

  created() {
    this.fetchTerritory();
  }
}

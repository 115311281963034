




















import {Component, Mixins, Prop, Vue} from 'vue-property-decorator';
import Asset from "@/intefaces/Asset";
import BaseCUModalComponentMixin from "@/mixins/BaseCUModalComponent";
import {AxiosResponse} from "axios";

@Component({
    name: 'AssetForm'
})
export default class AssetForm extends Mixins(BaseCUModalComponentMixin){
    @Prop() currentEntity!: Asset;
    @Prop({
        default: () => ({
            name: '',
            qty: 0
        })
    })
    form!: {
        name: string,
        qty: number
    }

    formReset(): any {
        this.form.name = '';
        this.form.qty = 0;
        return this.form;
    }

    get createApi(): string
    {
        return '/assets'
    }

    async afterCreate(response: AxiosResponse<any>): Promise<void> {
        this.$emit('created', response.data.asset);
    }

    get updateApi(): string {
        return `/assets/${this.currentEntity.id}`
    }

    afterUpdate(response: AxiosResponse<any>): void {
        this.$emit('updated', response.data.asset);
    }
}

























import {Component, Mixins, Prop, Vue} from 'vue-property-decorator';
import Unit from "@/intefaces/Unit";
import BaseCUModalComponentMixin from "@/mixins/BaseCUModalComponent";
import {AxiosResponse} from "axios";

@Component({
    name: 'UnitForm'
})

export default class UnitForm extends Mixins(BaseCUModalComponentMixin){
    @Prop({
        default: () => ({
            name: ''
        })
    })
    form !: {name: string}
    @Prop() currentEntity! : Unit;

    formReset(): any {
        this.form.name = '';
        return this.form;
    }

    get createApi(): string
    {
        return '/units'
    }

    async afterCreate(response: AxiosResponse<any>): Promise<void> {
        this.$emit('created', response.data.unit);
    }

    get updateApi(): string {
        return `/units/${this.currentEntity.id}`
    }

    afterUpdate(response: AxiosResponse<any>): void {
        this.$emit('updated', response.data.unit);
    }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header border-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-inline"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Attendance")]),_c('el-tooltip',{staticClass:"ml-3",attrs:{"content":"Manage attendance time limit for check-in and check-out","placement":"right"}},[_c('i',{staticClass:"fas fa-info-circle",staticStyle:{"color":"grey","font-size":"18px"}})])],1)])])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"px-3 py-3"}),_c('div',{staticClass:"list-group list-group-flush"},[_c('div',{staticClass:"list-group-item"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-3"},[_vm._v(" Set time limit for daily "),_c('b',{staticClass:"text-success"},[_vm._v("check-in")]),_vm._v(": ")])]),_c('div',{staticClass:"row my-4"},[_c('div',{staticClass:"col-3"},[_c('base-input',{attrs:{"label":"In Time Start","name":"In Time Start","prepend-icon":"fas fa-clock","rules":{required:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var focus = ref.focus;
var blur = ref.blur;
return _c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{ allowInput: true, enableTime: true, noCalendar: true, dateFormat: 'H:i:ss' }},on:{"on-open":focus,"on-close":blur},model:{value:(_vm.form.in_time_start),callback:function ($$v) {_vm.$set(_vm.form, "in_time_start", $$v)},expression:"form.in_time_start"}})}}],null,true)})],1),_c('div',{staticClass:"offset-1 col-3"},[_c('base-input',{attrs:{"label":"In Time End","name":"In Time End","prepend-icon":"fas fa-clock","rules":{required:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var focus = ref.focus;
var blur = ref.blur;
return _c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{ allowInput: true, enableTime: true, noCalendar: true, dateFormat: 'H:i:ss' }},on:{"on-open":focus,"on-close":blur},model:{value:(_vm.form.in_time_end),callback:function ($$v) {_vm.$set(_vm.form, "in_time_end", $$v)},expression:"form.in_time_end"}})}}],null,true)})],1)])]),_c('div',{staticClass:"list-group-item"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-3"},[_vm._v(" Set time limit for daily "),_c('b',{staticClass:"text-danger"},[_vm._v("check-out")]),_vm._v(": ")])]),_c('div',{staticClass:"row my-4"},[_c('div',{staticClass:"col-3"},[_c('base-input',{attrs:{"label":"Out Time Start","name":"Out Time Start","prepend-icon":"fas fa-clock","rules":{required:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var focus = ref.focus;
var blur = ref.blur;
return _c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{ allowInput: true, enableTime: true, noCalendar: true, dateFormat: 'H:i:ss' }},on:{"on-open":focus,"on-close":blur},model:{value:(_vm.form.out_time_start),callback:function ($$v) {_vm.$set(_vm.form, "out_time_start", $$v)},expression:"form.out_time_start"}})}}],null,true)})],1),_c('div',{staticClass:"offset-1 col-3"},[_c('base-input',{attrs:{"label":"Out Time End","name":"Out Time End","prepend-icon":"fas fa-clock","rules":{required:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var focus = ref.focus;
var blur = ref.blur;
return _c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{ allowInput: true, enableTime: true, noCalendar: true, dateFormat: 'H:i:ss' }},on:{"on-open":focus,"on-close":blur},model:{value:(_vm.form.out_time_end),callback:function ($$v) {_vm.$set(_vm.form, "out_time_end", $$v)},expression:"form.out_time_end"}})}}],null,true)})],1)])])]),_c('div',{staticClass:"row my-5"},[_c('div',{staticClass:"col-2"},[_c('base-button',{staticClass:"mx-4",attrs:{"block":"","loading":_vm.loading,"type":"success"},on:{"click":function($event){return handleSubmit(_vm.updateAttendanceLimits)}}},[_vm._v("Save ")])],1)])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
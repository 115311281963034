








































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Popover, Switch, Select, Option } from "element-ui";
import SingleImageUploader from "@/components/ui/SingleImageUploader.vue";
import store from "@/store";

@Component({
  components: {
    SingleImageUploader,
    [Switch.name]: Switch,
    [Popover.name]: Popover,
    [Select.name]: Select,
    [Option.name]: Option,
  }
})

export default class Company extends Vue {
  private form: any = {
    theme_color: '#3083a7',
    logo: ''
  };
  image: any = '';
  private loading = false;

  async updateCompanySettings() {
    this.loading = true;
    let form = new FormData();
    form.append('logo', this.image);
    form.append('theme_color', this.form.theme_color);
    await this.$http.post(this.$api('/company-settings'),
      this.image ? form : {theme_color: this.form.theme_color}).then(response => {
      if (response.status == 201) {
        store.dispatch('Theme/updateThemeColor', this.form.theme_color);
        store.dispatch('Theme/updateLogoUrl', JSON.parse(response.data.setting.company).logo);
        this.updateCompanyNotification(response.status);
        this.$http.get(this.$api(`/settings`)).then(response => {
          localStorage.setItem('settings', JSON.stringify(response.data.settings));
        });
        this.image = '';
      } else {
        this.updateCompanyNotification(response.status);
      }
    }).catch(error => {
      (this.$refs.formValidator as Vue & {
        setErrors: (errors: []) => any;
      }).setErrors(error.response.data.errors);
      this.$notify({
        message: "Please contact your service provider.",
        title: "Something went wrong!",
        type: 'warning'
      })
    }).finally(
      () => (this.loading = false)
    );
  }


  fetchSettings() {
    this.$http.get(this.$api(`/settings`)).then(response => {
      if ('company' in response.data.settings) {
        if(response.data.settings.company){
          this.form = JSON.parse(response.data.settings.company);
        }
        else {
          this.form = {
            theme_color: '#3083a7',
            logo: ''
          }
        }
      }
    });
  }

  updateCompanyNotification(status: number) {
    this.$notify({
      title: status == 201 ? 'Success' : 'Error',
      message: status == 201 ? `Settings successfully updated` : `Settings could not be updated`,
      duration: 5000,
      iconClass: 'ni ni-bell-55',
      // @ts-ignore
      verticalAlign: 'bottom',
      horizontalAlign: 'right',
      type: status == 201 ? 'success' : 'error'
    });
  }

  created() {
    this.fetchSettings();
  }
}





























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Radio, TabPane, Tabs, ButtonGroup, Button } from 'element-ui';

const auth = namespace('Auth');
@Component({
  components: {
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [ButtonGroup.name]: ButtonGroup,
    [Button.name]: Button
  }
})
export default class PackageSelection extends Vue {
  private schemes: any[] = [];
  private currentTab: string = '';
  private selectedSchemeId: number = 0;
  private requireUserQuota: boolean = false;
  private showUserQuotaDialog: boolean = false;
  private userQuota = null;
  private coupon: string|null = null;
  @Prop()
  selectedPackage!: any;

  async mounted() {
    let self = this;
    let { data } = await this.$http.get(this.$landLordApi('/schemes'),{withCredentials:false});
    data.schemes.forEach(function(value: any, index: number, array: any) {
      switch (value['cycle']) {
        case 1:
          self.extractPackage('Monthly', value);
          break;
        case 3:
          self.extractPackage('Quarterly', value);
          break;
        case 6:
          self.extractPackage('Half Yearly', value);
          break;
        case 12:
          self.extractPackage('Yearly', value);
          break;
        default:
          self.extractPackage(`${value['cycle']} Months`, value);
          break;
      }
    });
  }

  get schemaByCycle(): any {
    return this.schemes.sort((a, b) => a.cycle - b.cycle);
  }

  extractPackage(title: string, schema: any) {
    let index = this.schemes.findIndex(value => value.title == title);
    if (index >= 0) {
      schema.package['price'] = schema.price;
      schema.package['schema_id'] = schema.id;
      schema.package['cycle'] = schema.cycle;
      this.schemes[index].packages.push(schema.package);
    } else {
      schema.package['price'] = schema.price;
      schema.package['schema_id'] = schema.id;
      schema.package['cycle'] = schema.cycle;
      this.schemes.push({ title: title, cycle: schema.cycle, packages: [schema.package] });
    }
  }

  onNext() {
    this.showUserQuotaDialog = false;
    this.selectedPackage.user_quota = this.userQuota;
    this.selectedPackage.scheme_id = this.selectedSchemeId;
    this.selectedPackage.coupon_code = this.coupon;

    this.$emit('next');
  }
  onSelectPackage(pk: any) {
    this.selectedSchemeId = pk.schema_id;
    this.requireUserQuota = pk.cycle !== 1;
  }

}

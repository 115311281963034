







import Vue from "vue";
import {Component, Mixins, Prop} from "vue-property-decorator";
import LazyDropdown from "../LazyDropdown.vue";
import DropdownField from "@/mixins/DropdownField";
import QuestionForm from "@/views/inspection/survey/QuestionForm.vue";
@Component({
  components: {
    LazyDropdown,
    QuestionForm
  }
})
export default class QuestionLazyDropdown extends Mixins(DropdownField) {
  @Prop({default: 'questions'}) endPoint!: string;
  @Prop({default: true}) addButton!: boolean;
  @Prop({default: false}) collapseTags!: boolean;
  @Prop({default: 'title'}) itemText!: string;
}





















































































import { Component, Mixins, Watch } from 'vue-property-decorator'; // @ts-ignore
// @ts-ignore
import PieChart from '@/components/argon-core/Charts/PieChart';
// @ts-ignore
import { Charts } from '@/components/argon-core/Charts/config';
import DashboardStatsCard from '@/components/DashboardStatsCard.vue';
import 'flatpickr/dist/flatpickr.css';
import TaxonomyMixin from '@/mixins/TaxonomyComponent';
import TerritoryTypeAutocomplete from '@/components/resources-autocomplete/TerritoryTypeAutocomplete.vue';
import TerritoryAutocomplete from '@/components/resources-autocomplete/TerritoryAutocomplete.vue';
import DateRange from '@/components/DateRange.vue';
import { QueryBuilder } from '@/classes/QueryBuilder';
import { ButtonGroup, Button } from "element-ui";
import moment from "moment";
import MemberAutocomplete from "@/components/resources-autocomplete/MemberAutocomplete.vue";
import DailyReport from "@/intefaces/DailyReport";
import OutletVisit from "@/views/dashboard/TodaysData/OutletVisit.vue";
import NewOutlet from "@/views/dashboard/TodaysData/NewOutlet.vue";
import DeliveryLeadTime from "@/views/dashboard/TodaysData/DeliveryLeadTime.vue";
import OrderCancelledAmount from "@/views/dashboard/TodaysData/OrderCancelledAmount.vue";
import InvoiceAmount from "@/views/dashboard/TodaysData/InvoiceAmount.vue";
import InvoiceCreated from "@/views/dashboard/TodaysData/InvoiceCreated.vue";
import AverageInvoice from "@/views/dashboard/TodaysData/AverageInvoice.vue";
import LinePerCall from "@/views/dashboard/TodaysData/LinePerCall.vue";
import TargetVsAchievement from "@/views/dashboard/TodaysData/Charts/TargetVsAchievementRevenue.vue";
import InvoiceVsDelivered from "@/views/dashboard/TodaysData/Charts/InvoiceVsDelivered.vue";
import TargetVsAchievementVisit from "@/views/dashboard/TodaysData/Charts/TargetVsAchievementVisit.vue";
import TargetVsAchievementRevenue from "@/views/dashboard/TodaysData/Charts/TargetVsAchievementRevenue.vue";
import InvoiceVsShopVisit from "@/views/dashboard/TodaysData/Charts/InvoiceVsShopVisit.vue";


const flatPicker = require('vue-flatpickr-component');

@Component({
  components: {
    InvoiceVsShopVisit,
    TargetVsAchievementRevenue,
    TargetVsAchievementVisit,
    InvoiceVsDelivered,
    TargetVsAchievement,
    LinePerCall,
    AverageInvoice,
    InvoiceCreated,
    InvoiceAmount,
    OrderCancelledAmount,
    DeliveryLeadTime,
    NewOutlet,
    OutletVisit,
    MemberAutocomplete,
    TerritoryAutocomplete,
    TerritoryTypeAutocomplete,
    PieChart,
    DashboardStatsCard,
    DateRange,
    flatPicker,
    [Button.name]: Button,
    [ButtonGroup.name]: ButtonGroup
  },
})
export default class TodaysData extends Mixins(TaxonomyMixin) {
  private dailyReports: DailyReport = {
    invoiceCreated: 0,
    invoiceDelivered: 0,
    invoiceAmount: 0,
    invoiceOrderRatio: 0,
    linePerCall: 0,
    revenueTarget: 0,
    visitTarget: 0,
    achievedRevenueTarget: 0,
    achievedVisitTarget: 0,
    deliveryLeadTime: 0,
    outletsCreated: 0,
    outletsVisited: 0,
    ordersCancelled: 0,
    avgInvoice: 0,
    orderCancelledAmount: 0,
  };
  private selectedDate: any = '';
  private selectedDateRange: string = moment().format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD');
  private selectedTerritoryType: number | null = null;
  private selectedTerritory: number | null = null;
  private selectedUser: number | null = null;
  private selectedButton: string = 'Today';
  private error: string = '';
  private isFetchData: boolean = false;
  private loading: boolean = false;
  private queryBuilder: QueryBuilder = new QueryBuilder();


  private InvoicePieChart: any = {
    chartData: {
      labels: ['Invoice', 'Delivery'],
      datasets: [
        {
          data: [],
          backgroundColor: [Charts.colors.theme['success'], Charts.colors.theme['info']],
          label: 'Dataset 1',
        },
      ],
    },
    extraOptions: {
      responsive: true,
      legend: {
        position: 'top',
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
    },
  };

  private RevenuePieChart: any = {
    chartData: {
      labels: ['Revenue Target', 'Achievement'],
      datasets: [
        {
          data: [],
          backgroundColor: [Charts.colors.theme['warning'], Charts.colors.theme['primary']],
          label: 'Dataset 1',
        },
      ],
    },
    extraOptions: {
      responsive: true,
      legend: {
        position: 'top',
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
    },
  };

  private visitTarget: any = {
    chartData: {
      labels: ['Visit Target', 'Achievement'],
      datasets: [
        {
          data: [],
          backgroundColor: [Charts.colors.theme['primary'], Charts.colors.theme['success']],
          label: 'Dataset 1',
        },
      ],
    },
    extraOptions: {
      responsive: true,
      legend: {
        position: 'top',
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
    },
  };

  private invoiceVsShopVisit: any = {
    chartData: {
      labels: ['No. of Invoice', 'Shop Visited'],
      datasets: [
        {
          data: [],
          backgroundColor: [Charts.colors.theme['primary'], Charts.colors.theme['success']],
          label: 'Dataset 1',
        },
      ],
    },
    extraOptions: {
      responsive: true,
      legend: {
        position: 'top',
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
    },
  };

  // get avgInvoice() {
  //   if (this.dailyReports.invoiceCreated != 0) {
  //     return this.dailyReports.invoiceAmount / this.dailyReports.invoiceCreated;
  //   } else {
  //     return 0;
  //   }
  // }

  get getCurrency() {
    return this.$store.getters['Settings/getCurrency'];
  }

  @Watch('queryBuilder.watch')
  fetchTodyasData() {
    this.loading = true;
    this.invoiceVsShopVisit.chartData.datasets[0].data = [];
    this.InvoicePieChart.chartData.datasets[0].data = [];
    this.visitTarget.chartData.datasets[0].data = [];
    this.RevenuePieChart.chartData.datasets[0].data = [];
    this.isFetchData = false;
    // this.$http
    //   .get(this.$api(`/daily-reports${this.queryBuilder.getFilters(true)}`))
    //   .then((response) => {
    //     this.dailyReports = response.data.dailyReports;
    //     this.InvoicePieChart.chartData.datasets[0].data.push(this.dailyReports.invoiceAmount, this.dailyReports.invoiceDelivered);
    //     this.invoiceVsShopVisit.chartData.datasets[0].data.push(this.dailyReports.invoiceCreated, this.dailyReports.outletsVisited);
    //     this.visitTarget.chartData.datasets[0].data.push(this.dailyReports.visitTarget, this.dailyReports.achievedVisitTarget);
    //     this.RevenuePieChart.chartData.datasets[0].data.push(this.dailyReports.achievedRevenueTarget, this.dailyReports.revenueTarget);
    //     this.isFetchData = true;
    //   })
    //   .catch((error) => {
    //     this.isFetchData = true;
    //     this.error = error.response.data.errors.manager[0];
    //   });
  }

  @Watch('selectedTerritoryType')
  onChangeTerritoryType(value: number) {
  }

  @Watch('selectedTerritory')
  onChangeTerritory(value: number) {
    this.queryBuilder.addListener('territory_id', value);
  }

  @Watch('selectedUser')
  onChangeUser(value: number) {
    this.queryBuilder.addListener('user_id', value);
  }

  @Watch('selectedDateRange')
  onChangeDateRange(value: string) {
    this.queryBuilder.addListener('range', value);
  }

  todaysDate() {
    var currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
    return currentDate;
  }

  updateDateRange(range: string) {
    this.selectedButton = range;
    if (range === 'Today') {
      this.selectedDateRange = moment().format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD');
    } else if (range === 'WTD') {
      this.selectedDateRange = moment().day(-1).format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD');
    } else if (range === 'MTD') {
      this.selectedDateRange = moment().startOf('month').format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD');
    } else if (range === 'QTD') {
      this.selectedDateRange = moment().startOf('quarter').format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD');
    } else if (range === 'YTD') {
      this.selectedDateRange = moment().startOf('year').format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD');
    }
  }

  created() {
    this.fetchTodyasData();
  }
}

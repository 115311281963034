


























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseTable from '@/components/argon-core/BaseTable.vue';
import { Table, TableColumn, Tag } from 'element-ui';

@Component({
  components: { [Table.name]: Table, [TableColumn.name]: TableColumn, BaseTable, [Tag.name]: Tag }
})
export default class OutletsModal extends Vue {
  @Prop()
  outlets!: any;

  @Prop()
  showOutletModal!: any;

  HideOutletModal() {
    this.$emit('update:showOutletModal', false);
  }
}
